import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  TextField,
  Typography,
  Stack,
  Divider,
  Snackbar,
  Link,
} from "@mui/material";
import GoogleIcon from "@mui/icons-material/Google"; // Ensure you have @mui/icons-material installed
import { useAuth } from "./Auth"; // Adjust the import path as necessary
import Alert from "@mui/material/Alert";
import ChangePasswordDialog from "./ChangePasswordDialog";

const LoginPopup = ({ open, onClose }) => {
  const { currentUser, signInWithGoogle, signInWithEmail, signUpWithEmail } =
    useAuth();
  const [isSignUp, setIsSignUp] = useState(false); // Toggle between Login and Sign-Up
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [showChangePasswordDialog, setShowChangePasswordDialog] =
    useState(false);
  const [failedLoginAttempts, setFailedLoginAttempts] = useState(0);

  const showSnackbar = (message) => {
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };
  const handleLogin = async () => {
    try {
      await signInWithGoogle();
      onClose(); // Close the popup after successful login
    } catch (error) {
      console.error("Failed to sign in:", error.message);
    }
  };

  const handleLoginSignUp = async () => {
    try {
      if (isSignUp) {
        await signUpWithEmail(email, password);
        showSnackbar("Sign-up successful! You can now log in.");
      } else {
        await signInWithEmail(email, password);
        showSnackbar("Login successful!");
      }
      onClose(); // Close the dialog on successful sign in or sign up
    } catch (error) {
      // Handling specific Firebase authentication errors
      let errorMessage = "Authentication Failed: An unexpected error occurred.";
      switch (error.code) {
        case "auth/email-already-in-use":
          errorMessage = "An account already exists with this email.";
          break;
        case "auth/invalid-credential":
          errorMessage = "Wrong username and/or password.";
          break;
        // Add more error cases as needed
        default:
          errorMessage += ` ${error.message}`;
      }
      showSnackbar(errorMessage, "error");
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Login Required</DialogTitle>
      <DialogContent>
        <Typography variant="body1" gutterBottom>
          You must be logged in to apply. Please log in to continue.
        </Typography>
        <Stack spacing={2} mt={2}>
          <Button
            startIcon={<GoogleIcon />}
            variant="contained"
            onClick={handleLogin}
            fullWidth
          >
            Log in with Google
          </Button>
          <Divider>Or</Divider>
          <TextField
            label="Email"
            variant="outlined"
            fullWidth
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <TextField
            label="Password"
            variant="outlined"
            type="password"
            fullWidth
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            sx={{ py: { xs: 0, sm: 0 } }}
          />
          <Typography textAlign="center" sx={{ mt: 2 }}>
            <Link
              href="#"
              onClick={(e) => {
                e.preventDefault();
                setShowChangePasswordDialog(true);
              }}
              style={{ cursor: "pointer" }}
            >
              Forgot password?
            </Link>
          </Typography>
          <Button
            variant="outlined"
            onClick={() => setIsSignUp(!isSignUp)}
            fullWidth
            sx={{ fontSize: { xs: 12, sm: 12 } }}
          >
            {isSignUp
              ? "Already have an account? Sign In"
              : "Don't have an account? Sign Up"}
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleLoginSignUp}
            fullWidth
          >
            {isSignUp ? "Sign Up" : "Sign In"} with Email
          </Button>
        </Stack>
      </DialogContent>
      <ChangePasswordDialog
        open={showChangePasswordDialog}
        handleClose={() => setShowChangePasswordDialog(false)}
      />
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        message={snackbarMessage}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity="error"
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Dialog>
  );
};

export default LoginPopup;
