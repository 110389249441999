import React, { memo } from "react";
import { Popover, Button, IconButton } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import FieldComponent from "./FieldComponent";

const FilterPopover = ({ headCell, onFilterChange, onClose }) => {
  // This component would require state to manage the checkboxes and possibly the Popover's open/close state
  const applyFilter = () => {
    onFilterChange(headCell.id);
    headCell.filter.forClose();
  };

  return (
    <Popover
      id={headCell?.filter?.id}
      open={headCell.filter?.open}
      anchorEl={headCell.filter?.anchorEl}
      onClose={headCell.filter?.forClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
    >
      <IconButton onClick={headCell.filter?.forClose}>
        <ClearIcon></ClearIcon>
      </IconButton>
      <div style={{ padding: 20 }}>
        <FieldComponent
          name={`Min ${headCell.filter?.name}`}
          blur={headCell.filter?.minHook}
          id={headCell?.filter?.key}
        ></FieldComponent>
        <FieldComponent
          name={`Max ${headCell.filter?.name}`}
          blur={headCell.filter?.maxHook}
          id={headCell?.filter?.key}
        ></FieldComponent>
        <div style={{ marginTop: 10 }}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => applyFilter(headCell.id)}
          >
            Apply Filter
          </Button>
        </div>
      </div>
    </Popover>
  );
};

export default memo(FilterPopover);
