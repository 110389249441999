// ApplicationHandling.js

import {
  getDatabase,
  ref,
  set,
  get,
  query,
  orderByChild,
  equalTo,
  push,
} from "firebase/database";

export const submitFormHandling = async (
  franchiseName,
  answers,
  currentUser,
  setAppliedFranchises
) => {
  const db = getDatabase(); // Get a reference to the database service
  try {
    const userApplicationRef = ref(db, `applications/${currentUser.uid}`);
    await set(userApplicationRef, {
      userId: currentUser.uid, // Assuming you have the user's UID
      answers,
      createdAt: new Date().toISOString(), // Store the creation time as an ISO string
    });
    console.log("Form data saved successfully");
    onFormSubmitSuccess(franchiseName, currentUser, setAppliedFranchises);
  } catch (error) {
    console.error("Error saving form data:", error);
  }
};

export const AutoApplyHandling = async (
  franchiseName,
  currentUser,
  setAppliedFranchises,
  setLoginPopupOpen,
  setCurrentFranchise,
  setFormPopupOpen
) => {
  if (!currentUser) {
    console.log("No user logged in, prompting for login.");
    setLoginPopupOpen(true);
    return;
  }

  const db = getDatabase();
  const userAppliedFranchisesRef = ref(
    db,
    `appliedFranchises/${currentUser.uid}`
  );

  try {
    const snapshot = await get(userAppliedFranchisesRef);
    let appliedFranchises = snapshot.exists() ? snapshot.val() : [];

    if (!appliedFranchises.includes(franchiseName)) {
      const applicationSubmitted = await checkIfApplicationSubmitted(
        currentUser,
        franchiseName
      );

      if (!applicationSubmitted) {
        setCurrentFranchise(franchiseName);
        setFormPopupOpen(true);
      } else {
        appendFranchiseNameToApplied(
          currentUser,
          franchiseName,
          setAppliedFranchises
        );
      }
    }
  } catch (error) {
    console.error("Error handling auto apply:", error);
  }
};

const onFormSubmitSuccess = (
  franchiseName,
  currentUser,
  setAppliedFranchises
) => {
  appendFranchiseNameToApplied(
    currentUser,
    franchiseName,
    setAppliedFranchises
  );
};

export const fetchAppliedFranchises = async (currentUser) => {
  if (!currentUser) return [];
  const db = getDatabase();
  const userAppliedFranchisesRef = ref(
    db,
    `appliedFranchises/${currentUser.uid}`
  );
  try {
    const snapshot = await get(userAppliedFranchisesRef);
    return snapshot.exists() ? snapshot.val() : [];
  } catch (error) {
    console.error("Error fetching applied franchises:", error);
    return [];
  }
};

export const appendFranchiseNameToApplied = async (
  currentUser,
  franchiseName,
  setAppliedFranchises
) => {
  const postURL =
    "https://us-central1-franchise-5bd11.cloudfunctions.net/app/send-email";
  const contactEmail = "contact@FranchiseNow.me";
  const db = getDatabase();
  const userAppliedFranchisesRef = ref(
    db,
    `appliedFranchises/${currentUser.uid}`
  );
  try {
    const snapshot = await get(userAppliedFranchisesRef);
    let appliedFranchises = snapshot.exists() ? snapshot.val() : [];
    if (!appliedFranchises.includes(franchiseName)) {
      appliedFranchises.push(franchiseName);
      await set(userAppliedFranchisesRef, appliedFranchises);
      console.log(`Added ${franchiseName} to applied franchises.`);
      setAppliedFranchises(appliedFranchises);
      const emailData = {
        to: [currentUser.email, contactEmail],
        subject: `Application for ${franchiseName} Submitted`,
        text: `Hi there!\n\nThis is to confirm your Auto Apply Application has been submitted for ${franchiseName}. The FranchiseNow team will be in touch with any potential updates`,
      };

      fetch(postURL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(emailData),
      })
        .then((response) => {
          if (!response.ok) throw new Error(response.statusText);
          return response.text(); // Use .text() instead of .json() if the response might not be JSON
        })
        .then((data) => {
          console.log("Email sent successfully:");
        })
        .catch((error) => {
          console.error("Error sending email:", error);
        });
      return;
    }
    return appliedFranchises; // Return the original list if not updated
  } catch (error) {
    console.error(
      "Error appending franchise name to applied franchises:",
      error
    );
    return null; // Or handle as needed
  }
};

export const checkIfApplicationSubmitted = async (
  currentUser,
  franchiseName
) => {
  const db = getDatabase();
  const userApplicationsRef = ref(db, `applications/${currentUser.uid}`);
  const snapshot = await get(userApplicationsRef);

  if (snapshot.exists()) {
    console.log(`User has already filled out an application.`, snapshot.val());
    return true;
  } else {
    return false;
  }
};
