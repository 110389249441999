import React from 'react';
import { Box, Typography } from '@mui/material';

const cx = (...classNames) =>
  classNames.filter(Boolean).join(' ');

export default function Label(props) {
  const color = {
    green: { text: '#065f46', bg: '#d1fae5' },
    blue: { text: '#2563eb', bg: '#dbeafe' },
    orange: { text: '#c2410c', bg: '#ffedd5' },
    purple: { text: '#6b21a8', bg: '#f3e8ff' },
    pink: { text: '#db2777', bg: '#fce7f3' },
  };

  const textColor = color[props.color]?.text || color.pink.text;
  const bgColor = color[props.color]?.bg || color.pink.bg;
  const margin = props.nomargin;

  if (props.pill) {
    return (
      <Box
        component="div"
        sx={{
          display: 'inline-flex',
          alignItems: 'center',
          justifyContent: 'center',
          fontWeight: 'bold',
          px: 2,
          height: 24,
          fontSize: '0.875rem',
          borderRadius: '9999px',
          bgcolor: bgColor,
          color: textColor,
          dark: {
            bgcolor: '#1f2937',
            color: '#d1d5db',
          },
        }}
      >
        {props.children}
      </Box>
    );
  }

  return (
    <Typography
      component="span"
      variant="body2"
      sx={{
        display: 'inline-block',
        fontSize: '0.75rem',
        fontWeight: 'medium',
        letterSpacing: '0.1em',
        textTransform: 'uppercase',
        mt: margin ? 0 : 2,
        color: textColor,
      }}
    >
      {props.children}
    </Typography>
  );
}
