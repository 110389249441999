import React, { useState, useEffect } from "react";
import { Typography, Grid, Box, Button } from "@mui/material";
import { getRecordBySlug } from "../utils/insightData";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import SpotlightCard from "./Spotlight";
import { useParams, useNavigate } from "react-router-dom"; // Assuming you're using React Router
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import CircleRoundedIcon from "@mui/icons-material/CircleRounded";
import UTurnLeftIcon from "@mui/icons-material/UTurnLeft";

const InsightComponent = () => {
  const { id } = useParams();
  const [record, setRecord] = useState(null);
  const navigate = useNavigate();

  const handleNavigate = () => {
    navigate("/insight");
  };

  useEffect(() => {
    const fetchedRecord = getRecordBySlug(id);
    setRecord(fetchedRecord);
  }, [id]);

  if (!record) {
    return <div>Loading...</div>;
  }

  return (
    <Box
      sx={{
        padding: { xs: 2, sm: 4, md: 1 },
        maxWidth: { xs: "100%", sm: "1200px", md: "1700px" },
        margin: "0 auto",
      }}
    >
      <Box
        display="flex"
        flexDirection={{ xs: "column", sm: "row" }}
        alignItems="center"
        mt={4}
        mb={4}
        sx={{ gap: { xs: 2, sm: 4 } }}
      >
        <img
          src={record.pageData.pic}
          alt={record.pageData.title}
          style={{
            maxWidth: "100%",
            height: "auto",
            maxHeight: "150px",
            marginRight: "16px",
            marginBottom: 0,
          }}
        />
        <Typography
          variant="h3"
          fontWeight="bold"
          sx={{
            marginRight: { xs: 0, sm: "16px" },
            textAlign: { xs: "center", sm: "left" },
          }}
        >
          {record.pageData.title}
        </Typography>
        <Typography>
          <strong>Initial Investment:</strong>{" "}
          {record.pageData.initialInvestment}
        </Typography>
        <Typography>
          <strong>Franchisee Fee:</strong> {record.pageData.franchiseeFee}
        </Typography>
        <Typography>
          <strong>Royalty Rate:</strong> {record.pageData.royaltyRate}
        </Typography>
        <Box
          ml={{ sm: "auto" }}
          display="flex"
          flexDirection={{ xs: "column", sm: "row" }}
          sx={{ gap: { xs: 1, sm: 2 } }}
        >
          {/* <Button
            variant="outlined"
            color="primary"
            sx={{
              borderColor: "#00A6D6",
              color: "#00A6D6",
              borderRadius: "20px",
              textTransform: "none",
              marginRight: { xs: 0, sm: "8px" },
              padding: "8px 16px",
              width: { xs: "100%", sm: "auto" },
            }}
            onClick={() => (window.location.href = record.pageData.application)}
          >
            Franchisee application
          </Button> */}
          <Button
            variant="contained"
            color="primary"
            onClick={handleNavigate}
            sx={{
              backgroundColor: "#00A6D6",
              color: "#FFFFFF",
              borderRadius: "20px",
              textTransform: "none",
              padding: "8px 16px",
              display: "flex",
              alignItems: "center",
              width: { xs: "100%", sm: "auto" },
            }}
          >
            View all insights
            <UTurnLeftIcon sx={{ ml: 1, transform: "rotate(90deg)" }} />
          </Button>
        </Box>
      </Box>
      <Typography
        variant="body1"
        color="textSecondary"
        paragraph
        sx={{ textAlign: "justify" }}
      >
        {record.pageData.description}
      </Typography>
      <Box textAlign="left" mb={4}>
        <Typography variant="h3" fontWeight="bold">
          Key Trends
        </Typography>
      </Box>
      {record.keyTrends.map((trend, index) => (
        <Box key={index} mb={5}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Box display="flex" ml={{ xs: 0 }}>
                <CheckCircleRoundedIcon
                  sx={{ mr: 2, color: "#097969", fontSize: "2rem" }} // Green circle with checkmark
                />
                <Typography
                  variant="body1"
                  color="textSecondary"
                  sx={{ fontSize: "1.25rem" }}
                  dangerouslySetInnerHTML={{ __html: trend.leftDescription }}
                />
              </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box display="flex">
                <CircleRoundedIcon
                  sx={{
                    ml: 1,
                    mr: 2,
                    mt: 1,
                    color: "black",
                    fontSize: "0.7rem",
                  }} // Solid gray circle
                />
                <Typography
                  variant="body1"
                  color="textSecondary"
                  sx={{ fontSize: "1.25rem" }}
                  dangerouslySetInnerHTML={{ __html: trend.rightDescription }}
                />
              </Box>
            </Grid>
          </Grid>
        </Box>
      ))}
      <SpotlightCard data={record} />
    </Box>
  );
};

export default InsightComponent;
