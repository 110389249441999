import { useEffect, useState } from "react";
import { getDatabase, ref, onValue } from "firebase/database";
import Fuse from "fuse.js";

const useFetchReviews = (initialQuery = "") => {
  const [reviews, setReviews] = useState([]);
  const [searchQuery, setSearchQuery] = useState(initialQuery);
  const [sortDirection, setSortDirection] = useState("desc"); // Default to descending
  const [filteredReviews, setFilteredReviews] = useState([]);

  // Function to sort reviews
  const sortReviews = (reviewsToSort) => {
    return reviewsToSort.sort((a, b) => {
      return sortDirection === "asc"
        ? a.overallScore - b.overallScore
        : b.overallScore - a.overallScore;
    });
  };

  useEffect(() => {
    const db = getDatabase();
    const reviewsRef = ref(db, "franchiseReviews");

    onValue(reviewsRef, (snapshot) => {
      const reviewsData = snapshot.val();
      const loadedReviews = [];

      for (const key in reviewsData) {
        loadedReviews.push({
          id: key,
          ...reviewsData[key],
        });
      }

      const sortedReviews = sortReviews(loadedReviews);
      setReviews(sortedReviews);
      // Perform initial filtering
      filterReviews(sortedReviews, searchQuery);
    });

    return () => {};
  }, [sortDirection]); // Re-run this effect when sortDirection changes

  useEffect(() => {
    // Filter reviews whenever the search query changes
    filterReviews(reviews, searchQuery);
  }, [searchQuery, reviews]);

  const filterReviews = (reviews, query) => {
    let result = reviews;
    if (query.trim()) {
      const fuse = new Fuse(reviews, {
        keys: ["franchisorName"],
        threshold: 0.3,
      });
      result = fuse.search(query).map((item) => item.item);
    }
    // Sort after filtering
    setFilteredReviews(sortReviews(result));
  };

  return {
    reviews: filteredReviews,
    setSearchQuery,
    setSortDirection,
    sortDirection,
  };
};
export default useFetchReviews;
