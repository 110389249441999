import React, { useState } from "react";
import {
  useTheme,
  useMediaQuery,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Typography,
  Checkbox,
  FormControlLabel,
  Link as MUILink,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import Grid from "@mui/material/Grid";
import CardContent from "@mui/material/CardContent";
import Avatar from "@mui/material/Avatar";
import FastfoodIcon from "@mui/icons-material/Fastfood";
import BusinessCenterIcon from "@mui/icons-material/BusinessCenter";
import HotelIcon from "@mui/icons-material/Hotel";
import LocalHospitalIcon from "@mui/icons-material/LocalHospital";
import StoreIcon from "@mui/icons-material/Store";
import BuildIcon from "@mui/icons-material/Build";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import BeautyIcon from "@mui/icons-material/Face";
import SportsIcon from "@mui/icons-material/SportsSoccer";
import ChildCareIcon from "@mui/icons-material/ChildCare";
import PetsIcon from "@mui/icons-material/Pets";
import ComputerIcon from "@mui/icons-material/Computer";

const Home = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isLaptop = useMediaQuery(theme.breakpoints.between("sm", "md"));
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  const navigate = useNavigate();

  // State to manage modal visibility
  const [open, setOpen] = useState(false);
  const [selectedIndustry, setSelectedIndustry] = useState([]);
  const [investmentLevel, setInvestmentLevel] = useState("");

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  // Handle checkbox toggle for industry selection
  const handleIndustryToggle = (industry) => {
    setSelectedIndustry((prevSelected) =>
      prevSelected.includes(industry)
        ? prevSelected.filter((item) => item !== industry)
        : [...prevSelected, industry]
    );
  };

  const handleInvestmentLevelChange = (event) =>
    setInvestmentLevel(event.target.value);

  const handleSubmit = () => {
    const finalSelectedIndustry =
      selectedIndustry.length === 0
        ? industries.map((industry) => industry.name)
        : selectedIndustry;

    const selectedFilters = {
      industries: finalSelectedIndustry,
      maxInvestment: investmentLevel,
    };

    // Pass the selected filters to the all-franchises page
    navigate("/all-franchises", {
      state: selectedFilters, // Pass the filters using state
    });
    handleClose(); // Close the modal after submitting
  };

  const industries = [
    { name: "Food & Beverage", icon: <FastfoodIcon /> },
    { name: "Professional Services", icon: <BusinessCenterIcon /> },
    { name: "Lodging & Leisure", icon: <HotelIcon /> },
    { name: "Health & Wellness", icon: <LocalHospitalIcon /> },
    { name: "Retail", icon: <StoreIcon /> },
    { name: "Home, Cleaning, and Repair", icon: <BuildIcon /> },
    { name: "Auto & Travel", icon: <DirectionsCarIcon /> },
    { name: "Hair & Beauty", icon: <BeautyIcon /> },
    { name: "Sports & Entertainment", icon: <SportsIcon /> },
    { name: "Childrens", icon: <ChildCareIcon /> },
    { name: "Pets", icon: <PetsIcon /> },
    { name: "Tech & IT", icon: <ComputerIcon /> },
  ];

  const getFontSize = () => {
    if (isMobile) return 18;
    if (isLaptop) return 24;
    if (isDesktop) return 36;
    return 18;
  };

  const getShapeSize = () => {
    if (isMobile) return 150;
    if (isLaptop) return 250;
    if (isDesktop) return 350;
    return 18;
  };

  return (
    <>
      {/* Main Home Content */}
      <div
        style={{
          height: isMobile ? "120vh" : "80vh",
          marginTop: isMobile ? "5vh" : "5vh",
          marginBottom: "2vh",
        }}
      >
        <Grid
          container
          spacing={4}
          justifyContent="center"
          style={{ background: "#f4f4f4" }}
        >
          {/* Existing content for franchise-related sections */}
          <Grid item xs={12} sm={6} md={5}>
            <Typography
              variant="h4"
              align="center"
              style={{
                color: "#0098dc",
                marginBottom: "20px",
                marginTop: "10px",
                fontWeight: 700,
                fontSize: 60,
              }}
            >
              Want to be a Franchisee?
            </Typography>
            <Typography
              variant="h5"
              align="center"
              style={{
                color: "#0098dc",
                marginBottom: "20px",
                marginTop: "10px",
                fontWeight: 700,
                fontSize: 30,
              }}
            >
              Let's get you Franchable today
            </Typography>
            <CardContent style={{ textAlign: "center" }}>
              <Avatar
                style={{
                  margin: "0 auto",
                  backgroundColor: "#EBF8FB",
                  width: getShapeSize(),
                  height: getShapeSize(),
                  overflow: "hidden",
                }}
              >
                <img
                  src="/Splash_Image1.jpg"
                  alt="Business Owner"
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                    filter: "grayscale(100%)",
                  }}
                />
              </Avatar>
              <Button
                variant="contained"
                fullWidth={isMobile ? false : true}
                style={{
                  backgroundColor: "#0098dc",
                  color: "#fff",
                  marginBottom: "10px",
                  marginTop: "10px",
                  padding: "10px 20px",
                  borderRadius: "20px",
                  fontWeight: 600,
                  fontSize: isMobile ? "14px" : "24px",
                  maxWidth: isMobile ? 300 : 600,
                }}
                onClick={handleOpen}
              >
                Get Started Now
              </Button>
            </CardContent>

            {/* Moved the Current or Former Franchisee text below the card */}
            <Box mt={3} textAlign="center" sx={{ lineHeight: 1.5 }}>
              <Typography
                variant="subtitle1"
                color="textSecondary"
                sx={{ fontSize: 25 }}
              >
                Current or Former Franchisee?
              </Typography>
              <Typography
                variant="subtitle1"
                color="textSecondary"
                component="span"
              >
                <MUILink
                  href="https://docs.google.com/forms/d/e/1FAIpQLSfoI7vsHoyVgHp19d_YZ1Qn318ORKTf9ANsMiGM-0vIRVS0XA/viewform"
                  underline="hover"
                  sx={{ textDecoration: "underline" }}
                  target="_blank"
                >
                  Join our Expert Network
                </MUILink>
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </div>

      {/* Modal Popup */}
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="sm"
        fullWidth
        PaperProps={{
          style: {
            padding: "20px",
            borderRadius: "15px",
            minWidth: "350px",
          },
        }}
        style={{ textAlign: "center" }}
      >
        <DialogTitle
          style={{ fontWeight: "bold", color: "#0098dc", fontSize: "24px" }}
        >
          Select Your Preferences
        </DialogTitle>
        <DialogContent dividers>
          {/* Industry Selection */}
          <Typography variant="h6" gutterBottom style={{ fontWeight: 500 }}>
            Select industry(s) you are interested in:
          </Typography>

          <Grid container spacing={2}>
            {industries.map((industry) => (
              <Grid
                item
                xs={12}
                sm={6}
                key={industry.name}
                style={{ display: "flex", alignItems: "center" }}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={selectedIndustry.includes(industry.name)}
                      onChange={() => handleIndustryToggle(industry.name)}
                    />
                  }
                  label={
                    <div style={{ display: "flex", alignItems: "center" }}>
                      {industry.icon}
                      <Typography variant="body1" style={{ marginLeft: "8px" }}>
                        {industry.name}
                      </Typography>
                    </div>
                  }
                />
              </Grid>
            ))}
          </Grid>

          {/* Investment Level */}
          <Typography variant="h6" gutterBottom style={{ marginTop: "20px" }}>
            How much cash or liquid savings do you have?
          </Typography>
          <FormControl fullWidth margin="normal">
            <InputLabel>Investment Level</InputLabel>
            <Select
              value={investmentLevel}
              onChange={handleInvestmentLevelChange}
            >
              <MenuItem value="$50-150k">&lt; $75k</MenuItem>
              <MenuItem value="$150-250k">$75-125k</MenuItem>
              <MenuItem value="$250-500k">$125-250k</MenuItem>
              <MenuItem value="+500k">$250k</MenuItem>
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleSubmit}
            style={{
              backgroundColor: "#0098dc",
              color: "#fff",
              padding: "10px 20px",
              borderRadius: "15px",
            }}
          >
            Submit
          </Button>
          <Button
            onClick={handleClose}
            style={{
              color: "#f44336",
              padding: "10px 20px",
              borderRadius: "15px",
            }}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      {/* Footer Links */}
      <Box
        mt={-40}
        display="flex"
        justifyContent="center"
        alignItems="center"
        gap={4}
      >
        <Link to="/all-franchises" style={{ textDecoration: "none" }}>
          <Typography
            variant="body1"
            sx={{
              color: "#0098dc",
              "&:hover": {
                color: "#005f99",
              },
            }}
          >
            Explore Franchisors
          </Typography>
        </Link>
        <Link to="/blog" style={{ textDecoration: "none" }}>
          <Typography
            variant="body1"
            sx={{
              color: "#0098dc",
              "&:hover": {
                color: "#005f99",
              },
            }}
          >
            Blog
          </Typography>
        </Link>
        <Link to="/site-suggestions" style={{ textDecoration: "none" }}>
          <Typography
            variant="body1"
            sx={{
              color: "#0098dc",
              "&:hover": {
                color: "#005f99",
              },
            }}
          >
            Contact Us
          </Typography>
        </Link>
      </Box>
    </>
  );
};

export default Home;
