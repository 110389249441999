import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Box, Paper, Typography, Avatar, Button } from "@mui/material";
import Container from "./Container";
import CategoryLabel from "./CategoryLabel";
import { useAuth } from "../../auth/Auth";
import dummyPosts, {
  updatePostComments,
  updatePostLikes,
} from "../../utils/dummyData";

export default function BlogPage({ post }) {
  const { currentUser } = useAuth();
  const [likes, setLikes] = useState(post.likes || []);
  const [comments, setComments] = useState(post.comments || []);
  const [commentText, setCommentText] = useState("");
  const [showCommentForm, setShowCommentForm] = useState(false);
  const [likedByUser, setLikedByUser] = useState(false);

  useEffect(() => {
    if (currentUser) {
      setLikedByUser(post.likes.includes(currentUser.uid));
    }
  }, [currentUser, post.likes]);

  const handleLike = () => {
    if (currentUser) {
      const userLiked = likedByUser;
      const updatedLikes = userLiked
        ? likes.filter((id) => id !== currentUser.uid)
        : [...likes, currentUser.uid];

      setLikes(updatedLikes);
      setLikedByUser(!userLiked);

      updatePostLikes(post._id, currentUser.uid, !userLiked);
    }
  };

  const handleCommentSubmit = (e) => {
    e.preventDefault();
    const newComment = {
      email: currentUser.email,
      text: commentText,
      user: currentUser.uid,
    };
    const updatedComments = [...comments, newComment];
    setComments(updatedComments);
    setCommentText("");
    setShowCommentForm(false);
    updatePostComments(post._id, updatedComments);
  };

  const handleCommentClick = () => {
    setShowCommentForm(!showCommentForm);
  };

  const slug = post?.slug;

  if (!slug) {
    return <div>Post Not Found</div>;
  }

  // Split title at the colon
  const titleParts = post.title.split(":");

  return (
    <>
      <Container>
        <Box sx={{ maxWidth: "md", mx: "auto", pt: 0 }}>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <CategoryLabel categories={post.categories} />
          </Box>

          <Typography
            variant="h3"
            component="h1"
            sx={{
              textAlign: "center",
              color: "primary.main",
              mb: 1,
              mt: 2,
              fontWeight: "bold",
            }}
          >
            {titleParts[0]}
            {titleParts.length > 1 && (
              <>
                :<br />
                {titleParts.slice(1).join(":").trim()}
              </>
            )}
          </Typography>

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              mt: 2,
              mb: -3,
              ml: 3,
              color: "text.secondary",
            }}
          >
            <Avatar
              alt={post.author.name}
              src={post.author.image.src}
              sx={{ width: 40, height: 40, mr: 2 }}
              component={Link}
              to={`/author/${post.author.slug}`}
            />
            <Box>
              <Typography
                variant="body2"
                component={Link}
                to={`/author/${post.author.slug}`}
                sx={{ color: "text.primary" }}
              >
                {post.author.name}
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 2,
                  fontSize: "small",
                }}
              >
                <time dateTime={post.publishedAt}>{post.publishedAt}</time>
                <span>· {post.estReadingTime || "5"} min read</span>
              </Box>
            </Box>
          </Box>
        </Box>
      </Container>

      <Container>
        <Paper elevation={0} sx={{ maxWidth: "md", mx: "auto", p: 3 }}>
          <Box sx={{ mb: 3 }}>
            <img
              src={post.mainImage.src}
              alt={post.mainImage.alt || "Thumbnail"}
              style={{ width: "100%", height: "auto" }}
            />
          </Box>

          <Typography variant="body1" component="div">
            <div dangerouslySetInnerHTML={{ __html: post.body }} />
          </Typography>

          <Box sx={{ mb: 7, mt: 7, display: "flex", justifyContent: "center" }}>
            <Link to="/blog" style={{ textDecoration: "none" }}>
              <Button variant="contained" color="primary" sx={{ mt: 3 }}>
                All Blogs
              </Button>
            </Link>
          </Box>
        </Paper>
      </Container>
    </>
  );
}
