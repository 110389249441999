import React from 'react';
import dummyPosts from '../../utils/dummyData';
import PostList from './PostList';
import { Link } from "react-router-dom";
import Container from './Container';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';

const Blog = () => {
  return (
    <>
      {dummyPosts && (
        <Container>
          <Grid container spacing={2}>
            {dummyPosts.slice(0, 2).map((post) => (
              <Grid item xs={12} md={6} key={post._id}>
                <PostList
                  key={post._id}
                  post={post}
                  aspect="landscape"
                  preloadImage={true}
                />
              </Grid>
            ))}
          </Grid>
          <Grid container spacing={2} sx={{ mt: 4 }}>
            {dummyPosts.slice(2, 14).map((post) => (
              <Grid item xs={12} md={6} lg={4} key={post._id}>
                <PostList key={post._id} post={post} aspect="square" />
              </Grid>
            ))}
          </Grid>
          <Grid container justifyContent="center" sx={{ mt: 4 }}>
            <Button
              component={Link}
              to="/archive"
              variant="outlined"
              color="primary"
            >
              View all Posts
            </Button>
          </Grid>
        </Container>
      )}
    </>
  );
};

export default Blog;
