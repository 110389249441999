import React from 'react';
import { Link } from "react-router-dom";
import Label from "./Lable";
import Box from '@mui/material/Box';

export default function CategoryLabel({ categories, nomargin = false }) {
  return (
    <Box display="flex" gap={3}>
      {categories?.length &&
        categories.slice(0).map((category, index) => (
          <Link to={`/category/${category.slug.current}`} key={index}>
            <Label nomargin={nomargin} color={category.color}>
              {category.title}
            </Label>
          </Link>
        ))}
    </Box>
  );
}
