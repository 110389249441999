var data = [
  {
    id: 1,
    Franchisor: "Little Caesars",
    Name: "Shehzad",
    Multi_site: true,
    Hourly_Rate: "110",
    Experience: "+10",
    Franchisee: "Expert 55",
    availability: {
      Mon: ["12-1pm", "1-2pm", "2-3pm", "3-4pm"],
      Tue: ["12-1pm", "1-2pm", "2-3pm", "3-4pm"],
      Wed: ["12-1pm", "1-2pm", "2-3pm", "3-4pm"],
      Thu: ["12-1pm", "1-2pm", "2-3pm", "3-4pm"],
      Fri: ["12-1pm", "1-2pm", "2-3pm", "3-4pm"],
      Sat: ["12-1pm", "1-2pm", "2-3pm", "3-4pm"],
      Sun: ["12-1pm", "1-2pm", "2-3pm", "3-4pm"],
    },
    Email: "shehzad0131@yahoo.com",
  },

  {
    id: 2,
    Franchisor: "Subway",
    Name: "Ana",
    Multi_site: true,
    Hourly_Rate: "110",
    Experience: "5",
    Franchisee: "Expert 56",
    availability: {
      Mon: ["12-1pm", "1-2pm", "2-3pm", "3-4pm"],
      Tue: ["12-1pm", "1-2pm", "2-3pm", "3-4pm"],
      Wed: ["12-1pm", "1-2pm", "2-3pm", "3-4pm"],
      Thu: ["7-8am", "8-9am", "9-10am", "10-11am", "11-12pm"],
    },
    Email: "yagnikana@gmail.com",
  },

  {
    id: 3,
    Franchisor: "Manhattan Bagel",
    Name: "Nada",
    Multi_site: true,
    Hourly_Rate: "110",
    Experience: "+20",
    Franchisee: "Expert 57",
    availability: {
      Sat: ["7-8pm", "8-9pm", "9-10pm"],
      Sun: ["7-8pm", "8-9pm", "9-10pm"],
    },
    Email: "nkwaik1@gmail.com",
  },

  {
    id: 4,
    Franchisor: "Tropical Smoothie Cafe",
    Name: "Frank",
    Multi_site: false,
    Hourly_Rate: "110",
    Experience: "4",
    Franchisee: "Expert 58",
    availability: {
      Mon: ["7-8am", "8-9am", "9-10am", "10-11am", "11-12pm"],
      Tue: ["7-8am", "8-9am", "9-10am", "10-11am", "11-12pm"],
      Wed: ["7-8am", "8-9am", "9-10am", "10-11am", "11-12pm"],
      Thu: ["7-8am", "8-9am", "9-10am", "10-11am", "11-12pm"],
      Fri: ["7-8am", "8-9am", "9-10am", "10-11am", "11-12pm"],
      Sat: ["7-8am", "8-9am", "9-10am", "10-11am", "11-12pm"],
      Sun: ["7-8am", "8-9am", "9-10am", "10-11am", "11-12pm"],
    },
    Email: "frank@happycustomerrg.com",
  },

  {
    id: 5,
    Franchisor: "Great Clips",
    Name: "Paul",
    Multi_site: true,
    Hourly_Rate: "110",
    Experience: "+10",
    Franchisee: "Expert 59",
    availability: {
      Mon: ["1-2pm", "2-3pm", "3-4pm"],
      Tue: ["1-2pm", "2-3pm", "3-4pm"],
      Wed: ["1-2pm", "2-3pm", "3-4pm"],
      Thu: ["1-2pm", "2-3pm", "3-4pm"],
      Fri: ["1-2pm", "2-3pm", "3-4pm"],
      Sat: ["1-2pm", "2-3pm", "3-4pm"],
      Sun: ["1-2pm", "2-3pm", "3-4pm"],
    },
    Email: "paul.baumback@greatclips.net",
  },

  {
    id: 6,
    Franchisor: "Ivy Kids",
    Name: "Karim",
    Multi_site: false,
    Hourly_Rate: "110",
    Experience: "2",
    Franchisee: "Expert 60",
    availability: {
      Mon: ["7-8am", "8-9am", "9-10am", "10-11am", "11-12pm"],
      Tue: ["7-8am", "8-9am", "9-10am", "10-11am", "11-12pm"],
      Wed: ["7-8am", "8-9am", "9-10am", "10-11am", "11-12pm"],
      Thu: ["7-8am", "8-9am", "9-10am", "10-11am", "11-12pm"],
      Fri: ["7-8am", "8-9am", "9-10am", "10-11am", "11-12pm"],
      Sat: ["7-8am", "8-9am", "9-10am", "10-11am", "11-12pm"],
      Sun: ["7-8am", "8-9am", "9-10am", "10-11am", "11-12pm"],
    },
    Email: "karim@ikelc.com",
  },

  {
    id: 7,
    Franchisor: "Sign Gypsies",
    Name: "Marion",
    Multi_site: false,
    Hourly_Rate: "110",
    Experience: "+5",
    Franchisee: "Expert 61",
    availability: {
      Mon: ["1-2pm", "2-3pm", "3-4pm", "4-5pm"],
      Tue: ["1-2pm", "2-3pm", "3-4pm", "4-5pm"],
      Wed: ["1-2pm", "2-3pm", "3-4pm", "4-5pm"],
      Thu: ["1-2pm", "2-3pm", "3-4pm", "4-5pm"],
      Fri: ["1-2pm", "2-3pm", "3-4pm", "4-5pm"],
      Sat: ["1-2pm", "2-3pm", "3-4pm", "4-5pm"],
      Sun: ["1-2pm", "2-3pm", "3-4pm", "4-5pm"],
    },
    Email: "signgypsiesneworleanseast@gmail.com",
  },

  {
    id: 8,
    Franchisor: "European Wax Center",
    Name: "Shehzad",
    Multi_site: true,
    Hourly_Rate: "110",
    Experience: "+10",
    Franchisee: "Expert 62",
    availability: {
      Mon: ["12-1pm", "1-2pm", "2-3pm", "3-4pm"],
      Tue: ["12-1pm", "1-2pm", "2-3pm", "3-4pm"],
      Wed: ["12-1pm", "1-2pm", "2-3pm", "3-4pm"],
      Thu: ["12-1pm", "1-2pm", "2-3pm", "3-4pm"],
      Fri: ["12-1pm", "1-2pm", "2-3pm", "3-4pm"],
      Sat: ["12-1pm", "1-2pm", "2-3pm", "3-4pm"],
      Sun: ["12-1pm", "1-2pm", "2-3pm", "3-4pm"],
    },
    Email: "shehzad0131@yahoo.com",
  },

  {
    id: 9,
    Franchisor: "Blimpie",
    Name: "Nada",
    Multi_site: true,
    Hourly_Rate: "110",
    Experience: "+20",
    Franchisee: "Expert 63",
    availability: {
      Sat: ["7-8pm", "8-9pm", "9-10pm"],
      Sun: ["7-8pm", "8-9pm", "9-10pm"],
    },
    Email: "nkwaik1@gmail.com",
  },

  {
    id: 10,
    Franchisor: "Ziggi's",
    Name: "Karim",
    Multi_site: false,
    Hourly_Rate: "110",
    Experience: "2",
    Franchisee: "Expert 64",
    availability: {
      Mon: ["7-8am", "8-9am", "9-10am", "10-11am", "11-12pm"],
      Tue: ["7-8am", "8-9am", "9-10am", "10-11am", "11-12pm"],
      Wed: ["7-8am", "8-9am", "9-10am", "10-11am", "11-12pm"],
      Thu: ["7-8am", "8-9am", "9-10am", "10-11am", "11-12pm"],
      Fri: ["7-8am", "8-9am", "9-10am", "10-11am", "11-12pm"],
      Sat: ["7-8am", "8-9am", "9-10am", "10-11am", "11-12pm"],
      Sun: ["7-8am", "8-9am", "9-10am", "10-11am", "11-12pm"],
    },
    Email: "karim@ikelc.com",
  },

  {
    id: 11,
    Franchisor: "World Gym",
    Name: "Shehzad",
    Multi_site: true,
    Hourly_Rate: "110",
    Experience: "+10",
    Franchisee: "Expert 65",
    availability: {
      Mon: ["12-1pm", "1-2pm", "2-3pm", "3-4pm"],
      Tue: ["12-1pm", "1-2pm", "2-3pm", "3-4pm"],
      Wed: ["12-1pm", "1-2pm", "2-3pm", "3-4pm"],
      Thu: ["12-1pm", "1-2pm", "2-3pm", "3-4pm"],
      Fri: ["12-1pm", "1-2pm", "2-3pm", "3-4pm"],
      Sat: ["12-1pm", "1-2pm", "2-3pm", "3-4pm"],
      Sun: ["12-1pm", "1-2pm", "2-3pm", "3-4pm"],
    },
    Email: "shehzad0131@yahoo.com",
  },

  {
    id: 12,
    Franchisor: "C Town",
    Name: "Nada",
    Multi_site: true,
    Hourly_Rate: "110",
    Experience: "+20",
    Franchisee: "Expert 66",
    availability: {
      Sat: ["7-8pm", "8-9pm", "9-10pm"],
      Sun: ["7-8pm", "8-9pm", "9-10pm"],
    },
    Email: "nkwaik1@gmail.com",
  },

  {
    id: 13,
    Franchisor: "Taco Bueno",
    Name: "Mohammed",
    Multi_site: true,
    Hourly_Rate: "110",
    Experience: "+30",
    Franchisee: "Expert 67",
    availability: {
      Sat: ["7-8pm", "8-9pm", "9-10pm"],
      Sun: ["7-8pm", "8-9pm", "9-10pm"],
    },
    Email: "rizwanlahore@hotmail.com",
  },

  {
    id: 14,
    Franchisor: "Furry Land",
    Name: "Tim",
    Multi_site: false,
    Hourly_Rate: "110",
    Experience: "+5",
    Franchisee: "Expert 67",
    availability: {
      Mon: ["7-8pm", "8-9pm", "9-10pm"],
      Tue: ["7-8pm", "8-9pm", "9-10pm"],
      Wed: ["7-8pm", "8-9pm", "9-10pm"],
      Thu: ["7-8pm", "8-9pm", "9-10pm"],
      Fri: ["7-8pm", "8-9pm", "9-10pm"],
      Sat: ["7-8pm", "8-9pm", "9-10pm"],
      Sun: ["7-8pm", "8-9pm", "9-10pm"],
    },
    Email: "tim.barrans@furryland.us",
  },

  {
    id: 15,
    Franchisor: "Crumbl",
    Name: "Leroy",
    Multi_site: true,
    Hourly_Rate: "110",
    Experience: "2",
    Franchisee: "Expert 68",
    availability: {
      Mon: ["7-8pm", "8-9pm", "9-10pm"],
      Tue: ["7-8pm", "8-9pm", "9-10pm"],
      Wed: ["7-8pm", "8-9pm", "9-10pm"],
      Thu: ["7-8pm", "8-9pm", "9-10pm"],
      Fri: ["7-8pm", "8-9pm", "9-10pm"],
      Sat: ["7-8pm", "8-9pm", "9-10pm"],
      Sun: ["7-8pm", "8-9pm", "9-10pm"],
    },
    Email: "avila_leroy@yahoo.com",
  },
  {
    id: 16,
    Franchisor: "SuperStop",
    Name: "Arkam",
    Multi_site: true,
    Hourly_Rate: "110",
    Experience: "2",
    Franchisee: "Expert 69",
    availability: {
      Mon: ["7-8pm", "8-9pm", "9-10pm"],
      Tue: ["7-8pm", "8-9pm", "9-10pm"],
      Wed: ["7-8pm", "8-9pm", "9-10pm"],
      Thu: ["7-8pm", "8-9pm", "9-10pm"],
      Fri: ["7-8pm", "8-9pm", "9-10pm"],
      Sat: ["7-8pm", "8-9pm", "9-10pm"],
      Sun: ["7-8pm", "8-9pm", "9-10pm"],
    },
    Email: "arkamjaved@gmail.com",
  },
];

export default data;
