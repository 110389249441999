export const wingStopData = [
  {
    slug: "McDonald's",
    pageData: {
      pic: "/logos/McDonald's.jpg",
      title: "McDonald's",
      initialInvestment: "$1.5M-$2.5M",
      franchiseeFee: "$45k",
      royaltyRate: "5.0%",
      description:
        "McDonald's Corporation is a global fast-food chain and one of the world's largest restaurant companies, known for its iconic golden arches logo. Founded in 1940, McDonald's serves a variety of quick-service meals, including its signature hamburgers, fries, chicken products, breakfast items, and beverages. The company operates in over 100 countries with thousands of locations, primarily through a franchising model, making it a familiar and trusted brand for millions of customers worldwide. McDonald's is committed to delivering consistent, high-quality food and customer service, with a focus on convenience, affordability, and innovation in its offerings.",
    },
    keyTrends: [
      {
        leftDescription:
          "The new <strong>$5 value meal in 2024</strong> seems to be succeeding in bolstering traffic which has led to the vast majority of franchisees commit to extending the promotion",
        rightDescription:
          "McDonald’s franchisees <strong>cannot own the underlying real estate</strong> (unlike Burger King franchisees) and must be mindful of rent raises if the landlord is not Corporate",
      },
      {
        leftDescription:
          "Several <strong>tech-enabled efficiency initiatives</strong> (e.g., system-wide digital app, Service Area Optimization) have allowed McDonald’s to maintain its <strong>QSR leadership position</strong>",
        rightDescription:
          "Impact of <strong>weight-loss GLP-1 drugs</strong> has not been felt by franchisees yet but it is still early in the adoption cycle and the jury is still out on the behavioral evolution of users",
      },
      {
        leftDescription:
          "New <strong>smaller McDonald’s restaurant prototypes</strong> are lauded as higher ROI with <strong>shorter payback periods</strong> especially given the rise of online orders and 3rd party delivery services",
        rightDescription:
          "<strong>Franchisee buy-in</strong> is not always guaranteed when Corporate wants to introduce menu innovations (e.g., plant-based foods, more involved coffee items) as they may add operational complexity and can <strong>impair guest throughput</strong> (important for margins)",
      },
    ],
    cardData: [
      {
        id: 1,
        title: "Spotlight #1 – McDonald's Franchise Owner – October 2023",
        description:
          "Addresses a range of topics including the impact of minimum wage increases on pricing and sales, pros and cons of the new $5 value meal, structure of franchise agreements, rent payments, margins, technology implementation, and the important of adapting to changing market conditions",
        points: [
          `
<ul>
  <li>The interviewee owns <strong>6 McDonald’s restaurants in Southern California</strong> and has been a franchisee for <strong>35 years</strong>; CA is a high-cost market where the <strong>minimum wage is +$20/hour</strong> (25% increase in labor costs overnight as of April once the Governor of California signed this developed into law earlier this year)
    <ul>
      <li>Franchisors that have <strong>at least 60 sites</strong> are bound by this law and need to comply – which leaves smaller regional concepts with a <strong>labor cost advantage</strong></li>
      <li>There has been <strong>no direct financial assistance</strong> from corporate in response to wage increases and thus operators must focus on efficiency and productivity to manage increased costs</li>
    </ul>
  </li>
  <li>Sales and guest counts have decreased as of late due to price increases and <strong>negative media narratives</strong> about fast food prices; franchisees have the <strong>autonomy to set prices</strong> but generally participate in local and national promotions funded by their sales contributions to advertising co-ops (participation in promotions is crucial for maintaining sales volumes)
    <ul>
      <li>Media portrayals of price taking are overblown as McDonald’s restaurants have on average only raised the price of a combo meal by <strong>~$0.20-0.30</strong> which is not exorbitant</li>
      <li><strong>Convenience stores</strong> have been taking some market share, especially the ones that have not raised prices – unclear how sustainable this dynamic is however as customers aren’t really getting access to fresh food at convenience stores</li>
      <li>McDonald’s is well-positioned to weather these temporary headwinds due to the support from corporate and their <strong>R&D</strong> leading to technology driven improvements</li>
    </ul>
  </li>
  <li>There is no doubt that <strong>McDonald’s remains a leader</strong> in the QSR sector but competitive dynamics will vary geographically – for example <strong>In-N-Out Burger</strong> is a significant competitor in the Western US while in some markets <strong>Tim Hortons</strong> (which has maintained value pricing) is benefiting from current economic conditions and macro softness
    <ul>
      <li>The interviewee believes that McDonald’s is well-positioned at the end of the day to weather economic storms and <strong>maintain market leadership</strong></li>
    </ul>
  </li>
  <li>Corporate is trying to stimulate traffic through the roll-out and extension of value promotions (e.g., <strong>$5 value meal</strong>) but some franchisees have been resisting these initiatives
    <ul>
      <li><strong>30-40% of franchisees</strong> do not want to participate in value promotions and thus there is some strain building on the relationships between corporate and its franchisees</li>
      <li>The original promo was <strong>4-weeks long</strong> which could throw in a <strong>4-piece McNuggets box</strong> with a combo order (McDouble cheeseburger or McChicken sandwich with a small fries and small drink) in order to jump-start routine combo meal spending again – Corporate has now decided to extend the timeline of this deal with <strong>+90%</strong> of restaurants committing to extending the deal even further into the summer</li>
      <li>The <strong>$5 value combo meal</strong> itself has about a <strong>1-5% profit margin</strong> relative to the <strong>5-10% profit margin</strong> on a traditional McDonald’s meal – but the deal has the added benefit of getting price-sensitive customers back in the door with the possibility that they will spend beyond the value meal once they are inside</li>
      <li>Sandwiches (chicken or beef burgers) often have a <strong>gross margin</strong> (not including labor, rent, insurance, and other non-food costs) of about <strong>~40%</strong> while fries have a <strong>gross margin as high as 95%</strong> - so packaging these various items together is what enables profitable outcomes</li>
      <li>Now other QSRs are following suit like <strong>Burger King’s $5 “Your Way Meal”</strong> and <strong>Jack in the Box’s “Munchies under $4”</strong> deal</li>
    </ul>
  </li>
  <li><strong>McDonald’s franchisees never own the real estate</strong> – that is owned by either corporate or by another landlord who leases it to corporate which passes on the rent expense to the franchisee
    <ul>
      <li>In instances where corporate owns the real estate, the franchisee pays a fixed % of sales every month as rent which corporate usually keeps constant and doesn’t raise; in situations where the land is owned by someone else, then the franchisee must deal with the <strong>landlord raising rents</strong> as they see fit</li>
      <li>Unlike McDonald’s, some competitors like Burger King allow franchisees to own the underlying property but sometimes franchisees can perform poorly which allows competing franchisors to come in and swoop up the land in the event of a bankruptcy or liquidation</li>
      <li>Older stores tend to have <strong>lower rent percentages (around 8.5%)</strong> due to lower initial real estate costs while newer stores may have <strong>higher rent percentages (13%-15%)</strong> due to higher initial real estate investments by McDonald's</li>
    </ul>
  </li>
  <li>Franchised sites are valued based on <strong>cash flows</strong> and their valuation can range between <strong>5-10x</strong> based on the site’s sales, margins, and growth potential as informed by the local demographics and competitive landscape – making each store <strong>“unique like a snowflake”</strong>
    <ul>
      <li>Despite increased upfront investments and operational costs, McDonald’s franchises remain attractive to buyers due to the <strong>stable and predictable rent structure</strong> – and buyers often see opportunities to improve profitability by optimizing operations and costs</li>
    </ul>
  </li>
  <li>Technological improvements over the past five years include back-office management, revenue management, scheduling, and kitchen equipment – they have been <strong>labor-neutral</strong> and have primarily improved product quality as opposed to reducing labor costs
    <ul>
      <li>Much focus has gone towards <strong>Service Area Optimization (SAO)</strong> which calls for the removal of beverage bars from dining rooms and places them behind the counter which allows for more seating and ordering kiosks while also reducing waste</li>
      <li>The franchise system-wide app features both national and local promotions – local events like sports achievements can trigger specific promotions (e.g., free nuggets if a local team scores a certain number of points)</li>
    </ul>
  </li>
  <li>There is an increasing trend of corporate passing on more costs and responsibilities to the franchisees as they have shifted from providing extensive field support to cutting down on these services
    <ul>
      <li>This development has gone hand in hand with the fact that corporate <strong>eliminated its 10 field offices</strong> in the US and centralized operations in Chicago in accordance with McKinsey’s counsel which means that the field staff work at home and <strong>interact less with the franchisees</strong></li>
    </ul>
  </li>
  <li>McDonald’s has struggled to achieve success in the <strong>coffee category</strong>, failing to meet corporate targets with the main competition being <strong>Starbucks</strong> which has a strong brand and market presence
    <ul>
      <li>The overall coffee category has started to buckle under <strong>customer’s price sensitivity</strong> as Starbucks experienced a <strong>3.5% year-over-year decline</strong> in sales in Q1 2024 as consumers are less willing to pay higher prices for such beverages</li>
      <li>Despite Starbucks’s dominance, McDonald's provides a value proposition by offering meals along with beverages, which may be more appealing to <strong>price-conscious customers</strong></li>
      <li>Corporate is exploring a new concept called <strong>CosMc</strong> which focuses on drinks and drive-through services, but details are limited as McDonald's is not sharing much information yet – nonetheless McDonald’s still suffers from a perception issue as its coffee is not seen as <strong>“premium” like Starbucks’s</strong></li>
    </ul>
  </li>
  <li>Many McDonald's operators are <strong>generational</strong>, with 2nd or 3rd-generation owners running the business
    <ul>
      <li>All <strong>6 of the interviewee’s managers</strong> started as crew members and have stayed on for many years – underscoring the <strong>talent retention</strong> within the franchise system</li>
    </ul>
  </li>
</ul>
`,
        ],
        image: "/spotlight/mcd_3.png",
      },
      {
        id: 2,
        title: "Spotlight #2 – McDonald's Franchise Owner – June 2022",
        description:
          "Discussion focuses on store unit economics, pricing algorithms, the impact of weight-loss GLP-1 drugs, the ongoing modernization drive, and salient items regarding the relationship between franchisees and corporate",
        points: [
          `
<ul>
  <li>Interviewee’s family started its affiliation with McDonald’s in <strong>1976</strong> when his father flipped burgers at a site, working his way up the corporate ladder for <strong>35 years</strong> to become a company director, and then started owning franchised sites in <strong>2020</strong> (the family now operates <strong>40 different locations</strong>)
    <ul>
      <li>Interviewee also worked for <strong>corporate’s pricing department</strong> for a while and helped to set the pricing in several international markets; has then gone on to operate his own sites for the <strong>past 10 years</strong></li>
      <li>The franchisee’s stores have <strong>outperformed the market</strong> over the past decade by achieving sales growth of <strong>4% higher</strong> than the average US McDonald’s restaurant sales growth in recent quarters due to strategic pricing decisions and value offerings</li>
    </ul>
  </li>
  <li>Maintained <strong>competitive pricing</strong>, particularly on value items, unlike other operators who have leaned into price hikes which ends up harming traffic over the longer-term
    <ul>
      <li>The most important line-item in a McDonald’s franchisee’s P&L is <strong>Profit After Controllables (“PAC”)</strong> – normal PAC margins used to be <strong>32%</strong> but this climbed to <strong>42%</strong> during Covid which several operators are still trying to chase through outrageous price increases</li>
      <li>Interviewee’s decision to keep <strong>value-based pricing</strong> and not engage in hikes has allowed him to chip away at his local competing Burger King and Wendy’s</li>
      <li>Historically operators have increased pricing year-over-year at <strong>~3%</strong> to keep pace with inflation but over the past 2.5 years the interviewee has increased prices cumulatively by <strong>~10%</strong> while other operations have done <strong>14-15%</strong></li>
    </ul>
  </li>
  <li>Corporate has invested significant resources in its pricing algorithms and franchisees additionally work with companies like <strong>Revenue Management Solutions (RMS)</strong> and <strong>Deloitte</strong> to further validate their pricing strategies
    <ul>
      <li>McDonald’s will likely continue to have a few more years of <strong>high single digit price increases (7-9%)</strong> unless there is serious deflation in commodity prices but if wage inflation creeps back up then <strong>+10% pricing</strong> wouldn’t be out of the question</li>
    </ul>
  </li>
  <li>There are emerging threats from regional (e.g., Whataburger, In-N-Out) and non-traditional (e.g., Wawa) players who are expanding into new markets but after the initial excitement these concepts <strong>do not sustain higher customer interest</strong>
    <ul>
      <li>The presence of popular coffee chains like Starbucks and Dutch Bros. can sometimes boost McDonald's breakfast sales, as they attract customers to the area and gravitate towards McDonald’s <strong>superior breakfast sandwich</strong></li>
    </ul>
  </li>
  <li>Interviewee has not seen any impact to his operations stemming from the increasing adoption of <strong>weight-loss GLP-1 drugs</strong>
    <ul>
      <li><strong>GLP-1s</strong> are expensive for the average American and are more likely to impact the demographic of a concept like Chick-fil-A (slightly more affluent customer base) than McDonald’s; far larger portion of McDonald’s customers make less then <strong>$100k</strong> vs the portion of Chick-fil-A’s customer base in that low-income bracket</li>
      <li>If this starts to have an impact there is enough <strong>menu flexibility</strong> to where operators could emphasize healthier options like chicken salads and parfaits</li>
    </ul>
  </li>
  <li>In terms of operating leverage, if the franchisee saw a sales reduction of <strong>10%</strong> then that would probably translate to about <strong>~200-300 bps of PAC margin deterioration</strong>
    <ul>
      <li>Interviewee’s average store from his <strong>60 sites</strong> does <strong>$3.6M</strong> in sales annually and generates about <strong>~$600k in cash flow per year</strong> (<strong>~17% margins</strong>)</li>
      <li>Had to remodel <strong>37 of his 40 stores</strong> over the past <strong>4 years</strong> due to a nation-wide modernization campaign that corporate was spearheading</li>
    </ul>
  </li>
  <li>Corporate has been highly focused on increasing <strong>digital transactions</strong> which are now approaching <strong>30% of all transactions</strong> within the franchise system
    <ul>
      <li>The McDonald’s <strong>digital app</strong> is likely to surpass even <strong>Starbucks’s</strong> in terms of both user engagement and transaction volume</li>
      <li>Despite the success of these digital initiatives, there is concern of corporate’s trimming of the <strong>digital workforce</strong> which has impacted the agility of the digital strategy (reduced ability to quickly implement and change offers)</li>
    </ul>
  </li>
  <li>Sometimes there are complications in the relationships between corporate and the franchisees – especially regarding the handling of <strong>graded visits</strong> (known as “RGRs” – Restaurant Graded Reviews) but this was not discussed at all unfortunately at the last system-wide meeting in Las Vegas
    <ul>
      <li>Interviewee is frustrated that he spends <strong>+$15k on every restaurant</strong> before a graded visit which otherwise could go towards increasing customer count by <strong>3-4%</strong></li>
      <li>Corporate is already taking <strong>16-18% of sales</strong> in rent and now adding another point of <strong>royalty fees</strong> for new franchisee (going from <strong>4% of sales to 5%</strong>)</li>
    </ul>
  </li>
</ul>
`,
        ],
        image: "/spotlight/mcd_2.jpeg",
      },
      {
        id: 3,
        title: "Spotlight #3 – McDonald's Franchise Owner – June 2022",
        description:
          "Provides a detailed overview on the interplay of McDonald’s digitization strategy and the 3rd party food delivery aggregators (e.g., Uber Eats) while also exploring implications for customer loyalty, logistical efficiencies, and the broader automation of franchisee workflows",
        points: [
          `
<ul>
  <li>Expert worked at Corporate from <strong>2014 to 2021</strong> and was instrumental in the roll-out of the digital strategy with an emphasis on the <strong>mobile app, kiosks, and website</strong>
    <ul>
      <li>Also led the global product team for the mobile app, overseeing initiatives like mobile offers, ordering ahead, curbside pickup, and the <strong>integration of Uber Eats</strong> (allowing for Uber Eats orders to be fulfilled without the need for a separate iPad)</li>
      <li>Managed <strong>two distinct apps</strong>: one for the US and one for <strong>30 other countries</strong> – the international app had a lighter feature set and lacked certain capabilities like order-ahead which were present in the US version</li>
    </ul>
  </li>
  <li>McDonald's did not have a <strong>dedicated digital team</strong> until the end of 2014 (a little late to the trend relative to competitors); the focus was to add value through digital channels, including developing a mobile app and integrating third-party delivery services like <strong>Uber Eats</strong>
    <ul>
      <li>McDonald's primarily worked with <strong>Uber Eats</strong>, with some initial involvement from <strong>Postmates</strong> - focus was on ensuring operational consistency and food quality, including packaging modifications and training for restaurant staff</li>
      <li>The integration of delivery required <strong>new procedures</strong>, such as sealing bags with stickers and adjusting menu offerings to exclude items not suitable for delivery; McDonald's also <strong>limited delivery radii</strong> to ensure food quality, often conflicting with delivery partners' desires to <strong>expand the delivery range</strong></li>
      <li>Prioritized <strong>maintaining food quality</strong>, even for delivery orders - the company faced challenges in managing customer expectations around delivery fees, which are a common point of contention among QSR customers</li>
    </ul>
  </li>
  <li>At the time of the expert's tenure, the <strong>vast majority of delivery orders</strong> were placed through <strong>Uber Eats</strong> rather than McDonald's app (~<strong>90%</strong>) - the integration with Uber Eats allowed McDonald's to reach customers more effectively than developing an in-house delivery system
    <ul>
      <li>In some international markets, particularly in <strong>Asia</strong>, McDonald's employs its own delivery drivers - this is due to longer market presence, greater population density, and higher demand, making it economically viable to manage delivery internally; the U.S. market, however, relies heavily on <strong>third-party platforms</strong> due to different market dynamics and cost considerations</li>
      <li>Unlikely for McDonald's to bring delivery in-house in the U.S. due to existing networks of third-party delivery drivers and the high costs and liabilities associated with managing an in-house fleet; therefore, the domestic priority was more on <strong>operational efficiency</strong> within restaurants rather than managing the last mile of delivery</li>
    </ul>
  </li>
  <li>There may be a potential market shift between using <strong>aggregator platforms</strong> (like Uber Eats) and <strong>proprietary apps</strong> (like the Starbucks app); while McDonald's currently benefits from aggregators' wide reach, there may be a future where proprietary apps become more prevalent, depending on consumer preferences and market trends
    <ul>
      <li>McDonald's viewed delivery as an <strong>incremental channel</strong> rather than a primary method for building customer loyalty; can be compared to the experience of visiting a McDonald's in an airport—serving immediate needs rather than fostering long-term brand loyalty</li>
      <li><strong>Taco Bell</strong> and <strong>Burger King</strong> do not have the same focus on creating a routine customer experience while McDonald's strategy emphasized <strong>consistent, comforting food</strong> at an affordable price, particularly during economic downturns</li>
    </ul>
  </li>
  <li>Corporate has shown interest in <strong>automation</strong>, including exploring drone delivery and <strong>automated drive-thru systems</strong>; they are continually seeking ways to reduce labor costs and improve efficiency, aligning with their broader strategic goal
    <ul>
      <li>McDonald’s was working on <strong>drone projects</strong> as early as 2015 but these initial iterations were killed due to technological limitations at the time</li>
    </ul>
  </li>
</ul>
`,
        ],
        image: "/spotlight/mcd_1.avif",
      },
      {
        id: 4,
        title: "Spotlight #4 – McDonald's Franchise Owner – June 2022",
        description:
          "Highlights the evolution of McDonald’s new store prototype as well as the impact of the digital mobile application on Corporate’s intentional shift towards the smaller store footprint coupled with the automation of internal workloads",
        points: [
          `
<ul>
  <li>Covid-19 influenced a shift towards <strong>smaller, more efficient setups</strong> while the rise in digital ordering platforms and <strong>3rd-party delivery services</strong> have significantly contributed to <strong>higher AUVs</strong> (Average Unit Volumes) which have been a <strong>major attraction for new franchisees</strong> (McDonald’s AUVs are now near the top of the QSR sector)
    <ul>
      <li>The new smaller McDonald’s prototypes have led to <strong>lower build-out costs</strong> with <strong>faster payback periods</strong> – the focus from corporate over the next few years is to have new and current franchisees open as many of these more capital-efficient sites as possible</li>
    </ul>
  </li>
  <li>Revenue growth for McDonald’s sites has been <strong>better than pre-Covid levels</strong> due to the combination of <strong>increased pricing and traffic</strong> – but the most important part of developing new sites is always <strong>finding the right real estate</strong> (which increasingly are in suburban locations)</li>
  <li>The relationship with corporate is positive and they have made tremendous strides with the <strong>roll-out of the digital app</strong> which has garnered a <strong>cult-like following</strong> and has directly been responsible for more order volume per customer
    <ul>
      <li>Corporate also handles <strong>food delivery mishaps directly</strong> with the 3rd party aggregator services (e.g., Uber Eats, DoorDash)</li>
    </ul>
  </li>
  <li>Digitization of workloads at the new buildouts meaningfully increases employee productivity by <strong>automating communication from the point-of-sale to the production line</strong> – this process involves a load station (orders are prepared) and a dress station (orders are completed) as orders taken on iPads by employees are instantly sent to respective stations in a seamless manner</li>
</ul>
`,
        ],
        image: "/spotlight/mcd_4.jpeg",
      },
      {
        id: 5,
        title: "Spotlight #5 - McDonald's Franchise Owner - May 2024",
        description:
          "Focuses on the shift from informal eating out to fast casual dining, with the lines between quick service and fast casual starting to blur while also touching upon the impact of inflation, store traffic trends, and franchisor-franchisee dynamics",
        points: [
          `
          <ul>
  <li>The expert has extensive experience in the food service and QSR industry, starting as a <strong>teenager working at McDonald's</strong> for a franchisee then later transitioned to corporate roles, including a regional insights position at McDonald's, where they worked for approximately <strong>25 years</strong> (their tenure ended in December 2018 due to a consolidation of field offices)
    <ul>
      <li>In the <strong>regional insights role</strong>, the expert's primary responsibilities included analyzing sales data, understanding <strong>sales trends</strong>, and evaluating the effectiveness of marketing programs - this role was closely tied to various departments, including <strong>finance, marketing, and even reporting to the CEO</strong> at times</li>
    </ul>
  </li>
  <li>McDonald's is the <strong>leading player in the QSR space</strong>, with Burger King and Wendy's historically vying for the second and third spots as McDonald’s strength lies in its <strong>widespread locations</strong>; However, Chick-fil-A has emerged as a significant competitor, especially in terms of <strong>average unit volume (AUV)</strong> and consumer perception
    <ul>
      <li>Chick-fil-A's strong <strong>brand loyalty</strong>, perceived higher food quality, and superior customer service have made it a formidable competitor, potentially surpassing McDonald's in certain metrics</li>
      <li>QSR landscape has seen a shift with <strong>Chick-fil-A and fast-casual brands like Chipotle gaining market share</strong>; Chick-fil-A is particularly strong among families and children, who perceive its offerings as healthier compared to traditional fast-food options - this perception has been a significant factor in its appeal, despite actual nutritional content comparisons</li>
    </ul>
  </li>
  <li>McDonald's has struggled with <strong>declining traffic</strong>, with only one year of growth out of the last ten years before 2021; the category of informal eating out, which includes QSRs, is generally in decline, with consumers increasingly gravitating towards fast-casual options like Chipotle, driven by perceptions of healthier and higher-quality food
    <ul>
      <li>The lines between QSR and fast-casual pricing have blurred, with <strong>Chipotle often being perceived as offering better value</strong> despite similar or higher prices - this shift has partly resulted from rising operational costs in the QSR industry, necessitating price increases</li>
      <li>McDonald's has made efforts to improve food quality, including testing <strong>plant-based options like the McPlant Burger</strong>; however, overcoming long-standing perceptions of being a <strong>low-quality, value-driven brand</strong> remains a challenge</li>
      <li>Has lagged behind competitors like <strong>Starbucks and Chipotle</strong> in digital innovation, particularly in mobile app functionalities, order-ahead capabilities, and loyalty programs. However, the company has been catching up, albeit slowly</li>
    </ul>
  </li>
  <li>The relationship between McDonald's corporate and its franchisees has fluctuated, with periods of tension, particularly under previous leadership; franchisees have significant control over pricing and labor decisions, and there has been a shift from a <strong>family-like culture</strong> to a more <strong>business-centric approach</strong>
    <ul>
      <li>McDonald's <strong>handles procurement</strong> for its franchisees, leveraging its scale for cost efficiencies; however, franchisees have autonomy over menu pricing, which they use to manage their P&Ls, especially in the face of inflation and rising costs</li>
    </ul>
  </li>
  <li><strong>Inflation has pressured margins</strong> across the QSR industry; while McDonald's corporate structure, primarily franchised, insulates it from some direct cost pressures, the overall cost of doing business has increased, affecting franchisees' profitability
    <ul>
      <li>Historically, McDonald's has performed well during recessions due to its value positioning; however, with rising food costs and the blurring of price lines with fast-casual competitors, the company's value proposition may not be as strong as in previous downturns</li>
    </ul>
  </li>
  <li>The expert noted a transition from the <strong>"old guard"</strong> at McDonald's to a younger leadership team; while this brings fresh perspectives, it also means a loss of historical knowledge and experience, potentially <strong>affecting strategic continuity</strong></li>
</ul>
          `,
        ],
        image: "/spotlight/mcd_5.png",
      },
      {
        id: 6,
        title: "Spotlight #6 - McDonald's Franchise Owner - July 2024",
        description:
          "Overview of McDonald’s strategic moat within QSR enabled by its savvy real estate selection coupled with its unparalleled supply chain network",
        points: [
          `
          <ul>
  <li>The expert's tenure at McDonald's spanned from <strong>2007 to 2019</strong> where he started as a <strong>Finance Manager</strong> for the New England and New York regions and quickly ascended to the role of <strong>Finance Director for the Southeast U.S. region</strong>, which included South Carolina, Alabama, Florida, and Key West
    <ul>
      <li>He managed a team of four Finance Managers and was responsible for the financial health and strategic oversight of over <strong>1,700 locations</strong>, generating more than <strong>$4.4 billion in annual revenue</strong>; the role encompassed overseeing financial results, identifying trends, and advising on franchising strategies</li>
      <li>Provided comprehensive <strong>financial risk evaluations</strong> and proposed strategic alternatives to the U.S. leadership team and regional VP</li>
      <li>The expert was also pivotal in implementing the <strong>"Experience of the Future"</strong> initiative, which modernized restaurants and enhanced service experiences, leading to a <strong>6% increase in incremental annual average sales per restaurant</strong> in its first year</li>
    </ul>
  </li>
  <li>McDonald's differentiates itself through its <strong>consistent value pricing and prime real estate locations</strong>; the company's scale allows it to negotiate favorable terms with suppliers, ensuring low food costs
    <ul>
      <li>McDonald's significant cash flow and reputation as a <strong>stable tenant</strong> enable it to secure ideal real estate locations, often on <strong>premium sites with high visibility and accessibility</strong> - these strategic advantages, combined with a vast and efficient supply chain, contribute to McDonald's ability to maintain a <strong>competitive edge</strong> in the fast-food industry</li>
      <li>Market share in the U.S. has remained relatively stable, although the rise of <strong>quick-casual brands like Chipotle and Panera Bread</strong> and QSRs like <strong>Chick-fil-A</strong> has eroded guest counts; expert noted that if Chick-fil-A were to expand significantly, particularly by opening on Sundays or attracting substantial investment, it could pose a serious threat to McDonald's dominance – but despite these challenges, McDonald's <strong>extensive market penetration</strong> and financial resources position it well to <strong>withstand competitive pressures</strong></li>
    </ul>
  </li>
  <li>McDonald's approach to innovation is <strong>cautious and methodical</strong>, balancing the need for new products with the operational complexities they introduce; the company has a dedicated team of chefs and research professionals who develop and test new menu items, considering factors like supply chain capacity and franchisee buy-in
    <ul>
      <li>However, franchisees in the U.S. have been <strong>resistant to adding complex menu items</strong> that could slow down service and reduce throughput, a critical factor in profitability</li>
    </ul>
  </li>
  <li>The current CEO of corporate is <strong>strategic and forward-looking</strong> and has focused on maintaining McDonald's relevance in a rapidly changing market; however, his <strong>private equity background</strong> has sometimes led to a more top-down management style, which can clash with the <strong>franchise-based nature of McDonald's operations</strong></li>
  <li>In the U.S., McDonald's faces ongoing challenges related to <strong>public perceptions of its food as unhealthy</strong> - this is compounded by the broader industry trend toward healthier eating and the rise of <strong>quick-casual competitors</strong> offering more health-conscious options
    <ul>
      <li>Potential for labor issues, such as <strong>unionization and minimum wage increases</strong>, poses a significant risk - higher labor costs could compress margins, forcing McDonald's to raise prices or reduce profitability</li>
    </ul>
  </li>
</ul>
          `,
        ],
        image: "/spotlight/mcd_6.png",
      },
    ],
  },
  {
    slug: "Wingstop",
    pageData: {
      pic: "/logos/Wing-Stop.jpg",
      title: "Wingstop",
      initialInvestment: "$325k-$975k",
      franchiseeFee: "$20k",
      royaltyRate: "6.0%",
      description:
        "Wingstop Inc. is a prominent player in the fast-casual dining segment, specializing in chicken wings. Founded in 1994, Wingstop offers a variety of wing flavors along with a selection of sides, catering to a broad customer base. The company operates over 1,500 locations worldwide, leveraging a franchising model that emphasizes simplicity in operations and consistent product quality. Wingstop&#39;s commitment to freshly prepared, never-frozen wings and a streamlined menu has positioned it as a leader in the wing segment, appealing to customers seeking quick, flavorful meals. The brand&#39;s strategic focus on digital and delivery services has further enhanced its accessibility and convenience.",
    },
    keyTrends: [
      {
        leftDescription:
          "The <strong>introduction of the chicken sandwich</strong> has significantly boosted sales and attracted a younger demographic; this product has been well-received, complementing Wingstop's core menu and <strong>driving lunchtime traffic</strong>",
        rightDescription:
          "<strong>Introducing advanced customer tracking systems</strong> beyond basic order information can help track repeat purchase behavior – currently difficult to assess loyalty and retention",
      },
      {
        leftDescription:
          "Wingstop's investment in digital ordering platforms has paid off, with over <strong>70% of sales now coming through online and delivery channels</strong>; this digital emphasis has streamlined operations and improved customer convenience",
        rightDescription:
          "The <strong>rise of ghost kitchens and virtual concepts</strong> have not been able to erode Wingstop’s loyalty base but should be monitored to see how the overall fast casual space will mature",
      },
      {
        leftDescription:
          "The brand has seen <strong>tremendous increase in awareness</strong> due to savvy advertising at <strong>high-profile sporting events</strong> – helping the concept expand beyond its historic customer niche",
        rightDescription:
          "While the Wingstop brand continues to grow, there are concerns <strong>about market saturation</strong>, particularly in established regions; careful management of new store openings is necessary to avoid <strong>cannibalizing existing locations</strong>",
      },
    ],
    cardData: [
      {
        id: 1,
        title: "Spotlight #1 - Wingstop Franchise Owner - February 2024",
        description:
          "Exploration of operational-specific initiatives the multi-unit owner has taken to improve the economics of both new and mature units and an outline of Wingstop’s competitive differentiation amidst the chicken wing category",
        points: [
          `
          <ul>
  <li>The franchise owner operates multiple Wingstop locations, with a recent store opening in February exceeding initial sales expectations; the store experienced a strong start, hitting <strong>$42,000 per week</strong> before stabilizing around <strong>$24,000-$25,000</strong>
    <ul>
      <li>The <strong>"honeymoon phase"</strong> typically seen in new store openings was followed by a gradual settling of sales figures, indicating strong market entry but the need for consistent customer engagement</li>
    </ul>
  </li>
  <li>Existing stores have also seen significant improvements in performance; a previously underperforming location now averages <strong>$27,000 weekly</strong>, up from <strong>$10,000</strong>, showcasing effective management and customer service strategies
    <ul>
      <li>Improvements can be attributed to <strong>refined operational practices</strong>, enhanced customer service, and better local market penetration</li>
    </ul>
  </li>
  <li>A notable reduction in food costs has been achieved, dropping from the <strong>high 30% range</strong> to the <strong>high 20% range</strong>; this is a result of strategic supplier negotiations and efficient inventory management by Wingstop
    <ul>
      <li>Despite initially higher costs compared to competitors, Wingstop's approach has secured price stability, safeguarding against future market fluctuations and potential supply chain disruptions</li>
    </ul>
  </li>
  <li>Wingstop distinguishes itself in the quick-service restaurant (QSR) sector by emphasizing <strong>"selling flavor"</strong> rather than just chicken, leveraging a diverse and unique menu to attract a wide customer base
    <ul>
      <li>Limited-time offers and promotional items, such as the chicken sandwich, have been pivotal in drawing non-wing consumers, boosting lunch sales to account for <strong>25% of daily transactions</strong></li>
    </ul>
  </li>
  <li>Buffalo Wild Wings, particularly with its new Buffalo Wild Wings GO concept, poses the most direct competition; however, Wingstop maintains a competitive edge through a <strong>streamlined menu</strong> and efficient operational model
    <ul>
      <li>Unlike Buffalo Wild Wings GO, which includes a broader menu with items like burgers and salads, Wingstop <strong>focuses exclusively on chicken</strong>, allowing for a more efficient kitchen operation and lower operational costs</li>
    </ul>
  </li>
  <li>Despite the potential <strong>overlap in customer bases</strong>, Wingstop remains confident in its ability to provide superior product quality and customer service, thereby retaining customer loyalty even when faced with direct competition</li>
  <li>Wingstop's operations are characterized by a straightforward menu and a focus on digital orders, which constitute over <strong>70% of sales</strong>; this digital integration allows for efficient kitchen management and quick adaptation to customer needs
    <ul>
      <li>The digital focus not only enhances order accuracy but also improves customer wait times by allowing <strong>precise kitchen scheduling</strong></li>
    </ul>
  </li>
  <li>The company has adopted new technologies selectively, ensuring that investments contribute to improved product quality without unnecessary complexity
    <ul>
      <li>For example, while Buffalo Wild Wings GO invests heavily in <strong>advanced kitchen technology</strong>, Wingstop prioritizes <strong>consistency and simplicity</strong>, maintaining a focus on freshly prepared food that meets customer expectations</li>
    </ul>
  </li>
  <li>The franchise owner expressed concerns about the potential impact of <strong>macroeconomic factors</strong>, such as a severe recession or rising labor costs, particularly in states like <strong>California and New York</strong> where minimum wage increases are imminent
    <ul>
      <li>A <strong>$20 minimum wage</strong> in California, for instance, could significantly raise operational costs, impacting profitability; similar policies in New York could follow, affecting nearby New Jersey operations</li>
    </ul>
  </li>
  <li>Despite these challenges, the franchise's robust business model, characterized by <strong>high AUVs</strong> and a strategic focus on maintaining high-quality customer service, provides a buffer against economic downturns
    <ul>
      <li>The owner is prepared to absorb a potential <strong>10% drop in sales</strong>, indicating strong financial planning and a commitment to maintaining service standards without compromising on quality</li>
    </ul>
  </li>
  <li>The interview also highlighted interest in emerging restaurant concepts like <strong>Tropical Smoothie Cafe</strong>, emphasizing the importance of diversification and exploration of new business opportunities
    <ul>
      <li>Tropical Smoothie Cafe's <strong>rapid growth and diverse menu offerings </strong>present an attractive model, though territory availability and market fit are crucial considerations for expansion</li>
    </ul>
  </li>
</ul>
          `,
        ],
        image: "/spotlight/wingstop_1.webp",
      },
      {
        id: 2,
        title: "Spotlight #2 - Wingstop Franchise Owner - March 2024",
        description:
          "Ex-hotelier explains his preference for Wingstop’s resilient fast-casual model over the extreme cyclicality of the hospitality sector; he also believes that “saturation” is ultimately a good thing because minor cannibalization is more than offset by the increased strength in brand awareness",
        points: [
          `
          <ul>
  <li>The franchise owner transitioned from a corporate job to the <strong>hotel business in 2004</strong>, driven by a desire for independence; starting with a hotel acquisition in the South, they gained valuable experience and insights into community engagement and business operations
    <ul>
      <li>The economic downturn in 2008 prompted a temporary exit from the hotel industry, but by 2011, they returned to hotel management; in 2014, they sold their second hotel and sought new opportunities in the restaurant sector, leading them to Wingstop</li>
      <li>The decision to enter the Wingstop franchise was influenced by several factors, including the <strong>brand's appeal in high-cost real estate areas</strong> like the Northeast, the <strong>simplicity of its menu</strong>, and the <strong>ease of operations with a small footprint</strong>; the typical Wingstop location is around <strong>1,500 square feet</strong>, though recent trends favor even smaller units due to the predominance of takeout and delivery services</li>
    </ul>
  </li>
  <li>The franchise owner's <strong>first Wingstop location opened in 2016</strong>, followed by a <strong>second in early 2017</strong> - the initial location <strong>quickly broke even</strong>, indicating strong market demand; however, the second location, in a less optimal area, faced slower growth until the opening of a <strong>nearby Taco Bell in 2019</strong> boosted traffic and sales
    <ul>
      <li>The owner emphasizes the importance of efficient order management, particularly as takeout and delivery dominate, comprising about <strong>95% of sales post-pandemic</strong>; proximity to a Taco Bell drive-through highlights the <strong>efficiency and convenience of Wingstop's online ordering</strong> and quick <strong>pickup</strong> process</li>
    </ul>
  </li>
  <li>On average, the stores handle between <strong>100 to 180 transactions daily</strong>, with weekend sales being higher; the franchise owner estimates weekly sales at around <strong>$20,000</strong>, with an average ticket size of <strong>$20</strong>; larger orders, such as those for group events, are common on weekends and evenings
    <ul>
      <li>The business's growth strategy involves analyzing customer segments, though specific data on customer demographics and order patterns has only recently become available from corporate; the owner notes that while beverage sales are generally low, comprising <strong>less than 5% of total sales</strong>, the inclusion of <strong>Coca-Cola Freestyle machines</strong> aligns with Wingstop's branding focus on offering a variety of flavors</li>
    </ul>
  </li>
  <li>The protein, particularly chicken wings, accounts for about <strong>70% of sales</strong>, with a notable shift towards <strong>boneless wings and tenders</strong> due to volatility in <strong>bone-in wing pricing</strong>; this shift has been encouraged by pricing strategies that make boneless options more attractive to customers - the franchise currently sees a <strong>50-50 split</strong> between bone-in and boneless products
    <ul>
      <li>The franchisee has observed significant <strong>price inflation</strong> in the cost of both boneless and bone-in wings, necessitating regular price adjustments; recent changes have included a <strong>7% price increase on boneless wings</strong>, partly to balance costs and steer customers back towards bone-in options, which have become comparatively less expensive</li>
    </ul>
  </li>
  <li>The franchise owner discusses the complexities of pricing strategies and regional differences; while Wingstop provides guidance, franchisees have the <strong>autonomy to adjust prices</strong> based on local conditions - despite substantial price increases in the past year, overall <strong>sales and profitability</strong> have remained strong, suggesting that the pricing strategy has been effective
    <ul>
      <li>Reflecting on the impact of the COVID-19 pandemic, the franchise owner reports significant sales growth since 2019, with one store seeing a <strong>25-30% increase</strong> and another experiencing a <strong>45-50% rise</strong>, largely due to <strong>increased brand awareness</strong> and new customer acquisition through advertising, including television</li>
    </ul>
  </li>
  <li>The owner highlights the <strong>importance of brand recognition</strong>, noting that recent television advertising has significantly boosted new customer traffic; about <strong>30% of these new customers have become regulars</strong>, defined as visiting multiple times per quarter - investment in advertising, particularly in high-cost markets like the New York City metro area, is seen as <strong>crucial despite the high costs</strong>
    <ul>
      <li>The franchise's <strong>focus on premium locations</strong> over cheaper alternatives has been key to its strategy; the first two stores were carefully chosen for <strong>high visibility and traffic</strong>, with one benefiting from being near <strong>major retailers</strong> and a new Taco Bell</li>
    </ul>
  </li>
  <li>The cost of building out new Wingstop locations has risen significantly, from around <strong>$400,000-$450,000</strong> to potentially over <strong>$600,000</strong> due to increased costs of equipment, labor, and materials; the franchise owner notes the challenges of navigating <strong>local regulations</strong>, especially in <strong>older buildings</strong>
    <ul>
      <li>Operationally, Wingstop locations are equipped with a <strong>minimum of five fryers</strong>, allowing them to handle <strong>weekly sales of up to $45,000</strong>; however, expansion within existing stores is limited by <strong>physical space constraints</strong></li>
    </ul>
  </li>
  <li><strong>Territory and market saturation</strong> are significant considerations - while the owner acknowledges the potential cannibalization from nearby Wingstop locations, they believe it ultimately <strong>benefits the brand by increasing overall market presence</strong>; the franchise owner has observed that the <strong>corporate strategy now favors multi-unit operators</strong>, reducing the likelihood of new single-unit franchisees entering established markets
    <ul>
      <li>The owner plans to <strong>expand from 3 to 5 locations</strong>, highlighting the evolving market dynamics and increased brand awareness that now make previously less viable territories more attractive</li>
    </ul>
  </li>
  <li>The franchise owner expresses <strong>confidence in Wingstop's long-term prospects</strong>, noting the stability provided by experienced multi-unit operators and the brand's continued growth; they also acknowledge the challenges posed by rising costs and the need for careful financial planning, particularly in <strong>managing debt and cash flow</strong>
    <ul>
      <li>Despite current economic uncertainties, the owner sees continued opportunities for growth, particularly as Wingstop's <strong>brand awareness increases</strong> and new markets open up</li>
    </ul>
  </li>
  <li>The discussion concludes with reflections on the differences between the hotel and restaurant industries, with the owner noting the <strong>cyclical nature and intense competition in the hotel sector</strong>; they express a preference for the <strong>restaurant business</strong>, citing its potential for consistent growth and expansion</li>
</ul>

          `,
        ],
        image: "/spotlight/wingstop_2.webp",
      },
      {
        id: 3,
        title: "Spotlight #3 - Wingstop Franchise Owner - May 2024",
        description:
          "A look into Wingstop’s historic target demographic and how that has evolved over time, culminating into a loyal customer base with a robust brand that has been further bolstered by episodes of high-profile national advertising",
        points: [
          `
          <ul>
  <li>The franchise owner operates <strong>3 Wingstop locations on the East Coast</strong>, with the first opening in 2016, followed by the second in 2017; a third location has recently opened after <strong>significant delays</strong> due to building regulations and supply chain issues
    <ul>
      <li>Initial success was marked by the <strong>first location breaking even on day one</strong>, with consistent growth in sales; the <strong>second location faced challenges</strong> due to its suburban setting and demographic nuances but eventually gained traction after the introduction of a <strong>nearby Taco Bell</strong> and delivery options</li>
      <li>The newest location is in a <strong>110-year-old building</strong>, presenting unique challenges due to <strong>historical preservation regulations</strong> and pandemic-related <strong>construction delays</strong></li>
    </ul>
  </li>
  <li>Wingstop has traditionally relied on a demographic base of <strong>Hispanic and African American customers</strong>; however, the brand has been expanding its appeal, particularly with the introduction of <strong>delivery services</strong>
    <ul>
      <li>The company's market strategy initially targeted high Hispanic areas, specifically <strong>Mexican communities</strong>, which proved more successful compared to other Latin American demographics</li>
      <li>The franchise has noticed a shift with the inclusion of delivery services, which have attracted a <strong>broader demographic with higher disposable incomes</strong>, increasing the average ticket size for delivery orders to around <strong>$27-$28</strong> compared to <strong>$20-$21 in-store</strong></li>
    </ul>
  </li>
  <li>The franchisee has navigated <strong>volatile wing costs</strong>, which peaked at <strong>$130 per case</strong> during 2021; current costs have <strong>stabilized between $55 and $75 per case</strong>, reflecting a significant decrease from the peak and even below 2019 levels
    <ul>
      <li><strong>Price increases</strong> have been implemented strategically, with a total rise of around <strong>20% in the Northeast over the past two years</strong> to offset rising labor costs and inflation; this includes a specific focus on adjusting boneless wing prices due to higher costs</li>
      <li>Despite challenges, the franchisee reports that current food costs are now around <strong>30%</strong>, a notable improvement from previous years, thereby enhancing overall profitability</li>
    </ul>
  </li>
  <li>A significant shift in Wingstop's advertising strategy has seen <strong>local ad funds redirected to a national push</strong>, resulting in increased brand visibility and market penetration, particularly through <strong>high-profile sports sponsorships</strong> and increased TV presence
    <ul>
      <li>The national focus has been beneficial, particularly in markets like the <strong>Northeast</strong>, which were newer to Wingstop and where local marketing efforts were less established</li>
    </ul>
  </li>
  <li>The introduction of the <strong>chicken sandwich</strong> has been a major success, initially <strong>boosting daily sales by 20%</strong> and contributing significantly to lunch traffic; this product has been particularly popular among younger demographics and has helped attract new customers
    <ul>
      <li>The sandwich, priced at <strong>$9</strong>, offers a competitive alternative to similar products from other fast-food chains, often <strong>providing better value</strong> with included dips and larger drink sizes</li>
      <li>The owner notes that the chicken sandwich has <strong>not overly complicated kitchen operations</strong>, aside from slight delays during peak times like the Super Bowl; training and equipment adjustments have been minimal, requiring only a new toaster</li>
    </ul>
  </li>
  <li>Wingstop continues to differentiate itself from competitors through its focus on <strong>freshly prepared wings and high-quality customer service</strong>; competitors like <strong>virtual brands and ghost kitchens</strong> have not significantly impacted Wingstop’s market position due to their limited advertising and delivery reach
    <ul>
      <li>The franchise owner expresses concerns about the potential for increased competition, particularly from ghost kitchens and other brands entering the wing market; however, Wingstop's established brand and <strong>loyal customer base</strong> provide a strong competitive advantage</li>
    </ul>
  </li>
  <li>The franchisee is <strong>optimistic about future growth</strong>, with plans to open additional locations and capitalize on the brand's growing popularity; challenges such as rising labor costs and economic uncertainties are acknowledged but are seen as manageable within the current business model
    <ul>
      <li>The potential for new product lines, such as <strong>plant-based options</strong>, is noted as a future consideration, though <strong>not currently a priority</strong> given the strong performance of existing menu items</li>
    </ul>
  </li>
  <li>There has been a noticeable shift in corporate policy towards <strong>stricter enforcement of development agreements</strong>, reflecting a broader push for growth and consistency across the franchise network; this includes potential contract enforcement and a focus on meeting growth targets
    <ul>
      <li>The owner appreciates the support and strategic direction provided by Wingstop's corporate leadership, highlighting effective management and communication, particularly in navigating market challenges and leveraging new opportunities</li>
    </ul>
  </li>
  <li>The interviewee also expresses interest in other fast-growing franchises, particularly <strong>Tropical Smoothie Cafe</strong>, noting its strong performance and appealing business model; however, current focus remains on expanding within the Wingstop brand
    <ul>
      <li><strong>Skepticism</strong> is expressed towards the <strong>salad market</strong> and other niche concepts, which are viewed as <strong>less stable</strong> and having <strong>lower barriers to entry</strong> compared to the structured and branded offerings of Wingstop</li>
    </ul>
  </li>
</ul>
          `,
        ],
        image: "/spotlight/wingstop_3.webp",
      },
      {
        id: 4,
        title: "Spotlight #4 - Wingstop Franchise Owner - July 2024",
        description:
          "Discussion of the pandemic-related gyrations that underscored Wingstop’s unique strengths with a simplified menu leveraged for a fast casual model; interviewee provides details on how margins vary across various poultry categories and expresses optimism for growth prospects",
        points: [
          `
          <ul>
  <li>The franchisee began their journey with Wingstop in 2014 and opened their first store in October 2015; they currently own <strong>6 Wingstop locations</strong>, with additional stores under development - their background also includes managing <strong>83 Burger King locations</strong> and involvement with <strong>Orangetheory Fitness</strong>, highlighting their broad experience in the franchise and fitness industries
    <ul>
      <li>Wingstop operates <strong>1,500 restaurants across 30 countries</strong> - the typical cost to open a Wingstop store ranges from <strong>$450,000 to $500,000</strong> depending on factors like location and build-out specifications; the average annual sales per store range between <strong>$950,000 and $1 million</strong>, with a noticeable increase in 2020 due to heightened delivery demand during the COVID-19 pandemic</li>
    </ul>
  </li>
  <li>Franchisees are subject to a <strong>6% royalty fee</strong> and a <strong>4% advertising fee</strong>, broken down into <strong>3% for national advertising</strong> and <strong>1% for local efforts</strong>; the initial franchise fee is <strong>$20,000</strong> for the first store, with subsequent stores requiring a <strong>$12,500 fee</strong> and an additional <strong>$10,000 territory development fee</strong> - these fees cover the right to use the Wingstop brand, access to their proprietary menu, and corporate support
    <ul>
      <li>Wingstop's competitive positioning is unique, with <strong>few direct national competitors</strong> - while Buffalo Wild Wings offers a similar product, it operates as a <strong>full-service restaurant</strong> rather than a fast-casual model; Popeyes, known for its fried chicken, and Chick-fil-A, another major player, focus on broader chicken offerings rather than exclusively on wings - this differentiation allows Wingstop to carve out a <strong>niche in the fast-casual market</strong> with a strong emphasis on <strong>fresh, never-frozen chicken wings</strong></li>
    </ul>
  </li>
  <li>The franchisee estimates that approximately <strong>80% of customers are repeat patrons</strong>; tracking customer loyalty is challenging due to a lack of advanced analytics tools - the current system does not collect comprehensive customer data beyond basic order information, making it difficult to measure repeat visits accurately; the franchisee expresses a desire for better customer tracking systems, such as a loyalty program, to enhance customer engagement and retention</li>
  <li>Wingstop has been <strong>proactive in adopting digital and delivery platforms</strong>, a strategy that has proven crucial, especially during the pandemic; the company initially lost partnerships with Uber Eats and Grubhub in 2019 due to a national agreement with <strong>DoorDash</strong> - however, following significant feedback from franchisees, particularly in regions where DoorDash's market presence was weak, Wingstop reinstated <strong>Uber Eats in March 2020</strong> - this decision, coinciding with the onset of the COVID-19 pandemic, significantly boosted sales as delivery became a vital revenue stream
    <ul>
      <li>Despite the added costs associated with delivery platform commissions (<strong>12% for DoorDash</strong> and <strong>10% for Uber Eats</strong>), these services are essential; they not only facilitate order processing but also increase average ticket sizes, which helps <strong>offset the commission fees</strong> - the delivery partnerships are integrated into Wingstop's point-of-sale (POS) systems, streamlining operations and enhancing customer convenience by allowing orders to be placed <strong>directly through Wingstop's website</strong></li>
    </ul>
  </li>
  <li>Pricing strategies vary by region, reflecting local economic conditions, labor costs, and market dynamics - for instance, Wingstop locations in Texas may have lower prices due to cheaper labor and supply costs compared to those in New York; the franchisee has some flexibility in setting prices, particularly on delivery platforms, where prices are slightly increased to cover additional costs - this strategy helps maintain profitability despite <strong>fluctuating poultry prices</strong>, which have been particularly volatile, with <strong>bone-in chicken wing prices reaching unprecedented highs</strong></li>
  <li>The franchisee discusses the product mix and margin considerations, noting that <strong>boneless wings and tenders offer the highest margins</strong>; in contrast, bone-in wings, while popular, have a <strong>higher cost structure</strong> due to their fresh nature - Wingstop's menu is streamlined, focusing primarily on chicken and a limited selection of sides and drinks; despite the simplicity of the menu, the franchisee feels there is room for innovation, particularly in developing new sides and flavors to attract customers and increase sales
    <ul>
      <li>Wingstop's promotional strategy is conservative, positioning itself as a <strong>non-discount brand</strong>; while promotions are infrequent, they do occur, typically focusing on items like boneless wings bundles - for example, a promotion might offer a <strong>boneless bundle for $16.99</strong>, which includes a set amount of wings, fries, and dips - these promotions are usually <strong>short-lived</strong>, running for about eight weeks, and are managed at the corporate level to maintain brand consistency</li>
    </ul>
  </li>
  <li>The COVID-19 pandemic has significantly shifted Wingstop's sales dynamics; prior to the pandemic, the split was roughly <strong>70% in-store orders to 30% delivery</strong> - this shifted to a <strong>50-50 balance</strong> during the pandemic, with delivery becoming a crucial component of the business - the franchisee anticipates that while some customers will return to dine-in, the convenience of delivery will continue to drive a significant portion of sales; this shift underscores the importance of maintaining strong digital and delivery infrastructures
    <ul>
      <li>Wingstop's approach to international expansion has been measured, with notable success in markets like <strong>Colombia, Mexico, and the UK</strong>; the franchisee is not directly involved in these international operations but acknowledges the brand's potential for global growth, driven by the <strong>universal appeal of chicken wings</strong> and the simplicity of the operational model</li>
    </ul>
  </li>
  <li>The relationship between franchisees and Wingstop's corporate team has been mixed, with some challenges noted due to <strong>high turnover among corporate staff</strong> - despite these issues, the franchisee praises the <strong>CEO</strong> for his leadership and consistency, which has provided a stable vision for the company; however, they also note that frequent changes in corporate personnel can lead to inconsistencies in communication and support, affecting franchise operations
    <ul>
      <li>The franchisee's involvement with the <strong>New Jersey and New York Wingstop co-op</strong>, a group formed to pool resources for regional advertising and other initiatives, highlights a collaborative approach among franchisees - this co-op allows for more <strong>substantial advertising efforts</strong> than individual stores could afford, leveraging collective funds for broader impact; the co-op's activities are primarily communicated through group chats and occasional meetings, reflecting a flexible and adaptive approach to regional marketing efforts</li>
    </ul>
  </li>
  <li>Looking ahead, the franchisee expresses <strong>cautious optimism</strong> about Wingstop's growth prospects; while the brand has been expanding rapidly, particularly in regions like New Jersey and New York, there is a recognition of the need for careful <strong>market saturation management</strong> to avoid cannibalizing existing stores - the franchisee believes there is still <strong>room for growth</strong>, estimating that the market could support additional stores without negatively impacting current locations</li>
  <li>The franchisee also reflects on the broader fast-casual market, noting that Wingstop's <strong>focused menu and high-quality product</strong> differentiate it from competitors; the emphasis on <strong>fresh, cooked-to-order chicken wings</strong> sets Wingstop apart from other chains that use frozen products - this focus on quality, combined with strategic pricing and a strong digital presence, positions Wingstop well in a competitive market</li>
</ul>

          `,
        ],
        image: "/spotlight/wingstop_4.jpg",
      },
      {
        id: 5,
        title: "Spotlight #5 - Wingstop Franchise Owner - August 2024",
        description:
          "Dives into the puts and takes of site selection when franchisees look to expand and opines on the impact of the launch of chicken sandwich, posing a slight departure from the traditional wings-centricity of the concept but noting its meaningful success as a value-option in the current macro",
        points: [
          `
          <ul>
  <li>The franchise owner transitioned from a career in hospitality, starting in <strong>2004 with Holiday Inn Express</strong>, before moving to a larger resort hotel where they gained experience with restaurant operations
    <ul>
      <li>In <strong>2014</strong>, the opportunity to become a <strong>brand partner for Wingstop</strong> arose, driven by <strong>attractive sales per square foot</strong> and the simplicity of operations; the franchise model appealed due to its <strong>low complexity</strong>, ease of training staff, and ability to manage with a <strong>lean crew</strong>, particularly during <strong>peak times like the NFL season</strong></li>
      <li>The franchise owner opened their <strong>first Wingstop location in 2016</strong>, followed by a second six months later in 2017; initial challenges included establishing brand recognition in the <strong>Northeast</strong>, where Wingstop was <strong>relatively new</strong></li>
    </ul>
  </li>
  <li>The <strong>first location broke even on day one</strong>, indicating strong market demand, while the second location faced slower growth initially due to its <strong>less ideal location in a strip center</strong> away from high-population areas; however, the opening of a nearby <strong>Taco Bell</strong> in 2019 boosted traffic and sales
    <ul>
      <li>Delivery services, particularly through <strong>DoorDash</strong>, became a crucial component in driving sales, especially during the COVID-19 pandemic, helping the store reach <strong>$1 million in annual sales</strong></li>
      <li>The franchise owner is currently developing a <strong>third location</strong> in a <strong>110-year-old building</strong>, facing significant delays and cost overruns due to regulatory hurdles and the high cost of construction, with final costs expected around <strong>$600,000</strong>; plans for a fourth and fifth location are underway, with a timeline of opening within the next two years</li>
    </ul>
  </li>
  <li>Rising interest rates and increased construction costs are key concerns, with build-out expenses increasing from around <strong>$400,000-$450,000</strong> to over <strong>$600,000</strong> for new locations; these factors impact the financial planning and debt servicing for new stores
    <ul>
      <li>The owner adopts a conservative financial approach, planning for a <strong>five- to six-year payback period</strong> for new stores, balancing debt service with the need for growth; the financial model is stressed by the recent opening of nearby Wingstop locations, impacting local market saturation</li>
      <li>Despite these challenges, the owner reports healthy sales, with one store expecting a <strong>20% year-over-year growth</strong>; four-wall margins initially expected around <strong>22%</strong> have realistically been closer to <strong>15%</strong>, influenced by higher labor costs and generous employee compensation to maintain low turnover</li>
    </ul>
  </li>
  <li>The recent launch of the <strong>chicken sandwich</strong> has been a significant success, with strong initial sales and positive reception due to its value proposition; the sandwich, priced competitively, offers a substantial meal that rivals other fast-food offerings
    <ul>
      <li>The franchise owner highlights the sandwich's role in attracting new customers and boosting <strong>lunchtime sales</strong>, traditionally a slower period for Wingstop; this addition complements the core menu items, helping to offset the <strong>high cost of wings and tenders</strong></li>
      <li>The owner expresses confidence in Wingstop's ability to maintain competitive pricing, even as food costs fluctuate, noting that <strong>prices are likely to remain stable</strong> even as supply costs decrease; this strategy aims to protect margins and manage consumer perceptions of value</li>
    </ul>
  </li>
  <li>The franchise owner acknowledges the potential challenges posed by an upcoming recession, noting that Wingstop's menu and pricing provide some insulation against economic downturns; the <strong>Chicken Sandwich</strong> is seen as a particularly valuable product during tougher economic times due to its affordability
    <ul>
      <li>The owner is cautious about rapid expansion, citing the <strong>high cost of premium locations</strong> and the uncertainty of the economic environment; however, there is optimism about continued growth in the Northeast and other less saturated markets</li>
      <li>The interview also touches on the broader Wingstop network, with the franchise owner noting that corporate has stopped selling new franchises in established markets, likely to manage market saturation and protect existing franchisees</li>
    </ul>
  </li>
  <li>The owner expresses satisfaction with the support received from Wingstop's corporate team, noting that compared to other franchise systems like Dunkin' Donuts or Pizza Hut, Wingstop offers <strong>strong backing and a clear operational focus</strong>
    <ul>
      <li>There is a general sense of optimism among Wingstop franchisees, with many planning further expansion despite current economic uncertainties; however, there is also a recognition of the challenges posed by rising costs and the need for careful site selection</li>
      <li>The interviewee's perspective on the broader quick-service restaurant (QSR) industry includes observations on other franchises like <strong>Jersey Mike's</strong> and <strong>Tropical Smoothie Cafe</strong>, highlighting differences in operational complexity and market appeal</li>
    </ul>
  </li>
  <li>Delivery has become a significant part of the business, accounting for approximately <strong>35% of sales</strong>; the addition of Uber Eats alongside DoorDash has expanded delivery options, though the impact is considered incremental
    <ul>
      <li>The owner notes the potential for <strong>further partnerships</strong>, such as with Grubhub, though there is skepticism about its impact compared to the established players like <strong>DoorDash and Uber Eats</strong></li>
    </ul>
  </li>
  <li>The franchise owner observes <strong>seasonal fluctuations in sales</strong>, with strong performances around sports events and challenges during holiday periods when customers are likely to travel; recent advertising campaigns, particularly during NFL games, have been positively received and are expected to drive further sales growth
    <ul>
      <li>The Chicken Sandwich's introduction is expected to contribute significantly to sales, potentially <strong>adding 5-6% to the top line</strong>, with initial results indicating strong customer interest and incremental growth</li>
    </ul>
  </li>
</ul>

          `,
        ],
        image: "/spotlight/wingstop_5.jpg",
      },
      {
        id: 6,
        title: "Spotlight #6 - Wingstop Franchise Owner - September 2024",
        description:
          "Interviewee discusses the strong interest of institutional capital in buying out multi-unit franchisee groups and expresses optimism for how vertical integration can lead to supply chain stability and profit margin expansion",
        points: [
          `
          <ul>
  <li>The franchise owner operates <strong>5 stores within a defined territory</strong>, with no current development agreements allowing for further expansion; the market saturation in the area has limited opportunities for opening new stores organically, leading to a situation where the only expansion possibility is through <strong>acquiring existing stores</strong> from other franchisees</li>
  <li>There has been <strong>significant interest from potential buyers</strong>, with five different groups expressing interest in purchasing the owner's stores, three of which are willing to pay cash
    <ul>
      <li>The valuations being offered are around <strong>5x EBITDA</strong>, reflecting strong demand and the perceived value of the brand, with many offers coming from existing franchisees looking to expand their holdings</li>
    </ul>
  </li>
  <li>Wing prices have seen significant fluctuations, impacting profitability; last year, prices were as high as <strong>$3.22 per pound</strong>, but have recently dropped to around <strong>$1.63 per pound</strong>, which has alleviated some of the cost pressures
    <ul>
      <li>This drop in wing prices has <strong>revitalized franchisee interest</strong> in expanding their store count, with the potential for <strong>10% growth in store numbers</strong> within the area, translating to about <strong>5 new stores</strong></li>
      <li>Franchisees are <strong>cautiously optimistic</strong>, understanding that while wing prices have decreased, there is always the possibility of future increases</li>
    </ul>
  </li>
  <li>The cost of opening a new Wingstop has risen from about <strong>$350,000 15 years ago</strong> to around <strong>$500,000-$550,000 today</strong>; the increase is largely attributed to <strong>higher labor costs</strong> and general inflation in material costs
    <ul>
      <li>Despite the increase in capital expenditure, new stores are expected to generate robust average unit volumes (AUVs), typically between <strong>$1.3 million and $1.4 million</strong> in the first year</li>
    </ul>
  </li>
  <li>Approximately <strong>70% of the franchise's sales are from delivery</strong>, underscoring the critical role of delivery platforms in the business model; this high percentage of delivery sales reflects consumer behavior shifts, particularly during and post-pandemic
    <ul>
      <li>Of the 70% delivery share, around <strong>60% comes from Wingstop's own app or website</strong>, while <strong>40% is facilitated through third-party platforms</strong> like DoorDash</li>
    </ul>
  </li>
  <li>The franchise currently pays DoorDash a fee of <strong>14.5% on gross sales</strong>, which includes credit card processing fees; this is a reduction from a previous 20% fee, achieved through corporate negotiations
    <ul>
      <li>DoorDash marks up the price of items by approximately <strong>15%</strong>, meaning that while a product might cost <strong>$11.99</strong> on Wingstop's platform, it would be priced at <strong>$13.79</strong> on DoorDash; despite the markup, customers continue to use DoorDash due to its convenience and the platform's broad visibility</li>
    </ul>
  </li>
  <li>The franchise owner reported varied same-store sales performance in the first half of the year, with a notable spike in June attributed to the <strong>NBA Finals</strong> and significant advertising during the games
    <ul>
      <li>Specific monthly performance: January up <strong>12.88%</strong>, February down <strong>1%</strong>, March down <strong>6.5%</strong>, April down <strong>2.99%</strong>, May up <strong>5.84%</strong>, and June estimated up <strong>7.15%</strong></li>
    </ul>
  </li>
  <li>The national TV advertising campaign, particularly during major sporting events, has been effective in boosting brand visibility and driving sales; Wingstop's strategic placement of ads during high-visibility events like the <strong>NBA Finals</strong> has reinforced its brand image and appeal</li>
  <li>Wingstop uses <strong>Olo</strong> for its online ordering system, with a fixed fee per location; this system has been integral in managing digital orders and integrating with delivery platforms
    <ul>
      <li>The franchise owner notes a shift where the cost of Olo is now covered by the franchisees rather than the marketing fund, though the fee has been slightly reduced</li>
    </ul>
  </li>
  <li>The discussion touched on the introduction of new products like the <strong>chicken sandwich</strong>, which, although not yet available at the interviewee's locations, has been positively received in taste tests
    <ul>
      <li>The owner also noted the end of the <strong>Thighstop</strong> virtual brand, which did not perform as expected, accounting for <strong>less than 2% of sales</strong></li>
      <li>Wingstop's consideration of <strong>vertical integration</strong>, including owning chicken farms, is seen as a positive strategic move to stabilize supply and potentially reduce costs; this initiative could help mitigate supply chain disruptions and improve profit margins</li>
    </ul>
  </li>
  <li>The franchise owner remains <strong>optimistic about the future</strong>, with ongoing interest from potential buyers and strong sales performance; the potential for further <strong>growth through acquisitions</strong> remains, particularly if corporate initiatives like vertical integration are successful
    <ul>
      <li>There is also acknowledgment of the challenges ahead, including potential increases in wing prices and labor costs, but the overall outlook remains positive given the brand's strong market position and the ongoing demand for its products</li>
    </ul>
  </li>
</ul>


          `,
        ],
        image: "/spotlight/wingstop_6.webp",
      },
    ],
  },
  {
    slug: "Domino's",
    pageData: {
      pic: "/logos/Domino's Pizza.jpg",
      title: "Domino's",
      initialInvestment: "$160k-$683k",
      franchiseeFee: "$10k",
      royaltyRate: "5.5%",
      description:
        "Domino's Pizza Inc, founded in 1960, has evolved into a global leader in the pizza industry with over 18k locations in more than 90 countries. Initially growing significantly in the late 1980s and 1990s, the company overcame financial struggles through Bain Capital's 1999 acquisition and a successful public offering in 2004. Known for its technological innovations, such as multiple ordering methods and real-time GPS tracking, Domino's relies heavily on its franchise model, with 99% of stores franchised, promoting rapid expansion. Despite recent challenges like labor shortages and competition from gig economy jobs, Domino's has adapted by partnering with third-party delivery services while maintaining a focus on delivery quality. Under strategic leadership, the company continues to thrive, leveraging strong brand recognition and customer loyalty for sustained growth.",
    },
    keyTrends: [
      {
        leftDescription:
          "Domino's franchisees enjoy strong profitability with an average <strong>EBITDA around $150k</strong> and a return on investment (ROI) within <strong>3 to 5 years</strong>",
        rightDescription:
          "Faces competitive pressure from <strong>third-party delivery services like Uber Eats and DoorDash</strong>, leading to a strategic partnership to leverage their customer base while maintaining delivery control",
      },
      {
        leftDescription:
          "Invests heavily in <strong>technology</strong>, including <strong>advanced ordering systems</strong> and <strong>GPS tracking</strong>, to enhance customer convenience and operational efficiency",
        rightDescription:
          "The company struggles with maintaining its <strong>30–40-minute delivery promise</strong> due to labor shortages as franchisees are <strong>unable to exercise wage flexibility</strong> to attract more drivers (must pay within a certain range decided by corporate)",
      },
      {
        leftDescription:
          "<strong>Broad-based appeal of pizza</strong> coupled with the strong consumer trust in Domino’s due to <strong>tight consistency</strong> in product quality and delivery allows for macro resiliency",
        rightDescription:
          "Domino's faces <strong>margin pressures</strong> with increased delivery driver costs post-COVID, exacerbated by competition from higher-paying gig economy jobs",
      },
    ],
    cardData: [
      {
        id: 1,
        title:
          "Spotlight #1 – Former Franchisee Owner at Dominos – August 2023",
        description:
          "Focus on initial delivery challenges and subsequent strategic partnerships while running through store unit economics and valuation",
        points: [
          `
          <ul>
    <li>The interviewee is a <b>former Domino's franchisee owner</b> with over <b>20 years of experience</b>, left Domino's in <b>February 2016</b>
        <ul>
            <li>Managed <b>13 franchise stores</b>, including <b>9 located in University of Florida stadiums</b>, overseeing all aspects of operations, marketing, and sales and sales of around <b>$12M</b></li>
        </ul>
    </li>
    <li>The expert purchased first store in <b>1995</b>, with <b>$250k investment</b> expanding to <b>13 stores</b> and leading them to become the <b>busiest Domino's franchise globally</b> for <b>5 years</b> and sold the stores at <b>6x EBITDA</b>
        <ul>
            <li>The interviewee held significant roles in Domino's, including serving on <b>advisory boards</b> and contributing to the selection of the <b>original POS system</b></li>
            <li>The expert received multiple accolades, including <b>Franchise of the Year 6 times</b> and <b>National Manager of the Year three times</b></li>
        </ul>
    </li>
    <li>Dominos faced labour shortages due to significant increase in costs of delivery drivers after COVID, the delivery companies are paying <b>$30/hour</b>, whereas Dominos pay <b>$15/hour</b> which is much less than the market - this is the reason they face delivery driver shortages
        <ul>
            <li>The management of Dominos direct the franchisee to pay a range to the delivery drivers, the franchisee themselves cannot decide to pay outside of that</li>
            <li>Domino's faces challenges in competing with companies like <b>Amazon</b> for labour, as Amazon pays significantly higher wages</li>
            <li>The previous <b>CEO</b> did not raise wages to compete with higher-paying jobs, but the new leadership under <b>Dave Brandon</b> is now making changes to remain competitive in the market</li>
        </ul>
    </li>
    <li>The labour market, particularly after COVID-19, has posed challenges for delivery staffing, leading to partnerships with third-party delivery services like <b>Uber Eats, Amazon and DoorDash</b></li>
    <li>Dominos is the market leader, and its major strength is its fastest delivery in <b>30-40 minutes</b> if you order directly from the company
        <ul>
            <li>The competitor companies take at least <b>50-60 minutes</b> to deliver a pizza; <b>Papa John’s</b> average delivery time is <b>1 hour or greater</b></li>
        </ul>
    </li>
    <li>Has <b>advanced ordering systems</b> enabling multiple ways to order - including through social media; <b>GPS technology</b> allows precise delivery locations, enhancing customer convenience through pinpoint delivery</li>
    <li>Buying a Domino franchise is very easy anyone can buy there is no limitation to apply for franchisee rights – corporate <b>requires every growing franchisee to split into two stores</b> to increase penetration
        <ul>
            <li>Franchisees have opportunities to purchase existing stores or build new ones - the cost of building a new store is approximately <b>$400k</b>, while purchasing an existing store range between <b>$200k and $400k</b></li>
            <li>Profitability remains strong with average EBITDA around <b>16%</b></li>
        </ul>
    </li>
    <li>Many Domino's franchisees start as delivery drivers and work their way up, which contributes to a deep understanding of the business and operational success</li>
    <li><b>99%</b> of Domino's stores are franchised, allowing for rapid expansion and local market penetration</li>
    <li>The corporation earns from <b>commissaries</b> and franchise royalties, making it less reliant on direct store revenue</li>
    <li>Dominos faces dual competition first with Pizza chains – for example <b>mom-and-pop stores, Papa Johns</b> and other pizza chains, and the other competition is third-party delivery services - like <b>Uber Eats and DoorDash</b>
        <ul>
            <li>Companies like <b>DoorDash and Uber Eats</b> have changed the delivery landscape, often charging higher fees than Domino's; despite this, the demand for food delivery remains strong</li>
        </ul>
    </li>
    <li>Domino's effectively targets different generations through <b>strategic advertising</b>, including a strong presence on platforms like <b>TikTok</b></li>
    <li>Dominos has struggled with delivery times due to a lack of drivers, but it remains competitive in delivery speed Domino's still leads in delivery speed compared to competitors but has faced challenges in meeting the <b>30–40-minute delivery expectation</b> due to driver shortages
        <ul>
            <li>Due to lack to delivery drivers now the company has partnered with <b>Uber Eats</b> to improve delivery operations</li>
            <li>Customers prioritize ease of ordering, quality, consistency, and fast delivery; <b>online ordering</b> is particularly important for convenience</li>
            <li>Dominos has invested heavily in technology, making it easy for customers to <b>order online</b> through various platforms, including text, social media, and even through car interfaces</li>
        </ul>
    </li>
    <li>Pizza is a <b>universally loved food</b> across all demographics and income levels in the US and international markets
        <ul>
            <li>Pizza consumption spans a wide demographic, with no specific category of pizza eaters - preferences vary by <b>convenience, value, taste</b>, and support for local versus corporate brands</li>
            <li>Domino has included pizza types in the menu that supports food preferences like <b>vegan, vegetarian and gluten free options</b></li>
            <li>Nationally <b>consistent pricing</b> builds consumer trust and simplifies the ordering process</li>
        </ul>
    </li>
    <li>Acquisition by <b>Bain Capital (1999)</b> marked a significant financial turnaround for Domino's, leading to improved management and profitability
        <ul>
            <li><b>IPO in 2003</b> provided Domino's with the capital to expand and innovate, contributing to its substantial growth</li>
        </ul>
    </li>
    <li>Domino's corporate supports franchisees with marketing and operational guidance to ensure profitability; with a <b>national advertising budget of around $240 million</b>, Dominos maintains a strong presence in the market and targets every generation</li>
</ul>

          `,
        ],
        image: "/spotlight/dom_4.png",
      },
      {
        id: 2,
        title:
          "Spotlight # 2 – Former Director of Franchise Development at Domino’s Pizza Inc– September 2023",
        description:
          "Overview of site real estate dynamics, performance across various international markets, and longer-term growth trends for the concept",
        points: [
          `
          <ul>
  <li>The interviewee is a <strong>former Director of Franchise Development</strong> at Domino's Pizza Inc, bringing extensive experience in overseeing the expansion and strategic direction of the franchise network</li>
  <li>Franchisees are paying approximately <strong>50% more for Tier B locations</strong> compared to lower-tier options
    <ul>
      <li>5 years ago, franchisees were more inclined to invest in higher-tier properties (Tier D), but now there is a <strong>50-50 split</strong> between Tier B and Tier C locations</li>
      <li>About 50% of new store locations are in Tier B, with the rest in Tier C or lower</li>
    </ul>
  </li>
  <li>The interviewee estimates that Dominos could potentially reach <strong>8,000 to 8,500 stores</strong> in the US over the long term
    <ul>
      <li>High building costs and decreased EBITDA have made franchisees <strong>hesitant to expand</strong>, and this reluctance may persist even if economic conditions improve</li>
      <li>The company has been lenient with franchisee development commitments, allowing some <strong>flexibility in meeting obligations</strong>; under new leadership, there may be stricter enforcement of these commitments, which could create challenges for franchisees accustomed to a more relaxed approach</li>
    </ul>
  </li>
  <li>The <strong>international team</strong> is well-run, and there is optimism about continued growth in markets outside the US
    <ul>
      <li>Problems with master franchisees, particularly in Australia and Brazil, have impacted store performance. In Brazil, challenges with site selection and market adoption have been significant</li>
      <li><strong>Australia:</strong> Facing operational issues, with recent store closures. The situation may reflect broader macroeconomic challenges rather than market saturation</li>
      <li><strong>Mexico:</strong> The market is performing well, with a strong franchisee (Alsea) and healthy growth</li>
      <li><strong>Brazil:</strong> Struggles due to site selection and marketing issues, with limited reasons for optimism in the near term</li>
    </ul>
  </li>
  <li>Despite current challenges and slower growth, the interviewee remains confident in Domino’s <strong>long-term potential</strong> and would still invest in the company, albeit acknowledging that past growth rates may not be achievable in the near term</li>
  <li>Dominos has signed an <strong>exclusive agreement with Uber Eats</strong> but will handle delivery in-house - this approach is intended to leverage Uber Eats for customer acquisition without compromising delivery control
    <ul>
      <li>Franchisees are generally supportive of this move, especially in competitive labor markets, but the impact on incremental profits remains uncertain</li>
      <li>The strategy might not significantly boost profits due to Domino’s existing strong brand recognition and customer loyalty</li>
    </ul>
  </li>
</ul>

          `,
        ],
        image: "/spotlight/dom_2.png",
      },
      {
        id: 3,
        title:
          "Spotlight # 3 – Former Director of Franchise Development at Domino’s Pizza Inc– August 23, 2023",
        description:
          "Explores the interrelated dynamics of agreements with third-party aggregators, labor shortages, and the competitive advantage of Domino’s delivery and consumer trust",
        points: [
          `
          <ul>
  <li>The interviewee, a <strong>former Director of Franchise Development</strong> at Domino's Pizza Inc, offers a wealth of insights into the operational, financial, and strategic aspects of the company
    <ul>
      <li>His role included working closely with franchisees to ensure their success, identifying <strong>new market opportunities</strong>, and implementing strategies to enhance the profitability and operational efficiency of the stores</li>
    </ul>
  </li>
  <li>Quick Service Restaurant (QSR) industry, including Domino's, faces a <strong>labor shortage</strong> due to the less attractive nature of the job, which often involves night and weekend shifts and requires drivers to use their own vehicles
    <ul>
      <li>The <strong>flexibility</strong> of gig economy jobs like Uber Eats and DoorDash makes them more appealing compared to traditional delivery roles</li>
    </ul>
  </li>
  <li>Domino's could improve <strong>employee retention</strong> by offering career advancement opportunities and additional benefits such as healthcare, tuition reimbursement, and retirement savings
    <ul>
      <li>Emphasizing <strong>career growth</strong> from delivery driver to store manager or franchise owner can enhance the value proposition for employees</li>
    </ul>
  </li>
  <li><strong>Carry-out orders</strong>, especially those placed digitally and prepaid, are more profitable for Domino's compared to delivery orders due to reduced labor and operational costs
    <ul>
      <li>There are distinct customer bases for delivery and carry-out, and economic conditions might shift some delivery customers towards carry-out to save on delivery fees</li>
    </ul>
  </li>
  <li>Opening a Domino's store typically requires an <strong>investment</strong> ranging from <strong>$400k to $1 million</strong>, depending on the location and type of build
    <ul>
      <li>Franchisees generally seek a return on investment (ROI) within three years, with an average store-level EBITDA of around <strong>$150k</strong> – this is considered highly lucrative, especially when compared to other pizza chains like Papa John’s, which might generate only half of that amount</li>
      <li>The company’s expansion plans are more realistic over a 5-6 year period, aiming to add <strong>250 new units</strong> annually</li>
    </ul>
  </li>
  <li>Despite the rise of delivery platforms, competitors like <strong>Little Caesars</strong> remain strong in the carry-out segment
    <ul>
      <li>The proliferation of delivery options has increased competition, but Domino's <strong>competitive advantage</strong> lies in its ability to deliver a consistently high-quality product</li>
      <li>While third-party services offer a wide range of food options and heavily subsidized promotions, Domino's relies on the quality and consistency of their delivery experience to retain customers; the interviewee expressed confidence that Domino's value proposition would remain strong even if third-party services stopped offering subsidies</li>
      <li>Domino's maintains a significant competitive edge by controlling its own delivery system, which allows them to ensure a more consistent, hotter, and fresher product compared to competitors who rely on third-party delivery services like Uber Eats and DoorDash</li>
    </ul>
  </li>
  <li>Domino's is exploring the use of <strong>company-owned vehicles</strong>, including electric fleets, to address labor shortages - this approach can reduce insurance costs and attract drivers who lack their own vehicles
    <ul>
      <li>Domino's places a strong emphasis on encouraging customers to place orders digitally, whether through their app or online</li>
      <li>Digital orders that are prepaid and picked up carry-out are the <strong>most profitable</strong>, as they streamline the process and reduce labor costs</li>
      <li>The Domino's app and <strong>loyalty programs</strong> are critical components of their marketing strategy - the app not only facilitates easy ordering but also allows customers to earn rewards, enhancing customer retention and encouraging repeat business</li>
    </ul>
  </li>
</ul>

          `,
        ],
        image: "/spotlight/dom_3.png",
      },
      {
        id: 4,
        title:
          "Spotlight # 4 – Former Director of Franchise Development at Domino’s Pizza Inc– July 28, 2023",
        description:
          "Discussion on localization and menu diversification to appeal to a broader base of evolving demographics while noting Domino’s share gains and technological leadership",
        points: [
          `
          <ul>
  <li>The interviewee, a former <strong>Director of Franchise Development</strong> at Domino’s Pizza, brings a wealth of experience and insider knowledge to the discussion about the company's franchise model, growth strategies, and competitive positioning.</li>
  <li>Domino's is a <strong>well-known and trusted brand globally</strong> - this brand recognition helps drive initial interest and adoption in new markets.</li>
  <li>Domino's has been successful in adapting its menu to cater to <strong>local tastes and preferences</strong> - this includes offering region-specific toppings and sides, which helps the brand resonate with local consumers.
    <ul>
      <li>The expert highlights the need for Domino's to embrace more innovation, including <strong>vegan alternatives</strong> and new products.</li>
      <li>The <strong>Domino's app</strong> is a crucial tool for attracting and retaining customers, though younger generations may be less loyal.</li>
    </ul>
  </li>
  <li>The franchise remains highly profitable, with an <strong>average EBITDA of $134k</strong> and a return on investment within <strong>~5 years</strong>, making it an appealing option in the quick-service restaurant (QSR) sector.
    <ul>
      <li>The carryout segment has grown significantly, now accounting for over <strong>40% of sales</strong>, thanks to strategic real estate placements and national advertising - however, future growth in this area may be slower.</li>
    </ul>
  </li>
  <li>The interviewee also discussed the impact of <strong>third-party aggregators</strong>, which offer more delivery options and could affect Domino's market share.
    <ul>
      <li>They also mentioned the challenge of <strong>cannibalization</strong>, where new Domino's stores might take sales from existing ones, and the rise of ghost kitchens as a potential growth avenue.</li>
      <li>About <strong>60% of franchisees</strong> prefer third-party aggregators to handle deliveries, while <strong>40% support Domino's traditional delivery model.</strong></li>
      <li>The expert suggests that Domino's could negotiate a fee of <strong>15-20%</strong> with Uber Eats, which is lower than the standard <strong>25-35%</strong> for smaller chains.</li>
    </ul>
  </li>
  <li>Despite Domino's <strong>historical leadership in technology</strong>, continuous innovation is necessary to maintain its edge.
    <ul>
      <li>Environmental initiatives, such as <strong>electric delivery vehicles</strong>, are positive but face practical challenges for widespread adoption by franchisees.</li>
    </ul>
  </li>
  <li>Finally, the interviewee suggested that Domino's might need to adapt its franchise model to allow for faster growth, possibly by integrating more <strong>large area developers</strong> or modifying sponsorship requirements for new franchisees.
    <ul>
      <li>The expert believes that big chains, except <strong>Chick-fil-A</strong>, may lose market share to smaller competitors and aggregators.</li>
      <li>Domino's has successfully <strong>taken market share</strong> from smaller, independent pizza shops, particularly in localized markets - this opportunity remains significant, especially in regions with a high density of mom-and-pop pizza stores.</li>
    </ul>
  </li>
</ul>


          `,
        ],
        image: "/spotlight/dom_1.png",
      },
      {
        id: 5,
        title:
          "Spotlight # 5– Former Director of Franchise Development at Domino’s Pizza Inc– February 13, 2023",
        description:
          "Highlighted the strengths and challenges faced by Domino's, particularly in the context of the COVID-19 pandemic, and offered insights into the company's succession planning, international expansion, and innovation efforts",
        points: [
          `
          <ul>
    <li>The interviewee is a former <strong>Director of Franchise Development</strong> at Domino's Pizza Inc, who left the company in <strong>April 2021</strong> - during his tenure, he oversaw a team responsible for the growth of <strong>318 franchise groups</strong> and <strong>1,900 stores across 18 states</strong></li>
    <li>Responsible for a team overseeing the growth of <strong>318 franchises</strong> and <strong>1,900 stores across 18 states</strong> Accountable for franchising strategies, sales budget analysis, and market growth
        <ul>
            <li>The success of Domino's Pizza is largely attributed to their passionate and experienced franchisees, many of whom began their careers as <strong>drivers</strong></li>
            <li>The franchise model requires potential franchisees to <strong>manage a store for at least a year</strong> and have a <strong>sponsor</strong> from within the franchisee network, which results in higher operational efficiencies</li>
            <li>Challenges during the pandemic included <strong>bringing back drivers</strong> and a decrease in sales</li>
            <li>The expert emphasized the potential need for <strong>new management perspectives</strong> to address issues with third-party aggregators and improve service times</li>
        </ul>
    </li>
    <li>Passionate franchisees lead to <strong>better-trained staff</strong>, resulting in higher operating efficiencies and <strong>lower attrition rates</strong> compared to industry standards
        <ul>
            <li>Domino’s has a strong talent pool and <strong>succession planning</strong> but faces issues with <strong>retaining talent</strong> and balancing experience with youthful leadership - this sometimes creates tension with franchisees who may find younger leaders less credible</li>
        </ul>
    </li>
    <li>Innovations such as <strong>improved pizza boxes</strong>, <strong>delivery apps</strong>, and <strong>GPS tracking systems</strong> contribute to operational efficiencies
        <ul>
            <li>There is still substantial <strong>growth potential</strong> in the pizza industry in the US, but Domino’s needs to address the shift towards <strong>healthier options</strong> and improve their approach to aggregators</li>
        </ul>
    </li>
    <li>All major innovations come from the Domino's <strong>Innovation Garage</strong>, ensuring uniformity and standardization across stores - while innovation is centralized, franchisees play a critical role in <strong>local market adaptations</strong> and operational improvements</li>
    <li>A major challenge was the inability to bring back drivers post-pandemic, affecting delivery times and sales
        <ul>
            <li>The company needs to re-evaluate its stance on <strong>third-party aggregators</strong> and explore new delivery methods, including <strong>automated vehicles</strong>, despite the high costs involved</li>
        </ul>
    </li>
    <li>The rise of <strong>third-party delivery services</strong> poses a <strong>significant threat</strong> - Domino's traditionally resisted these aggregators, but the pandemic highlighted the need for potential strategic shifts
        <ul>
            <li>The company is slow to adapt, and their focus on <strong>in-house delivery</strong> is seen as a potential weakness</li>
        </ul>
    </li>
    <li>Domino’s <strong>international business</strong> is generally more successful than domestic operations, with significant growth in countries like <strong>India</strong>; success varies by market, with some regions outperforming others due to factors such as marketing effectiveness and local execution
        <ul>
            <li>During the interview, it was noted that franchisees in Domino’s had an average <strong>EBITDA of > $136k per year</strong> - this figure reflects the strong profitability of the franchise model</li>
        </ul>
    </li>
    <li>Franchise agreements vary by country and are influenced by factors like <strong>market maturity</strong> and <strong>local conditions</strong> - agreements often include royalty abatements and development fees to support growth</li>
    <li>Despite being stuck in traditional models, Domino’s is <strong>exploring innovations</strong> like <strong>driverless vehicles</strong> and alternative delivery methods - however, competitors have already adapted to aggregator challenges more effectively
        <ul>
            <li><strong>Carryout</strong> already constitutes a significant portion of Domino’s business and is a strong aspect of their model - further increasing carryout is seen as a way to mitigate delivery challenges, but its growth potential may be limited</li>
        </ul>
    </li>
    <li>Companies like <strong>Chick-fil-A</strong> and emerging brands like <strong>Slim Chickens</strong> and <strong>Chicken Salad Chick</strong> are noted as strong players in the QSR space, with potential for continued success both domestically and internationally</li>
</ul>


          `,
        ],
        image: "/spotlight/dom_5.png",
      },
    ],
  },
  {
    slug: "Club Pilates",
    pageData: {
      pic: "/logos/clubpilates.png",
      title: "Club Pilates",
      initialInvestment: "$194k-$407k",
      franchiseeFee: "$60k",
      royaltyRate: "8.0%",
      description:
        "Club Pilates is a fitness franchise specializing in Pilates classes designed to cater to various skill levels and fitness goals. Established in 2007, Club Pilates offers a range of classes that incorporate different Pilates techniques and equipment, such as Reformer machines, TRX, and Bosu balls. The studio environment is welcoming and supportive, focusing on building core strength, flexibility, and overall body awareness. With certified instructors and a diverse class schedule, Club Pilates aims to make Pilates accessible and enjoyable for everyone.",
    },
    keyTrends: [
      {
        leftDescription:
          "<strong>Strong beneficiary of holistic health and wellness trends</strong> especially following the broad-based semi-permanent effects of the Covid pandemic",
        rightDescription:
          "<strong>Substantial payroll, rent, and marketing expenses</strong> pose significant challenges to maintaining profitability",
      },
      {
        leftDescription:
          "<strong>Extensive support from Xponential Fitness</strong> and a focus on affordable, high-quality classes have cemented Club Pilates' strong brand legitimacy and top sales performance with limited national competition",
        rightDescription:
          "Success can be more localized to <strong>affluent locations</strong>, requiring more tactful pricing in less wealthier or younger markets",
      },
      {
        leftDescription:
          "<strong>The membership model</strong> is core to store economics and drives the attractive reliable performance nature of the fitness concept",
        rightDescription:
          "Increasing <strong>private equity interest</strong> may hamper corporate’s willingness to approve single-site franchisees – preferring institutional capital to open new sites",
      },
    ],
    cardData: [
      {
        id: 1,
        title:
          "Spotlight # 1 - Franchise Owner of Club Pilates at Xponential Fitness, August 2022",
        description:
          "Exploring the permanence of the growth in widespread interest of Pilates following Covid, the increasing strength of the Club Pilates brand value over time, and the centrality of the membership model to overall store unit economics",
        points: [
          `
          <ul>
  <li>The interviewee is a Franchise Owner of Club Pilates at Xponential Fitness - they have been involved with the franchise since its early days and have seen significant growth and acceleration in recent years, particularly post-2021
    <ul>
      <li>The owner operates in <strong>affluent areas</strong>, which has influenced their pricing and membership strategies</li>
      <li>The owner is <strong>debt-free</strong> on their initial studios and sees a <strong>strong value proposition</strong> in the Club Pilates brand - they have observed higher performance and legitimacy of the brand over time and have plans to open more studios. The interviewee sees the brand as a leader in the Pilates space, with <strong>minimal national competition</strong></li>
    </ul>
  </li>
  <li>During the early years, management was <strong>very hands-on</strong>, with close support from Xponential’s team but over time the franchisor management has become more <strong>corporate</strong>, with a <strong>standardized sales model</strong> applied across brands
    <ul>
      <li>The sales model emphasizes <strong>recurring subscription revenue</strong>, which was a core aspect of Club Pilates from the start</li>
    </ul>
  </li>
  <li>Club Pilates primarily focuses on <strong>value</strong>, <strong>customer engagement</strong>, and <strong>brand legitimacy</strong> - a crucial element of their strategy is offering <strong>high-quality, affordable classes</strong> compared to local studios
    <ul>
      <li>Offering high-quality instruction is a key factor in the success of Club Pilates studios - larger class size (up to <strong>12 people</strong>) and a higher number of daily classes (<strong>8-9 per day</strong>)</li>
    </ul>
  </li>
  <li>Relies on a <strong>recurring subscription model</strong> with a focus on memberships rather than individual class packages.
    <ul>
      <li>Uses a structured lead follow-up process involving <strong>text and phone calls</strong>, with adjustments based on successful practices across Xponential’s brands</li>
      <li>Primarily utilizes <strong>Facebook and Instagram ads</strong>, spending between <strong>$500 to $2k per month per studio</strong> - adjusting marketing spend based on studio capacity and performance</li>
    </ul>
  </li>
  <li><strong>Pre-COVID</strong> average studio revenue was in the mid to high <strong>$60k range</strong>; <strong>post-COVID</strong> revenue has increased to high <strong>$80k range</strong>, driven by higher membership numbers rather than price increases
    <ul>
      <li>Experienced a slow recovery after reopening in June 2020; however, recent performance has been strong with <strong>membership numbers</strong> and revenue at all-time highs</li>
      <li>Pre-COVID, margins were around <strong>20-25%</strong> and post-COVID, margins have improved to about <strong>30%</strong></li>
    </ul>
  </li>
  <li><strong>Fixed costs</strong> are stable, but <strong>payroll costs</strong> have increased due to higher wages for skilled instructors and front desk staff. The cost structure is largely fixed aside from variable instructor costs
    <ul>
      <li><strong>Recruiting skilled instructors and front desk staff</strong> is challenging, particularly in high-net-worth areas</li>
    </ul>
  </li>
  <li>Franchise owner of Club Pilates broke out their cost structure: <strong>6% royalties</strong>, <strong>3% marketing</strong>, <strong>2% national marketing</strong>, and <strong>3% credit card fees</strong>, totalling <strong>11% of gross revenue</strong>
    <ul>
      <li>Major costs are <strong>payroll</strong> (approximately <strong>2.25x the rent</strong>) and <strong>rent</strong>, which ranges from <strong>$7.5k to $10k</strong> a month</li>
    </ul>
  </li>
  <li>Initial investment costs around <strong>$250k to $300k</strong>; current costs could reach <strong>$500k</strong> due to increased construction and equipment costs.
    <ul>
      <li>Used SBA loans for initial studios; estimates a <strong>two-year payback period</strong> for the new studio due to higher costs.</li>
      <li><strong>Lower costs and pricing</strong> in less affluent areas, but demand remains strong due to the perceived value</li>
      <li><strong>Demographics</strong>: Mainly <strong>35-55 years old</strong> for the overall concept, but the owner’s studios in particular attract slightly older clients (<strong>45-65 years old</strong>)</li>
    </ul>
  </li>
  <li>The franchise leverages <strong>introductory discounts</strong> and a <strong>tiered pricing model</strong> to attract new members and then gradually transitions them to standard rates as they become more committed</li>
  <li><strong>Private equity firms</strong> are attracted to the <strong>predictable revenue streams</strong> and <strong>scalable business models</strong> that such franchises offer.
    <ul>
      <li>Club Pilates has seen interest from private equity, with some firms owning <strong>multiple studios</strong> highlighting the franchise’s <strong>attractiveness as a stable investment</strong></li>
      <li>The franchisee notes that regardless of ownership, the success of each studio hinges on adhering to the <strong>proven operational model</strong> and maintaining <strong>high-quality instruction</strong></li>
      <li>Private equity's involvement is seen as a potential accelerant for growth, provided it aligns with the brand’s core values and operational standards</li>
    </ul>
  </li>
</ul>
          `,
        ],
        image: "/spotlight/pil_1.jpg",
      },
      {
        id: 2,
        title:
          "Spotlight # 2 - Franchise Owner of Club Pilates and YogaSix, November 2021",
        description:
          "Dives into tactful pricing adjustments for navigating through economic downturns, operational support levers provided by corporate management, and the puts and takes associated with accommodating high membership levels in both Club Pilates and YogaSix",
        points: [
          `
          <ul>
  <li>The interviewee is a franchise owner of Club Pilates and YogaSix with a background in managing fixed costs and pricing strategies, they have successfully navigated their business through challenges, such as the COVID-19 pandemic, leveraging robust support from Xponential Fitness
    <ul>
      <li>Their strategic focus on professional management and thoughtful pricing adjustments has positioned their studios among the <strong>top 20% in sales within the franchise network</strong> - looking ahead, they are aiming to increase membership to <strong>500 per location</strong> while maintaining high operational standards</li>
    </ul>
  </li>
  <li>Initial investment of <strong>$200k to $300k</strong> to open a Club Pilates location with marketing expense around <strong>$2k per studio</strong>; fixed costs are <strong>$35k to $40k per month per studio</strong>
    <ul>
      <li>Breakeven can be achieved at <strong>~200 members</strong> for Club Pilates and <strong>220 members</strong> for YogaSix</li>
      <li>Franchise fees are around <strong>11%</strong>, including franchise fee, marketing fee, and POS charges</li>
    </ul>
  </li>
  <li>Average revenue per member is <strong>$170</strong>, with current membership around <strong>360 per studio</strong>, down from <strong>400 pre-COVID</strong>
    <ul>
      <li>Revenue includes retail sales and private training, with private training yielding around a <strong>35% margin</strong></li>
      <li>Monthly revenue per studio is approximately <strong>$61k</strong>, with a target to increase to pre-COVID levels and beyond; current profit margin over <strong>30% after franchise fees</strong></li>
    </ul>
  </li>
  <li><strong>Xponential Fitness support</strong> is highly praised, especially for their swift adaptation during COVID-19, including transitioning to digital classes and providing extensive operational guidance</li>
  <li>Pre-sales and <strong>founder member benefits</strong> (20% off for life) are crucial for early member acquisition and retention</li>
  <li>Club Pilates was chosen over other franchises due to its <strong>strong support system and operational model</strong>
    <ul>
      <li>The franchise system is designed to operate efficiently with professional management, allowing for absentee ownership if desired.</li>
      <li><strong>Franchisees are in the top 20% of sales among the 700 Club Pilates locations</strong>, indicating strong performance – corporate support included strategies for maintaining membership without freezing accounts and negotiating with landlords</li>
    </ul>
  </li>
  <li>The franchise system at Club Pilates and YogaSix emphasizes operational support and management guidance.
    <ul>
      <li>Xponential Fitness, the parent company, provides significant support to franchisees, helping them with day-to-day management and strategic decisions.</li>
      <li>Initial franchise selection involved considering various options, including <strong>9 Round and Snap Fitness</strong>, with Club Pilates being chosen for its investor-minded approach</li>
    </ul>
  </li>
  <li>Pricing strategies are crucial for maintaining and increasing revenue. The interviewee successfully raised membership prices from <strong>$199 to $229 per member</strong>, with minimal churn
    <ul>
      <li>Emphasis on the importance of pricing in the fitness industry, drawing parallels to the beer industry where different pricing tiers and options are used to maximize revenue</li>
      <li>Potential for further price increases, provided they are implemented across all franchise locations</li>
    </ul>
  </li>
  <li>Challenges in accommodating high membership levels operationally, particularly in wealthier areas where members have more availability to attend classes
    <ul>
      <li>Emphasis on operational efficiency to support membership growth without compromising the quality of service</li>
    </ul>
  </li>
</ul>
          `,
        ],
        image: "/spotlight/pil_2.webp",
      },
      {
        id: 3,
        title:
          "Spotlight # 3 - Franchise Owner of Club Pilates locations, June 2023",
        description:
          "Provides a comparison of Pure Barre vs Club Pilates – finding improved profitability and retention in the latter; speak to the acceleration of demand in both concepts following the overall rise in wellness after the pandemic",
        points: [
          `
          <ul>
  <li>The interviewee is a franchise owner of <strong>multiple Club Pilates and Pure Barre</strong> locations - they have managed their businesses with no current loans, benefiting from historically low interest rates when they did utilize financing
    <ul>
      <li>Demonstrating strong operational insights, they have observed consistent growth in their studios, particularly notable since March, attributed to a <strong>change in public sentiment post-COVID-19</strong></li>
      <li>Their attention to key performance metrics such as <strong>recurring dues</strong>, <strong>attendance</strong>, and <strong>member retention</strong>, has enabled them to navigate and thrive in the evolving fitness industry landscape</li>
    </ul>
  </li>
  <li>The interview highlights a comparison between <strong>Club Pilates</strong> and <strong>Pure Barre</strong>, emphasizing the higher profitability and retention rates of Club Pilates
    <ul>
      <li>It also delves into the operational and financial challenges faced by <strong>Pure Barre</strong> franchises, especially post-COVID-19, and the competition within the fitness industry</li>
    </ul>
  </li>
  <li><strong>Pure Barre</strong> has introduced new formats like Define, which focuses on <strong>strength training</strong> - this adaptation to current fitness trends can attract more customers and improve the retention rate</li>
  <li>The interviewee currently has no loans on their businesses, except for a recent unit purchase
    <ul>
      <li>Previous loans had very low interest rates around <strong>3%</strong>, compared to current rates closer to <strong>7%</strong></li>
    </ul>
  </li>
  <li>Steady and consistent growth in <strong>Club Pilates</strong> locations, with <strong>two-year-old studios</strong> performing similarly to <strong>one-year-old studios</strong>
    <ul>
      <li>Sales have accelerated notably since March, attributed to the diminishing impact of COVID-19 on consumer behavior</li>
      <li>Significant increase in business activity around March-April as people became less concerned about COVID-19 - this period marked the <strong>best performance in three years</strong> for the locations</li>
    </ul>
  </li>
  <li>Increase in recurring dues by approximately <strong>$5k</strong> since March
    <ul>
      <li>Attendance and total number of active members have risen, with Pure Barre seeing a <strong>10–20-member increase</strong> while having lower attrition rates year-over-year</li>
      <li>The interviewee has noticed similar growth patterns in other locations within their brands - monthly reports from both brands reflect <strong>positive trends in key performance indicators</strong></li>
    </ul>
  </li>
  <li>No direct comparison with other brands outside of <strong>Club Pilates</strong> and <strong>Pure Barre</strong>; however, internal reports suggest <strong>positive growth trends</strong> consistent across the franchise’s locations</li>
  <li><strong>Occupancy costs</strong> and <strong>labour costs</strong> are significant factors - franchisees must manage these effectively to ensure profitability; if these costs remain manageable, it can support the opening of more stores
    <ul>
      <li><strong>$20k</strong> is the operating cost run rate for a single site and you need an <strong>initial investment of $550k</strong> is required to open a studio with revenue per month ranging from <strong>$5k to $20k</strong></li>
      <li>Labor costs at least <strong>$30k</strong> a month for Pure Barre and closer to <strong>$40k</strong> in payroll cost at Club Pilates</li>
      <li>In Club Pilates, teachers make a lot more money. Each teacher makes at least <strong>$10 more a class in an urban area</strong> (also depends on the state and the minimum wage)</li>
    </ul>
  </li>
</ul>
          `,
        ],
        image: "/spotlight/pil_3.webp",
      },
      {
        id: 4,
        title:
          "Spotlight # 4 - Franchise Owner of Club Pilates Locations, October 2021",
        description:
          "Discussion regarding unit economics both prior to and following the pandemic as well as commentary on the impact of XPASS and potential for future growth",
        points: [
          `
          <ul>
    <li>The interviewee owns <strong>multiple Club Pilates</strong> locations with experience spanning from the <strong>brand’s early days in 2019</strong> to the recovery from the impacts of <strong>COVID-19</strong>
        <ul>
            <li>The first studio opened in <strong>2019 with around 200 members</strong>, growing to approximately <strong>375-400 members within 6 months</strong></li>
            <li>Monthly revenue was about <strong>$70k</strong>, with major costs including payroll (up to <strong>50% of revenue</strong>), rent (<strong>15%</strong>), marketing (<strong>$1,500-$2,000</strong>), and ancillary expenses (<strong>2%</strong>); franchise fees are <strong>8% of revenue</strong></li>
            <li>Current <strong>operating margins are around 20%</strong>, with a potential to <strong>reach 30%</strong> under optimal conditions</li>
        </ul>
    </li>
    <li>Average revenue per studio is currently about <strong>$45k per month</strong>, with a target to reach <strong>$55k-$67k per month by 2023</strong>
        <ul>
            <li>Key costs include <strong>high payroll expenses</strong>, <strong>significant rent</strong>, marketing costs, and <strong>ancillary expenses</strong> - equipment maintenance is periodic but major replacements are infrequent</li>
            <li>Revenue had <strong>fallen significantly in early 2021</strong> but has recovered to pre-pandemic levels; growth is expected to continue, with potential for reaching <strong>$55k per month</strong> by the end of <strong>2022</strong></li>
        </ul>
    </li>
    <li>Major costs include <strong>payroll (up to 50% of revenue)</strong>, <strong>rent (around 15%)</strong>, and <strong>marketing ($1,500-$2,000 per studio per month)</strong>.
        <ul>
            <li><strong>Ancillary expenses</strong>, including supplies and cleaning, account for about <strong>2% of sales</strong> meanwhile franchise fees are <strong>8% of sales</strong>; profit margins vary, with current <strong>operating margins around 20%</strong> and a potential to <strong>reach 30%</strong> under optimal conditions</li>
        </ul>
    </li>
    <li><strong>Pre-pandemic growth was robust</strong>, with new studios experiencing <strong>rapid membership increases</strong>; current trends indicate a return to pre-pandemic revenue levels with continued growth potential
        <ul>
            <li>No direct impact from new <strong>Xponential</strong> locations on existing studios; however, <strong>market saturation</strong> and competition are concerns</li>
        </ul>
    </li>
    <li>The <strong>XPASS</strong> is seen as a potential positive, bringing in <strong>new customers</strong> without affecting current studio memberships - the exact implementation and impact are still to be fully evaluated
        <ul>
            <li><strong>XPASS is a token system</strong> where purchase a certain number of tokens per month used to book classes (plans start at <strong>$99/month for 5 tokens</strong>) and provides access to other <strong>Xponential</strong> brands like CycleBar and Pure Barre</li>
            <li><strong>Consumer confidence</strong> and <strong>household income</strong> are important metrics – a stable economy supports membership growth, while economic downturns can lead to decreased spending on fitness</li>
        </ul>
    </li>
    <li>There is <strong>significant room for growth</strong> with ongoing expansion plans - the studios are not yet maxed out, and there is optimism about increasing membership and revenue further
        <ul>
            <li>New locations are planned, but current market conditions and existing studio performance are factors in expansion strategies</li>
        </ul>
    </li>
</ul>
          `,
        ],
        image: "/spotlight/pil_4.jpg",
      },
    ],
  },
  {
    slug: "European Wax Center",
    pageData: {
      pic: "/logos/European Wax Center.jpg",
      title: "European Wax Center",
      initialInvestment: "$397k-$555k",
      franchiseeFee: "$45k",
      royaltyRate: "6.0%",
      description:
        "European Wax Center (EWC) is a prominent chain of hair removal salons in the United States, specializing in waxing services. Founded in 2004 by siblings Joshua and David Coba, EWC has grown to become a leader in the waxing industry, known for its signature Comfort Wax™, a proprietary blend designed to provide a more comfortable waxing experience. With a focus on providing high-quality, affordable waxing services in a clean and professional environment, European Wax Center offers a range of services for both men and women, including eyebrow, bikini, body, and facial waxing. EWC emphasizes customer satisfaction and education, aiming to empower individuals to feel confident and beautiful in their own skin.",
    },
    keyTrends: [
      {
        leftDescription:
          "Waxing is seen as <strong>non-discretionary</strong> and thus a service largely resilient to macroeconomic volatility – given the <strong>lack of direct scalable competitors</strong>, EWC is a primary beneficiary of the essential nature of waxing which",
        rightDescription:
          "<strong>Saturation</strong> in certain geographies remains a pertinent consideration as <strong>site location</strong> is an extremely crucial predictor and causal factor for success",
      },
      {
        leftDescription:
          "The roll-out of <strong>Wax Passes</strong> coupled with a strong brand and cultural focus on staff productivity enables <strong>sticky repeat revenues</strong> with the potential for <strong>+$10M/site sales",
        rightDescription:
          "<strong>Mandatory store refreshes</strong> can drive substantial expenditure (<strong>~$150k/site</strong>) and can impact a franchisee’s decision to renew their agreement",
      },
      {
        leftDescription:
          "EWC offers comprehensive support to franchisees, including <strong>preopening assistance, marketing guides</strong>, and <strong>strong relationships with aesthetician schools</strong>",
        rightDescription:
          "<strong>Staff burnout</strong> is not unheard of which is why financial incentives for well-performing waxers coupled with proactive staff management are key to operational stability",
      },
    ],
    cardData: [
      {
        id: 1,
        title:
          "Spotlight #1 - Multi-Unit Franchisee at European Wax Center - June 2024",
        description:
          "Exploration of skilled staff management, concept growth potential, margin pressures within EWC, and the process of establishing a sizeable platform to sell to a private equity consolidator",
        points: [
          `
<ul>
    <li><b>The interviewee is a seasoned multi-unit franchisee of European Wax Center (EWC)</b>, with extensive experience in the franchise industry - they have owned and operated multiple centers, particularly in <b>Northern New Jersey</b>, and have been involved in the franchise system since its early days
        <ul>
            <li>With a strong focus on growth and operational efficiency, they emphasize the importance of metrics like <b>wax pass sales</b> for driving revenue; having sold their centers to <b>Artemis</b>, they highlight the importance of trust and integrity in business transactions</li>
        </ul>
    </li>
    <li><b>The interviewee compared the growth potential of EWC franchises with other concepts</b> they have been involved with, such as Massage Envy
        <ul>
            <li>They highlighted the <b>rapid growth potential</b> of EWC due to its unique market position and ability to generate significant revenue quickly</li>
            <li>EWC is described as one of the <b>most organized and easy-to-operate franchises</b>; it was also highlighted for its quick revenue generation and easier operation</li>
        </ul>
    </li>
    <li><b>Consumers view waxing as a regular, essential service</b>, leading to consistent demand despite economic fluctuations
        <ul>
            <li>The <b>primary customer base is women aged 20 to 45</b>, who prioritize waxing as part of their routine</li>
            <li>Economic downturns may affect revenue slightly but do not significantly deter consumers from waxing services</li>
            <li>The interviewee highlighted <b>staffing as a critical factor</b> in the success of EWC centers, emphasizing the importance of hiring and retaining skilled associates</li>
        </ul>
    </li>
    <li><b>The interviewee discussed the process of selling their EWC centers to Artemis, a private equity group</b>
        <ul>
            <li>They emphasized the importance of finding a buyer they trusted to ensure continued success for their associates and the business</li>
            <li>The transaction was described as quick and smooth, with the only challenges related to <b>renegotiating leases</b> with landlords</li>
            <li>Private equity buyers aim to increase sales and profitability <b>post-acquisition</b>, although success can vary based on operational challenges</li>
        </ul>
    </li>
    <li><b>The primary driver of revenue growth for EWC centers is wax pass sales</b>, which increase guest retention and service frequency
        <ul>
            <li><b>Waxing is seen as a necessity</b> rather than a luxury - similar to services like haircuts - distinguishing it from more discretionary beauty services</li>
            <li>New guest acquisition and service add-ons also contribute to growth but have a smaller impact compared to wax pass sales</li>
            <li>EWC has a <b>unique position in the market</b> with no direct competitors offering the same rapid growth potential</li>
        </ul>
    </li>
    <li><b>The typical upfront cost to open an EWC center is between $600,000 and $700,000</b>, with efforts to lower these costs ongoing
        <ul>
            <li>Contractor costs vary by market and can be higher in areas requiring union builders</li>
            <li>The royalty fee for EWC remains at a consistent <b>6%</b>, and there have been no indications of changes to this structure</li>
            <li>A healthy EBITDA margin for an EWC center ranges from <b>15% to 25%</b>, depending on revenue levels; profitability has decreased slightly over time due to rising wages and costs</li>
        </ul>
    </li>
    <li><b>Downturns can affect revenue</b> but the impact on EWC is less pronounced due to the necessity of the services provided
        <ul>
            <li>Owning <b>multiple centers in a region</b> can enhance marketing efficiency and reduce costs Wax passes are a primary marketing focus as they drive repeat visits and loyalty</li>
            <li>The core demographic of EWC clients’ views waxing as an essential part of their routine, leading to consistent demand</li>
        </ul>
    </li>
</ul>

          `,
        ],
        image: "/spotlight/ewc_5.jpg",
      },
      {
        id: 2,
        title:
          "Spotlight #2 - Former Director of Franchise Development at European Wax Center - July 2022",
        description:
          "Commentary on franchisee relations, marketing strategies, the specifics of store economics, vendor management, and perspectives on EWC’s proprietary POS system",
        points: [
          `
<ul>
  <li>The interviewee is a former <strong>Director of Franchise Development</strong> at EWC with extensive experience in overseeing franchise operations and development</li>
  <li>Franchisees occasionally experienced issues with <strong>turnaround times</strong> and access to the founders as the company grew
    <ul>
      <li>Changes in <strong>marketing expenditure</strong> led to mixed feelings among franchisees, with some valuing the increased direction and others resenting the shift from local to national marketing</li>
      <li>The initial goal of <strong>1,500 stores</strong> was not based on detailed demographics but rather on a general intuition and successful benchmarks from other franchise models</li>
    </ul>
  </li>
  <li>Corporate marketing efforts included national ad placements in <strong>fashion magazines</strong> and <strong>Hulu</strong>, aiming for increased exposure - the effectiveness was measured through <strong>POS tracking</strong>, which indicated that national ads helped drive new guest count but had varying impacts locally
    <ul>
      <li>Corporate provided a <strong>grand opening guest list</strong>, <strong>marketing guides</strong>, and recommended local events</li>
      <li>Effective <strong>preopening marketing</strong> could significantly impact initial guest counts and ramp-up</li>
    </ul>
  </li>
  <li>The company focused on <strong>hiring for personality</strong> and training in-house, maintaining strong relationships with <strong>aesthetician schools</strong>
    <ul>
      <li>The average tenure for waxers was around <strong>5 years</strong>, with turnover attributed to life changes and potential industry shifts</li>
      <li>Average waxers earned <strong>$40,000 to $50,000</strong>, with top performers earning up to <strong>$80,000 or more</strong></li>
    </ul>
  </li>
  <li>The initial investment for an EWC store ranged around <strong>$350,000</strong>, including franchise fees - leasehold improvements and fixtures contributed significantly to this cost, with high-end estimates accounting for outliers in expensive markets
    <ul>
      <li>Stores typically reached <strong>$1 million AUV</strong> in about 5 years - faster ramp-ups were achieved through <strong>aggressive marketing</strong> and effective performance management</li>
      <li>Mature stores generally saw continued growth, with some achieving over <strong>$12M in annual revenue</strong> - growth was influenced by marketing efforts, employee incentives, and franchisee engagement</li>
    </ul>
  </li>
  <li><strong>SBA financing</strong> was available for franchisees, and the average amount financed was between <strong>$300,000 to $325,000</strong>
    <ul>
      <li>Corporate provided a list of lenders who had previously financed other franchisees</li>
    </ul>
  </li>
  <li><strong>Saturation</strong> led to the opening of secondary locations within the same protected territory - expansion was considered when existing stores reached capacity
    <ul>
      <li>Stores were expected to undergo <strong>retrofitting</strong> approximately every 10 years which is associated with significant expenditure</li>
      <li>The company planned to implement a broader <strong>retrofit strategy</strong>, impacting all locations</li>
    </ul>
  </li>
  <li>The initial <strong>POS system, Millennium</strong>, was unsuitable for EWC's needs, leading to an internal development project that was eventually scrapped - the current POS system was chosen for better <strong>customization</strong> and <strong>functionality</strong></li>
  <li>EWC negotiated discounts on products but did not take a cut of the payment processing fees; <strong>Medline</strong> was a key supplier for wax and medical supplies, with bulk deals providing some benefits for both franchisees and corporate
    <ul>
      <li>Strong rapport with <strong>aesthetician schools</strong>, preferring to hire for personality and teach the EWC method</li>
      <li>A variety of campaigns were suggested, with guest list sizes ranging from <strong>250 to 1,200 names</strong> depending on marketing intensity</li>
    </ul>
  </li>
</ul>
          `,
        ],
        image: "/spotlight/ewc_7.jpg",
      },
      {
        id: 3,
        title:
          "Spotlight #3 - Multi -Unit Franchisee at European Wax Center Inc - December 2023",
        description:
          "Focus on operational standards that emphasize consistency as well as training and development strategies that enable staff retention",
        points: [
          `
<ul>
<li>The interviewee is a multi-unit franchisee at EWC, overseeing multiple locations in **Michigan**; with a background in marketing from her previous role at **Little Caesars**, she has a strong focus on maintaining a competitive edge in the beauty industry</li>

<li>EWC benefits from an established brand reputation that drives continued success; experienced some fluctuations due to the pandemic, with **2021 being an anomaly year** while also experiencing a downturn in **2022** but a recent turnaround in sales
<ul>
<li>EWC is the leader in Michigan, facing **limited competition** mainly from mom-and-pop operations</li>
<li>Prices are raised smartly with advance notice to guests, allowing them to **lock in current rates through wax passes**; his approach minimizes pushback and maintains guest loyalty</li>
</ul>
</li>

<li>Has implemented a series of initiatives and programs in place to support **employee development** and minimize staff churn; recruiting and retaining state-licensed estheticians or cosmetologists is crucial and challenging
<ul>
<li>Incentivizes associates with bonuses for achieving **higher SPT** (Services per Ticket) which is a measure of overall order value and represents any successful **cross-selling / up-selling** the associate is able to achieve</li>
<li>There is less difficulty in **hiring front desk associates**, often filled by college students</li>
<li>Training programs are robust, but **retention varies** by location and operational approach</li>
</ul>
</li>

<li>Interviewee expressed general optimism for continued growth and the franchise’s beneficial impact on the local community while contextualizing her hopes with certain limitations
<ul>
<li>She expressed concerns about potential **over-expansion and cannibalization** of existing centers</li>
<li>Has confidence in the corporate team's ability to **research and vet new sites**, but still cautious about aggressive growth plans</li>
</ul>
</li>

<li>**Wax Passes** are critical for revenue and guest loyalty; pass sales provide steady income during slow months through payment plans; guests typically **return every 4-6 weeks**</li>
</ul>

          `,
        ],
        image: "/spotlight/ewc_3.png",
      },
      {
        id: 4,
        title:
          "Spotlight # 4 - Former Director of Franchise Development at European Wax Center - July 2022",
        description:
          "Overview of store economics and growth potential coupled with corporate’s involvement in brand evolution and supply chain efficiencies",
        points: [
          `
<ul>
<li>The interviewee is a former <strong>Director of Franchise Development</strong> at EWC, with extensive experience in franchise operations, store development, and labor relations </li>
<li>EWC provided <strong>marketing guides</strong> and <strong>presale activities</strong> to new stores - effective campaigns included physical interactions at <strong>local events</strong> and a robust grand opening guest list
<ul>
<li>Stores that invested more in local marketing and networking saw <strong>quicker revenue ramp-up</strong></li>
</ul>
</li>
<li>EWC hires based on <strong>personality</strong> and trains staff in their specific waxing method - strong relationships with <strong>aesthetician schools</strong> help in recruiting talent and the average waxer tenure is around <strong>4 to 5 years</strong></li>
<li>Some feedback suggests a <strong>fast-paced, high-pressure work environment</strong>, leading to <strong>potential burnout</strong>; EWC focuses on education rather than aggressive sales tactics as young employees may leave for different careers or personal reasons</li>
<li>Store development costs include <strong>$125,000 to $200,000</strong> for leasehold improvements and <strong>$40,000 to $50,000</strong> for fixtures - average total investment is around <strong>$350,000</strong>
<ul>
<li>Financing options are available through <strong>SBA loans</strong>, with typical financing covering <strong>$300,000 to $325,000</strong> of the total investment</li>
<li>EWC provided a list of lenders that had financed existing franchisees to new franchisees - the <strong>#1 bank</strong> for SBA loans was <strong>Spirit of Texas</strong></li>
</ul>
</li>
<li>Stores typically reach <strong>$1M in annual revenue</strong>, with potential growth to <strong>$12M</strong> or more
<ul>
<li>Growth depends on <strong>aggressive marketing</strong>, system adherence, and franchisee commitment; Mature stores can continue to grow year-over-year with ongoing effort</li>
</ul>
</li>
<li>If a store reaches capacity, a <strong>secondary location</strong> may be opened nearby - stores have been known to expand within the same protected territory</li>
<li>EWC initially used a general <strong>POS system</strong> before attempting to develop their own, which was eventually scrapped – the current system is <strong>off-the-shelf</strong> and customizable for EWC's services</li>
<li>EWC negotiated discounts with suppliers like <strong>Medline</strong> for bulk products but did not charge premiums to franchisees</li>
</ul>
          `,
        ],
        image: "/spotlight/ewc_4.jpg",
      },
      {
        id: 5,
        title:
          "Spotlight #5 - Former Director of Franchise Development at European Wax Center - August 2021",
        description:
          "Discussion of growth levers (prebooking, cross-selling) and overarching risks (location-related factors, supply chain) with EWC",
        points: [
          `
          <ul>
<li>The interviewee, a former <strong>Director of Franchise Development</strong> at EWC, has extensive experience in franchise development and operations within the beauty industry with insights into real estate strategy, franchisee support, and business growth</li>
<li>The interviewee discussed the <strong>typical occupancy rate</strong> of rooms at EWC locations - most stores operate with <strong>2 rooms in the morning</strong> and increase to full capacity (<strong>6 rooms</strong>) during peak hours, then scale back in the evening
<ul>
<li><strong>Prebooking</strong> was emphasized as a crucial strategy, with successful locations achieving <strong>50% to 80% prebooking rates</strong></li>
<li>The business model's success depends on the <strong>location and operator skill</strong>; poor performance is often attributed to operator error</li>
</ul>
</li>
<li>Ideal locations are <strong>high-end plazas</strong> near ancillary businesses such as health and beauty stores, fitness centers, and grocery stores like Whole Foods
<ul>
<li>Income levels of the target demographic are considered, with a preference for areas with <strong>household incomes of $75,000 and higher</strong></li>
<li>The <strong>real estate committee</strong> plays a vital role in site selection to ensure alignment with brand image and success potential</li>
</ul>
</li>
<li>The interviewee shared insights on <strong>market saturation</strong>, stating New Jersey was fully saturated during their tenure
<ul>
<li>They discussed the feasibility of <strong>expanding to 3,000 locations</strong> in North America, considering demographic changes and territory adjustments</li>
</ul>
</li>
<li><strong>Franchise fees</strong> are <strong>$45k for the first license</strong> and <strong>$36k for additional licenses</strong>, with a total build-out cost of approximately <strong>$400k</strong>
<ul>
<li><strong>ROI</strong> for franchisees is around <strong>22% to 23%</strong>, with <strong>$1M sales</strong> being a milestone for success</li>
<li>A mature location with <strong>$1 million in sales</strong> can generate <strong>$220k to $230k</strong> in cash flow annually</li>
<li>Opportunities include <strong>expanding the product line</strong>, increasing pricing, and improving same-store sales through packages and add-ons</li>
</ul>
</li>
<li><strong>Consistency in customer service</strong> is achieved by strictly following established systems and processes, which are crucial for franchise success
<ul>
<li>The importance of following the established system and processes was emphasized</li>
</ul>
</li>
<li>The shift from <strong>area representatives to corporate management</strong> was discussed, highlighting the efficiencies gained and <strong>cost savings</strong> achieved by eliminating the area representative model
<ul>
<li>The interviewee shared positive impressions of the new <strong>CEO, David Berg</strong>, and the direction set by the new management team</li>
</ul>
</li>
</ul>
          `,
        ],
        image: "/spotlight/ewc_5.jpg",
      },
      {
        id: 6,
        title:
          "Spotlight #6 - Former Developer and Multi-Unit Franchisee at European Wax Center - December 2020",
        description:
          "Comparison of high-performing vs underperforming sites and low-hanging fruit to justify initial premiums paid on purchasing an EWC site",
        points: [
          `
          <ul>
<li>The interviewee is a <strong>former developer and multi-unit franchisee</strong> at EWC with expertise in evaluating franchise performance, identifying areas for improvement, and understanding the impact of management quality and location on business success</li>
<li>Successful franchises are often characterized by having <strong>competent managers</strong> in place
<ul>
<li>The potential for improvement: investing in franchises with obvious <strong>fixable problems</strong> (e.g., management, cleanliness) can be a good opportunity if the issues can be addressed</li>
<li>The interviewee discusses the considerations when choosing between investing in a <strong>high-performing franchise</strong> with a full multiple valuation or a lower-performing one with room for improvement</li>
</ul>
</li>
<li>The <strong>location</strong> of a franchise coupled with <strong>client outreach</strong> can greatly influence its success or failure
<ul>
<li>A franchise in the <strong>Boston suburbs</strong> struggled due to <strong>poor accessibility</strong> despite being in a good town, indicating the importance of choosing the right location</li>
<li><strong>Customer acquisition</strong> is a key element of successful franchise operations, particularly in choosing locations with high foot traffic or accessibility</li>
<li><strong>Marketing strategies</strong> play a role in how franchises attract and retain customers, particularly in underperforming locations</li>
</ul>
</li>
<li>Paying a <strong>high multiple</strong> (e.g., 45x EBITDA) for a high-performing franchise might be worthwhile, especially if the franchise has a proven track record of success
<ul>
<li>The decision to pay a full multiple or seek a lower multiple depends on the <strong>potential for growth</strong> and the presence of fixable issues</li>
</ul>
</li>
</ul>
          `,
        ],
        image: "/spotlight/ewc_6.jpg",
      },
      {
        id: 7,
        title:
          "Spotlight #7 - Former Franchisee/Owner Operator/General Manager at European Wax Center - December 2020",
        description:
          "Highlights of the unique benefits of wax products and the importance of customer and staff relationships – with a specific focus on the correlation between staff incentives and financial outcomes",
        points: [
          `
          <ul>
  <li>The interviewee is a former <strong>franchisee, owner-operator, and general manager</strong> of an EWC location where they navigated various challenges such as unexpected expenses, marketing changes, and staffing issues - their insights highlight both the <strong>operational intricacies</strong> and the <strong>personal fulfillment</strong> derived from running a successful franchise
    <ul>
      <li>Ultimately the franchisee didn’t renew their agreement due to high costs and corporate decisions</li>
    </ul>
  </li>
  <li>The profitability of the EWC site was impacted by <strong>significant expenses</strong>, including <strong>marketing updates and store refurbishments</strong>
    <ul>
      <li>Expenses such as <strong>$20k per store</strong> for branding changes and <strong>$150k for store refreshes</strong> influenced financial stability and decisions about renewing franchise agreements</li>
      <li>The franchise agreement lasted for <strong>10 years</strong>, with significant costs associated with renewing it (i.e., mandatory store refreshes)</li>
      <li>Consistent EBITDA ranging from <strong>$250k to $350k</strong> - the model included strong customer relationships and a focus on <strong>quality waxing services</strong></li>
    </ul>
  </li>
  <li>The presence of <strong>nearby laser hair removal studios</strong> did not negatively impact business; instead, it potentially increased customer traffic
    <ul>
      <li><strong>Laser hair removal</strong> is seen as a niche service, expensive, and not suitable for everyone, whereas <strong>waxing remains a more accessible and widely preferred option</strong></li>
    </ul>
  </li>
  <li>The interviewee detailed the <strong>benefits of EWC’s low-heat wax</strong> and accompanying products that enhance skin care and reduce ingrown hairs
    <ul>
      <li>They emphasized the <strong>exfoliating and skin-softening effects</strong> of waxing compared to laser treatments</li>
    </ul>
  </li>
  <li>Strong <strong>customer-waxer relationships</strong> contribute to <strong>repeat business</strong> and client loyalty
    <ul>
      <li>Incentive structures included <strong>contests for staff, commissions on products, and bonuses</strong> for meeting sales goals - staff performance and customer service were crucial for business success</li>
      <li>There were challenges with <strong>corporate communication</strong> and demands, impacting business decisions</li>
    </ul>
  </li>
  <li>The importance of <strong>hiring motivated and sales-oriented staff</strong>, especially at the front desk, was highlighted
    <ul>
      <li>The interviewee advised on promptly <strong>addressing poor staff performance</strong> to maintain a positive work environment and overall team effectiveness</li>
      <li>They recommended being <strong>proactive in staff management</strong>, avoiding complacency, and continuously reinventing oneself and the business</li>
      <li>Despite personal challenges with corporate, they considered EWC a <strong>good investment opportunity</strong> with a strong business model</li>
    </ul>
  </li>
</ul>
          `,
        ],
        image: "/spotlight/ewc_7.jpg",
      },
    ],
  },
  {
    slug: "Planet Fitness",
    pageData: {
      pic: "/logos/Planet Fitness.jpg",
      title: "Planet Fitness",
      initialInvestment: "$1.5M-$5M",
      franchiseeFee: "$20k",
      royaltyRate: "7.0%",
      description: `Planet Fitness is an American fitness center franchise known for its welcoming, non-intimidating environment, often marketed as a "Judgement Free Zone." Founded in 1992, the company has grown rapidly, emphasizing affordability and accessibility. With over 2,000 locations worldwide, Planet Fitness offers a variety of cardio and strength-training equipment, fitness classes, and amenities like tanning and massage chairs. The brand caters to first-time gym users and casual fitness enthusiasts, differentiating itself with its inclusive atmosphere and budget-friendly membership options.`,
    },
    keyTrends: [
      {
        leftDescription:
          "strong>Planet Fitness's low-cost $10/month membership</strong> appeals to a broad demographic base and allows it to have <strong>lower attrition rates (~30%)</strong> compared to industry averages (~50%)",
        rightDescription:
          "strong>Ongoing tension</strong> between corporate-owned locations and franchisee growth ambitions as both categories compete with each other for prime locations – the impact of location can be the make or break between successful and unsuccessful sites",
      },
      {
        leftDescription: `The <strong>"Judgement Free Zone"</strong> marketing and inclusive atmosphere appeal to first-time gym users and casual fitness enthusiasts, differentiating Planet Fitness from competitors and attracting a <strong>diverse membership base</strong>`,
        rightDescription:
          "<strong>Ongoing difficulties</strong> in obtaining equipment and construction resources due to <strong>supply chain disruptions</strong>, especially post-COVID, have affected new openings and franchisee satisfaction",
      },
      {
        leftDescription:
          "Most gym-goers consider it an <strong>essential service</strong>, giving sizable players like Planet Fitness resilience to macro pressures and provides enough financial stability to allow corporate to test various <strong>ancillary services</strong> (e.g., online classes) and more cost-efficient box sizes",
        rightDescription:
          "Increasing <strong>competitive pressures</strong> from players like EoS Fitness which is offering a lower price point – making it all the more critical for Planet Fitness to provide <strong>added-value</strong> to support price increases on existing membership plans",
      },
    ],
    cardData: [
      {
        id: 1,
        title:
          "Spotlight # 1 - Senior Planet Fitness Franchise Owner - March 2023",
        description:
          "Commentary on the fitness concept’s aggressive growth, correlation to macroeconomic volatility, the implications of certain supply chain dynamics, and the evolving fitness preferences of Gen Z",
        points: [
          `
<ul>
<li>The interviewee is a <strong>Senior Planet Fitness Franchise Owner</strong> with over <strong>30 years</strong> of experience in the business - they offer valuable insights into Planet Fitness's operations and expansion strategy</li>
<li>Expressed concerns about Planet Fitness’s <strong>aggressive expansion</strong> into new zones, suggesting that while this strategy might drive growth, it could also lead to <strong>oversaturation</strong> and competition among existing franchisees
<ul>
<li>Nonetheless the company announced they had planned <strong>160 new openings</strong>, which was lower than investor expectations of <strong>200</strong>, due to construction and equipment supply issues</li>
</ul>
</li>
<li>Planet Fitness <strong>controls all aspects</strong> of the gym’s operation, including equipment purchases, décor, and branding, requiring franchisees to adhere strictly to these guidelines
<ul>
<li>Franchisees are required to <strong>buy equipment</strong> through Planet Fitness at a discount, though the franchisor keeps a larger portion of the savings</li>
<li>Franchisees are typically required to sign <strong>Area Development Agreements (ADAs)</strong> which involve a lower upfront fee but require them to build multiple locations within a set timeframe</li>
<li>Franchisees often <strong>sell their locations</strong> to other franchisees or equity firms, contributing to market dynamics</li>
</ul>
</li>
<li><strong>Impact of COVID-19</strong>, a significant increase in equity banking firms buying up franchise locations due to many owners wanting to exit the business after COVID-19
<ul>
<li>The interviewee highlighted <strong>ongoing challenges</strong> with obtaining equipment and construction resources due to lingering effects from COVID-19 and general industry-wide shortages</li>
</ul>
</li>
<li>They discussed how Planet Fitness manages <strong>member attrition</strong>, noting that their clubs have a lower attrition rate compared to industry averages and that their strategy of maintaining a clean and well-managed gym helps retain members even in competitive areas
<ul>
<li>Planet Fitness maintains a <strong>lower attrition rate (~30%)</strong> compared to industry averages (<strong>~50% for some competitors</strong>), aided by their low-cost membership model and high-quality gym maintenance</li>
<li>The low membership fee (<strong>$10</strong>) provides a buffer during economic downturns, keeping members committed despite financial strains</li>
</ul>
</li>
<li>The franchise owner observed a shift in gym demographics, with <strong>Gen Z</strong> showing increased participation in fitness - Planet Fitness is adapting its model to appeal to this younger generation</li>
<li>There is <strong>increased competition</strong> from other fitness brands such as <strong>EoS, Crunch, and 24-Hour Fitness</strong>; Planet Fitness faces internal competition from both corporate-owned and franchisee-operated gyms</li>
<li>Interviewee addressed concerns about <strong>inflation</strong> and reduced purchasing power among lower-income members; despite economic pressures, the interviewee is confident that the low-cost membership model of Planet Fitness helps <strong>maintain membership levels</strong> even during economic downturns
<ul>
<li>The <strong>banking crisis</strong> has affected regional banks, which are the primary lenders for new franchise openings - despite this, there is still ample cash available and the interviewee expects continued access to credit for expansion</li>
<li><strong>Equity banking firms</strong> are playing a significant role in funding expansions, often using cash reserves to acquire and develop new locations</li>
<li>Members are likely to retain their <strong>$10 gym memberships</strong> even during economic hardships, as gym memberships are considered essential by many</li>
</ul>
</li>
</ul>
          `,
        ],
        image: "/spotlight/pf (5).png",
      },
      {
        id: 2,
        title: "Spotlight #2 - Planet Fitness Franchise Owner - February 2021",
        description:
          "Analysis of store unit economics, the impact of the pandemic and other volatility on the membership base, and a broader perspective on long-term growth prospects",
        points: [
          `
<ul>
    <li>The interviewee has been a franchisee with Planet Fitness since <strong>2005-2006</strong> and currently oversees <strong>15 locations</strong> across <strong>4 states</strong>
        <ul>
            <li>The fitness industry has seen growth, with a rising focus on <strong>affordable memberships</strong> and accessible gym locations</li>
            <li>Members increasingly seek <strong>value-for-money services</strong> and <strong>flexible membership options</strong></li>
            <li>Planet Fitness operates with <strong>fewer employees</strong> compared to traditional gyms - the average number of employees per club is around <strong>13</strong>, but it can increase slightly for clubs open <strong>24/7</strong></li>
            <li>Franchisee prefers <strong>smaller towns</strong> where competition is lower and the penetration rate tends to be higher</li>
        </ul>
    </li>
    <li>They manage a <strong>membership base of approximately 106,000</strong> and have been satisfied with the overall operation of their clubs
        <ul>
            <li>Clubs generally range from <strong>6,500 to 15,000 members</strong>, with profitability starting at <strong>6,500 members</strong></li>
            <li>Monthly EFT (membership income) for clubs ranges from <strong>$105k to over $260k</strong> not including auxiliary income (e.g., merchandise)</li>
            <li>The franchisee is cautious about <strong>expanding further</strong>, preferring to focus on maintaining operational quality and financial stability over rapid growth</li>
            <li>Strategic planning and research are used to determine the viability of <strong>new locations</strong></li>
        </ul>
    </li>
    <li>Clubs typically occupy <strong>18,000 to 20,000 square feet</strong>, and the franchisee’s clubs spend around <strong>10-12%</strong> of revenue on rent with rental costs ranging from <strong>$0.50 to $2.75 per square foot</strong>, depending on the location
        <ul>
            <li>Building a club costs around <strong>$2.2M</strong>, including franchise fees, equipment, tenant improvements (TIs) – becomes operating cash flow positive in <strong>13 months</strong></li>
            <li>Clubs operate with <strong>EBITDA margins between 32-35%</strong>; payroll runs at <strong>$12-$15 per hour</strong>, with a typical club running about <strong>$300k</strong> annually in payroll expenses</li>
        </ul>
    </li>
    <li>The franchisee experiences an annual attrition rate of <strong>25-30%</strong>, lower than the industry average of <strong>35-40%</strong></li>
    <li>There is a focus on possibly acquiring <strong>smaller, financially weaker franchisees post-COVID</strong>, rather than opening new locations - the franchisee sees this as a more lucrative and less risky growth strategy
        <ul>
            <li>Initially experienced an <strong>11% drop in total membership</strong> from Covid due to temporary closures of California clubs but other states actually saw membership growth during this period</li>
        </ul>
    </li>
    <li>Early on, franchisees faced challenges with corporate regarding <strong>equipment replacement cycles (3-5 years)</strong>, branding guidelines, and operational nitpicking
        <ul>
            <li>In the past couple of years, communication between corporate and franchisees has <strong>improved</strong>, with the corporate entity being more flexible and understanding franchisee needs</li>
            <li>Corporate earns significant revenue from mandatory equipment sales, with franchisees required to replace equipment every <strong>5-7 years</strong></li>
        </ul>
    </li>
    <li>The franchisee believes Planet Fitness is well-positioned to <strong>outperform competitors like 24 Hour Fitness</strong>, which closed <strong>100 locations</strong> during the pandemic - Planet Fitness’s lower price point, cleanliness, and better service give it an edge even in markets where larger competitors operate
        <ul>
            <li>Franchisee expects annual membership growth to stabilize at <strong>2-3%</strong>, with the potential for growth spurred by future corporate pricing adjustments (such as the increase in Black Card membership fees in 2019)</li>
        </ul>
    </li>
</ul>
          `,
        ],
        image: "/spotlight/pf (4).png",
      },
      {
        id: 3,
        title:
          "Spotlight #3 - Former Chief Operating Officer at Excel Fitness Holdings, a Franchisee of Planet Fitness - May 2024 ",
        description:
          "Focus on unit economics, franchise agreements, the tension between growth that investors are pushing for in corporate vs franchisee profitability, and the future dynamics of the fitness sector",
        points: [
          `
<ul>
    <li>The interviewee, a former <strong>Chief Operating Officer</strong> at Excel Fitness Holdings and a franchisee of Planet Fitness, offers deep insights into the fitness industry and Planet Fitness's operations</li>
    <li>Recent adjustments to the franchise agreement, including an <strong>extended renewal period for equipment</strong>, may be seen as a move to address ongoing concerns, but they might not significantly impact franchisee satisfaction or profitability
        <ul>
            <li>There needs to be more alignment between <strong>franchisee incentives and franchisor profitability</strong>, and how adjustments might affect long-term financial projections</li>
            <li>Franchisees also face <strong>difficulty in finding prime locations post-COVID</strong> and the potential need to consider less desirable locations for expansion</li>
            <li>The feasibility of using <strong>smaller box sizes</strong> to reduce real estate costs and support more aggressive growth targets is still being tested</li>
        </ul>
    </li>
    <li>A move towards <strong>smaller, more cost-efficient boxes</strong> could help Planet Fitness reach its ambitious growth targets – this is a complex conversation between corporate, investor desires, and franchisee feedback
        <ul>
            <li>The impact of <strong>increasing membership pricing from $10 to $15</strong> is being contemplated by corporate but they need to balance membership rates with <strong>added value</strong>, potentially through technology partnerships or additional services</li>
            <li>There is a <strong>trade-off between profitability and growth</strong> - investors in corporate might prefer more aggressive growth strategies even if it means lower margins</li>
            <li>Many are also mulling over the impact of equipment costs and box size on overall pricing strategy, with suggestions for <strong>reducing expenses to improve profitability</strong></li>
        </ul>
    </li>
    <li>Recommendations for reducing the size and cost of new facilities by <strong>rethinking locker room designs</strong> and cardio equipment - for example, integrating lockers into shared spaces and cutting back on underused cardio equipment could improve efficiency and lower costs
        <ul>
            <li>There are challenges in achieving the planned number of locations <strong>(4,000)</strong> given current market conditions and the need for strategic adjustments</li>
        </ul>
    </li>
    <li>There are opportunities for leveraging Planet Fitness’s scale to build technological partnerships and enhance member value, - such as integrating with <strong>Peloton</strong> or <strong>wearable tech companies</strong>
        <ul>
            <li>The potential benefits of partnerships with technology companies (eg, <strong>Peloton, Loop</strong>) would be to enhance member value and attract new customers – this could lead to better competitive differentiation as smaller competitors might not be able to secure such partnerships</li>
        </ul>
    </li>
</ul>
          `,
        ],
        image: "/spotlight/pf (2).png",
      },
      {
        id: 4,
        title: "Spotlight #4 - Planet Fitness Franchise Owner - May 2022",
        description:
          "Emphasis on the importance of site selection and the impact of private equity within the space; close look at the emerging tension between franchisee locations and corporate owned stores",
        points: [
          `
<ul>
    <li>The interviewee is an experienced <strong>multi-site Planet Fitness franchise owner</strong> with a deep understanding of the gym industry</li>
    <li>Planet Fitness's decision to acquire the <strong>Sunshine group</strong> rather than just supporting their expansion highlights the benefits of acquiring mature clubs for solid cash flow and cost efficiencies
        <ul>
            <li>Corporate made the acquisition to diversify the geographic footprint of its owned-store base - there is ongoing speculation on whether Planet Fitness might pursue similar strategies to consolidate and control more of their locations</li>
            <li><strong>Private equity</strong> plays a significant role, with numerous equity firms involved in the fitness industry, influencing consolidation and franchise operations</li>
            <li>Planet Fitness is <strong>selective about franchisees</strong>, ensuring financial stability and operational capability before approval</li>
        </ul>
    </li>
    <li>Poor location choices and high rent can lead to financial difficulties but successful location selection requires careful market analysis and cost management
        <ul>
            <li>Franchisees must be cautious of <strong>corporate competition</strong> for desirable locations</li>
        </ul>
    </li>
    <li>Potential challenges Planet Fitness may face in the next <strong>3-5 years</strong>, including franchisee competition and expansion conflicts – but keep in mind that excessive debt and poor location choices can negatively impact gym operations
        <ul>
            <li>The fitness industry is expanding and Planet Fitness is positioned to become the <strong>largest gym company globally</strong></li>
        </ul>
    </li>
    <li>Proper leverage and location selection are crucial for maintaining <strong>healthy EBITDA margins</strong> – there is a general expectation that a new site can achieve cash flow positivity within <strong>12-13 months</strong></li>
    <li><strong>Humor</strong> has played an effective role in the concept’s national advertising and lends itself to healthy, upbeat perceptions in the minds of gym members
        <ul>
            <li>Franchisees contribute to <strong>national advertising</strong>, which is currently at <strong>2%</strong></li>
            <li>The recent partnership with <strong>Publicis</strong> might affect advertising strategies, though the impact is uncertain</li>
        </ul>
    </li>
</ul>
          `,
        ],
        image: "/spotlight/pf (3).png",
      },
      {
        id: 5,
        title: "Spotlight #5 - Planet Fitness Franchise Own - March 2022",
        description:
          "Discussion of corporate support for franchisees, competing fitness concepts, membership base evolution since the pandemics, and the capital intensity of maintaining operations",
        points: [
          `
<ul>
    <li>
        The interviewee is a seasoned <strong>Planet Fitness franchise owner</strong> who has successfully overseen the <strong>transformation of an old Gold's Gym</strong> into a top-performing Planet Fitness
    </li>
    <li>
        The <strong>Hayward, California</strong> location, opened in <strong>2006</strong>, has shown significant growth; despite not growing as rapidly as before, it continues to see positive revenue increases and remains one of the highest-performing locations
        <ul>
            <li>
                <strong>CEO Chris Rondeau</strong> is praised for his understanding of the gym industry and franchisee needs; the corporate management team is seen as <strong>supportive</strong> but sometimes a little myopically focused on minor operational changes that may not significantly impact franchisees
            </li>
        </ul>
    </li>
    <li>
        <strong>Post-COVID recovery</strong> has led to a shift in membership demographics, with a mix of new and returning members
        <ul>
            <li>Overall, most locations have shown growth, though some newer ones, particularly in Oregon, faced challenges due to restriction</li>
        </ul>
    </li>
    <li>
        The <strong>Black Card membership</strong>, which includes tanning services, has varying penetration rates depending on location
        <ul>
            <li>Average penetration is around <strong>60%</strong>, with higher rates in areas with less natural sunlight like Alaska</li>
            <li>There is consideration of increasing the <strong>$24.99 Black Card price point</strong>, with suggestions to add value through services like online training to justify the price increase</li>
        </ul>
    </li>
    <li>
        The interviewee describes the <strong>advantages of older franchise agreements</strong> with lower royalty rates compared to newer ones
        <ul>
            <li>
                The <strong>ADA (Area Development Agreement)</strong> system provides opportunities for well-financed investors but comes with obligations to develop multiple units
            </li>
        </ul>
    </li>
    <li>
        The interviewee views <strong>Crunch Fitness</strong> as a less favorable competitor due to its business model and customer dissatisfaction with overcrowded facilities; they mention <strong>EOS Fitness</strong> as a growing competitor with a lower price point
    </li>
    <li>
        The interviewee highlights that their <strong>Capex (Capital Expenditure)</strong> at the gym level is relatively low, around <strong>4-5% of sales</strong>, which contributes to strong unit economics - they suggest that this level of expenditure is typical for maintenance and growth
    </li>
</ul>
          `,
        ],
        image: "/spotlight/pf (4).png",
      },
      {
        id: 6,
        title: "Spotlight #6 - Planet Fitness Franchise Owner - July 2021",
        description:
          "Close up analysis of the pace of store recovery following economy re-openings across the country as well as an overview of the seasonality of membership",
        points: [
          `
<ul>
  <li><strong>The interviewee is a Planet Fitness franchise owner</strong> with experience in expanding and remodeling clubs during COVID-19</li>
  <li>The interviewee noted that while there was an <strong>initial drop</strong> in membership numbers in April 2021, subsequent months showed improvement but they are still not back to January 2020 levels</li>
  <li>Discussed the <strong>seasonal nature</strong> of gym memberships, highlighting that summer months typically see a decline in attendance which is expected to improve as schools reopen and the summer ends
    <ul>
      <li>There are also <strong>geography-specific dynamics</strong> such as gym memberships typically declining during the summer due to seasonal activities like hunting and fishing in places like Alaska</li>
    </ul>
  </li>
  <li>The franchise owner observed that despite some improvements in membership, they are currently <strong>down 8% compared to January 2020</strong> but they expect to see a rebound by early next year</li>
  <li>Noted that while smaller gyms have closed, larger competitors like <strong>24-hour Fitness</strong> have generally managed to survive the impact of the pandemic shutdowns
    <ul>
      <li>Planet Fitness is seeing some benefit from these closures but also faces competition from <strong>corporate-owned locations</strong></li>
      <li>Planet Fitness is exploring <strong>online programming</strong> as an additional revenue stream – this includes adding an extra fee for online workouts in addition to gym memberships</li>
    </ul>
  </li>
  <li>Increased labor costs by <strong>15%</strong> and explained that Planet Fitness's model focuses on maintaining a low membership price while operating with a leaner staff compared to full-service gyms</li>
  <li>Spoke about <strong>ongoing and planned expansions</strong>, including one new club in North Medford, Oregon – the franchise owner used the downtime during COVID-19 to remodel and improve existing clubs and open a new location in North Medford, Oregon
    <ul>
      <li>They also mentioned that corporate is <strong>cautious about expanding aggressively</strong> and is currently focusing on stabilizing existing operations</li>
    </ul>
  </li>
</ul>
          `,
        ],
        image: "/spotlight/pf (5).png",
      },
    ],
  },

  {
    slug: "Goosehead",
    pageData: {
      pic: "/logos/Goosehead.png",
      title: "Goosehead",
      initialInvestment: "$40k-$120k",
      franchiseeFee: "$25k",
      royaltyRate: "20.0%",
      description: `Goosehead Insurance Inc is a dynamic and rapidly expanding personal lines insurance agency founded in 2003 by Mark and Robyn Jones. It operates on a unique franchise model combined with a strong emphasis on technology and customer service, allowing entrepreneurial agents to establish their own agencies while benefiting from extensive support and proprietary tools. Goosehead offers a wide range of insurance products, including homeowners, auto, renters, flood, umbrella, and life insurance. The company went public in 2018 and trades on NASDAQ under the ticker symbol "GSHD," reflecting its robust growth and commitment to innovation and excellence in the insurance industry.`,
    },
    keyTrends: [
      {
        leftDescription:
          "Offers franchisees immediate comprehensive access to a wide array of <strong>+60 top insurance carriers</strong> – allowing them to cater to a diverse set of client needs and immediately grow (not to mention earning <strong>80% commission</strong> on all new business)",
        rightDescription:
          "<strong>50-50 split on renewal commissions</strong> places significant pressure on franchisee to continue to grow and scour for new business – making longer-term franchisee satisfaction difficult",
      },
      {
        leftDescription:
          "Emphasis on building robust referral networks with <strong>loan officers</strong> and <strong>real estate professionals</strong> provides a sustainable growth engine – while this could be slower than some direct lead gen methods like cold calling, it is more sustainable over the longer-term",
        rightDescription:
          "In some markets like <strong>NY, FL, and CA</strong>, carriers have been withdrawing due to rising claims and market volatility, limiting franchisees’ abilities to offer sufficient coverage",
      },
      {
        leftDescription:
          "Use of advanced tools like <strong>Salesforce</strong> and <strong>Aviator</strong> for comparative quoting, combined with seamless integration with rater tools and document management systems like <strong>DocuSign</strong>, creates a streamlined, <strong>highly efficient quoting process</strong>",
        rightDescription:
          "<strong>Internal competition</strong> among franchisees for referral partners can create friction and reduce the collaborative potential of Goosehead’s network-based growth strategy",
      },
    ],
    cardData: [
      {
        id: 1,
        title:
          "Spotlight #1 - Agency Owner at Goosehead Insurance Inc - May 2024",
        description:
          "In-depth focus on ramping up as a new franchisee to the Goosehead network, comparison with State Farm, and challenging dynamics in certain insurance markets",
        points: [
          `

          <ul>
          <li>The interviewee is an <strong>experienced insurance agency owner</strong> at Goosehead Insurance Inc, who transitioned from being a <strong>State Farm agent</strong> to running their own franchise
            <ul>
              <li>Their agency thrives primarily through a robust <strong>referral partner network</strong>, particularly with loan officers and real estate professionals</li>
              <li>They are knowledgeable about the tools and processes Goosehead provides, including the <strong>Comparative Radar system</strong> in Salesforce, and they actively engage with the data to optimize their business operations</li>
              <li>Found the <strong>exclusive relationship with State Farm</strong> to be limiting – especially in though insurance market where they couldn’t offer coverage to certain clients</li>
              <li>Unlike State Farm, which relied heavily on internet leads and cold calling, Goosehead's model emphasizes building relationships with referral partners (e.g., realtors, mortgage brokers); while the franchisee wrote more business in their first year at State Farm due to the volume of leads, <strong>Goosehead’s slower referral-based approach</strong> is expected to provide better long-term growth</li>
            </ul>
          </li>
          <li>Upon signing up, the franchisee immediately gained access to over 60 top insurance carriers, compared to the exclusivity of State Farm
            <ul>
              <li>Franchisee earns <strong>80%</strong> of the commission on new policies, and Goosehead takes 20%; for renewals, this split changes to 50-50; commission rates vary by carrier, usually ranging from <strong>12% to 16%</strong></li>
              <li>While <strong>Goosehead’s commission rates</strong> for new business are higher than State Farm’s, the renewal commissions are lower, which the franchisee saw as a downside compared to State Farm's more consistent commissions across policy terms</li>
              <li><strong>Lower overhead</strong> at Goosehead because at State Farm the interviewee had 5 employees and higher costs associated with office space, staffing, and other operational expenses; State Farm also had more corporate control over hiring and firing decisions</li>
            </ul>
          </li>
          <li>The franchisee noted that certain states (e.g., New York, California, Florida) have become particularly difficult insurance markets due to increasing claims and high premiums - this has led to fewer carriers being available in these states; for instance, in New York, the number of carriers available to the franchisee <strong>dropped by 10 to 15</strong> since they first started
            <ul>
              <li>Carriers have been <strong>pulling out of markets</strong> where they are paying out more in claims than they are receiving in premiums; in some cases, carriers may cancel policies after home inspections reveal issues, forcing the franchisee to find <strong>alternative coverage</strong> for clients</li>
            </ul>
          </li>
          <li>Franchisee growth at Goosehead has slowed, with new franchisee additions <strong>dropping by 50%</strong> nationwide in the last year - this is likely due to the challenging market conditions, particularly in key states.
            <ul>
              <li>The referral-based model is also impacted by <strong>high mortgage rates</strong>, which have slowed down home purchases and thus reduced the number of new insurance opportunities from mortgage brokers</li>
            </ul>
          </li>
          <li>Many franchisees have prior insurance industry experience and Goosehead continues to become more selective in choosing franchisees
            <ul>
              <li>The process involves multiple interviews and evaluation of candidates' fit and potential with a preference for candidates with a <strong>track record of success</strong> in insurance or entrepreneurial endeavors</li>
              <li>Generally there is <strong>no direct monetary compensation</strong> for franchisee referrals</li>
            </ul>
          </li>
          <li>Initial <strong>2 weeks of Zoom call training</strong> covering sales presentation and mock sales calls; this is followed by a <strong>5 day in-person training session</strong> in Chicago with live selling and group activities</li>
          <li>There is availability of a support number for issues and new franchisees must conduct <strong>regular meetings</strong> with a regional manager, especially in the first few months
            <ul>
              <li>Emphasis on individual initiative to seek help when needed</li>
            </ul>
          </li>
          <li>Requirement to sell a certain number of policies (around <strong>20</strong>) during the training period
            <ul>
              <li>The 1st year serves as a <strong>grace period</strong> with no stringent sales requirements but there is ongoing performance evaluation, particularly after the first year</li>
              <li>Potential consequences for being in the bottom quartile of agents, including <strong>franchise termination</strong></li>
            </ul>
          </li>
          <li>Use of <strong>Salesforce</strong> to access data on loan officers and real estate agents
            <ul>
              <li>Database includes information on loan officers' activity levels and existing relationships with Goosehead agents</li>
            </ul>
          </li>
          <li>Focus on developing relationships with <strong>loan officers</strong> as primary referral partners
            <ul>
              <li>Importance of building a network of professionals, including realtors and loan officers - <strong>majority (70%) of new business</strong> comes from referral partner network while the remaining <strong>30%</strong> comes from past clients and personal network</li>
            </ul>
          </li>
          <li>Goosehead provides specific training on <strong>developing referral partner networks</strong>
            <ul>
              <li>Availability of regional support for prospecting, although some agents prefer to handle it independently</li>
            </ul>
          </li>
          <li><strong>Real estate market conditions</strong> significantly impact business volume and agents adapt strategies based on market dynamics
            <ul>
              <li>Competition with other independent agents and existing relationships of referral partners</li>
            </ul>
          </li>
          <li>There is an internal system called <strong>Aviator</strong> used for quote comparison and it facilitates efficient handling of referral leads and processing quotes from multiple carriers</li>
        </ul>
          `,
        ],
        image: "/spotlight/gh.png",
      },
      {
        id: 2,
        title:
          "Spotlight #2 - Owner & Agent of a Goosehead Insurance Franchise - January 2024",
        description:
          "Overview of corporate mandated growth targets, valuation methodologies, factors critical to customer decisioning, and projected renewal rate increases",
        points: [
          `
<ul>
    <li>The interviewee is an <strong>Owner & Agent</strong> of a Goosehead Insurance Franchise who has demonstrated significant growth and resilience in a challenging insurance market
        <ul>
            <li>The interviewee bought a book of business from another agent, with Goosehead valuing it at <strong>1.5x the renewal commission</strong> - they negotiated a higher price and expect to recover the cost within <strong>2.5 years</strong></li>
        </ul>
    </li>
    <li>There is a <strong>growth target</strong> set by Goosehead that varies, and franchises are in default if they don't meet it – it is <strong>difficult to lose a franchise</strong> if you're growing, but there are minimal strict targets</li>
    <li>Projected <strong>renewal rate increases</strong> are about <strong>20% to 30%</strong>, compared to a normal increase of ~8%
        <ul>
            <li>Franchisee has generally <strong>positive experiences with claims support</strong>, although there were some issues with payment processing and service inconsistencies</li>
            <li>In 2023, the interviewee doubled new business and plans to double it again in 2024 – has a retention rate of <strong>+90%</strong> and they are focusing on maintaining or exceeding this level</li>
        </ul>
    </li>
    <li>Overall, the <strong>franchisee is satisfied</strong> with Goosehead’s support system, technology platform, and customer service - they find the company to be responsive and helpful, though they expressed some dissatisfaction with the lower renewal commission structure</li>
    <li>Satisfaction with Goosehead's claims support and overall service was generally positive, though they noted some issues with <strong>payment processing</strong> and service inconsistencies
        <ul>
            <li>They observed that the insurance market is facing unprecedented challenges, affecting both the franchise and the industry as a whole</li>
            <li><strong>Price is a major factor</strong> in winning new policies, along with personal engagement, responsiveness, and market knowledge</li>
        </ul>
    </li>
    <li>Potential for significant growth with proper referral partnerships and market conditions, with a long-term goal of reaching <strong>$1M in commission</strong>
        <ul>
            <li>The market is experiencing unprecedented challenges, affecting overall business performance and franchise operations</li>
            <li>The market's instability includes <strong>carriers pulling out</strong> and resulting high rates</li>
        </ul>
    </li>
    <li>The interviewee provides multiple quotes to potential clients, with <strong>price being a significant determinant</strong> in their decision</li>
</ul>
          `,
        ],
        image: "/spotlight/gh (1).png",
      },
      {
        id: 3,
        title:
          "Spotlight #3 - Former Agency Owner at Goosehead Insurance Inc - January 2024",
        description:
          "Examining the puts and takes of corporates operational and support systems and diving into the nuances of the relationship between carriers and agents",
        points: [
          `
<ul>
  <li>The interviewee is a seasoned insurance professional with over <strong>40 years of experience</strong> in the industry - they previously built and sold a large agency before joining Goosehead Insurance
    <ul>
      <li>Their tenure at Goosehead was marked by significant challenges, including <strong>carrier instability</strong> and issues with the support and resources provided by Goosehead</li>
      <li>Despite their extensive background and success in the insurance sector, their experience with Goosehead was <strong>mixed</strong>, characterized by frustration with the company's operational and support systems</li>
    </ul>
  </li>
  <li>Their book grew from minimal business in <strong>2020 to $1M by June 2023</strong>
    <ul>
      <li><strong>Initial growth was slow</strong> due to COVID-19 but picked up significantly after resuming in-person interactions</li>
      <li>Goosehead provides a <strong>centralized distribution model</strong> with a network of agents - the interviewee found this model challenging due to the lack of flexibility and the burden of managing renewals and remarketing</li>
    </ul>
  </li>
  <li>Significant disruptions in <strong>2021 due to major carriers folding</strong> and changes in market conditions
    <ul>
      <li>Franchisee criticized the <strong>lack of notification for policy changes</strong> and insufficient local marketing support - the system was seen as discouraging for agents, especially in handling renewals</li>
      <li>Goosehead promised <strong>backroom support</strong>, which was not fully realized; issues included inadequate carrier contracts and a lack of promised high-value homeowners and commercial lines products</li>
    </ul>
  </li>
  <li>New business commissions are typically <strong>10% of the premium</strong>
    <ul>
      <li>Renewal commissions can be <strong>reduced to 8%</strong> by some carriers, with differences in commission rates for different carriers</li>
    </ul>
  </li>
  <li>The interviewee faced issues with homeowners’ insurance, particularly with the largest insurer, <strong>Citizens</strong> - challenges included managing policy cancellations and renewals under difficult market conditions
    <ul>
      <li>Goosehead promised access to high-value homeowners insurance products, which were <strong>not provided as expected</strong></li>
    </ul>
  </li>
  <li>Carriers view Goosehead as a way to access a <strong>large volume of business</strong> but have mixed feelings about the quality of some agents
    <ul>
      <li>The interviewee experienced challenges with <strong>carrier onboarding and support</strong></li>
      <li>Carriers view Goosehead as a means to access a larger volume of business but have <strong>mixed experiences</strong> with the quality of agents Some carriers expressed frustration with the performance of less experienced agents</li>
      <li>Goosehead has a <strong>quality control system</strong> for new business applications, ensuring that applications are vetted properly before submission</li>
      <li>Despite quality control efforts, the reputation of some agents was seen as a concern, impacting the overall perception of Goosehead’s network</li>
    </ul>
  </li>
  <li>High ongoing expenses including <strong>fees for phone</strong>, errors and emissions insurance, and other operational costs
    <ul>
      <li>There were also <strong>uncertain and variable royalty fees</strong> and other charges</li>
      <li>The interviewee expressed dissatisfaction with Goosehead’s support and training, particularly in terms of local marketing and carrier contracts</li>
    </ul>
  </li>
  <li>Estimated that a <strong>book size of around $2M</strong> would be needed to cover expenses and be profitable
    <ul>
      <li>The interviewee struggled to reach profitability and <strong>lost money in the initial years</strong></li>
      <li>Similar to homeowners insurance, auto insurance commissions are typically <strong>10% for new business</strong> while renewal commissions can vary, with some carriers reducing them</li>
    </ul>
  </li>
  <li>Customers in the US South are generally <strong>unfamiliar with Goosehead</strong>
    <ul>
      <li>Goosehead’s system may benefit new agents initially, but established relationships and personal service are critical for retaining clients</li>
    </ul>
  </li>
  <li>Interviewee has a limited view on <strong>broader market trends</strong> and his commentary focused on the South where premiums are higher but flexibility is limited
    <ul>
      <li>The industry faced significant <strong>disruptions in 2021</strong> due to major carriers folding and increased market volatility - this affected the stability and growth of the book of business</li>
    </ul>
  </li>
</ul>
          `,
        ],
        image: "/spotlight/gh (2).png",
      },
      {
        id: 4,
        title:
          "Spotlight #4 – Agency Owner Goosehead Insurance Inc – January 2024",
        description:
          "Overview of commission structure, competitive landscape within the industry, and future outlook as well as expansion plans",
        points: [
          `
<ul>
    <li>
        The interviewee is a <strong>seasoned agency owner</strong> with extensive experience in the insurance industry having transitioned from State Farm to Goosehead Insurance
    </li>
    <li>
        Franchisees receive <strong>support from corporate</strong>, but it's often less intensive than for corporate agents
        <ul>
            <li>
                Success for new franchisees is possible but requires significant effort and a learning curve; the interviewee envisions <strong>significant growth over time</strong>, with the potential to scale their business substantially beyond the initial goals
            </li>
        </ul>
    </li>
    <li>
        The Goosehead model emphasizes <strong>referral partnerships</strong>, and while the current housing market is challenging, there are still opportunities through strong relationships
        <ul>
            <li>
                The ability to compete across states and leverage technology to track lead production provides a <strong>competitive edge</strong>
            </li>
            <li>
                The system allows for <strong>quick assessment</strong> and comparison of insurance options
            </li>
        </ul>
    </li>
    <li>
        On average, carriers pay between <strong>12% and 18%</strong> of the premium as commissions; Goosehead takes <strong>20%</strong> of that amount for new business, and 50% on renewals
    </li>
    <li>
        The franchisee retains approximately <strong>88-89%</strong> of their book during renewals, with commissions remaining roughly the same as on new business (around <strong>15%</strong> of the premium)
    </li>
    <li>
        Internal competition exists <strong>between Goosehead agents</strong>, especially in securing referral partners; competition is also present from realtors and lenders who may already work with other Goosehead agents
    </li>
    <li>
        The market is <strong>currently tightening</strong> with higher restrictions and rising rates, which affects new business and renewals
        <ul>
            <li>
                Franchisees can benefit from having <strong>multiple insurance options</strong>, but face challenges with direct-to-consumer insurers
            </li>
            <li>
                While scaling, the franchisee considers <strong>expanding into additional states</strong> if the market conditions improve and carrier appointments become feasible
            </li>
        </ul>
    </li>
    <li>
        The agency owner aims for substantial growth, with a goal of reaching a <strong>$10M</strong> book of business by year 5 and potentially <strong>$100M</strong> in the long term
    </li>
    <li>
        Renewal rates have increased by <strong>10% to 25%</strong>, which can impact client retention and satisfaction
        <ul>
            <li>
                The agency focuses on <strong>maintaining coverage levels</strong> while managing cost increases through technology and market analysis
            </li>
        </ul>
    </li>
    <li>
        Goosehead's <strong>technology</strong> helps in assessing and comparing multiple insurance options efficiently – it essentially allows for <strong>tracking lead production</strong> and assessing potential referral partners effectively
        <ul>
            <li>
                Goosehead agents may compete for the <strong>same referral partners</strong>, which can lead to conflicts over leads and relationships
            </li>
            <li>
                Competing with <strong>direct-to-consumer insurers</strong> can be challenging, as their quotes may sometimes be lower, but Goosehead's multiple carrier options provide a competitive advantage
            </li>
            <li>
                Lead generation primarily relies on building and maintaining relationships with <strong>lenders, realtors, and other referral sources</strong>
            </li>
        </ul>
    </li>
</ul>
          `,
        ],
        image: "/spotlight/gh (3).png",
      },
      {
        id: 5,
        title:
          "Spotlight #5 - Agency Owner at Goosehead Insurance Inc - September 2023",
        description:
          "Perspective on insurance market dynamics unique to Florida, the benefits of Goosehead’s relationship model, and notes on commission structures",
        points: [
          `
<ul>
  <li>The interviewee is a seasoned insurance professional with over <strong>40 years of experience</strong> in the industry; formerly an agency owner, they have a deep understanding of the insurance market, particularly in <strong>Florida</strong> long-term prospects and its ability to adapt to market changes
    <ul>
      <li>Despite being experienced in the insurance industry, the franchisee had to attend basic training in Dallas, which they felt was unnecessary given their knowledge – after training there were <strong>delays</strong> with securing contracts with carriers</li>
    </ul>
  </li>
  <li><strong>Goosehead Insurance Inc does not have exclusive territorial rights</strong>, leading to competition among agents
    <ul>
      <li>The largest Goosehead agency in <strong>Coral Gables</strong> has an advantage due to its access to a <strong>wider range of carriers</strong></li>
    </ul>
  </li>
  <li>Goosehead’s model of building <strong>relationships with mortgage brokers, realtors, and title companies</strong> was praised as a winning strategy - however, the franchisee opted not to focus heavily on this area, preferring to build long-term relationships with clients instead of chasing leads from desperate mortgage brokers</li>
  <li>Goosehead uses <strong>Salesforce</strong> integrated with various rater tools for policy quotations
    <ul>
      <li>The process <strong>includes fine-tuning quotes to match specific regional regulations</strong> and client details</li>
      <li>Homeowners policies require additional documentation such as <strong>four-point inspections and wind mitigation reports</strong>, affecting quote accuracy</li>
    </ul>
  </li>
  <li>The franchisee acknowledged that the <strong>real estate market had slowed</strong>, which affected new business - mortgage brokers were struggling to qualify clients due to high interest rates, leading to fewer home closings and a <strong>general slowdown</strong> in the industry</li>
  <li>Clients may experience <strong>discrepancies between quotes</strong> from Goosehead and other providers, such as <strong>GEICO</strong>
    <ul>
      <li>In South Florida, policies require <strong>additional documentation</strong> like four-point inspections and wind mitigation reports, affecting quote accuracy</li>
      <li>Importance of experienced agents in handling complex cases and <strong>ensuring accurate quotes</strong></li>
    </ul>
  </li>
  <li>Training at Goosehead was deemed insufficient for experienced agents, with a <strong>notable failure rate</strong> among new agents due to the complexity of the insurance market and lack of adequate support</li>
  <li>Goosehead agents receive <strong>80% commission</strong> on new business and <strong>50%</strong> on renewals
    <ul>
      <li>Homeowners and auto policies have <strong>varying commission rates</strong>, with some carriers offering better terms than others</li>
      <li>Auto insurance commissions vary by carrier but generally follow an <strong>80/20 split</strong> for new business and 50/50 for renewals</li>
      <li>Homeowners insurance policies typically <strong>offer a 10% commission</strong>, but commissions can be lower for certain carriers like Citizens</li>
    </ul>
  </li>
  <li>Goosehead is actively seeking <strong>new carriers</strong> and adapting to market changes
    <ul>
      <li>The interviewee believes in Goosehead's <strong>long-term success</strong> but acknowledges current challenges and the need for improved local support</li>
      <li>The insurance market in Florida is <strong>particularly challenging</strong> due to high premiums and regulatory requirements</li>
    </ul>
  </li>
  <li>The interviewee would consider investing in Goosehead, reflecting confidence in the company’s growth potential despite current issues
    <ul>
      <li>The interviewee believes Goosehead has <strong>significant growth potential</strong> and could become a leading writer of insurance in the long term</li>
    </ul>
  </li>
  <li>There is significant competition among Goosehead agents in overlapping regions, with some larger agencies having access to more carriers and resources
    <ul>
      <li>In South Florida, competition includes companies like <strong>Progressive and Mercury</strong>, with variations in coverage and pricing</li>
    </ul>
  </li>
</ul>


          `,
        ],
        image: "/spotlight/gh (4).png",
      },
      {
        id: 6,
        title:
          "Spotlight #6 - Former Franchise Owner/Agent at Goosehead Insurance - September 2022",
        description:
          "Highlighting Goosehead’s competitive advantages over captive insurance companies, an analysis of corporate support, and streamlining initiatives undertaken",
        points: [
          `
<ul>
  <li>The former franchise owner/agent at Goosehead Insurance had worked at <strong>Cintas</strong> as a branch manager for 6.5 years and <strong>Bimbo Bakers</strong> for 10 years as a sales manager
    <ul>
      <li>Researched both captive (e.g., State Farm, Allstate) and independent agencies before deciding that the independent model was preferable - the rationale was that an independent structure offers more <strong>flexibility</strong> and the ability to serve a wider range of clients, regardless of underwriting standards or pricing constraints</li>
    </ul>
  </li>
  <li>The former franchise owner primarily sourced business through referrals from loan officers, realtors, and other local business owners
    <ul>
      <li>Their success was driven by a strong network and the ability to save <strong>clients</strong> money, which encouraged <strong>word-of-mouth</strong> referrals</li>
      <li>The majority of referrals came from <strong>loan officers</strong> and <strong>realtors</strong>, with additional referrals from other business owners and local networking groups</li>
      <li>Maintaining good relationships with these partners was crucial for <strong>generating leads</strong> and growing the business</li>
    </ul>
  </li>
  <li>Goosehead's value proposition was centered around offering <strong>multiple options</strong> to clients and ensuring smooth closings for referral partners, like loan officers and realtors
    <ul>
      <li>As an independent agent, they had access to multiple carriers and could offer <strong>more tailored solutions</strong> compared to captive agents, whose product offerings were restricted by their company’s underwriting standards</li>
    </ul>
  </li>
  <li>The agent found Goosehead’s <strong>prospecting tools</strong> valuable, though they relied heavily on personal networks and referrals
    <ul>
      <li>Despite having many ideas and strategies, time constraints limited their ability to fully leverage these tools</li>
    </ul>
  </li>
  <li>There were a few other Goosehead franchisees in the <strong>New Braunfels</strong> area - the former owner did not feel competition was a major issue due to a <strong>sufficient market size</strong> and differing niches</li>
  <li>The agent felt there was inadequate support from Goosehead's corporate level, noting the absence of <strong>field visits</strong> and <strong>one-on-one support</strong>
    <ul>
      <li>They suggested that more consistent field management would have been beneficial</li>
      <li>The franchise had access to <strong>25 annual rated carriers</strong>, with responses from about 20 of them per quote request</li>
      <li>There was a perceived disparity between corporate agents and franchise agents, with the former receiving more support and resources - however, the interviewee acknowledged that this lack of support didn’t affect their business growth but could have been a bigger issue for other franchisees</li>
    </ul>
  </li>
  <li>The franchisee expressed a desire for greater flexibility to <strong>operate from home</strong> rather than being required to maintain a physical office, citing potential savings in <strong>overhead costs</strong> and increased efficiency</li>
  <li>The use of Goosehead’s quoting technology, including <strong>Salesforce</strong> and <strong>DocuSign</strong>, was highlighted as a significant advantage for quickly providing clients with quotes and improving efficiency</li>
  <li>The technology facilitated a <strong>streamlined process</strong> for clients, saving time and reducing the need for physical office visits
    <ul>
      <li>There were concerns about the <strong>quality of customer service</strong>, particularly with hold times and wait times when contacting Goosehead's support</li>
      <li>While service was initially better, the growth of the company may have led to <strong>slower response times</strong></li>
      <li>Despite this, the former owner felt confident that issues would be addressed and resolved</li>
    </ul>
  </li>
  <li>The agent considered the possibility of <strong>going fully independent</strong> in the future but recognized the challenges of maintaining carrier appointments and the need for additional servicing capabilities
    <ul>
      <li>They observed that another franchisee had transitioned to <strong>independence</strong>, indicating it was a viable option for those who could manage the complexities</li>
    </ul>
  </li>
  <li>Commissions varied between <strong>13%</strong> and <strong>20%</strong> of premiums, with an <strong>80% commission rate</strong> on new business and <strong>50%</strong> on renewals</li>
  <li>While <strong>unsure about Goosehead’s presence in all states</strong>, the agent felt the model could work broadly but lacked specific insights into challenges in individual states</li>
</ul>
          `,
        ],
        image: "/spotlight/gh (5).png",
      },
    ],
  },

  {
    slug: "Burger King",
    pageData: {
      pic: "/logos/Burger King.jpg",
      title: "Burger King",
      initialInvestment: "$1.8M-$4.5M",
      franchiseeFee: "$50k",
      royaltyRate: "4.5%",
      description:
        "Burger King, founded in 1954, is a globally recognized fast-food restaurant chain known for its flame-grilled burgers, particularly the iconic Whopper. With over 18,000 locations in more than 100 countries, Burger King has built a reputation for delivering high-quality, affordable fast food. The brand emphasizes customer choice, offering a customizable menu that includes a variety of burgers, chicken sandwiches, fries, and beverages. Over the years, Burger King has also focused on innovation, introducing plant-based options and digital ordering to cater to evolving consumer preferences.",
    },
    keyTrends: [
      {
        leftDescription:
          "<strong>Bountiful exit opportunities</strong> due to ongoing market consolidation and favorable acquisition terms; as larger, well-capitalized operators continue to absorb smaller franchisees, those looking to exit can capitalize on higher valuation multiples—this trend is further supported by the franchisor's active <strong>encouragement of consolidation</strong>",
        rightDescription:
          "<strong>Remodeling capital expenditure requirements</strong> can impose a heavy burden on franchisees; the return on these investments may <strong>differ from corporate projections</strong>—sometimes franchisees observe only <strong>modest sales increases</strong> post-remodels",
      },
      {
        leftDescription:
          "Franchisees have effectively utilized financial maneuvers such as <strong>sale-leaseback strategies</strong> to unlock capital for expansion; this approach has enabled them to <strong>reduce occupancy costs</strong> while strategically reinvesting in growth—demonstrating the ability to <strong>optimize financial resources</strong> in a capital-intensive environment",
        rightDescription:
          "Corporate's preference for larger, well-capitalized franchisees and the consolidation trend may <strong>marginalize smaller operators</strong>; the franchisor's <strong>right of first refusal</strong> on acquisitions often benefits larger operators, limiting expansion opportunities for smaller franchisees",
      },
      {
        leftDescription:
          "The shift to <strong>digital and touchless ordering</strong>, including curbside pickup, has significantly increased sales; franchisees who embraced these innovations early on have seen these channels grow to account for <strong>10% to 20% of sales</strong>—indicating that Burger King is successfully adapting to <strong>evolving consumer preferences</strong>",
        rightDescription:
          "While Burger King's strategic focus on drive-through and digital channels offers significant advantages, the <strong>competitive landscape</strong> remains challenging, especially with formidable rivals like McDonald's and Chick-fil-A; Burger King's use of discounts to attract customer traffic, while effective, can <strong>put pressure on margins</strong>, and occasional inconsistencies in quality and operations may influence customer perceptions",
      },
    ],
    cardData: [
      {
        id: 1,
        title: "Spotlight #1 – Burger King Franchisee – December 2020",
        description:
          "Overview of capital intensity of ongoing operations, the impact of promotional activity coupled with wage inflation, the role of sale-leaseback strategies, and franchisee-corporate relations",
        points: [
          `
<ul>
    <li>The interviewee is an <strong>experienced Burger King franchisee</strong> with a long history in the business; has expanded his operation from <strong>20 to 23 stores</strong> over the <strong>past 3 years</strong>—strategic growth and real estate management have been key
        <ul>
            <li>Began working with Burger King at a very young age; mentions being involved since he was <strong>6 years old</strong>—deep-rooted experience in the brand</li>
            <li>Owns and operates Burger King franchises primarily in the <strong>US South</strong>; focuses on both urban and rural locations—regional emphasis where competition may be less fierce</li>
            <li>Grew his store base from <strong>20 to 23 units in the past 3 years</strong>; opened <strong>3 new stores</strong>, which is considered aggressive growth for his operation—expansion into rural markets proved successful</li>
            <li>Owns the real estate on which his restaurants are located; a common practice among Burger King franchisees—real estate ownership serves as both an asset and a potential burden</li>
            <li>Implemented <strong>sale-leaseback strategies</strong> in 2017 to free up capital for expansion; selling owned real estate and leasing it back reduced occupancy costs—this financial maneuver facilitated growth</li>
            <li>Addressed struggling units by optimizing real estate costs; reduced rent to <strong>sub-$6,000 per month</strong> on a store doing approximately <strong>$1 million in sales</strong>—lowering occupancy costs improved profitability</li>
            <li>Expanded into rural areas where real estate costs are lower and sales volumes are higher; rural locations offered better opportunities—strategic site selection was crucial</li>
            <li>Purchased a closed KFC location <strong>15 months ago</strong> and converted it into a successful Burger King; capitalized on competitors' closures—opportunistic acquisitions enhanced growth</li>
        </ul>
    </li>
    <li><strong>Pre-COVID challenges included increased competition and wage inflation</strong>, making operations more difficult for Burger King franchisees; these factors collectively strained profitability—no single factor was damning, but together they impacted the bottom line
        <ul>
            <li>The restaurant space became very crowded leading up to pre-COVID times; as competition rose due to "sillier and sillier deals" fueled by cheap money, comparable sales became difficult to achieve—franchisees faced anemic comps of around <strong>1.5% growth per year</strong></li>
            <li>Wage inflation set in; while wage growth between 2018 and 2019 was marginal, it added to the cumulative pressure on profitability—franchisees had to manage increased labor costs</li>
        </ul>
    </li>
    <li>Strategies like <strong>cost containment and opening new units</strong> helped offset low comp growth and wage pressures; expansion and diligence were key to maintaining profitability—franchisees had to be vigilant
        <ul>
            <li>Franchisees remained diligent in containing costs; profitability could suffer if not managed carefully—cost containment became essential</li>
            <li>In the South, competition was perhaps less fierce; this may have mitigated some pressures—regional differences played a role</li>
            <li>Opening new units helped boost top-line growth; aggressive expansion from <strong>20 to 23 units in 3 years</strong>—expansion was a strategic move</li>
        </ul>
    </li>
    <li><strong>Expansion into rural areas and sale-leaseback strategies enabled growth</strong>; optimizing real estate was crucial—lower real estate costs and higher sales volumes in rural areas were advantageous
        <ul>
            <li>Addressed struggling units by implementing sale-leasebacks; freed up capital and reduced occupancy costs—financial strategy facilitated growth</li>
            <li>Reduced rent to <strong>sub-$6,000 per month</strong> on a store doing approximately <strong>$1 million in sales</strong>; occupancy cost is a significant line item—lowering rent enhanced profitability</li>
            <li>Rural expansion offered higher sales volumes and lower real estate expenses; proved successful in their market—strategic site selection was beneficial</li>
        </ul>
    </li>
    <li><strong>Burger King's capital expenditure requirements place a heavy burden on franchisees</strong>; significant investments are mandated—remodels and new builds require substantial capital
        <ul>
            <li>New builds require substantial investment: <strong>$1 million for the building, $450,000 to $500,000 for FF&E, plus land cost</strong>—initial costs are high</li>
            <li>Midlife remodels at the 10-year mark can cost a couple hundred thousand dollars; major remodels at the 20-year mark can cost around <strong>$500,000</strong>—ongoing investments are necessary</li>
            <li>While corporate advertises a 25% sales increase post-remodel, franchisees have observed at best maybe a <strong>10% increase</strong>; not the "greatest thing ever"—actual returns may be modest</li>
        </ul>
    </li>
    <li><strong>Frequent corporate-driven remodel requirements can strain franchisees</strong>; ROI on remodels is often lower than corporate projections—expected sales increases may not materialize fully
        <ul>
            <li>Corporate may introduce new incentive programs requiring additional investments even after recent remodels; this can be frustrating—franchisees may feel pressured</li>
            <li>An example where after completing a major renovation, the regional rep immediately presented another incentive program to do more to the store; constant capital demands are challenging</li>
        </ul>
    </li>
    <li><strong>Maintaining good relations with franchisor is crucial for expansion opportunities</strong>; compliance with standards is necessary—being in good graces enables growth
        <ul>
            <li>Completing remodels and adhering to standards keeps franchisees in good graces; important for growth, expansion, or acquisition—franchisees must meet franchisor's acceptable store standards</li>
            <li><strong>Franchisor's support is essential for pursuing opportunities</strong>; relationship management is key—strategic alignment is beneficial</li>
        </ul>
    </li>
    <li><strong>Expansion opportunities are limited due to corporate preference for larger franchisees and market saturation</strong>; acquisitions and relocations may present better opportunities—franchisees must adapt
        <ul>
            <li>Burger King is trending towards super franchisees; <strong>corporate has right of first refusal on acquisitions</strong>, favoring larger operators like Carrols—smaller franchisees may be disadvantaged</li>
            <li>There is some encroachment in rural areas from bigger franchisees; limited expansion opportunities in markets that are not growing rapidly—market saturation is an issue</li>
            <li>Repositioning real estate through relocations is an opportunity; <strong>more restaurants closing due to COVID may make better real estate available</strong>—strategic moves are possible</li>
        </ul>
    </li>
    <li><strong>COVID may reduce competition by causing closures, presenting opportunities for surviving franchisees to gain market share</strong>; fewer options for consumers may benefit remaining players—market consolidation could occur
        <ul>
            <li>The restaurant space was overbuilt pre-COVID; COVID will hasten the demise of those already struggling—natural attrition may happen</li>
            <li>Fewer dining options will be available; for example, KFC closed a massive number of stores, possibly <strong>300 or 700</strong>—competitors are contracting</li>
            <li>Franchisees have taken advantage of closures; purchased a closed KFC location <strong>15 months ago</strong>, which worked out well—opportunistic acquisitions can be fruitful</li>
        </ul>
    </li>
    <li><strong>McDonald's and Chick-fil-A are strong competitors</strong>; overexpansion by others like Steak and Shake may have contributed to market saturation—competitive landscape is challenging
        <ul>
            <li><strong>McDonald's is considered the standard for fast food</strong>; Chick-fil-A is remarkable but in another class—both are potent competitors</li>
            <li>Observed many Steak and Shake openings; may have overbuilt with sale-leasebacks at high price points—unsustainable growth can backfire</li>
            <li>Franchisees consider McDonald's locations when expanding; target <strong>50% to 60% of McDonald's revenue</strong> for new sites—strategic positioning is essential</li>
        </ul>
    </li>
    <li><strong>Wage inflation and increased minimum wages impact labor costs</strong>; competition for employees intensified during COVID—labor market pressures affect operations
        <ul>
            <li><strong>Arkansas minimum wage is going up to $11 next year</strong>; franchisees already pay that due to competition—wage increases are anticipated</li>
            <li>During COVID, competing with unemployment benefits like the <strong>$600 per week</strong> made hiring challenging; had to exceed minimum wage—labor costs increased</li>
            <li>Wage increases can have minimal impact on sales; a <strong>$1 per hour increase</strong> doesn't significantly boost consumer spending—benefit to sales is negligible</li>
        </ul>
    </li>
    <li><strong>Potential federal minimum wage increase to $15 per hour poses significant challenges for franchisees</strong>; would require adjustments—price increases or other measures may be necessary
        <ul>
            <li>A mandated $15 minimum wage would impact operations; an industry-wide problem that would need to be handled—significant planning required</li>
            <li>Franchisees would need to navigate this change; potential adjustments in pricing or cost structures—strategic responses would be essential</li>
        </ul>
    </li>
    <li><strong>COVID accelerated digital adoption</strong>; curbside pickup via app now accounts for <strong>10% to 20% of sales</strong>; delivery remains minimal—shift in consumer behavior occurred
        <ul>
            <li>Pre-COVID, digital orders were minimal; only DoorDash delivery, which was less than <strong>3% of sales</strong>—digital presence was limited</li>
            <li>Franchisees had to invent curbside pickup themselves; now a significant part of sales—innovation was necessary</li>
            <li>Delivery is not popular for their products; customers prefer drive-up, pickup, and avoiding drive-through lines—convenience factors are important</li>
        </ul>
    </li>
    <li><strong>Corporate aims to consolidate franchisee base into fewer, larger, well-capitalized operators</strong>; this may impact smaller franchisees—consolidation trends are evident
        <ul>
            <li>Corporate prefers dealing with larger franchisees; better able to handle capital expenditures—economies of scale are favored</li>
            <li><strong>BK's right of first refusal allows them to assign acquisition agreements to preferred larger franchisees</strong>; smaller operators may lose opportunities</li>
            <li>Larger franchisees may have advantages in capital expenditure compliance; can implement new equipment more easily—capitalization is a factor</li>
        </ul>
    </li>
    <li><strong>CARES Act allowed accelerated depreciation for real estate, providing tax benefits for franchisees owning real estate</strong>; improved cash flow—tax legislation changes were beneficial
        <ul>
            <li>Correction in the Tax Cuts and Jobs Act via CARES Act allowed changing depreciation from <strong>39-year to 15-year schedule</strong>; increased deductions—errors were corrected</li>
            <li>Franchisees could restate taxes for previous years; larger deductions reduced income tax—cash flow improved</li>
            <li>This applies to stores where franchisees own the real estate; impacts cash flow positively—real estate ownership had advantages</li>
        </ul>
    </li>
    <li><strong>Breakfast offerings are weak compared to competitors like McDonald's</strong>; COVID exacerbated challenges—improving breakfast sales is difficult
        <ul>
            <li><strong>McDonald's is the champion of breakfast</strong>; Burger King was out-marketed and out-positioned—competitor dominance is clear</li>
            <li>Attempts to push lower-priced coffee did not resonate; <strong>BK is not top-of-mind for coffee</strong>—marketing efforts were ineffective</li>
            <li>COVID reduced breakfast sales further; fewer people commuting to work or school—changes in consumer routines affected sales</li>
        </ul>
    </li>
    <li><strong>Despite COVID, franchisee's sales have remained resilient</strong>, with only slight decreases compared to last year; <strong>drive-through is a competitive advantage</strong>—operations have been stable
        <ul>
            <li>Fast food has fared better than other restaurants; drive-throughs are a significant advantage—business model is robust</li>
            <li>Best store did <strong>$184,000 in July 2020 vs. $187,000 in July 2019</strong>; only about <strong>$3,000 less</strong>, approximately a 1-2% decrease—sales remained strong</li>
            <li>Overall, sales are comping in the mid to upper <strong>90% compared to last year</strong>—performance is satisfactory</li>
        </ul>
    </li>
    <li><strong>Misalignment between corporate and franchisee goals can impact profitability</strong>; some competitors have better alignment models—franchisee concerns exist
        <ul>
            <li>Franchisor pushes for sales regardless of franchisee profitability; wants franchisees to stay open longer and adopt new initiatives—conflicting interests may arise</li>
            <li>Franchisees bear costs of new equipment or product rollouts; corporate receives <strong>4% royalties regardless of franchisee profit</strong>—financial burdens are uneven</li>
            <li>Competitors like Chick-fil-A align better with franchisees; share profits and limit operators to one or two units—alternative models exist</li>
        </ul>
    </li>
    <li><strong>Current uncertainties hinder future planning</strong>; need clarity on COVID impacts and financial regulations—franchisees are cautious
        <ul>
            <li>Uncertainties around PPP forgiveness and tax deductibility of expenses make planning difficult—financial considerations are unclear</li>
            <li>Franchisees are waiting for the post-COVID world to see the next steps; can't make concrete expansion plans yet—strategic decisions are on hold</li>
            <li>Opportunities may arise in 2021 that are not apparent now due to the artificial environment created by government support—future prospects are uncertain</li>
        </ul>
    </li>
    <li>The franchisee believes that <strong>natural attrition will occur post-COVID</strong>; potential for market consolidation and acquisition opportunities—future landscape may change</li>
    <li>Once government support recedes, the true financial health of competitors will be revealed; opportunities may emerge—market dynamics will shift
        <ul>
            <li>Franchisees like them could operate stores on advantageous terms from failing franchisees; survival of the fittest scenario—acquisitions may be possible</li>
        </ul>
    </li>
    <li>There is skepticism about the sustainability of meat substitute products; nutritional benefits are questionable—consumer interest may wane
        <ul>
            <li>The Impossible Whopper may not have long-term traction; lacks nutritional benefits and primarily offers environmental benefits—future sales may decline</li>
            <li>Competitors introducing meatless options may not pose a significant threat; consumer demand may not be strong—franchisee is not overly concerned</li>
        </ul>
    </li>
    <li>The franchisee sees potential in <strong>digital and touchless ordering</strong>; app and curbside pickup are promising—could drive future sales
        <ul>
            <li>Improved drive-through execution and digital adoption are positive; customers appreciate ordering in advance and not waiting in line—enhances customer experience</li>
            <li>The shift to curbside pickup was a necessary innovation; accounts for a significant portion of sales now—adaptation was crucial</li>
            <li>Touchless transactions may become table stakes; staying competitive requires embracing digital solutions—technology is important</li>
        </ul>
    </li>
    <li>The franchisee notes that they have not reopened dining rooms since COVID closures; <strong>operational focus is on drive-through and digital channels</strong>—business model has adapted
        <ul>
            <li>Pre-COVID, they were already <strong>70% drive-through</strong>; post-COVID, this has increased—drive-through is dominant</li>
            <li>Not having to staff dining rooms may have labor savings; can focus on efficiency—operational adjustments have been made</li>
            <li>The closure of dining rooms may continue; consumer habits may have shifted—long-term implications are considered</li>
        </ul>
    </li>
</ul>
          `,
        ],
        image: "/spotlight/bk (1).png",
      },
      {
        id: 2,
        title: "Spotlight #2 – Burger King Franchisee – September 2017",
        description:
          "Highlighting scale efficiencies of larger operators, trends of franchisee consolidation, favorable exit opportunities for smaller franchisees, and cost management strategies",
        points: [
          `
<ul>
    <li>The interviewee is a <strong>former CEO of a large Burger King franchisee</strong>; he expanded the company from <strong>225 to nearly 400 restaurants</strong>—extensive experience in Burger King operations and acquisitions

        <ul>
            <li>Began his career with an <strong>undergraduate degree in business from Miami University of Ohio</strong>, then attended law school—background in business and law</li>
            <li>Worked as a <strong>civil litigator</strong>, moved into business litigation and M&A work—gained experience in corporate legal matters</li>
            <li>Became <strong>General Counsel for a Burger King franchisee with 65 restaurants</strong>, involved in multiple real estate portfolios and syndications—entered the Burger King system</li>
            <li>After about two years, transitioned from legal to the operational side of the business; worked in operations for approximately 10 years—deep operational experience</li>
            <li>Took over as <strong>CEO of franchisee group</strong> when his predecessor moved to Burger King Corporation under 3G Capital—assumed leadership during a critical transition</li>
            <li>Managed <strong>225 restaurants</strong>; grew the business to just under 400 restaurants over <strong>3.5 to 4 years</strong>—significant expansion under his leadership</li>
            <li>Faced significant challenges, including <strong>covenant non-compliance with banks</strong>; restructured loans and began to grow the organization—managed financial hurdles</li>
            <li>Left franchisee group when it was sold in 2014; company was sold in <strong>five separate transactions</strong>—took the opportunity to start consulting in the restaurant industry</li>
        </ul>
    </li>
    <li>Franchisee group pursued a growth strategy through <strong>acquisitions at attractive multiples</strong>; expanded to multiple states and Canada—leveraged scale to improve operations
        <ul>
            <li>Operated in <strong>Tennessee, Indiana, Ohio, Nebraska, Wisconsin, Minnesota, and Canada (Toronto and Ottawa)</strong>—wide geographic footprint</li>
            <li>Exclusively operated Burger King restaurants—focused on a single brand</li>
            <li>Acquired restaurants at multiples of <strong>4 to 4.5 times cash flow</strong>, plus discounts due to required capital expenditures—purchased assets at favorable prices</li>
            <li>Sold portions of the company to <strong>Carrol's Corp, Tri-State Foods, Cave Enterprises</strong>, and others—worked collaboratively with Burger King to identify buyers</li>
            <li>Burger King provided incentives to buyers, such as reduced capex or reduced royalty rates—strategic coordination with franchisor</li>
            <li>Intended to continue consolidating and scaling but was unsuccessful in retaining the organization in its totality—original growth plans were ambitious</li>
        </ul>
    </li>
    <li>Utilized data analytics and operational expertise to improve acquired restaurants; achieved sales and margin lifts—addressed inefficiencies in smaller operators
        <ul>
            <li>Compared proposed acquisitions to their existing portfolio to determine underperformance—used internal benchmarks</li>
            <li>Identified issues such as <strong>theft, improper ordering, expiration of product</strong>—smaller operators lacked capacity to detect inefficiencies</li>
            <li>Implemented measures like <strong>nightly counts of high-theft items and cameras tied into POS systems</strong>—monitored transactions closely</li>
            <li>Achieved <strong>sales increases of 1% to 2%</strong> by preventing theft and ensuring all sales were recorded—immediate top-line improvements</li>
            <li>Saw margin lifts of another <strong>1% to 2%</strong> by reducing food costs—enhanced profitability</li>
            <li>Addressed labor inefficiencies by implementing a detailed labor matrix based on half-hour and even 15-minute sales increments—optimized staffing levels</li>
            <li>Improved speed of service times, increased customer satisfaction scores, and generated repeat business—operational efficiencies led to higher sales</li>
        </ul>
    </li>
    <li>Small franchisees lacked resources and sophistication; large operators like Carrol's had competitive advantages—<strong>scale enabled better performance</strong>
        <ul>
            <li><strong>60% to 70% of the Burger King brand</strong> was run by operators with 10 or fewer restaurants back then—fragmented franchisee base</li>
            <li>Smaller operators lacked back-office capacity to analyze data and identify inefficiencies—limited operational insights</li>
            <li>Large operators could deploy teams to analyze each restaurant intensively—conducted thorough operational reviews</li>
            <li>Could afford investments in technology like cameras tied to POS systems—prevented theft and losses</li>
            <li>Smaller operators relied on intuition for staffing; large operators used data-driven labor scheduling—more precise cost control</li>
        </ul>
    </li>
    <li>Marketing strategies included <strong>local store marketing with Burger King's matching funds</strong>; dominant market presence allowed autonomy in marketing tactics—boosted sales and profitability
        <ul>
            <li>Took advantage of Burger King's matching funds for local marketing—either <strong>100% or 50% matching</strong> in certain years</li>
            <li>Invested in local store marketing to attract additional customers—leveraged additional funds effectively</li>
            <li>Having a dominant presence in the market meant they didn't need to consult other franchisees—freedom in marketing decisions</li>
            <li>Once operations and labor were optimized, targeted marketing campaigns further increased sales—strategic layering of improvements</li>
            <li>Repeat visits increased as customer satisfaction improved—customer counts turned positive</li>
        </ul>
    </li>
    <li><strong>3G Capital's ownership of Burger King</strong> had both negative and positive impacts on franchisees; initial challenges were followed by operational improvements—franchisor focused on financial discipline
        <ul>
            <li>Initially, impact was negative due to cost-cutting and outsourcing at corporate offices—loss of institutional knowledge</li>
            <li>Implementation of the Game Changer initiative required restaurants to purchase <strong>$15,000 to $20,000</strong> of new equipment—financial burden without sufficient returns</li>
            <li>Many initiatives had been tried before and failed—resulted in initial setbacks</li>
            <li><strong>3G is highly financially focused</strong>; when returns were not met, they quickly changed course—adaptive management</li>
            <li>Improved efficiencies in disseminating ideas and concepts to restaurants rapidly—benefited franchisees over time</li>
            <li>Realized that Burger King attracts customers with discounts; focused on strategically discounting popular products to drive customer counts</li>
            <li>Refranchised their portfolio and created several large franchisees ("mega-operators")—improved efficiency and reduced costs</li>
        </ul>
    </li>
    <li><strong>Large franchisees benefited</strong> from close relationships with Burger King; small franchisees felt marginalized—consolidation was strategic
        <ul>
            <li>Franchisee group had an unwritten understanding with Burger King to acquire stores in their markets—facilitated expansion</li>
            <li>Small franchisees often felt disadvantaged, as they had little voice in decisions—limited influence on brand direction</li>
            <li>Larger operators had better relationships with Burger King and more influence—enhanced collaboration</li>
            <li>Burger King preferred dealing with large operators, as it increased efficiency and reduced costs—aligned with corporate strategy</li>
        </ul>
    </li>
    <li><strong>Burger King's competitive position is challenged</strong> by inconsistencies and reliance on discounts; customer counts are a concern—competition with McDonald's and Wendy's remains intense
        <ul>
            <li>Finite number of customers; brands compete for the same customer base—market saturation</li>
            <li>Burger King has moved away from the dollar menu but still relies heavily on discounts—customer counts fluctuate with discount levels</li>
            <li>Negative customer count trends persist; increases in check averages may mask declining traffic—sales driven by price increases rather than volume</li>
            <li>Customer counts are a key indicator of brand success—more important than check averages</li>
            <li>Burger King's system-wide AUV is approximately <strong>$1.3 million</strong>, lower than Wendy's and McDonald's—lags behind competitors</li>
            <li>Inconsistencies in restaurant quality and operations impact brand perception—varied customer experiences</li>
            <li>Competitors introduce new full-margin products and invest in robust marketing—Burger King lacks in product innovation</li>
        </ul>
    </li>
    <li><strong>Structural limitations prevent significant increases in AUV</strong>; closure of underperforming restaurants has artificially inflated AUV—real growth is limited
        <ul>
            <li>Closing low-volume restaurants increases system-wide AUV without actual sales growth—statistical effect rather than operational improvement</li>
            <li>Past initiatives like Image 2020 led to many restaurants coming up for renewal simultaneously—required significant capital expenditure</li>
            <li>Limited ability to materially impact AUV through structural changes—growth constrained by market factors</li>
        </ul>
    </li>
    <li><strong>Remodeling is essential</strong> but must be carefully managed; ROI on remodels averages a 4 to 5-year return—defensive remodeling can protect market share
        <ul>
            <li>On average, remodels provide a <strong>6% to 8% sales increase</strong>, net of market growth—actual lift may be lower than reported</li>
            <li>Remodeling costs are significant, around <strong>$500,000 to $550,000 per restaurant</strong>—requires substantial capital</li>
            <li>Defensive remodeling may be necessary when competitors remodel nearby—prevents loss of sales</li>
            <li>Important to measure the true success of a remodel by considering net increase over market trends—need accurate metrics</li>
        </ul>
    </li>
    <li><strong>Key competencies for successful Burger King franchisees</strong> include controlling food and labor costs; larger operators have advantages—scale enables better cost management
        <ul>
            <li>Food and labor costs represent about <strong>60% of expenses</strong>—controlling these is critical to profitability</li>
            <li>Larger operators can implement systems like oil management programs—reduces costs significantly</li>
            <li>Small operators may lack capacity to invest in cost-saving equipment—hinders competitiveness</li>
            <li>Collaboration with franchisor to find areas for capital investment, such as accelerated remodel programs—strategic use of capital</li>
            <li>Prefer to remodel existing restaurants over building new ones—less risk and more predictable ROI</li>
        </ul>
    </li>
    <li><strong>Carrol's is a strong, disciplined operator</strong> with a consolidation strategy; aims to reach 1,000 stores—benefits from scale and close relationship with Burger King
        <ul>
            <li>Carrol's acquires stores at <strong>4x to 4.5x seller's cash flow</strong>—similar multiples as interviewee's franchise group did</li>
            <li>Achieves <strong>200 to 400 basis points of margin improvement</strong> on acquired stores—operational efficiencies realized</li>
            <li>Remodels result in <strong>8% to 12% sales lift</strong>, though actual net gain may be lower—important to consider market trends</li>
            <li>Sufficient number of willing sellers due to aging franchisees lacking succession plans—facilitates consolidation</li>
            <li>Right of first refusal and Burger King's preference for large operators facilitate Carrol's growth—strategic advantages</li>
            <li>Expected to continue expanding beyond 1,000 restaurants—growth likely to persist</li>
        </ul>
    </li>
    <li><strong>Labor rates and minimum wage increases</strong> are concerns; limited ability to raise prices due to reliance on discounts—pressure on margins
        <ul>
            <li>Historically, operators could raise prices to offset increased labor costs—less feasible now due to discounting strategies</li>
            <li>Reliance on discounts limits ability to increase menu prices—pricing power is constrained</li>
            <li>May lead to downward pressure on margins not seen in the past—financial challenges ahead</li>
        </ul>
    </li>
    <li><strong>Beef cost fluctuations are manageable</strong>; operators can adjust marketing and discounts—plan ahead based on cost visibility
        <ul>
            <li>Surge in beef costs can be mitigated by shifting discounts to other products like chicken sandwiches—flexibility in promotions</li>
            <li>Visibility into commodity costs allows for proper planning—helps in managing food costs</li>
        </ul>
    </li>
    <li><strong>Financial metrics indicate reasonable returns</strong>; margins at the store level are around 7% to 9%—rent factors impact profitability
        <ul>
            <li>Store-level margins within Burger King are typically <strong>7% to 9%</strong>, excluding G&A—profitability benchmarks</li>
            <li>Rent should be around <strong>8% to 8.5% of sales</strong>; higher rent reduces margins—important to manage lease agreements</li>
            <li>Acquisitions are made at <strong>4x to 4.5x seller's cash flow</strong>—common valuation multiples</li>
            <li>Margin improvements of <strong>200 to 400 basis points</strong> can be achieved on acquired stores—operational enhancements</li>
        </ul>
    </li>
    <li><strong>Continued consolidation is expected</strong>; 3G Capital favors large operators—small franchisees may have limited options
        <ul>
            <li>Many operators are reaching retirement age and lack succession plans—leading to sales opportunities</li>
            <li>3G selects "proven operators" for restaurant acquisitions—controlled consolidation</li>
            <li>Small operators have little choice but to sell to preselected large franchisees—market dynamics favor large operators</li>
            <li>No real downside for 3G in continued consolidation; they retain control and collect royalties—franchise model supports their strategy</li>
            <li>Consolidation enhances efficiency and consistency across the brand—aligns with corporate objectives</li>
        </ul>
    </li>
    <li>The interviewee chose not to pursue other concepts due to existing challenges and collaboration with Burger King—focused on leveraging existing systems
        <ul>
            <li>Considered stepping into fast-casual concepts but decided against it—preferred to stay within the Burger King system</li>
            <li>Challenges such as capex and rent factors influenced the decision—existing hurdles prioritized</li>
            <li>Working collaboratively with Burger King allowed for acquisitions within a familiar system—less risk and more expedient growth</li>
        </ul>
    </li>
    <li>Overall assessment is that <strong>being a large operator in the Burger King system</strong> offers advantages; as long as the brand remains stable, the model is sound—<strong>M&A opportunities exist</strong>
        <ul>
            <li><strong>The right of first refusal</strong> depresses pricing, benefiting large operators—acquisition opportunities at favorable terms</li>
            <li>As long as Burger King doesn't lose significant market share or discount too aggressively, the model is profitable—stability of the brand is key</li>
            <li>Scale provides operational efficiencies and better negotiation power—large operators can leverage size</li>
            <li>The franchise model allows franchisors to dictate terms; consolidation aligns interests of franchisor and large franchisees</li>
        </ul>
    </li>
</ul>


          `,
        ],
        image: "/spotlight/bk (2).png",
      },
      {
        id: 3,
        title: "Spotlight #3 – Burger King Franchisee – June 2020",
        description:
          "Highlighting scale efficiencies of larger operators, trends of franchisee consolidation, favorable exit opportunities for smaller franchisees, and cost management strategies",
        points: [
          `
<ul>
    <li>The interviewee is an <strong>experienced Burger King franchisee operating 23 stores in Arkansas</strong>; has deep roots in the business and owns both operations and some real estate—<strong>family-owned business</strong> with extensive experience
        <ul>
            <li>The franchise started back in <strong>1979</strong>; his father was originally a <strong>McDonald's executive</strong> who switched to Burger King—family has been in the fast food business for <strong>over 40 years</strong></li>
            <li>Based in <strong>Little Rock, Arkansas</strong>, with 23 stores centered in the metro area but spread throughout the state—covers both urban and rural markets</li>
            <li>They own both the operations and some of the real estate on the sites they operate; owning real estate is a significant part of their business model—provides additional revenue streams</li>
            <li>The business is a <strong>family partnership</strong>; he runs it with his brother and sister since their parents have passed away—family-owned and operated</li>
        </ul>
    </li>
    <li>The <strong>COVID-19 pandemic significantly impacted sales</strong>, with initial drops of <strong>up to 50%</strong>; however, sales have started to recover—<strong>drive-through is now a competitive advantage</strong>
        <ul>
            <li>Sales were down in the urban stores by high 30s to 40%; rural stores were down in the low 20% range—impact varied by location</li>
            <li>Initial sales dropped to between <strong>$30,000 and mid-$40,000 per day</strong> across 23 stores; normal sales range between <strong>$80,000 and $100,000 per day</strong>—significant decline in revenue</li>
            <li>Sales have since increased to about <strong>$50,000 per day</strong>; on good days, sales reach the <strong>$60,000s</strong>—sales are recovering but not yet back to normal</li>
            <li>Drive-through usually made up <strong>60-70% of business</strong>; with dining rooms closed, drive-through has become even more crucial—having a drive-through is now a competitive advantage</li>
            <li>Competitors without drive-throughs, like <strong>Cheesecake Factory and Waffle House</strong>, have been forced to close or struggle—drive-through capability provides resilience</li>
        </ul>
    </li>
    <li>They are focusing on <strong>cash flow and survival</strong>; implementing cost-cutting measures and relying on potential government assistance—<strong>CARES Act provisions</strong> could help sustain the business
        <ul>
            <li>Prioritizing cash flow and cash burn; determining available cash and reducing expenses—survival strategy involves careful financial management</li>
            <li>Delayed or canceled all capital improvements; focusing on essential expenses only—conserving cash is critical</li>
            <li>Calculated that they could survive for <strong>100 to 120 days</strong> without external help; some franchisees only had enough cash for <strong>teen number of days</strong>—varied levels of capitalization among franchisees</li>
            <li>Primary expenses are food costs and labor; focusing on estimating sales and necessary cash supplementation—managing operational costs</li>
            <li>Payroll may be backstopped by the government through the <strong>CARES Act</strong>; potential loans could be forgiven based on employee retention—could significantly reduce labor costs</li>
            <li>The <strong>QIP fix in the CARES Act</strong> allows changing depreciation from 39-year to 15-year schedule; they can restate <strong>2018 returns</strong>—accelerated depreciation improves cash flow</li>
        </ul>
    </li>
    <li>The franchisee expects that there will be <strong>fewer restaurants after the pandemic</strong>; opportunities may arise for survivors—potential for increased market share and acquisitions
        <ul>
            <li>Believes that many restaurants will not survive; overbuilt restaurant industry will contract—natural attrition will occur</li>
            <li>Fast food may benefit from consumers trading down from casual dining; during the 2007-2009 recession, their profits increased—historical precedent for increased sales</li>
            <li>Survivors may see increased volume on the back end; potential to acquire failing restaurants at lower multiples—opportunities for expansion and growth</li>
            <li>Examples of struggling brands include <strong>Logan's Roadhouse</strong> and other fast-casual pizza chains—competitors may exit the market</li>
        </ul>
    </li>
    <li>The franchisor (<strong>Restaurant Brands International</strong>) is not providing significant relief; franchisees must navigate the crisis largely on their own—franchisee concerns about support from corporate
        <ul>
            <li><strong>QSR</strong> has not been forthcoming with relief; continues to collect royalties and advertising fees—franchisees feel unsupported</li>
            <li><strong>Burger King requires significant capital investments</strong> from franchisees; offers limited incentives—capital requirements are burdensome</li>
            <li>The franchisor's model is asset-extremely light, focusing on royalty streams; less impacted financially than franchisees—franchisor and franchisee interests may not align</li>
            <li>Not every franchisee is well-capitalized; those with limited cash reserves may struggle—potential for closures among smaller operators</li>
        </ul>
    </li>
    <li>Franchisees are considering <strong>alternative financing options</strong> to maintain liquidity; banks are less willing to lend—creative financial strategies are necessary
        <ul>
            <li>Banks have become more restrictive; obtaining financing is laborious—traditional financing is challenging</li>
            <li>Considering using <strong>Interactive Brokers for margin loans</strong>; can borrow up to <strong>99% against treasuries</strong> at interest rates of 0.8%—accessing low-cost capital through brokerage accounts</li>
            <li>Unencumbered real estate was seen as a cash source; but banks are slow and require covenants—need for quicker financing options</li>
            <li>Sale-leaseback market is still alive and well; can be a good money source for those with real estate—alternative financing strategy</li>
        </ul>
    </li>
    <li><strong>Labor market changes may lead to cost savings</strong>; labor supply may increase post-crisis—potential to reduce labor costs
        <ul>
            <li>Implemented a <strong>10% reduction in payroll</strong>, starting from the top; salaries of executives and managers reduced—cost-cutting measures</li>
            <li>Pre-crisis, labor market was tight; post-crisis, supply of labor may be high—opportunity to adjust wages</li>
            <li>Minimum wage in Arkansas is <strong>$10</strong>; average pay was between <strong>$10 and $10.50</strong>—limited room for reduction at lower levels</li>
        </ul>
    </li>
    <li>Delivery is <strong>not a significant part of their business</strong>; customers prefer drive-through or pick-up—delivery may not have strong potential
        <ul>
            <li>Delivery through <strong>Uber Eats or DoorDash</strong> is minimal; not a big percentage of sales—delivery not a major channel</li>
            <li>Consumers may have concerns about food handling; prefer fewer hands touching their food—drive-through seen as safer</li>
            <li>The franchisee is skeptical about delivery's potential in its current form; does not believe it has legs—not a focus area</li>
        </ul>
    </li>
    <li><strong>Fast food remains a stable market</strong>; burgers and fries have enduring demand—product demand is steady
        <ul>
            <li>Believes that <strong>people's desire for burgers and fries is enduring</strong>; always a market for these products—core demand remains</li>
            <li>Fast food may be more resilient during economic downturns; provides affordable options—fits consumer needs</li>
            <li>Overbuilt restaurant industry will see a shakeout; fast food may come out stronger—less competition</li>
        </ul>
    </li>
    <li>The franchisee emphasizes the importance of being a survivor during the crisis; aims to build a bridge to the other side—focus on <strong>long-term viability</strong>
        <ul>
            <li>Cash is the big thing; need to know cash burn rate and access to funds—financial planning is critical</li>
            <li>Survival strategy involves maintaining operations and preparing for post-crisis opportunities—long-term thinking</li>
            <li>Believes that QSR will survive as an entity; franchisees need to ensure they can too—franchisee survival is essential</li>
        </ul>
    </li>
    <li>They are cautious about relying on government assistance; CARES Act provisions are promising but implementation is uncertain—waiting for details
        <ul>
            <li>Banks and lenders have no set processes for administering CARES Act programs; guidance is lacking—implementation is delayed</li>
            <li>Need to ensure loans are forgivable; otherwise, difficult business decisions about labor may be necessary—uncertainty impacts planning</li>
            <li>The franchisee has not yet seen the CARES Act rolled out; waiting for applications and specifics—timing is critical</li>
        </ul>
    </li>
    <li>Potential consolidation in the franchisee base; larger, well-capitalized franchisees may absorb smaller ones—industry dynamics may shift
        <ul>
            <li>Burger King prefers larger franchisees; aims to consolidate franchisees to deal with fewer, larger operators—corporate strategy</li>
            <li>Poorly capitalized franchisees may be swallowed up by more well-capitalized ones—market consolidation</li>
            <li>Survival of each location depends on franchisee capitalization; those with limited resources may not make it—financial strength is key</li>
        </ul>
    </li>
    <li><strong>Carrols Restaurant Group</strong> (a large Burger King franchisee) has been significantly impacted; stock price dropped from around <strong>$10 to $1.80</strong>—indicative of franchisee struggles
        <ul>
            <li>Carrols' stock price has dropped by approximately <strong>80%</strong>; reflects financial stress—franchisees are vulnerable</li>
            <li>The franchisee is uncertain about Carrols' access to capital markets; does not know their financial details—concern about their survival</li>
            <li>Notes that Carrols may face challenges in maintaining their store base; operating environment is tough—larger franchisees are not immune</li>
        </ul>
    </li>
    <li><strong>Historical data shows profit increases</strong> during economic downturns; their profits increased from <strong>$990,000 in 2008 to $1.3 million in 2009</strong>—trade-down effect benefits fast food
        <ul>
            <li>Profit margins increased from <strong>1.5-2% to 5-7%</strong> during the 2007-2009 recession; consumers traded down—economic downturns can boost fast food sales</li>
            <li>Added a couple of stores and improved operations during that time; not all profit increases were due to trade-down—multiple factors contributed</li>
            <li>Expects similar patterns post-COVID; optimistic about potential sales increases—history may repeat itself</li>
        </ul>
    </li>
    <li>Franchisee suggests that being the franchisor is advantageous during crises; <strong>asset-light model</strong> provides stability—royalty streams are resilient
        <ul>
            <li>Franchisors like Restaurant Brands International are asset-extremely light; rely on royalties—less exposed to operational risks</li>
            <li>Royalty streams provide steady revenue; franchisors have a "sweet stream" of income—business model is advantageous</li>
            <li>Franchisees bear the operational and financial risks; may face challenges that franchisors do not—disparity in impacts</li>
        </ul>
    </li>
</ul>


          `,
        ],
        image: "/spotlight/bk (3).png",
      },
      {
        id: 4,
        title: "Spotlight #4 – Franchise Operations Director – October 2017",
        description:
          "Delineating Burger King’s advantages over other concepts, the effect of the current corporate franchisor RBI, and the difficult balancing act between cost reduction and sales growth",
        points: [
          `
<ul>
    <li>The interviewee is a <strong>former Franchising Director at Burger King Corporation</strong>; extensive experience in operations, marketing, and franchise relations—deep insights into the Burger King system
        <ul>
            <li>Began his career in the beverage industry with <strong>Jolt Cola</strong> as a marketing manager/product manager for almost three years—early experience in product management and marketing.</li>
            <li>Worked for <strong>Aramark</strong> as a marketing manager responsible for P&Ls and growing sales for eight college food service programs across upstate New York—gained experience in food service operations and financial management.</li>
            <li>Joined Burger King Corporation shortly after <strong>3G Capital's acquisition in August 2011</strong>—entered during a significant transition period.</li>
            <li>Served as a Sales Profit Operations Coach, overseeing <strong>40 restaurants</strong> across five franchise groups—focused on improving operations, sales, and profits, and assisting with new product rollouts and training.</li>
            <li>Promoted to Director, overseeing a region of over <strong>300 restaurants</strong> and <strong>50 franchise groups</strong> from upstate New York to Virginia, including Pittsburgh and Wilkes-Barre—expanded leadership role with broader responsibilities in operations management, development, real estate, and franchise agreements.</li>
            <li>Managed operations in Philadelphia, the fourth-largest Burger King market, overseeing <strong>150 restaurants</strong> (which grew to 162 before he left)—handled development, real estate, and franchise agreements, working with various teams.</li>
            <li>Transitioned to <strong>Tim Hortons in 2016</strong> under Restaurant Brands International (RBI)—implemented Burger King's culture and operational practices at Tim Hortons, focusing on rolling out new initiatives, operational procedures, training platforms, audit rollouts, and development.</li>
            <li>Currently serves as <strong>VP of Operations</strong> for a <strong>19-unit Tim Hortons franchise group</strong>—responsible for turnaround efforts, growth initiatives, managing four district managers, acquiring two restaurants in the past six months, building a new restaurant expected to open in late October, and exploring opportunities to expand the portfolio, including other brands.</li>
            <li>Focused on <strong>turning around underperforming restaurants</strong> that had poor operations and ownership for the past 10 years—implementing new philosophies, culture, and systems into day-to-day operations.</li>
        </ul>
    </li>
    <li>Believes Burger King is performing well; franchisees are seeing same-store sales growth—brand is focused on franchisee profitability
        <ul>
            <li>Burger King is <strong>comping over strong previous years</strong>, indicating sustained growth—positive sales trends with same-store sales growth over 2016 and a strong 2015</li>
            <li>The brand is mature and focused on franchisee profitability, which is not common in all franchises—<strong>franchisee-centric approach</strong> with the system emphasizing the health of the P&L for franchise groups.</li>
            <li>Intelligent leadership at RBI is leveraging <strong>cross-brand synergies</strong>—expertise in development, real estate, and supply chain advantages, while acknowledging that it's not a one-size-fits-all approach between Popeyes, Tim Hortons, and Burger King.</li>
            <li>Burger King is expanding the <strong>breakfast daypart</strong>, addressing growth opportunities—developing platforms that are profitable and drive traffic, focusing on their biggest opportunity.</li>
            <li>The franchise system <strong>votes on marketing promotions</strong>, ensuring franchisee buy-in—collaborative decision-making process where franchisees have to approve marketing promotions and platforms.</li>
            <li>Burger King's testing processes are rigorous, ensuring profitability before rollout—<strong>data-driven approach</strong> with extensive test marketing (e.g., the hot dog platform was tested for nine months in five different markets).</li>
            <li>Would <strong>invest in Burger King over Tim Hortons</strong> due to its strategic approach and profitability—considers Burger King a smart company with intelligent people and effective strategies.</li>
        </ul>
    </li>
    <li><strong>Challenges and pressures for franchisees</strong> include pressure to expand quickly and meet operational expectations—being a public company intensifies these pressures.
        <ul>
            <li>Franchisees are pushed to grow quickly, with the franchisor managing every development milestone—<strong>constant follow-ups</strong> on site selection, permits, designs, and opening dates can be irritating for franchisees.</li>
            <li>Operational expectations are increasing, with emphasis on <strong>improving speed of service</strong>—Burger King's operations have lagged behind competitors like Chick-fil-A and Panera, leading to a push for improvement.</li>
            <li>Franchisees not committed to remodeling or building may feel pressured—the brand is enforcing higher standards, particularly impacting smaller operators who may struggle to meet expectations.</li>
        </ul>
    </li>
    <li>Importance of remodels is significant; remodels can lead to substantial sales increases but require operational excellence to be effective
        <ul>
            <li><strong>Remodels can boost sales by 5% to over 30%</strong>, depending on market and commitment to operations and team building—success varies widely.</li>
            <li>Without operational improvements, remodels may not be effective—risk of "putting lipstick on a pig" if underlying issues aren't addressed.</li>
            <li>Franchisees with poor operations often resist remodels, seeing less benefit without operational change.</li>
        </ul>
    </li>
    <li><strong>Franchisee base composition is diverse</strong>, ranging from single-unit operators to large groups like Carrol's Corporation with approximately 900 restaurants—consolidation is encouraged
        <ul>
            <li>Operators with 10 to 35 units are common, with some exceeding 100 units—mid-sized operators play a significant role in the system.</li>
            <li>Smaller operators struggle with the <strong>costs of remodels</strong> (e.g., $350,000)—financial strain can lead to them being absorbed by larger operators.</li>
            <li><strong>Consolidation is encouraged by Burger King</strong>; larger operators can evolve the brand faster due to greater resources—seeing tenured operators absorbing smaller ones.</li>
            <li>In Pennsylvania, a franchisee <strong>sold 12 restaurants to Carrol's</strong>, exemplifying the consolidation trend.</li>
        </ul>
    </li>
    <li><strong>Incentives for consolidation</strong> include favorable market multiples and the impact of remodels on valuation—operators are both encouraged and pressured to sell
        <ul>
            <li>Market multiples are higher due to improved profitability; typical multiples range from <strong>5x to 8x EBITDA</strong>—varies by market.</li>
            <li>Not investing in remodels <strong>decreases the business's value</strong>—akin to adding miles to a car.</li>
            <li>Franchisees are advised to remodel key restaurants before selling to enhance valuation—sales increases from remodels can offset investment costs.</li>
        </ul>
    </li>
    <li><strong>Carrol's Corporation</strong> is considered middle of the pack as an operator; focuses heavily on cost control rather than top-line growth.
        <ul>
            <li>Emphasizes controlling controllables, with a strong focus on <strong>P&L management from the top down</strong>—managers are expected to know daily food cost variances and labor costs.</li>
            <li>District Managers prioritize managing controls like food cost, paper, labor, and coupon profitability—primary focus is on cost management</li>
            <li>Less emphasis on driving top-line sales compared to other franchisees—believes tight cost control ensures bottom-line profitability.</li>
            <li>Possesses advanced control tools, including their own POS system—capable of detailed inventory tracking, such as knowing exact ketchup quantities.</li>
            <li>May be reluctant to fully adopt Burger King's standards—accepts a <strong>3-minute speed of service</strong> versus the corporate standard of 2:45.</li>
            <li>When acquiring restaurants, looks for <strong>cost reduction opportunities</strong>—aims to improve food, paper, and labor costs significantly (e.g., reducing from 65% to 59%).</li>
        </ul>
    </li>
    <li>Impact of Carrol's management style includes high turnover and employee challenges—strict accountability can be difficult for staff
        <ul>
            <li><strong>High accountability environment</strong> leads to challenges for some employees—held accountable for every piece of product, including "every chicken nugget."</li>
            <li>Turnover is common after acquisitions—employees may prefer working for other groups with different cultures.</li>
            <li>Transition difficulties are observed but may have improved over time—initial adjustments can lead to frustration and departures.</li>
        </ul>
    </li>
    <li>Burger King's strategies for encouraging consolidation involve applying pressure on underperforming franchisees—cannot force sales but can influence decisions
        <ul>
            <li>Engages in conversations with underperforming franchisees, offering assistance with exit strategies—emphasizes opportunity to sell at a high valuation.</li>
            <li>May hold franchisees accountable for contract compliance—looking for breaches to apply pressure if necessary.</li>
            <li>Might inform operators nearing renewal that they won't be renewed unless standards improve—encourages them to "leave the system with dignity."</li>
            <li>Applies significant pressure without forcing a sale—referred to as "putting the screws to them."</li>
        </ul>
    </li>
    <li>Industry trends show similar consolidation in McDonald's and Tim Hortons; high remodel costs make it tough for small operators
        <ul>
            <li>McDonald's remodels can cost <strong>$800,000 to $1 million</strong>—compared to Burger King's $350,000 remodels.</li>
            <li>Burger King keeps remodel costs lower through outsourcing and securing best pricing—makes investments more feasible for franchisees.</li>
        </ul>
    </li>
    <li><strong>Economics of Burger King stores</strong> indicate average sales of <strong>$1.3 million annually</strong> with EBITDA margins typically ~10%—Carrol's aims for similar or better performance
        <ul>
            <li>System average sales were approximately <strong>$1.3 million per year</strong> when the interviewee left—goal was to reach $1.5 million by 2018</li>
            <li>Food costs average around 30%; labor costs in the high teens to mid-teens—Carrol's may achieve food and paper costs of around 28%</li>
            <li>Variations in P&L reporting can affect comparisons—some franchisees report G&A expenses or debt service differently</li>
        </ul>
    </li>
    <li>Key competencies for successful operators include investing in people, customer focus, and balanced cost management—operators run great restaurants, not accountants
        <ul>
            <li>Success is driven by <strong>good managers</strong> and well-trained teams—employee satisfaction leads to better service and performance.</li>
            <li>Emphasizing fast service and guest satisfaction is crucial—teams work together to serve customers efficiently.</li>
            <li>Incentivizing staff to perform well encourages efficiency—positive customer interactions boost repeat business</li>
            <li>While controlling costs is important, overemphasis can harm service quality—operators should avoid cutting corners that impact the customer experience</li>
            <li>Focusing on increasing sales through suggestive selling and friendly service boosts profitability—top-line growth is essential alongside cost control.</li>
            <li>Operators understand daily challenges and run great restaurants—accountants manage controls but may lack operational insight</li>
        </ul>
    </li>
    <li><strong>Considerations for evaluating Carrol's</strong> as an investment include analyzing same-store sales growth, balancing cost control with sales growth, and recognizing data limitations
        <ul>
            <li>Important to assess whether Carrol's is growing same-store sales and customer traffic—not just improving EBITDA margins through cost cuts.</li>
            <li>Analyze legacy restaurants separately from recent acquisitions—break down performance by acquisition year to identify trends.</li>
            <li>Over-focusing on the bottom line without driving top-line growth can limit long-term success—need to ensure that cost-cutting doesn't lead to declining sales.</li>
            <li>Public investors may have limited access to detailed operational data—must piece together available information to form a comprehensive view.</li>
            <li>Acknowledging Carrol's ability to improve EBITDA, question remains if they can drive top-line sales and expand other areas of the P&L—sustainable growth requires balance</li>
        </ul>
    </li>
    <li>Final thoughts emphasize the need for balancing cost management with sales growth—Carrol's can grow EBITDA but must focus on increasing sales for long-term success
        <ul>
            <li>There's no doubt that Carrol's can grow EBITDA margins through acquisitions and cost control—but sustainable growth depends on driving sales and traffic</li>
        </ul>
    </li>
</ul>


          `,
        ],
        image: "/spotlight/bk (4).png",
      },
      {
        id: 4,
        title: "Spotlight #5 – Burger King Franchisee – December 2020",
        description:
          "Comparison of Burger King with other popular QSR concepts and a focus on rising labor cost implications, digital platform challenges, and the ROI on capital-intensive mandated remodeling",
        points: [
          `
<ul>
  <li>The interviewee has an <strong>extensive background and experience</strong> in QSR franchising that began in <strong>1998 at age 22</strong>—when he purchased three Dunkin' Donuts stores with <strong>$60,000 savings and a loan</strong>; over the years, he expanded his portfolio to include <strong>25 Dunkin' locations, 12 Taco Bells, and 7 Burger Kings</strong> through strategic acquisitions and development—demonstrating a strong ability in turnaround situations and multi-brand operations
    <ul>
      <li>Acquired the initial three Dunkin' Donuts stores despite having <strong>no prior food industry experience</strong>—couldn't even cook; leveraged the location insight from owning a laundromat across the street</li>
      <li>At age 25, purchased <strong>13 Diedrich Coffee stores in Arizona</strong>—a turnaround situation; successfully flipped them after improving operations, showcasing early prowess in business revitalization</li>
      <li>Returned to Chicago in <strong>2004</strong>; continued to expand Dunkin' Donuts portfolio to <strong>25 locations by 2012</strong>; began investing in real estate, buying properties and placing Dunkin' stores on them—marking his first foray into property development</li>
      <li>In <strong>2014</strong>, took over a food court location with <strong>four different concepts: KFC, Burger King, Subway, and Taco Bell</strong>; became an authorized franchisee of these brands within <strong>60 days</strong>, highlighting agility and capability to meet franchisor requirements</li>
      <li>Decided to focus growth on <strong>Burger King and Taco Bell</strong>; currently owns a diversified portfolio—<strong>25 Dunkin', 12 Taco Bells, and 7 Burger Kings</strong>—balancing across different market segments and brand strengths</li>
    </ul>
  </li>
  <li>The interviewee's <strong>contrasting experiences as a franchisee</strong> with Dunkin', Taco Bell, and Burger King highlight the unique dynamics and relationships within each brand; he observed significant differences in franchisee influence, franchisor control, and overall brand culture—ranging from <strong>Dunkin's franchisor dominance</strong> to <strong>Taco Bell's franchisee power</strong> to <strong>Burger King's contentious yet evolving relationship under RBI ownership</strong>
    <ul>
      <li>With Dunkin', franchisees are often immigrants—first or second generation; they are more subservient and grateful, giving the franchisor significant control; the brand is not afraid to assert authority, influencing everything from new products to operations</li>
      <li>In Taco Bell, franchisees are strong and powerful, often large operators or private equity groups; they have substantial influence over the franchisor—it's a franchisee-driven culture; the process to become a franchisee is rigorous—he had to go through 6 to 7 interviews</li>
      <li>At Burger King, franchisees are intelligent and larger operators; the relationship with the franchisor is more contentious due to bad history and is described as a "good old boy" network; under RBI ownership, the relationship is improving but old tensions remain—franchisees have a long memory of past grievances</li>
    </ul>
  </li>
  <li>Burger King's <strong>brand positioning as a value player</strong> deliberately targets Hispanic and African American customers—emphasizing disruptive marketing tactics, such as poking fun at competitors, and offering aggressive value promotions like <strong>2 Whoppers for $5</strong>; while this strategy aims to increase guest counts and differentiate Burger King from McDonald's and Wendy's, it often <strong>squeezes franchisee margins</strong> due to higher food costs and lower profitability per transaction
    <ul>
      <li>Burger King embraces being a <strong>value brand</strong>, acknowledging it's not McDonald's, Wendy's, or Chick-fil-A; focuses on being <strong>cheaper than competitors</strong> to attract customers</li>
      <li>Uses <strong>disruptive advertising</strong>, including poking fun at McDonald's, to create a distinct brand identity; aims to be the <strong>disruptor in the QSR space</strong></li>
      <li>The aggressive value promotions are designed to drive guest counts but result in high food costs for franchisees; the <strong>2 for $5 Whopper deal</strong> significantly impacts gross profit—franchisees express concern over reduced margins</li>
    </ul>
  </li>
  <li>Franchisee margins at Burger King are <strong>significantly tighter</strong> compared to Taco Bell and Dunkin' Donuts—requiring meticulous cost control and higher sales volumes to achieve profitability; with EBITDA margins ranging from <strong>8% to 12%</strong> and a critical sales threshold of <strong>$1.2 million</strong>, Burger King franchisees face challenges due to the brand's focus on value promotions that impact gross profits—whereas Taco Bell and Dunkin' Donuts offer higher margins
    <ul>
      <li>Burger King EBITDA margins are between <strong>8% and 12%</strong>; stores must achieve at least <strong>$1.2 million in sales</strong> to be profitable—below this threshold, operating the store isn't worthwhile</li>
      <li>In contrast, Taco Bell EBITDA margins range from <strong>18% to 22%</strong>, and Dunkin' Donuts from <strong>15% to 20%</strong>; these brands allow for profitability even at lower sales volumes due to lower food costs and higher margins</li>
      <li>Burger King's tight margins necessitate meticulous cost control; the brand's emphasis on value deals like the <strong>2 for $5</strong> impacts gross profit, making profitability more challenging for franchisees</li>
    </ul>
  </li>
  <li>Burger King's <strong>digital platform weaknesses</strong>, particularly its non-functional mobile app, are hindering potential growth opportunities and customer satisfaction—customers face issues with orders not being received and promotions not showing up, leading to frustration; despite having young, tech-savvy staff, Burger King's app lags behind competitors like Taco Bell and Starbucks, potentially impacting the brand's ability to attract digitally engaged consumers, even though many primary customers still prefer traditional methods
    <ul>
      <li>The <strong>mobile app is not functional</strong>; issues include orders not coming in and promotions not appearing, causing customer dissatisfaction and disputes at the point of sale</li>
      <li>Competitors like <strong>Taco Bell and Starbucks</strong> have advanced digital platforms; Burger King's lag in this area is surprising given its young, tech-savvy staff</li>
      <li>While the primary customer base may not heavily use digital ordering—many still pay with cash—the lack of a functional app may hinder the brand's ability to attract and retain younger, digitally savvy customers</li>
    </ul>
  </li>
  <li><strong>Labor challenges</strong> are intensifying for Burger King franchisees, with hiring and retention harder than ever—minimum wage increases and competition for workers have led to higher actual pay rates, with crewmembers earning above minimum wage and managers earning substantial salaries; the enhanced unemployment benefits during COVID-19 exacerbated the issue, making it difficult to attract employees who could earn more staying at home—although the reduction of benefits has slightly improved the hiring situation
    <ul>
      <li>Minimum wage in <strong>Chicago is $14</strong>; in Michigan, it's $11, but actual pay rates are higher—paying <strong>$12 to $14 in Michigan</strong> to attract workers</li>
      <li>Managers earn between <strong>$50,000 and $75,000</strong>; shift managers make about <strong>$15.50</strong>; competitive wages are necessary to attract and retain quality staff</li>
      <li>During COVID-19, enhanced unemployment benefits meant employees could earn more staying at home, making hiring extremely difficult; since benefits reduced, there's been a slight improvement, but challenges remain</li>
    </ul>
  </li>
  <li>Burger King's <strong>breakfast offerings</strong> are underperforming due to the lack of a strong coffee product—without quality coffee, it's challenging to attract breakfast customers in a market where competitors like McDonald's, Dunkin', and Starbucks excel; despite owning Tim Hortons, known for its good coffee, Burger King doesn't utilize this asset, leading to frustration among franchisees who see it as a missed opportunity; consequently, operators hesitate to invest in breakfast, preferring to focus resources on periods with higher returns
    <ul>
      <li><strong>Quality coffee is essential</strong> for a successful breakfast program; competitors excel in this area, making it hard for Burger King to compete without a comparable product</li>
      <li>Franchisees are <strong>frustrated</strong> that Burger King doesn't leverage Tim Hortons' coffee, which could enhance breakfast offerings and attract more customers</li>
      <li>Operators are reluctant to invest in breakfast hours due to low sales; they prefer to allocate resources to times with higher customer traffic and better return on investment</li>
    </ul>
  </li>
  <li>The <strong>COVID-19 pandemic</strong> has presented both challenges and opportunities for Burger King franchisees—while labor issues intensified, sales have increased significantly, with some locations comping <strong>10% to 20% over last year</strong>; the brand's resilience and stability have been demonstrated through consistent operations and drive-thru availability, attracting customers from competitors who faced closures or inconsistencies; the closure of smaller competitors and independent restaurants has offered Burger King an opportunity to gain market share as customers seek reliable and value-oriented dining options
    <ul>
      <li><strong>Consistency in operations</strong> has been crucial; competitors' inconsistencies due to COVID-related closures or staffing issues have driven customers to Burger King</li>
      <li>The brand's focus on being <strong>open and reliable</strong> has retained customers and attracted new ones seeking value and convenience</li>
      <li><strong>Drive-thru availability</strong> has been essential during the pandemic; locations with drive-thrus have performed better, highlighting the importance of this feature in current and future operations</li>
    </ul>
  </li>
  <li><strong>Capital expenditures and remodels</strong> are necessary for Burger King franchisees to remain competitive and meet brand standards—however, they pose financial challenges due to tight margins; the brand is pushing for costly upgrades like double drive-thrus, digital menu boards, and new store images, which require significant investment; while remodels can boost sales and customers notice when stores are updated—especially exterior enhancements—franchisees are concerned whether the increased sales will justify the substantial capital outlay given their already slim profitability
    <ul>
      <li>Upgrades like <strong>double drive-thrus</strong> are becoming more critical, especially in the post-COVID landscape where drive-thru efficiency is key</li>
      <li><strong>Exterior upgrades</strong> are particularly important as many customers never enter the store; a refreshed exterior can attract more customers and increase sales</li>
      <li>The financial challenge lies in investing in these upgrades when margins are tight; franchisees must assess whether the return on investment justifies the significant capital expenses</li>
    </ul>
  </li>
  <li>Future growth plans for the interviewee involve <strong>building more Burger King locations</strong> focusing on key demographics—planning to construct <strong>2 new stores in the next 12 to 15 months</strong>, targeting markets with primarily Hispanic or African American customers; emphasis is placed on securing sites with drive-thrus, recognizing their essential role in the current market; the interviewee believes that Burger King is the right brand for the times, offering the value and convenience that customers are seeking, and sees potential for continued growth and profitability
    <ul>
      <li>Actively looking for locations that meet criteria—good demographics, drive-thru capability, and favorable economics</li>
      <li>Drive-thrus are deemed essential; without them, a restaurant is considered obsolete in the current market environment</li>
      <li>Confident in the brand's direction and planning to invest despite challenges, indicating a bullish outlook on future performance</li>
    </ul>
  </li>
  <li>The <strong>competitive landscape post-COVID</strong> offers both risks and opportunities for Burger King franchisees—the interviewee does not expect to steal customers from major competitors like McDonald's or Wendy's but anticipates gaining customers from smaller chains and independent restaurants that are closing; competitors' inconsistencies, such as closures due to COVID cases or staffing issues, drive customers to Burger King, highlighting the importance of being open and consistent; the franchisee believes that maintaining current elevated sales levels is achievable and aims for <strong>3% to 5% annual comp growth</strong> over the next 5 years
    <ul>
      <li><strong>Consistent operations</strong> are crucial; being open when others are closed can shift customer habits in Burger King's favor</li>
      <li>The closure of <strong>smaller competitors</strong> provides an opportunity to capture additional market share; even non-direct competitors' closures can benefit Burger King</li>
      <li>The focus is on maintaining current gains rather than expecting huge jumps; setting realistic goals of <strong>3% to 5% growth</strong> indicates a strategic and sustainable approach</li>
    </ul>
  </li>
  <li><strong>Labor cost implications</strong> of potential minimum wage increases are a significant concern for Burger King franchisees—the proposed federal minimum wage hike to <strong>$15</strong> could impact profitability, especially in areas where the current minimum wage is much lower; while the franchisee already pays above minimum wage to attract and retain employees, further increases could strain margins; smaller businesses may struggle even more with increased labor costs, potentially leading to closures that could affect the competitive landscape; the franchisee adjusts pay rates based on local markets but recognizes the challenges ahead
    <ul>
      <li>In markets like <strong>Indiana</strong>, where minimum wage is $8, a jump to $15 would be substantial; paying above-market wages currently gives a competitive edge in hiring</li>
      <li>Increased labor costs cannot always be passed on to consumers through price increases due to market limitations on how much can be charged for products</li>
      <li>Recognizes that while larger franchises may absorb the costs, small businesses could be disproportionately affected, potentially changing the market dynamics</li>
    </ul>
  </li>
</ul>
          `,
        ],
        image: "/spotlight/bk (5).png",
      },
    ],
  },

  {
    slug: "F45 Training",
    pageData: {
      pic: "/logos/F45 Training.jpg",
      title: "F45 Training",
      initialInvestment: "$300k-$700k",
      franchiseeFee: "$35k",
      royaltyRate: "7.0%",
      description:
        "F45 Fitness is a global fitness franchise known for its innovative approach to functional training, combining elements of high-intensity interval training (HIIT), circuit training, and functional movements in a community-focused environment. The brand has gained popularity for its dynamic, group-based workouts that offer variety and challenge, appealing to a broad demographic, particularly younger, urban professionals. F45's success is driven by its effective programming, strong brand ambassadors, and the unique value proposition of providing personalized coaching within a group setting at an accessible price point.",
    },
    keyTrends: [
      {
        leftDescription:
          "<strong>F45 franchisees consistently highlight</strong> the strong sense of community and high-quality workout programming as pivotal to member retention and satisfaction, with many members appreciating the personalized attention from trainers and the diverse, ever-evolving workout routines, contributing to a loyal customer base and a steady growth trajectory",
        rightDescription:
          "The <strong>rapid expansion of F45 and market saturation</strong>, particularly in urban areas, has led to concerns about cannibalization of memberships and reduced profitability for some studios, with franchisees urging the company to reconsider its growth strategy and improve territory protection to prevent oversaturation",
      },
      {
        leftDescription:
          "Despite challenges, many franchisees express <strong>optimism about F45's growth potential</strong>, especially in regions where the brand's innovative approach and marketing strategies resonate well with local demographics, leading to successful expansions and plans for further studio openings in both domestic and international markets",
        rightDescription:
          "<strong>Corporate support and communication</strong> are frequently mentioned as areas needing improvement, with franchisees noting frustrations over slow response times, billing errors, and inconsistent support, particularly for smaller or single-unit owners, which has affected operational efficiency and satisfaction",
      },
      {
        leftDescription:
          "Franchisees recognize the appeal of <strong>F45's business model and its adaptability</strong> across different markets, with successful studios often managed by owners who are deeply involved in daily operations and community-building, fostering environments where members feel personally connected and supported",
        rightDescription:
          "<strong>Economic fluctuations and competitive pressures</strong> have intensified challenges in member acquisition and retention, particularly in studios located in less affluent or rural areas, where franchisees are struggling to achieve and maintain profitability despite their commitment to the brand and operational resilience",
      },
    ],
    cardData: [
      {
        id: 1,
        title: "Spotlight #1 – F45 Franchisee – December 2022",
        description:
          "Insights into operational dynamics, member trends, corporate relations, and market competition; emphasis on community-building, owner involvement, and the impact of market saturation and employee management",
        points: [
          `
<ul>
    <li>
        The interviewee has significant experience as an F45 franchisee, having opened his <strong>first studio in 2018</strong> and expanded to a <strong>second in 2021</strong>; despite challenges, he provides insights into <strong>operational dynamics, member trends, and corporate relations</strong>
        <ul>
            <li>
                Opened the <strong>first F45 studio in September 2018</strong>, after signing the agreement in February; invested fully by leaving his software career and being present daily—built the business around <strong>personal values and passion for fitness</strong>
            </li>
            <li>
                During the pandemic in <strong>fall of 2020</strong>, decided to invest in a second opportunity; purchased a prebuilt studio in Burlington, launching it in <strong>October 2021</strong>—took advantage of corporate incentives offered to existing owners
            </li>
            <li>
                Faces challenges with the second studio, which is bleeding about <strong>$5,000 to $6,000 cash a month</strong>; contrasts with the first studio, which is profitable and has a strong community
            </li>
            <li>
                Considers selling the second studio to focus on the first; content with one studio due to time constraints and better <strong>return on investment (ROI)</strong>—believes injecting energy into one studio yields more profit than spreading efforts thin
            </li>
        </ul>
    </li>
    <li>
        Member count and breakeven points vary between studios; the first studio maintains around <strong>150 auto-pay members</strong>, while the second struggles with <strong>60 auto-pay members</strong>, impacting profitability
        <ul>
            <li>
                Breakeven is around <strong>$27,000 a month</strong>, equating to about <strong>130 members</strong>; includes paying employees well and additional income from class packs and product sales—extra revenue streams contribute about <strong>20% to income</strong>
            </li>
            <li>
                The first studio had <strong>200 auto-pay members before COVID</strong>, now at <strong>150</strong>; including class pack purchasers, active attendees are around <strong>200</strong>—membership is trending up, adding <strong>15 members monthly with a net gain of 10</strong>
            </li>
            <li>
                The second studio is not performing as well, with <strong>60 auto-pay members</strong> and <strong>25 class pack purchasers</strong>, totaling around <strong>90 active members</strong>; leading to financial losses of <strong>$5,000 to $6,000 per month</strong>
            </li>
            <li>
                Membership trends at the second studio are slowly improving but not at a rate to reach breakeven soon; attributes challenges to lack of community and owner presence—acknowledges the importance of <strong>boots-on-the-ground marketing</strong>
            </li>
        </ul>
    </li>
    <li>
        The success of an F45 studio heavily relies on <strong>building a strong community and owner involvement</strong>; the first studio thrived due to the owner's daily presence and community-building efforts
        <ul>
            <li>
                The first studio was built around the owners' <strong>values and passion for fitness</strong>; their presence helped shape the community and retain members—created a culture aligned with their <strong>values</strong>
            </li>
            <li>
                The second studio was intended to be managed remotely with studio managers; struggled to replicate the same level of <strong>commitment, trust, and enthusiasm</strong>—employee behaviors and market differences affected performance
            </li>
            <li>
                Believes that <strong>boots-on-the-ground marketing and referrals</strong> are key to growth; the lack of owner presence impacted the second studio's performance—emphasizes that starting with <strong>100 founding members</strong> is critical
            </li>
            <li>
                Regrets not waiting to achieve <strong>100 founding memberships</strong> before opening the second studio; recognizes the importance of name recognition and proper market entry strategies
            </li>
            <li>
                F45 corporate provides innovative workouts and invests in the brand but lacks in support and responsiveness; the interviewee rates corporate support as <strong>7 out of 10</strong>
            </li>
            <li>
                Appreciates F45's <strong>continuous reinvestment into the brand</strong>—bringing new workouts, new equipment, and strategic partnerships like Loyalsnap CRM; helps prevent member boredom and enhances operations
            </li>
            <li>
                Criticizes the lack of responsive support; onboarding is self-managed, and getting assistance with challenges like technology issues is slow—suggests having <strong>customer success managers</strong> for better support
            </li>
            <li>
                Notes <strong>favoritism</strong> in supporting markets—more attention given to big markets with influencers; smaller markets receive less support—mentions influencers like <strong>Mark Wahlberg and David Beckham</strong> enhancing brand appeal
            </li>
            <li>
                Experiences <strong>sloppy accounting practices</strong>; instances of charging the wrong studio and unclear communication about fees—franchisees need to closely monitor their books due to these issues
            </li>
        </ul>
    </li>
    <li>
        Accounting practices at F45 corporate have room for improvement; instances of sloppy accounting and lack of clear communication cause frustration among franchisees
        <ul>
            <li>
                Reports errors such as charging the wrong studio for fees; communication about accounting charges is sometimes unclear—franchisees must be vigilant with their financial records
            </li>
            <li>
                While there is some improvement, issues persist; <strong>inconsistent billing practices</strong> add to operational burdens—franchisees hear similar stories from peers
            </li>
            <li>
                Suggests that corporate could improve transparency and accuracy in financial dealings; better accounting would enhance <strong>trust and efficiency</strong>
            </li>
        </ul>
    </li>
    <li>
        F45's workout product stands out due to its focus on <strong>coaches, community, and effective training</strong>; members value the personal attention and variety in workouts
        <ul>
            <li>
                Members cite the <strong>coaches, workouts, and community</strong> as top reasons for choosing F45 over competitors like Orangetheory and CrossFit—emphasizes the importance of <strong>two coaches per class</strong> for personal attention
            </li>
            <li>
                Workouts are diverse, including <strong>strength training and hybrid workouts</strong>; F45 emphasizes <strong>transformation over competition</strong>—contrasts with Orangetheory's focus on entertainment and repetitive equipment
            </li>
            <li>
                The brand maintains a <strong>cool factor</strong> and is considered a <strong>sexy brand</strong>; uses influencers like <strong>Mark Wahlberg and David Beckham</strong> to enhance appeal—marketing strategies have improved over the years
            </li>
            <li>
                Believes F45 offers <strong>unrivaled workouts</strong>; members stay longer and are more engaged—some have completed over <strong>1,200 classes</strong>
            </li>
        </ul>
    </li>
    <li>
        Competition and market saturation are concerns, with F45 sometimes opening studios too close together; this can confuse members and impact existing studios' success
        <ul>
            <li>
                Observes that there are <strong>three studios within a 10-minute drive</strong>; members feel compelled to try other locations, leading to confusion—territories may be too small or overlapping
            </li>
            <li>
                Believes F45 corporate may prioritize <strong>selling franchises</strong> over existing franchisees' success; opening new studios nearby before existing ones reach <strong>300 members</strong> can create problems
            </li>
            <li>
                Expresses concern that corporate actions may be driven by <strong>public market pressures</strong> rather than franchisee well-being—feels that sometimes corporate acts for Wall Street, not the franchisee
            </li>
            <li>
                Despite not currently affecting his successful studio, acknowledges that future nearby openings could create challenges—even strong communities could be impacted
            </li>
        </ul>
    </li>
    <li>
        Employee management and retention are challenging, particularly with personal trainers; managing staff adds stress and <strong>complexity</strong> to operating multiple studios
        <ul>
            <li>
                Has developed a <strong>bad taste</strong> for managing classic personal trainers; finding committed and reliable staff is difficult—employee behaviors can impact the community and success
            </li>
            <li>
                The success of a studio depends on having <strong>great trainers</strong>; his first studio wins awards for trainers, contributing to its success—believes in the importance of a passionate team
            </li>
            <li>
                Scaling the business requires <strong>good hiring and resourcing</strong>; without strong managers, expanding to multiple studios is less appealing—prefers to focus on one studio for better ROI
            </li>
        </ul>
    </li>
    <li>
        Despite operational challenges, the interviewee believes F45 has <strong>significant runway and is a strong investment</strong>; he is personally invested in the company's stock
        <ul>
            <li>
                Considers F45 to have <strong>unrivaled workouts</strong> and a strong brand; confident in the company's growth prospects—believes it's a <strong>very good stock</strong>
            </li>
            <li>
                Notes that more people are becoming interested in fitness, providing opportunities for growth—however, the number of fitness options may be growing faster than the number of participants
            </li>
            <li>
                Acknowledges that the overall market has many options, but F45's <strong>product quality and passionate owners</strong> give it an advantage—believes the company has a promising future despite market downturns
            </li>
            <li>
                Emphasizes that <strong>passionate owners</strong> who are unwilling to be beaten in their markets contribute to F45's success
            </li>
        </ul>
    </li>
    <li>
        Member acquisition primarily comes from <strong>big box gyms</strong>; new members often switch from traditional gyms to F45 due to the value proposition
        <ul>
            <li>
                Prospective members may find <strong>$200 a month</strong> expensive initially but realize the value is comparable to personal training—F45 offers group training with personal attention
            </li>
            <li>
                Members typically <strong>liquidate their big box gym memberships</strong> when joining F45—transition from traditional gyms to boutique fitness
            </li>
            <li>
                The challenge lies in educating potential members about the benefits of F45 over traditional gyms—highlighting <strong>transformation and community</strong>
            </li>
            <li>
                Notes that members often have tried various fitness options before settling on F45; retention is strong due to the quality of the experience
            </li>
        </ul>
    </li>
    <li>
        Market saturation in boutique fitness is a concern; while there is growth in fitness interest, the number of fitness businesses is growing faster than the participant base
        <ul>
            <li>
                Believes we're in the <strong>second inning of market saturation</strong>; many fitness firms are being created, but the number of interested participants isn't growing as rapidly
            </li>
            <li>
                Notes that people may opt for alternatives like <strong>barre or spin classes</strong>, thinking they offer similar benefits—education on the unique benefits of F45 is essential
            </li>
            <li>
                The abundance of options leads to participants moving from one fitness concept to another—emphasizes the need for F45 to stand out with the <strong>best team and product</strong>
            </li>
            <li>
                Recognizes that while more people are getting interested in fitness, the competition is fierce; success depends on offering <strong>unparalleled value</strong>
            </li>
        </ul>
    </li>
</ul>
          `,
        ],
        image: "/spotlight/f45_1.png",
      },
      {
        id: 2,
        title: "Spotlight #2 – F45 Franchisee – November 2022",
        description:
          "Overview of studio performance, membership trends, and operational challenges; emphasis on sustainable growth, owner involvement, and F45's brand potential despite market competition and expansion delays",
        points: [
          `
<ul>
    <li>
        The owner operates two F45 studios in Tennessee, with a third opening soon; despite opening just before COVID-19, they have managed to achieve breakeven in one studio and are close in the other, requiring between <strong>150 and 175 members</strong> for profitability
        <ul>
            <li>
                Opened the <strong>first F45 studio in March 2020</strong>, just days before the COVID-19 pandemic hit; located in Tennessee
            </li>
            <li>
                Currently operates two studios, with a <strong>third under construction</strong>, expected to open in about a month
            </li>
            <li>
                Requires <strong>150 to 175 members to breakeven</strong>; one studio has reached this number, while the other is slightly below but trending positively
            </li>
            <li>
                Operational costs, including <strong>consistent payroll for coaches</strong>, influence the breakeven point; economies of scale from multiple studios help offset costs
            </li>
            <li>
                Despite <strong>pandemic challenges</strong>, has not had to invest additional money since opening; considers performance satisfactory given global circumstances
            </li>
        </ul>
    </li>
    <li>
        Membership trends in 2022 show growth, with both studios increasing members monthly, though at different rates; competition and location factors influence membership dynamics
        <ul>
            <li>
                Membership has been <strong>increasing every month</strong> in 2022, though one studio is growing faster than the other
            </li>
            <li>
                The studio with slower growth faces <strong>more competition</strong> and is in an area with higher income levels but also higher gym saturation
            </li>
            <li>
                Summertime sees a <strong>temporary drop in membership</strong> as clients switch to gyms like Life Time Fitness for amenities like pools and childcare
            </li>
            <li>
                The area is suburban with an <strong>upper-middle-class demographic</strong>, influencing client behavior and competition dynamics
            </li>
            <li>
                Members at the slower-growing studio often have <strong>multiple gym memberships</strong>, attending F45 three to four times a week
            </li>
        </ul>
    </li>
    <li>
        <strong>Churn rates</strong> are low, with an average monthly loss of <strong>4-7 members</strong>, primarily due to moving or cost concerns among younger members; overall net membership is increasing
        <ul>
            <li>
                Churn is less than <strong>5% per month</strong>, significantly lower than typical boutique gyms which range from 5% to 10%
            </li>
            <li>
                Common reasons for leaving include <strong>relocation, cost concerns</strong>, and occasionally injuries, though injury rates are low due to F45's training style
            </li>
            <li>
                Younger members (ages <strong>20-25</strong>) are more likely to leave due to <strong>financial constraints</strong>, especially as they reassess budgets
            </li>
            <li>
                The gyms consistently gain <strong>more members than they lose</strong>, with net adds of about <strong>five to ten members per month</strong>
            </li>
            <li>
                The owner focuses on ensuring departures are not due to gym shortcomings, emphasizing <strong>retention strategies</strong>
            </li>
        </ul>
    </li>
    <li>
        F45's full-body, varied workouts contribute to lower churn and member satisfaction; the owner emphasizes the <strong>sustainability</strong> and diversity of training as key differentiators
        <ul>
            <li>
                F45 offers both <strong>cardio and resistance training</strong>, unlike competitors like Orangetheory, which are more cardio-focused
            </li>
            <li>
                Workouts are <strong>never the same</strong>, providing variety and preventing workout fatigue; programs cycle every <strong>four weeks</strong>
            </li>
            <li>
                The ability to accommodate a wide range of ages and fitness levels contributes to <strong>member retention and appeal</strong>
            </li>
            <li>
                Members appreciate the <strong>sustainability</strong> of the workouts, allowing consistent training without excessive strain or injury
            </li>
            <li>
                The owner's personal research and passion for fitness led them to choose F45 over other concepts due to these factors
            </li>
        </ul>
    </li>
    <li>
        The owner holds a positive view of F45 corporate, noting that while they are hands-off post-opening, they have been supportive and issues have been minimal
        <ul>
            <li>
                F45 corporate provides initial guidance on <strong>gym setup, training, and compliance</strong>, but allows franchisees autonomy afterward
            </li>
            <li>
                The owner has not experienced significant operational issues, such as <strong>billing errors</strong> or communication lapses reported by others
            </li>
            <li>
                Minor technical issues with <strong>IT systems and workout displays</strong> occur but are not detrimental to operations
            </li>
            <li>
                Appreciates the <strong>freedom to manage the gyms</strong> while adhering to F45 standards; no corporate interference in day-to-day activities
            </li>
            <li>
                Rates F45 corporate positively, with no major complaints affecting their franchise experience
            </li>
        </ul>
    </li>
    <li>
        Competition is primarily with other boutique gyms rather than other F45 studios; the owner emphasizes the importance of <strong>staff quality and gym cleanliness</strong> in standing out
        <ul>
            <li>
                The gyms compete with <strong>other boutique fitness concepts</strong>, not other F45 locations due to territorial arrangements
            </li>
            <li>
                Emphasizes that <strong>staffing is crucial</strong>; coaches should embody F45 values and deliver excellent member experiences
            </li>
            <li>
                Maintains high <strong>gym cleanliness standards</strong>, which is highly valued by members and contributes to positive feedback
            </li>
            <li>
                The hiring process is stringent, including a <strong>two-week trial</strong> where prospective coaches participate in workouts
            </li>
            <li>
                Believes that owner involvement directly impacts gym quality and success; personal engagement is a priority
            </li>
        </ul>
    </li>
    <li>
        There is some inconsistency among F45 studios due to varying levels of owner involvement, but the owner does not see this as significantly diluting the brand
        <ul>
            <li>
                Acknowledges that some F45 gyms differ in <strong>quality, cleanliness, and staff engagement</strong> based on owner involvement
            </li>
            <li>
                Has visited other F45 locations and observed disparities but considers them minor in the grand scheme; does not believe these inconsistencies pose a major threat to the brand's reputation or success
            </li>
            <li>
                Suggests that while <strong>uniformity would be ideal</strong>, the overall brand strength and member experiences remain positive
            </li>
            <li>
                Sees F45's growth and brand recognition as <strong>outweighing the impact</strong> of individual studio variances
            </li>
        </ul>
    </li>
    <li>
        The owner is highly optimistic about F45's future growth, rating their confidence as <strong>9 to 10 out of 10</strong>; plans for further expansion are likely among their investor group
        <ul>
            <li>
                Notes that F45 is <strong>gaining visibility</strong> and expanding into more cities, with increased brand recognition similar to Orangetheory
            </li>
            <li>
                Highlights F45's strategy of engaging <strong>high-profile brand ambassadors</strong> like David Beckham and Gunnar Peterson to enhance appeal
            </li>
            <li>
                Their investor group may open more gyms in the future, though the owner personally plans to wait to maintain close involvement
            </li>
            <li>
                The owner values being able to personally oversee the gyms and prefers not to expand beyond a manageable number
            </li>
        </ul>
    </li>
    <li>
        Construction delays for the third gym were primarily due to building construction outside their control; once access is granted, gym setup proceeds quickly
        <ul>
            <li>
                The third gym is in a <strong>brand-new building</strong> under an apartment complex, leading to dependency on the construction company's timeline
            </li>
            <li>
                Expected to open a few months ago but delayed by approximately <strong>four months</strong> due to construction setbacks
            </li>
            <li>
                Once access to the space was granted, their own construction and setup take about <strong>four to eight weeks</strong>
            </li>
            <li>
                Previous gyms were in existing structures, allowing faster turnaround; this is their first experience with new construction delays
            </li>
            <li>
                The owner acknowledges the delays are typical and outside of F45's control, focusing on moving forward with preparations
            </li>
        </ul>
    </li>
    <li>
        <strong>Equipment longevity</strong> is satisfactory, with most items lasting between <strong>five to ten years</strong>; only minor items like mats and slam balls require more frequent replacement
        <ul>
            <li>
                Major equipment such as <strong>benches, bikes, ski ergs, and rowers</strong> have exceeded longevity expectations
            </li>
            <li>
                Items like <strong>yoga mats and slam balls</strong> have shorter lifespans, typically needing replacement every <strong>one to two years</strong>
            </li>
            <li>
                Slam balls, filled with sand, tend to wear out after <strong>12-16 months</strong> regardless of the supplier; replacements for these items are relatively inexpensive and do not significantly impact operational costs
            </li>
            <li>
                The owner sometimes sources replacements from vendors other than F45 for faster turnaround times
            </li>
        </ul>
    </li>
    <li>
        Membership growth in 2022 is positive, with net additions of <strong>four to six members</strong> per month per gym; the gyms focus on quality over rapid expansion
        <ul>
            <li>
                Both gyms are <strong>adding five to ten new members per month</strong>, with net growth accounting for member losses
            </li>
            <li>
                One gym is close to net neutral due to similar numbers of additions and departures, while the other experiences consistent net gains
            </li>
            <li>
                The owner emphasizes the importance of <strong>sustainable growth</strong> and maintaining <strong>high-quality member experiences</strong>
            </li>
            <li>
                Marketing efforts and <strong>brand visibility</strong> contribute to steady membership increases
            </li>
            <li>
                The owner remains focused on delivering excellent service rather than aggressively pursuing rapid expansion
            </li>
        </ul>
    </li>
    <li>
        Overall, the owner is satisfied with the <strong>return on investment</strong> and performance of the F45 studios, especially considering the challenges posed by the pandemic
        <ul>
            <li>
                Despite opening the first gym just before COVID-19 lockdowns, the studios have performed well without needing additional capital injections
            </li>
            <li>
                Recognizes that while they are not achieving exponential growth, the gyms are <strong>financially stable</strong> and progressing positively
            </li>
            <li>
                Attributes success to a combination of factors, including <strong>effective staff</strong>, gym management, and the appeal of F45's workout model
            </li>
            <li>
                The owner's approach balances personal passion with practical investment, aiming for <strong>long-term sustainability</strong> over quick profits
            </li>
            <li>
                Remains confident in F45's potential and plans to continue supporting the brand through their current and future gyms
            </li>
        </ul>
    </li>
</ul>
          `,
        ],
        image: "/spotlight/f45_2.png",
      },
      {
        id: 3,
        title: "Spotlight #3 – F45 Franchisee – September 2022",
        description:
          "Focus on managing operational difficulties and fluctuating membership amid natural disasters and pandemic impacts; highlights the role of community engagement and strategic branding in sustaining F45's growth potential despite regional market saturation",
        points: [
          `
<ul>
    <li>
        The interviewee is an experienced F45 franchisee who opened their <strong>first studio in late 2015</strong> in the Northern Beaches area of Sydney, Australia, and a <strong>second studio in early 2019</strong> in a coastal regional town in mid-New South Wales; they provide insights into operational challenges, membership trends, and perspectives on F45 corporate support
        <ul>
            <li>
                The <strong>first F45 studio opened in late 2015</strong> in the Northern Beaches area of Sydney, a suburban area about 20 to 30 minutes from the city center; the decision to open was made quickly after discovering F45 through a colleague and conducting due diligence on the brand
            </li>
            <li>
                The <strong>second studio was opened in early 2019</strong> in a coastal regional town in mid-New South Wales, right on the beach; they <strong>took over this studio from another set of owners</strong>, noting that in hindsight, the studio space was larger than necessary
            </li>
            <li>
                Both studios required around <strong>110 active paid memberships</strong> to break even, considering higher staffing costs in Sydney and rent being the biggest expense; active paid memberships refer to members on three to six-month commitments, not casual or 10-pack buyers
            </li>
            <li>
                Prior to COVID-19, both studios were performing well with healthy membership growth; the first studio was adding <strong>6 to 10 new members per month</strong>, while the second studio was adding <strong>10 to 15 new members per month</strong>, even several years after opening
            </li>
        </ul>
    </li>
    <li>
        The <strong>COVID-19 pandemic</strong> and severe floods in New South Wales have significantly impacted membership numbers and growth trends, leading to financial challenges and uncertainty
        <ul>
            <li>
                Membership is <strong>significantly worse now</strong> compared to pre-COVID levels; the anticipated rush of returning members after lockdowns lifted in January 2022 did not materialize
            </li>
            <li>
                Contributing factors include the <strong>worst floods Australia has seen</strong>, affecting both studio locations, and a <strong>huge outbreak of the Omicron variant</strong> from January to March 2022, causing hesitancy among members to return to in-person training
            </li>
            <li>
                Over the past three months, membership growth has <strong>flattened out</strong>, with concerns heightened due to the inability to achieve a consistent growth of five additional members per month
            </li>
            <li>
                The combination of natural disasters and pandemic-related fears has led to a <strong>significant decline in active memberships</strong>, affecting the financial stability of both studios
            </li>
        </ul>
    </li>
    <li>
        Churn rates prior to the pandemic were manageable, with steady growth due to high conversion rates from leads and effective retention strategies
        <ul>
            <li>
                In 2019, the second studio received about <strong>70 organic leads per month</strong>, converting approximately <strong>40% into memberships</strong>; they would lose about <strong>10 members per month</strong>, resulting in a net growth of <strong>10 to 15 members per month</strong>
            </li>
            <li>
                The first studio experienced similar trends, with a net gain of <strong>6 to 10 new members per month</strong>; the studios did not face stagnation or decline even after several years of operation
            </li>
            <li>
                The growth was attributed to <strong>consistent marketing efforts, strong community engagement,</strong> and the appeal of F45's varied and challenging workouts
            </li>
            <li>
                Seasonal fluctuations were expected, with a lull in late November and December, but typically offset by a significant influx of new members in January due to New Year's resolutions
            </li>
        </ul>
    </li>
    <li>
        The interviewee holds a generally positive view of F45 corporate, citing strong branding, excellent programming, and effective marketing efforts, but suggests improvements in centralized marketing support and local promotions
        <ul>
            <li>
                Rates F45 corporate as a <strong>7 out of 10</strong>, acknowledging the company's success in building a well-known brand with strong brand ambassadors and innovative programming
            </li>
            <li>
                Praises the <strong>consistency and quality of F45's workout programming</strong>, which eliminates the need for coaches to develop their own programs and ensures member confidence in safe and effective workouts
            </li>
            <li>
                Recommends that F45 could improve by <strong>taking over more of the marketing efforts</strong>, specifically by having dedicated digital marketing teams for different regions to prevent studios from cannibalizing each other's advertising efforts
            </li>
            <li>
                Suggests increased support in <strong>local promotions</strong> and combining digital marketing with on-the-ground activities to boost member acquisition and engagement
            </li>
        </ul>
    </li>
    <li>
        Saturation of F45 studios in city areas like Sydney is at a maximum, with no room for additional studios; however, regional areas have better territorial spacing, though adding more studios could negatively impact existing ones
        <ul>
            <li>
                Describes the city areas as <strong>absolutely saturated</strong> with F45 studios, to the extent that F45 corporate likely won't allow more territories to be sold in those regions
            </li>
            <li>
                In the <strong>Northern Beaches</strong>, there are about <strong>10 F45 studios</strong>, with some only a mile apart, leading to intense local competition
            </li>
            <li>
                In regional towns like the coastal area where the second studio is located, they are the only F45 studio serving a population of around <strong>70,000</strong>; adding more studios in such areas could hurt existing studios due to market limitations
            </li>
            <li>
                Believes that the number of studios is not the main issue currently impacting business; rather, it's the external factors like COVID-19 and natural disasters
            </li>
        </ul>
    </li>
    <li>
        The current challenges have affected franchisee satisfaction and return on investment, with many owners feeling uncertain about the future; however, prior to COVID-19, franchisees were generally happy with good ROI
        <ul>
            <li>
                Before the pandemic, the interviewee and other franchisees were pleased with their investment, experiencing healthy returns and profitable operations
            </li>
            <li>
                Currently, many franchisees are <strong>feeling flat and fearful</strong>, as the expected recovery and membership growth have not occurred; return on investment is not good now, and there's widespread concern about financial sustainability
            </li>
            <li>
                The interviewee personally <strong>does not plan to open more F45 studios</strong> at this time due to the challenging environment but is open to reevaluating in a year's time depending on circumstances
            </li>
            <li>
                Emphasizes that the main problems are <strong>COVID-19 and the flooding</strong>, not the saturation of studios or corporate management issues
            </li>
        </ul>
    </li>
    <li>
        Members choose F45 over other boutique fitness concepts due to the strong sense of community, challenging workouts, and visible results; the programming appeals to a broad demographic, with about <strong>70% female membership</strong>
        <ul>
            <li>
                Members frequently cite the <strong>community aspect</strong>, where they become friends with coaches and fellow members, creating a supportive and motivating environment
            </li>
            <li>
                The workouts are described as <strong>hard and challenging</strong>, leading to significant results when members train more than three times a week
            </li>
            <li>
                Compared to competitors like <strong>CrossFit</strong>, which is perceived as more aggressive and hardcore, F45 offers a more inclusive environment suitable for various fitness levels
            </li>
            <li>
                The variety in programming keeps members engaged, contrasting with concepts like <strong>SoulCycle</strong>, which may offer less variety
            </li>
        </ul>
    </li>
    <li>
        The interviewee believes that F45 has strong potential for success in the U.S. market, bringing something new and appealing to American fitness enthusiasts; similarities between Australian and American fitness cultures support this view
        <ul>
            <li>
                Observes that F45 is expanding in the U.S., particularly in states like <strong>Texas and California</strong>, and believes it brings a fresh option to the market
            </li>
            <li>
                Personal experience training in <strong>California F45 studios</strong> showed that studios were doing well, with profitable businesses and satisfied members
            </li>
            <li>
                Notes that <strong>Americans, like Australians, love fitness and intense training</strong>, suggesting that F45's model will be well-received
            </li>
            <li>
                Acknowledges that F45 will face challenges competing with established brands like <strong>Orangetheory</strong> in the U.S. but remains confident in F45's ability to succeed due to its innovative programming and strong brand ambassadors
            </li>
        </ul>
    </li>
    <li>
        F45's <strong>continuous innovation</strong> in programming and strong marketing efforts, including high-profile brand ambassadors like <strong>David Beckham</strong>, contribute to its competitive advantage and appeal
        <ul>
            <li>
                The company consistently <strong>updates and innovates</strong> its workout programming, providing variety and maintaining member interest
            </li>
            <li>
                High-profile brand ambassadors, such as <strong>David Beckham for the U.K. market</strong>, enhance the brand's visibility and attractiveness
            </li>
            <li>
                In Australia, F45 has signed <strong>well-known athletes</strong> like rugby players and Olympic swimmers as brand ambassadors, indicating a strategic focus on leveraging sports figures
            </li>
            <li>
                The interviewee is unaware of specific U.S. brand ambassadors but suggests that F45 is likely working on similar partnerships to strengthen its position in the American market
            </li>
        </ul>
    </li>
    <li>
        Competitors like <strong>Body Fit Training (BFT)</strong> are emerging but are perceived as offering a <strong>cheaper and less quality product</strong>; the interviewee suggests due diligence in evaluating competition, especially regarding pricing strategies
        <ul>
            <li>
                BFT is mentioned as a competitor that is <strong>trying to copy F45</strong>, but feedback from other F45 owners suggests it is <strong>cheaper and less effective</strong>
            </li>
            <li>
                BFT is said to offer <strong>lower membership prices</strong>, which could attract price-sensitive customers but may not provide the same level of programming or community
            </li>
            <li>
                The interviewee advises conducting thorough due diligence when assessing competition, including <strong>signing up anonymously</strong> to understand their pricing and offerings
            </li>
            <li>
                Emphasizes the importance of understanding the <strong>revenue opportunities</strong> and market positioning when considering the impact of competitors like BFT
            </li>
        </ul>
    </li>
    <li>
        Overall, the interviewee remains optimistic about F45's concept and potential, highlighting the <strong>quality of the workouts</strong> and the strong community as key factors for success, despite current challenges
        <ul>
            <li>
                Personal passion for F45's training method and programming remains strong; the interviewee enjoys the workouts and believes in their effectiveness
            </li>
            <li>
                Acknowledges that while current conditions are challenging, the <strong>fundamentals of F45's business model are solid</strong>, offering a unique value proposition in the fitness market
            </li>
            <li>
                Encourages potential investors or interested parties to <strong>experience F45 classes firsthand</strong> to understand the appeal and community atmosphere
            </li>
            <li>
                Believes that with time and the easing of external pressures like the pandemic and natural disasters, F45 studios can regain momentum and continue to grow
            </li>
        </ul>
    </li>
</ul>
          `,
        ],
        image: "/spotlight/f45_3.png",
      },
      {
        id: 4,
        title: "Spotlight #4 – F45 Franchisee – August 2022",
        description:
          "Insights into the importance of leadership and community in driving membership growth; discusses the challenges of opening multiple studios, F45’s strong brand appeal, and the mixed experiences with corporate support and territory management",
        points: [
          `
<ul>
    <li>
        The interviewee is an experienced fitness industry professional with <strong>over 26 years of experience</strong>, and his partner has <strong>30 years</strong>; they transitioned from their own successful <strong>personal training company</strong> to open F45 studios, starting the first one in <strong>September 2018</strong>
        <ul>
            <li>
                Opened the first F45 studio in <strong>September 2018</strong>; have been in the fitness industry for decades, previously owning the second-largest personal training company in the country
            </li>
            <li>
                Initially opened <strong>four F45 studios</strong>: two in St. Louis, one in Indiana, and one in Nashville, Tennessee
            </li>
            <li>
                Sold the Indiana studio in <strong>July 2021</strong> due to logistical challenges of managing studios across three different states; the Indiana studio was performing well and won awards for three consecutive years
            </li>
            <li>
                The decision to focus on studios closer to their base was driven by the desire to be <strong>hands-on</strong> and manage the studios effectively
            </li>
        </ul>
    </li>
    <li>
        Membership levels vary among the studios, with one performing exceptionally well, and the others doing fine; all studios have surpassed pre-COVID membership levels
        <ul>
            <li>
                Breakeven point is around <strong>150 members</strong>; at <strong>300 members</strong>, a studio can make about <strong>$15,000 to $20,000 per month</strong>
            </li>
            <li>
                One studio has <strong>almost 400 members</strong> and performs exceptionally well; the other two are slightly above breakeven
            </li>
            <li>
                All studios are above pre-COVID membership levels, indicating strong recovery and growth
            </li>
            <li>
                The Tennessee studio faced a setback when a new F45 studio opened <strong>3 miles away</strong>, and their head trainer left to become a general manager at the new location, taking about <strong>50 members</strong> with him
            </li>
            <li>
                The difference in performance among studios is attributed mainly to the <strong>people and management staff</strong>; effective leadership and staff retention are crucial
            </li>
        </ul>
    </li>
    <li>
        Membership trends in 2022 show a steady increase, with a goal of netting <strong>16 new members per month</strong>; retention rates are excellent, and referrals play a significant role in growth
        <ul>
            <li>
                Membership is <strong>steadily increasing</strong> in 2022, with a consistent net gain of <strong>16 members per month</strong>
            </li>
            <li>
                Retention is <strong>excellent</strong>, with only about <strong>five cancellations per month</strong>; any higher number raises concern
            </li>
            <li>
                Growth is driven significantly by <strong>referrals</strong>, accounting for <strong>80%</strong> of new members; the remaining <strong>20%</strong> come from advertisements
            </li>
            <li>
                The growth trend has been consistent throughout 2022, without significant fluctuations in any particular month
            </li>
            <li>
                The increase in membership leads to a <strong>snowball effect</strong>, as more members generate more referrals
            </li>
        </ul>
    </li>
    <li>
        F45 corporate support has been a mixed experience; while the workout product is excellent, opening new studios presents challenges due to <strong>communication and support issues</strong>
        <ul>
            <li>
                Rates F45 corporate as a <strong>7.5 out of 10</strong>; praises the workout product but cites support issues as a drawback
            </li>
            <li>
                <strong>Opening new studios</strong> is arduous, with a lack of direct communication channels; franchisees must submit inquiries via email and wait for responses, leading to delays
            </li>
            <li>
                Describes the process of opening a new studio as frustrating, with simple questions taking days to resolve due to the <strong>ticketing system</strong>
            </li>
            <li>
                During COVID-19, F45 corporate was supportive, suspending franchise fees without being contractually obligated to do so
            </li>
            <li>
                Has experienced <strong>billing errors</strong>, such as being charged for another studio's fees; although efforts were made to rectify the issues, they recurred over two years
            </li>
            <li>
                Acknowledges that F45 has hired more staff and <strong>improved communication</strong> but feels execution on resolving issues is still lacking
            </li>
        </ul>
    </li>
    <li>
        F45's location selection process has room for improvement; while not inherently bad, the process can lead to studios opening close to one another, affecting individual studio performance
        <ul>
            <li>
                Notes that F45's territory model may be based on the <strong>denser urban layouts</strong> of Australian cities, which doesn't always translate well to suburban areas in the U.S.
            </li>
            <li>
                Experienced a situation where a new F45 studio opened <strong>three miles</strong> from their Tennessee location, impacting their membership due to staff and member migration
            </li>
            <li>
                Mentions that F45 is now implementing more sophisticated tools for <strong>territory analysis</strong>, similar to those used by competitors like Xponential Fitness
            </li>
            <li>
                In some cases, F45 has been willing to <strong>redraw territories</strong> to better position studios and prevent overlap
            </li>
            <li>
                Believes that with improved territory management, studios can <strong>coexist in the same city</strong> without significantly hurting each other's performance
            </li>
        </ul>
    </li>
    <li>
        The workout experience and community are the primary reasons members choose F45 over other boutique fitness options; the programming is unparalleled, and the environment fosters a strong sense of community
        <ul>
            <li>
                Describes F45's workout as the <strong>best functional workout available</strong>, surpassing competitors like Orangetheory
            </li>
            <li>
                Highlights the <strong>variety and adaptability</strong> of workouts, which can cater to a wide range of ages and fitness levels
            </li>
            <li>
                The programming is <strong>centralized</strong>, removing the burden from trainers and ensuring consistency and quality
            </li>
            <li>
                F45 provides a <strong>personal training experience</strong> in a group setting, offering value at half the price of traditional one-on-one personal training
            </li>
            <li>
                The <strong>community aspect</strong> is a significant draw, with members forming strong connections with each other and the staff
            </li>
            <li>
                Emphasizes that F45 is <strong>suitable for all ages</strong>, with members ranging from their 20s to as old as 79 years
            </li>
        </ul>
    </li>
    <li>
        The interviewee does not view F45 as a fad but as a <strong>sustainable fitness model</strong> with significant growth potential; believes functional fitness is enduring and adaptable
        <ul>
            <li>
                Argues that unlike trends centered around specific equipment (e.g., treadmills for Orangetheory, bikes for SoulCycle), F45's functional fitness approach is <strong>versatile and enduring</strong>
            </li>
            <li>
                Points out that while CrossFit became too extreme and less accessible to the average person, F45 remains <strong>inclusive and adaptable</strong>
            </li>
            <li>
                Sees F45's workouts as sustainable and capable of evolving over time to meet <strong>changing fitness needs</strong>
            </li>
            <li>
                Believes that with proper marketing to showcase the <strong>accessibility of workouts</strong>, F45 can continue to grow and attract a broader demographic
            </li>
            <li>
                Notes that F45's growth in the U.S. has the <strong>potential to mirror its success in Australia</strong>, provided it addresses marketing and operational challenges
            </li>
        </ul>
    </li>
    <li>
        Marketing strategies need adjustment to attract a broader audience; current marketing may portray workouts as too intense, potentially deterring some prospective members
        <ul>
            <li>
                Observes that F45's marketing often showcases <strong>intense workouts</strong> that might intimidate average individuals
            </li>
            <li>
                Suggests focusing on <strong>accessible exercises</strong> in social media and advertising to appeal to a wider audience
            </li>
            <li>
                Emphasizes the importance of <strong>demonstrating how trainers can help members progress</strong> and feel comfortable regardless of their fitness level
            </li>
            <li>
                Believes that <strong>changing the marketing approach</strong> can help attract more members in their 40s, 50s, and beyond
            </li>
        </ul>
    </li>
    <li>
        Plans to expand through <strong>acquisitions</strong> rather than opening new studios due to the challenging opening process; sees opportunities to improve underperforming studios with proper management
        <ul>
            <li>
                Prefers acquiring existing studios over opening new ones to avoid the arduous setup process
            </li>
            <li>
                Identifies strategic opportunities to purchase studios at lower costs than opening new ones
            </li>
            <li>
                Aims to implement their successful processes and management strategies to turn around underperforming studios
            </li>
            <li>
                Believes that <strong>10-15% of franchisees</strong> may not be suited for the business, presenting acquisition opportunities
            </li>
            <li>
                Sees potential in creating a <strong>multi-studio access model</strong> for members, enhancing value and convenience
            </li>
        </ul>
    </li>
    <li>
        Acknowledges that scaling multiple studios presents challenges; while individual studios can be highly profitable, expanding requires additional resources and can impact ROI
        <ul>
            <li>
                Notes that a single, well-managed studio can generate significant income, especially if the owner takes on roles like general manager
            </li>
            <li>
                Scaling to multiple studios necessitates hiring additional staff and management positions, which can reduce overall ROI
            </li>
            <li>
                Draws from experience with their previous company, which had <strong>25 locations</strong>, understanding the infrastructure required for large-scale operations
            </li>
            <li>
                Emphasizes the importance of <strong>maintaining quality and consistency</strong> across studios when expanding
            </li>
        </ul>
    </li>
    <li>
        F45 is enhancing its brand through <strong>high-profile influencers</strong> and innovative programs, contributing to its competitive edge and appeal
        <ul>
            <li>
                Mentions that F45 is leveraging corporate influencers like <strong>Mark Wahlberg and David Beckham</strong> to enhance brand visibility
            </li>
            <li>
                Highlights the new <strong>military program</strong>, which supports active-duty personnel transitioning to civilian life through internships and potential studio ownership
            </li>
            <li>
                The <strong>SkillBridge program</strong> allows active-duty military to intern at studios while being paid by the government, providing valuable experience without financial burden on the franchise
            </li>
            <li>
                Believes that these initiatives not only strengthen the brand but also <strong>attract new members and potential franchisees</strong>; Observes that increased marketing efforts and influencer partnerships are effectively drawing interest and participation
            </li>
        </ul>
    </li>
    <li>
        Overall, the interviewee is optimistic about F45's growth potential and believes that with improved support and strategic marketing, the brand can continue to expand successfully
        <ul>
            <li>
                Sees a <strong>ton of growth potential</strong> in F45, with opportunities to expand both domestically and internationally
            </li>
            <li>
                Supports the company's efforts to better vet potential franchisees, ensuring that new owners are suited for the business
            </li>
            <li>
                Encourages a focus on <strong>quality over quantity</strong> in franchise expansion to maintain brand integrity and performance
            </li>
            <li>
                Believes that addressing <strong>support and communication issues</strong> will enhance franchisee satisfaction and success
            </li>
            <li>
                Remains committed to the brand and its potential, leveraging their extensive industry experience to contribute to F45's ongoing growth
            </li>
        </ul>
    </li>
</ul>
          `,
        ],
        image: "/spotlight/f45_4.png",
      },
      {
        id: 5,
        title: "Spotlight #5 – F45 Franchisee – July 2022",
        description:
          "Examines the resilience needed to navigate pandemic-related disruptions; explores cautious expansion strategies, member retention tactics, and the competitive positioning of F45 in a recovering Canadian fitness market",
        points: [
          `
<ul>
    <li>
        The franchise owner entered the F45 franchise market after hearing about its expansion in Ontario, seeking to enter franchise life with a focus on the <strong>fitness industry</strong>; they opened their first studio in <strong>Canada at the start of 2019</strong> and soon after, initiated the second studio despite <strong>COVID-19 disruptions</strong>
        <ul>
            <li>
                The first studio had to shut down on its one-year anniversary due to <strong>COVID-19 restrictions</strong> in Canada; however, the second studio was opened in <strong>December 2020</strong>, about a year into the pandemic, reflecting their <strong>determination to expand</strong>
            </li>
            <li>
                Despite significant <strong>COVID-19 challenges</strong>, including stringent restrictions and public sentiment against reopening, they weathered the storm, leading to a 3-year-old and a 1.5-year-old studio, both showing positive projections as <strong>Canada</strong> moves beyond the pandemic
            </li>
        </ul>
    </li>
    <li>
        Breakeven initially set at <strong>120 members</strong> was achieved within three months; the first studio reached <strong>215 members</strong> in the first year, surpassing the goal of 200; the current breakeven has increased to <strong>130-135</strong> due to higher wage costs associated with more senior coaches
        <ul>
            <li>
                Current membership stands at <strong>140-150 per studio</strong>, with the second studio slightly outperforming the first; the older studio faces challenges in retaining members post-COVID due to <strong>expenditure shifts</strong> and changes in member routines
            </li>
            <li>
                The franchise is confident about returning to pre-COVID levels of <strong>215 members</strong> within six months, aiming to exceed <strong>200 members</strong> by mid-September; this confidence is bolstered by the removal of <strong>COVID-19 restrictions</strong> in mid-March, which allowed operations to normalize
            </li>
        </ul>
    </li>
    <li>
        <strong>COVID-19</strong> had a significant impact on business operations, leading to the closure of the first studio on its one-year anniversary; despite this, the owner pushed forward with the opening of a second studio during the pandemic, demonstrating <strong>resilience and commitment</strong>
        <ul>
            <li>
                The second studio opened in <strong>December 2020</strong>, almost a year into the pandemic, and is now outpacing the first studio; the pandemic’s effect on member retention and growth has been challenging, but recovery is now visible
            </li>
            <li>
                Ongoing challenges include <strong>member retention issues</strong> due to changes in consumer behavior and economic conditions, but both studios are projected to continue growing as the situation normalizes
            </li>
        </ul>
    </li>
    <li>
        The franchise owner’s initial membership target was <strong>120 for breakeven</strong>, which was met within the first three months; the first studio eventually grew to <strong>215 members</strong>, and current targets aim to return to this level by mid-September
        <ul>
            <li>
                Current membership stands at <strong>140-150 members per studio</strong>, with a focus on retaining and rebuilding the base, especially in the older studio which has faced challenges post-COVID
            </li>
            <li>
                The owner is optimistic about growth prospects, especially given the removal of restrictions and the onset of peak seasons in May and September, which are historically strong months for <strong>F45</strong>
            </li>
        </ul>
    </li>
    <li>
        Expansion plans were initially ambitious, with the franchise owner investing in two additional territories during COVID-19; however, the decision was made to pause further expansion due to the financial impacts of the pandemic and personal circumstances such as the birth of a newborn
        <ul>
            <li>
                The owner remains <strong>open to future expansion</strong>, provided that F45 continues to perform well and the market conditions are favorable; there is a strong belief in the <strong>long-term viability</strong> of the business model
            </li>
            <li>
                Other franchise owners in the network are also considering expansion, with <strong>six or seven out of ten</strong> expressing interest and capability in scaling their operations
            </li>
        </ul>
    </li>
    <li>
        The franchise owner has been generally satisfied with the support provided by F45 during the pandemic, noting that the company offered flexibility in operations and provided essential materials, training, and marketing assistance
        <ul>
            <li>
                F45’s <strong>performance management team</strong> has improved post-COVID, finding a balance between oversight and allowing franchisees the freedom to run their operations; this change has been well received after earlier issues with brand consistency
            </li>
            <li>
                The accounting department, though initially problematic with <strong>billing errors</strong>, has shown improvement; the introduction of a standardized <strong>reconciliation process</strong> is viewed as a positive development, reflecting F45's efforts to support franchisees through challenging times
            </li>
        </ul>
    </li>
    <li>
        F45 is seen as having a <strong>competitive edge</strong> over other boutique fitness brands like CrossFit and Orangetheory, particularly due to its focus on one-on-one training within a group setting, which appeals to a wider range of fitness levels and demographics
        <ul>
            <li>
                The franchise owner highlights F45’s <strong>technology</strong> that enables trainers to provide individualized attention, enhancing the member experience and contributing to <strong>low churn rates</strong>; this is seen as a significant advantage in a competitive market
            </li>
            <li>
                The <strong>community-oriented approach</strong> of F45 is another key differentiator, fostering a strong member network that drives engagement and retention
            </li>
        </ul>
    </li>
    <li>
        Concerns about <strong>market saturation</strong> in boutique fitness are acknowledged, but the franchise owner believes that F45’s strategy of focusing on income demographics rather than prime retail locations is effective; this approach allows for <strong>community-building</strong> over reliance on walk-by traffic
        <ul>
            <li>
                The franchise owner appreciates the <strong>flexibility</strong> in choosing locations within a territory, as long as income levels are adequate; this less rigorous approach is seen as a strength in allowing franchisees to adapt to local market conditions
            </li>
            <li>
                While territory overlap is a concern, the owner believes that a well-run studio can still thrive even with nearby competition; the key is maintaining a strong offering and community presence to <strong>differentiate</strong> from other fitness options
            </li>
        </ul>
    </li>
    <li>
        The franchise owner is well-connected with other F45 franchisees and serves as an accountant for several studios, which allows for frequent exchange of ideas and insights into the performance of other locations
        <ul>
            <li>
                Most studios in the network are performing well, with <strong>75% or more</strong> at or above breakeven; the owner emphasizes the importance of <strong>effort and accountability</strong> in achieving success, noting that those who focus on member experience rather than external factors tend to perform better
            </li>
            <li>
                There is a general interest in <strong>expansion among franchisees</strong>, with many considering additional studios if financially feasible; however, some are content with a smaller number of studios due to personal or financial reasons rather than dissatisfaction with the F45 model
            </li>
        </ul>
    </li>
    <li>
        F45 maintains a relatively <strong>low churn rate</strong> of around <strong>5% per month</strong>, which is considered strong within the industry; this is attributed to the premium offering of one-on-one training in a group environment, which delivers tangible results to members
        <ul>
            <li>
                The franchise owner believes that the high retention is due to the quality of the workouts and the personalized attention members receive; this premium service model differentiates F45 from competitors who may offer lower-cost but less personalized fitness options
            </li>
            <li>
                The <strong>community aspect</strong> of F45 also plays a significant role in member retention, with members forming strong social connections that encourage continued participation
            </li>
        </ul>
    </li>
    <li>
        The franchise owner has faced challenges in <strong>rebuilding membership</strong> after the disruptions caused by COVID-19; the older studio, in particular, has struggled with members who have shifted to other fitness routines or cut discretionary spending
        <ul>
            <li>
                Despite these challenges, both studios are now <strong>above breakeven</strong> and positioned for growth as the market stabilizes; the franchise owner is focused on re-engaging former members and attracting new ones to sustain this recovery
            </li>
            <li>
                The owner remains optimistic about the <strong>long-term prospects</strong>, especially with the approaching peak seasons in September and January, which are traditionally strong months for fitness studios
            </li>
        </ul>
    </li>
    <li>
        The franchise owner, being an <strong>accountant by profession</strong>, has been particularly attentive to financial management and has encountered issues with F45’s accounting department, particularly with <strong>billing errors</strong> during the pandemic
        <ul>
            <li>
                These issues were exacerbated by the pandemic, which stretched the accounting department thin; however, improvements have been noted, especially with the introduction of a standardized <strong>reconciliation process</strong> that has helped address past errors
            </li>
            <li>
                The owner acknowledges that while these issues were frustrating, they were mitigated by the overall support F45 provided during the pandemic, including <strong>franchise fee waivers</strong> and other financial accommodations
            </li>
        </ul>
    </li>
    <li>
        The franchise owner is <strong>optimistic about the future</strong> of their F45 studios, particularly as the market recovers from COVID-19 and restrictions continue to ease; they are focused on rebuilding membership and capitalizing on upcoming peak seasons
        <ul>
            <li>
                While there is interest in future expansion, the owner is cautious, preferring to see how the market develops and ensuring that F45 continues to stay relevant in a rapidly changing fitness landscape
            </li>
            <li>
                The franchise owner remains confident in the <strong>long-term viability</strong> of the F45 business model, provided the company continues to innovate and support its franchisees effectively
            </li>
        </ul>
    </li>
</ul>
          `,
        ],
        image: "/spotlight/f45_5.png",
      },
      {
        id: 6,
        title: "Spotlight #6 – F45 Franchisee – May 2022",
        description:
          "Emphasizes strategic growth and the evolving support from F45 corporate; discusses the critical importance of territory protection, staffing challenges, and the delicate balance between expansion and operational sustainability",
        points: [
          `
<ul>
    <li>
        <strong>The franchise owner</strong> signed their first franchise agreement in 2018 and opened their first studio in December of that year; since then, they have opened two more studios, with the third one launching in March 2023
        <ul>
            <li>
                The second studio was delayed from March to June 2020 due to the onset of <strong>COVID-19</strong>, but the owner continued to expand despite the challenges
            </li>
            <li>
                The owner doesn’t have a specific plan for further expansion but is <strong>open to it</strong> if the right territory becomes available and their current studios are performing well
            </li>
        </ul>
    </li>
    <li>
        The owner’s first studio requires around <strong>200 members</strong> to reach breakeven; however, they faced significant challenges during <strong>COVID-19</strong>, causing membership to hover around <strong>210</strong>
        <ul>
            <li>
                Payroll is the main cost that can be adjusted to maintain profitability; the owner managed costs by cutting payroll when necessary, ensuring they did not lose money
            </li>
            <li>
                The Tempe location is particularly successful, with <strong>350-360 members</strong> and is one of the top locations in Southern Arizona and sometimes in the U.S.; the newest studio currently has <strong>240-250 members</strong>
            </li>
        </ul>
    </li>
    <li>
        The first studio faced multiple challenges including proximity to another F45 location less than three miles away, employee drama, and a demographic more fearful of <strong>COVID-19</strong>
        <ul>
            <li>
                The proximity of another F45 studio hurt the initial growth of the first location, which wasn’t anticipated due to early mismanagement in assigning territories
            </li>
            <li>
                <strong>Employee turnover</strong> and difficulty in hiring reliable staff also contributed to the challenges; finding quality employees in this industry has been tough, with instances of candidates not showing up for interviews
            </li>
        </ul>
    </li>
    <li>
        Pre-COVID, the first studio was approaching <strong>300 members</strong>, but membership has declined since the pandemic
        <ul>
            <li>
                The second studio opened with fewer members than anticipated due to <strong>COVID-19</strong>, starting with about <strong>150 members</strong> instead of the expected <strong>220-230</strong>
            </li>
            <li>
                Despite these setbacks, the second studio has grown to <strong>365 members</strong> within two years, with a goal of adding <strong>20 net members</strong> each month
            </li>
        </ul>
    </li>
    <li>
        Membership growth has been steady across all studios, although the Tempe location faces unique challenges due to its transient population
        <ul>
            <li>
                The Tempe location benefits from being in a popular area but also suffers from high turnover due to its transient population, including students and traveling professionals
            </li>
            <li>
                May was a particularly strong month for membership growth, reflecting seasonal trends in the fitness industry
            </li>
        </ul>
    </li>
    <li>
        The proximity of another F45 studio to the first location was due to early territory assignment issues within the company
        <ul>
            <li>
                Both studios were signed up by different salespeople at the same time, leading to a conflict that wasn’t properly managed
            </li>
            <li>
                The owner believes this was a result of early growing pains in the company’s expansion process
            </li>
        </ul>
    </li>
    <li>
        F45’s support has significantly improved over the years, with the company building out better support teams and onboarding processes
        <ul>
            <li>
                Initially, communication was difficult, and support was minimal, but the company has since developed more structured and accessible systems
            </li>
            <li>
                The owner feels that F45 made the right moves during <strong>COVID-19</strong> to restructure internally, which encouraged them to open a third studio
            </li>
        </ul>
    </li>
    <li>
        The owner has not experienced any recent billing issues, which were a problem before and during <strong>COVID-19</strong>; a new team has since addressed these issues
        <ul>
            <li>
                The improvements in billing and overall communication have been substantial, contributing to a more seamless operational experience
            </li>
            <li>
                The owner still sees room for improvement in consistency across the brand, especially in ensuring all franchisees are aligned with the company’s best practices
            </li>
        </ul>
    </li>
    <li>
        The owner is critical of the <strong>equipment quality</strong>, noting that it all comes from China and has not improved over time
        <ul>
            <li>
                While the equipment isn’t terrible, there are issues typical of products sourced from China; the owner feels that higher-quality equipment should be a priority
            </li>
            <li>
                This issue has persisted since the beginning and has not seen any notable deterioration but remains a concern
            </li>
        </ul>
    </li>
    <li>
        The owner is methodical about expansion, preferring to build internal systems and ensure existing studios are running smoothly before considering new locations
        <ul>
            <li>
                As a single female owner of three studios with no partners and a day job, the owner is cautious about spreading resources too thin
            </li>
            <li>
                Expansion is not off the table, but the owner wants to approach it slowly and strategically, ensuring that each studio is properly built and managed
            </li>
        </ul>
    </li>
    <li>
        The owner has built relationships with other successful franchisees and surrounds themselves with those who are doing well in the business
        <ul>
            <li>
                Six or seven out of ten franchisees known to the owner are interested in opening more studios and find the business model effective
            </li>
            <li>
                The owner avoids engaging with franchisees who are negative or unsuccessful, believing that their lack of success is often due to their own inaction or poor management
            </li>
        </ul>
    </li>
    <li>
        F45’s value proposition lies in offering a dynamic, ever-changing workout environment that doesn’t rely on treadmills or repetitive routines
        <ul>
            <li>
                The studio provides a more personalized experience with two trainers per class, ensuring that members are performing exercises correctly and safely
            </li>
            <li>
                Compared to personal training at a big box gym, F45 offers a more affordable alternative with a strong community focus and high-intensity interval training that yields results
            </li>
        </ul>
    </li>
    <li>
        The owner acknowledges that the fitness industry has growth cycles, estimating that the high-growth phase for F45 could last around <strong>10 years</strong>
        <ul>
            <li>
                The constantly evolving nature of F45’s workouts and the introduction of new equipment help maintain member interest and prevent the brand from becoming a fad
            </li>
            <li>
                F45’s influencer marketing, including partnerships with celebrities like <strong>Mark Wahlberg and David Beckham</strong>, has also been a key factor in driving brand awareness and growth
            </li>
        </ul>
    </li>
    <li>
        Saturation in the boutique fitness market is recognized, but the owner believes that there is still room for growth and success
        <ul>
            <li>
                The owner strategically chooses territories, often focusing on areas of high growth with less competition to ensure their studios are well-positioned
            </li>
            <li>
                The current system for choosing territories has improved, and the owner is very selective about where to place new studios to avoid oversaturation
            </li>
        </ul>
    </li>
    <li>
        Despite the growing competition in boutique fitness, the owner believes that there is still ample opportunity for brands like F45 to succeed
        <ul>
            <li>
                The ability to adapt offerings and maintain flexibility in the face of competition is seen as crucial to staying relevant and successful
            </li>
            <li>
                The owner remains optimistic about the future of F45, emphasizing that their studios continue to meet membership goals despite the competitive landscape
            </li>
        </ul>
    </li>
    <li>
        Operationally, F45 has improved but still faces growing pains as the company expands rapidly
        <ul>
            <li>
                Communication can be <strong>last-minute</strong>, with events and changes often announced with little notice, which can be challenging for franchisees
            </li>
            <li>
                The owner advocates for more consistency across the brand, ensuring all franchisees are aligned with best practices and that the brand’s success isn’t left to individual efforts alone
            </li>
        </ul>
    </li>
</ul>
          `,
        ],
        image: "/spotlight/f45_6.png",
      },
      {
        id: 7,
        title: "Spotlight #7 – F45 Franchisee – March 2022",
        description:
          "Addresses the complexities of market saturation and competition in a growing region; delves into strategic staffing, the financial realities of multiple studios, and the need for careful expansion planning amid rapid brand growth",
        points: [
          `
<ul>
    <li><strong>The franchise owner</strong> transitioned from a career in the oil and gas industry, specifically in <strong>subsea deep water hardware sales</strong>, to owning F45 franchises after being introduced to the brand by his wife, who was looking for a change from Orangetheory; the owner has a strong athletic background, including <strong>college football</strong>, which aligned well with F45’s interval and circuit training
        <ul>
            <li>The owner was interested in franchising for some time, but the timing only became right when the oil and gas market experienced a downturn in <strong>2016-2017</strong>, leading to the first F45 franchise agreement signed in <strong>January 2018</strong></li>
            <li>The owner now operates <strong>three F45 locations</strong>, with the first opened in <strong>December 2018</strong>, the second in <strong>January 2020</strong>, and the third in <strong>September 2020</strong></li>
        </ul>
    </li>
    <li>The initiation process involved the owner reaching out to F45, with the first studio being the <strong>sixth or seventh</strong> in the area; now, there are around <strong>40 studios</strong> in the region, reflecting significant growth in a short period
        <ul>
            <li>The first agreement was signed in <strong>January 2018</strong>, with the first studio opening in <strong>December 2018</strong>; subsequent studios opened more quickly due to the owner’s experience, with the second and third agreements signed in <strong>May 2019</strong> and the studios opening in <strong>January 2020</strong> and <strong>September 2020</strong>, respectively</li>
            <li>The third studio’s opening was strategically delayed to allow the second one to stabilize before <strong>monthly royalties</strong> began, indicating careful planning in the expansion process</li>
        </ul>
    </li>
    <li><strong>Staffing</strong> was initially done through online platforms like Indeed, which has been successful; as the business grew, more candidates came through word-of-mouth referrals, but online sourcing remains a key method
        <ul>
            <li>Currently, the owner uses <strong>CareerPlug</strong> to market job postings across multiple platforms, which has continued to be effective in recruiting trainers</li>
            <li>The three studios are managed by <strong>22 coaches</strong> in total, with each location requiring around six or seven coaches; some coaches work across multiple locations, and all are independent contractors except for two area managers who oversee athletics and sales operations</li>
        </ul>
    </li>
    <li><strong>Pay structure</strong> for coaches varies, starting at <strong>$25 per class</strong> and increasing to <strong>$30</strong> after a probationary period; weekend classes pay more, with <strong>$40 for Saturday classes</strong> and <strong>$35 for Sunday classes</strong>; additional pay is provided for equipment setup and breakdown
        <ul>
            <li><strong>Area managers</strong>, who are salaried employees, earn around <strong>$50,000 per year</strong>, and they manage multiple locations, including coaching classes as needed</li>
            <li>The pay structure reflects a <strong>lean operational model</strong> that emphasizes versatility and efficiency among staff</li>
        </ul>
    </li>
    <li><strong>Territory size</strong> has been a significant issue, with the owner expressing concerns about the small and unprotected territories granted by F45; this has led to multiple studios opening within a <strong>two-mile radius</strong> of the downtown location, negatively impacting membership
        <ul>
            <li>The owner feels more secure about the territories of the other two studios, which are naturally protected by <strong>geographical features</strong> and residential areas with limited retail development</li>
            <li>Despite the challenges, the owner does not see any attractive territories left in the area for further expansion, indicating that the market may be <strong>saturated</strong></li>
        </ul>
    </li>
    <li>The <strong>financial outlay</strong> for each studio was between <strong>$300,000 to $400,000</strong>, all of which were financed through cash rather than F45’s preferred lenders
        <ul>
            <li>The owner chose the locations independently, although F45 now offers <strong>real estate advisory services</strong> to new franchisees, which the owner acknowledges would have been helpful at the time</li>
            <li>Two of the studios are in typical strip mall setups, while the downtown location is in a <strong>retrofitted warehouse</strong> within an entertainment district, reflecting the diverse nature of the F45 locations</li>
        </ul>
    </li>
    <li>The <strong>membership ramp</strong> for each studio varied significantly due to external factors like <strong>COVID-19</strong> and local construction; the downtown studio faced road construction that hindered its grand opening, while the other two studios had better starts but were later impacted by <strong>COVID-19</strong>
        <ul>
            <li>The second studio was <strong>profitable in its first month</strong>, while the third, opened during the pandemic, had a slower but better-than-expected start</li>
            <li>Membership dropped by about <strong>70%</strong> during the initial months of <strong>COVID-19</strong> due to cancellations and suspensions, but the second and third studios have since recovered and are performing well, though the downtown location continues to struggle</li>
        </ul>
    </li>
    <li>The downtown location is currently <strong>not profitable</strong>, with about <strong>100 members</strong>, while the other two studios have around <strong>150 and 200 members</strong>, respectively
        <ul>
            <li>Breakeven for the profitable studios is in the range of <strong>130 to 150 members</strong>, depending on rent and payroll costs, with overhead for the downtown location at <strong>$23,000 to $25,000 monthly</strong> and <strong>$30,000</strong> for the other two</li>
            <li>The third location is now making <strong>$3,000 a month</strong>, while the second location generates <strong>$10,000 in monthly profit</strong></li>
        </ul>
    </li>
    <li><strong>Churn rate</strong> is a concern, with downtown losing about <strong>eight members per month</strong>, while the second and third studios lose <strong>17 and 11 members</strong>, respectively; however, all studios are seeing net growth in membership
        <ul>
            <li>The target membership for each studio is over <strong>300</strong>, with a capacity to handle up to <strong>500</strong>, although the owner would be pleased with reaching <strong>300 members</strong></li>
            <li>Common reasons for cancellations include members moving to more <strong>convenient locations</strong> and financial constraints</li>
        </ul>
    </li>
    <li><strong>Pricing structure</strong> includes a mix of <strong>month-to-month</strong> and <strong>six-month unlimited memberships</strong>, with prices varying slightly by location; the downtown location is priced lower than the other two
        <ul>
            <li><strong>Foundation memberships</strong> offered at promotional rates were used to attract initial members, with prices raised for new members after these rates sold out</li>
            <li><strong>Social media marketing</strong>, particularly on Facebook, Instagram, and Google, is the primary method for attracting new members, supported by business-to-business efforts and community events</li>
        </ul>
    </li>
    <li><strong>CapEx for maintenance</strong> varies annually but averages between <strong>$4,000 to $7,000</strong> per studio; recent required purchases include <strong>SkiErgs and BikeErgs</strong>, costing about <strong>$6,000</strong> per studio
        <ul>
            <li><strong>Occupancy costs</strong> range from <strong>$10,000 to $12,000 per month</strong> per studio, with lease agreements aligning with the five-year franchise agreements and options to renew for another five years</li>
            <li>The business has developed largely as expected, though the owner notes the challenges presented by <strong>COVID-19</strong> and other external factors like local construction, which impacted the downtown location significantly</li>
        </ul>
    </li>
    <li><strong>Labor costs</strong> have not yet been significantly affected by wage inflation, and staffing has been relatively easy, possibly due to effective recruiting practices; however, maintaining the right balance of coaches is always a challenge
        <ul>
            <li>The owner was one of the first to open in the area, with <strong>six or seven studios</strong> at the time, but now there are around <strong>40</strong>; the owner believes the market is fully <strong>saturated</strong> and questions the wisdom of F45’s aggressive expansion strategy</li>
            <li>The owner suggests F45 should consider pausing new franchise sales, similar to what <strong>Orangetheory</strong> did during its growth phase, to avoid oversaturation and ensure better support for existing franchisees</li>
        </ul>
    </li>
    <li><strong>Competition</strong> is seen primarily with other boutique fitness brands like <strong>Orangetheory</strong>, although big box gyms like <strong>Life Time</strong> that offer free high-intensity interval training classes are also considered competitors
        <ul>
            <li>F45 positions itself as offering <strong>personalized training</strong> in a group setting, with two certified trainers per class, differentiating it from Orangetheory’s single trainer model</li>
            <li>The owner believes F45’s <strong>pricing</strong>, which is positioned between personal training and other boutique options, is justified by the level of personalized attention provided</li>
        </ul>
    </li>
    <li>Conversations with other franchisees reveal common concerns about <strong>territory size</strong> and proximity to other studios, which can impact growth and profitability
        <ul>
            <li>The owner notes that many franchisees are struggling post-<strong>COVID</strong>, with about <strong>50% making money</strong> and <strong>50% not</strong>, though there is optimism that the situation will improve as the pandemic recedes</li>
            <li>The owner emphasizes that the <strong>pace of expansion</strong> should be reconsidered, as the current strategy may lead to oversaturation and diminish support for existing franchisees</li>
        </ul>
    </li>
    <li>The owner sees <strong>corporate culture</strong> at F45 as improving, particularly in terms of support systems, though there are still issues with <strong>communication</strong> and a sense of <strong>exclusivity</strong> among higher-level management
        <ul>
            <li>The <strong>CEO</strong> has not particularly impressed the owner, especially in public speaking, but the overall network and culture are seen positively</li>
            <li><strong>Marketing</strong> is largely left to individual studios, with little regional or national support, which the owner sees as an area needing improvement</li>
        </ul>
    </li>
    <li>The <strong>overall outlook</strong> for F45 depends heavily on its growth strategy; while the brand and product are strong, the owner is concerned that <strong>oversaturation</strong> could lead to cannibalization of memberships and harm the brand’s long-term success
        <ul>
            <li><strong>Inflation</strong> and economic pressures may affect some studios more than others, particularly those in less affluent areas, but the owner believes their customer base is generally <strong>resilient</strong></li>
            <li>The owner remains <strong>cautiously optimistic</strong>, with hopes that F45 will adjust its strategy to balance growth with sustainability</li>
        </ul>
    </li>
</ul>
          `,
        ],
        image: "/spotlight/f45_7.png",
      },
      {
        id: 8,
        title:
          "Discusses the profound impact of COVID-19 on studio profitability and membership; highlights territory saturation, the role of corporate support in recovery efforts, and the long-term challenges facing the F45 brand in competitive markets",
        description:
          "Addresses the complexities of market saturation and competition in a growing region; delves into strategic staffing, the financial realities of multiple studios, and the need for careful expansion planning amid rapid brand growth",
        points: [
          `
<ul>
    <li><strong>The franchise owner</strong> transitioned from a career in the oil and gas industry, specifically in <strong>subsea deep water hardware sales</strong>, to owning F45 franchises after being introduced to the brand by his wife, who was looking for a change from Orangetheory; the owner has a strong athletic background, including <strong>college football</strong>, which aligned well with F45’s interval and circuit training
        <ul>
            <li>The owner was interested in franchising for some time, but the timing only became right when the oil and gas market experienced a downturn in <strong>2016-2017</strong>, leading to the first F45 franchise agreement signed in <strong>January 2018</strong></li>
            <li>The owner now operates <strong>three F45 locations</strong>, with the first opened in <strong>December 2018</strong>, the second in <strong>January 2020</strong>, and the third in <strong>September 2020</strong></li>
        </ul>
    </li>
    <li>The initiation process involved the owner reaching out to F45, with the first studio being the <strong>sixth or seventh</strong> in the area; now, there are around <strong>40 studios</strong> in the region, reflecting significant growth in a short period
        <ul>
            <li>The first agreement was signed in <strong>January 2018</strong>, with the first studio opening in <strong>December 2018</strong>; subsequent studios opened more quickly due to the owner’s experience, with the second and third agreements signed in <strong>May 2019</strong> and the studios opening in <strong>January 2020</strong> and <strong>September 2020</strong>, respectively</li>
            <li>The third studio’s opening was strategically delayed to allow the second one to stabilize before <strong>monthly royalties</strong> began, indicating careful planning in the expansion process</li>
        </ul>
    </li>
    <li><strong>Staffing</strong> was initially done through online platforms like Indeed, which has been successful; as the business grew, more candidates came through word-of-mouth referrals, but online sourcing remains a key method
        <ul>
            <li>Currently, the owner uses <strong>CareerPlug</strong> to market job postings across multiple platforms, which has continued to be effective in recruiting trainers</li>
            <li>The three studios are managed by <strong>22 coaches</strong> in total, with each location requiring around six or seven coaches; some coaches work across multiple locations, and all are independent contractors except for two area managers who oversee athletics and sales operations</li>
        </ul>
    </li>
    <li><strong>Pay structure</strong> for coaches varies, starting at <strong>$25 per class</strong> and increasing to <strong>$30</strong> after a probationary period; weekend classes pay more, with <strong>$40 for Saturday classes</strong> and <strong>$35 for Sunday classes</strong>; additional pay is provided for equipment setup and breakdown
        <ul>
            <li><strong>Area managers</strong>, who are salaried employees, earn around <strong>$50,000 per year</strong>, and they manage multiple locations, including coaching classes as needed</li>
            <li>The pay structure reflects a <strong>lean operational model</strong> that emphasizes versatility and efficiency among staff</li>
        </ul>
    </li>
    <li><strong>Territory size</strong> has been a significant issue, with the owner expressing concerns about the small and unprotected territories granted by F45; this has led to multiple studios opening within a <strong>two-mile radius</strong> of the downtown location, negatively impacting membership
        <ul>
            <li>The owner feels more secure about the territories of the other two studios, which are naturally protected by <strong>geographical features</strong> and residential areas with limited retail development</li>
            <li>Despite the challenges, the owner does not see any attractive territories left in the area for further expansion, indicating that the market may be <strong>saturated</strong></li>
        </ul>
    </li>
    <li>The <strong>financial outlay</strong> for each studio was between <strong>$300,000 to $400,000</strong>, all of which were financed through cash rather than F45’s preferred lenders
        <ul>
            <li>The owner chose the locations independently, although F45 now offers <strong>real estate advisory services</strong> to new franchisees, which the owner acknowledges would have been helpful at the time</li>
            <li>Two of the studios are in typical strip mall setups, while the downtown location is in a <strong>retrofitted warehouse</strong> within an entertainment district, reflecting the diverse nature of the F45 locations</li>
        </ul>
    </li>
    <li>The <strong>membership ramp</strong> for each studio varied significantly due to external factors like <strong>COVID-19</strong> and local construction; the downtown studio faced road construction that hindered its grand opening, while the other two studios had better starts but were later impacted by <strong>COVID-19</strong>
        <ul>
            <li>The second studio was <strong>profitable in its first month</strong>, while the third, opened during the pandemic, had a slower but better-than-expected start</li>
            <li>Membership dropped by about <strong>70%</strong> during the initial months of <strong>COVID-19</strong> due to cancellations and suspensions, but the second and third studios have since recovered and are performing well, though the downtown location continues to struggle</li>
        </ul>
    </li>
    <li>The downtown location is currently <strong>not profitable</strong>, with about <strong>100 members</strong>, while the other two studios have around <strong>150 and 200 members</strong>, respectively
        <ul>
            <li>Breakeven for the profitable studios is in the range of <strong>130 to 150 members</strong>, depending on rent and payroll costs, with overhead for the downtown location at <strong>$23,000 to $25,000 monthly</strong> and <strong>$30,000</strong> for the other two</li>
            <li>The third location is now making <strong>$3,000 a month</strong>, while the second location generates <strong>$10,000 in monthly profit</strong></li>
        </ul>
    </li>
    <li><strong>Churn rate</strong> is a concern, with downtown losing about <strong>eight members per month</strong>, while the second and third studios lose <strong>17 and 11 members</strong>, respectively; however, all studios are seeing net growth in membership
        <ul>
            <li>The target membership for each studio is over <strong>300</strong>, with a capacity to handle up to <strong>500</strong>, although the owner would be pleased with reaching <strong>300 members</strong></li>
            <li>Common reasons for cancellations include members moving to more <strong>convenient locations</strong> and financial constraints</li>
        </ul>
    </li>
    <li><strong>Pricing structure</strong> includes a mix of <strong>month-to-month</strong> and <strong>six-month unlimited memberships</strong>, with prices varying slightly by location; the downtown location is priced lower than the other two
        <ul>
            <li><strong>Foundation memberships</strong> offered at promotional rates were used to attract initial members, with prices raised for new members after these rates sold out</li>
            <li><strong>Social media marketing</strong>, particularly on Facebook, Instagram, and Google, is the primary method for attracting new members, supported by business-to-business efforts and community events</li>
        </ul>
    </li>
    <li><strong>CapEx for maintenance</strong> varies annually but averages between <strong>$4,000 to $7,000</strong> per studio; recent required purchases include <strong>SkiErgs and BikeErgs</strong>, costing about <strong>$6,000</strong> per studio
        <ul>
            <li><strong>Occupancy costs</strong> range from <strong>$10,000 to $12,000 per month</strong> per studio, with lease agreements aligning with the five-year franchise agreements and options to renew for another five years</li>
            <li>The business has developed largely as expected, though the owner notes the challenges presented by <strong>COVID-19</strong> and other external factors like local construction, which impacted the downtown location significantly</li>
        </ul>
    </li>
    <li><strong>Labor costs</strong> have not yet been significantly affected by wage inflation, and staffing has been relatively easy, possibly due to effective recruiting practices; however, maintaining the right balance of coaches is always a challenge
        <ul>
            <li>The owner was one of the first to open in the area, with <strong>six or seven studios</strong> at the time, but now there are around <strong>40</strong>; the owner believes the market is fully <strong>saturated</strong> and questions the wisdom of F45’s aggressive expansion strategy</li>
            <li>The owner suggests F45 should consider pausing new franchise sales, similar to what <strong>Orangetheory</

strong> did during its growth phase, to avoid oversaturation and ensure better support for existing franchisees</li>
        </ul>
    </li>
    <li><strong>Competition</strong> is seen primarily with other boutique fitness brands like <strong>Orangetheory</strong>, although big box gyms like <strong>Life Time</strong> that offer free high-intensity interval training classes are also considered competitors
        <ul>
            <li>F45 positions itself as offering <strong>personalized training</strong> in a group setting, with two certified trainers per class, differentiating it from Orangetheory’s single trainer model</li>
            <li>The owner believes F45’s <strong>pricing</strong>, which is positioned between personal training and other boutique options, is justified by the level of personalized attention provided</li>
        </ul>
    </li>
    <li>Conversations with other franchisees reveal common concerns about <strong>territory size</strong> and proximity to other studios, which can impact growth and profitability
        <ul>
            <li>The owner notes that many franchisees are struggling post-<strong>COVID</strong>, with about <strong>50% making money</strong> and <strong>50% not</strong>, though there is optimism that the situation will improve as the pandemic recedes</li>
            <li>The owner emphasizes that the <strong>pace of expansion</strong> should be reconsidered, as the current strategy may lead to oversaturation and diminish support for existing franchisees</li>
        </ul>
    </li>
    <li>The owner sees <strong>corporate culture</strong> at F45 as improving, particularly in terms of support systems, though there are still issues with <strong>communication</strong> and a sense of <strong>exclusivity</strong> among higher-level management
        <ul>
            <li>The <strong>CEO</strong> has not particularly impressed the owner, especially in public speaking, but the overall network and culture are seen positively</li>
            <li><strong>Marketing</strong> is largely left to individual studios, with little regional or national support, which the owner sees as an area needing improvement</li>
        </ul>
    </li>
    <li>The <strong>overall outlook</strong> for F45 depends heavily on its growth strategy; while the brand and product are strong, the owner is concerned that <strong>oversaturation</strong> could lead to cannibalization of memberships and harm the brand’s long-term success
        <ul>
            <li><strong>Inflation</strong> and economic pressures may affect some studios more than others, particularly those in less affluent areas, but the owner believes their customer base is generally <strong>resilient</strong></li>
            <li>The owner remains <strong>cautiously optimistic</strong>, with hopes that F45 will adjust its strategy to balance growth with sustainability</li>
        </ul>
    </li>
</ul>
          `,
        ],
        image: "/spotlight/f45_8.png",
      },
      {
        id: 9,
        title: "Spotlight #9 – F45 Franchisee – November 2021",
        description:
          "Focus on community building, member retention, and the challenges of operating in less urban markets; explores skepticism about F45’s aggressive expansion plans and the need for stronger corporate support to ensure franchisee success",
        points: [
          `
<ul>
    <li><strong>The interviewee</strong> has a <strong>background in software development</strong> and management, having served as a VP for a software company before transitioning to owning an F45 franchise; their interest in fitness was influenced by personal hobbies and coaching experience
        <ul>
            <li>The decision to leave the corporate world was driven by a desire to be more in control of their destiny; they researched various franchise options but were ultimately drawn to F45 due to its alignment with their <strong>beliefs in fitness and the brand’s key principles</strong></li>
            <li>The interviewee’s F45 studio is one of the <strong>earlier U.S. locations</strong>, having been in operation for almost <strong>five years</strong></li>
        </ul>
    </li>
    <li>The process of opening the F45 studio was <strong>lengthy and costly</strong>, cutting into the interviewee’s savings; they managed much of the build-out themselves to save costs, which extended the timeline to approximately <strong>nine months</strong>
        <ul>
            <li>The initial start-up costs were around <strong>$350,000</strong>, with equipment expenses totaling about <strong>$100,000</strong></li>
            <li>The interviewee expressed that in hindsight, speeding up the build-out process might have been more beneficial to get the operation running sooner</li>
        </ul>
    </li>
    <li>The interviewee found F45’s <strong>initial support</strong> during the sales process to be effective but noted a <strong>significant drop-off</strong> in support once contracts were signed; the system became more about compliance with little direct help available
        <ul>
            <li>The lack of a dedicated support line for urgent issues has led to reliance on <strong>Facebook groups</strong> for troubleshooting, where owners seek advice from each other due to slow response times from F45 corporate</li>
            <li>The interviewee observed that <strong>larger multi-unit owners</strong> might receive better support, possibly being assigned a person to work with, while single-unit owners often struggle with inadequate assistance</li>
        </ul>
    </li>
    <li>F45 is seen as a <strong>comprehensive fitness offering</strong>, taking elements from various fitness concepts like CrossFit, kickboxing, and yoga, and packaging them into a versatile product; this makes it easy to sell and appealing to a broad audience
        <ul>
            <li>The product is designed to offer <strong>a little bit of everything</strong>, which appeals to people who may not want to specialize in one type of fitness but prefer a well-rounded approach</li>
            <li>Despite being a <strong>group classes model</strong>, F45 includes elements such as weight lifting, cardio, and agility exercises, making it attractive to those who want variety in their workouts</li>
        </ul>
    </li>
    <li>The interviewee indicated that F45's <strong>pricing model</strong> is typically structured around weekly billing with minimal long-term commitments; this differs from traditional gyms that lock customers into annual contracts
        <ul>
            <li>Customers are encouraged to start with weekly billing, with the understanding that they can cancel with a <strong>month's notice</strong>; this approach aims to continually <strong>earn the customer's business</strong> rather than relying on long-term contracts</li>
            <li>The interviewee noted that in some <strong>high-demand locations</strong>, studios might not even require a month's notice due to high occupancy rates</li>
        </ul>
    </li>
    <li>The <strong>demographic</strong> for F45 typically consists of younger, urban professionals with higher incomes, often described as the <strong>“night club of gyms”</strong> due to its lively atmosphere; the clientele is approximately <strong>70-80% women</strong>
        <ul>
            <li>The interviewee highlighted that F45’s environment, characterized by <strong>loud music and a fun atmosphere</strong>, sets it apart from other fitness options, appealing especially to singles and couples without children</li>
            <li>The studio’s location in a more rural area poses challenges, as the market is less accustomed to the <strong>premium pricing</strong> associated with boutique fitness, making client acquisition more difficult</li>
        </ul>
    </li>
    <li>The <strong>competitive landscape</strong> for F45 includes various fitness concepts like Orangetheory and 9Round, but the interviewee does not view them as significant threats; F45 offers a <strong>broader and more varied fitness experience</strong> that often wins over clients who try it
        <ul>
            <li>F45 includes <strong>unique equipment and exercises</strong> like sleds, rope climbs, and box jumps, which are not typically found in competitors like Orangetheory, providing a more comprehensive workout experience</li>
            <li>The interviewee emphasized that they rarely lose clients to competitors; once clients experience F45, they often find it offers <strong>greater variety and a better overall fitness regimen</strong></li>
        </ul>
    </li>
    <li><strong>Client acquisition</strong> for the interviewee’s studio relies heavily on referrals, with about <strong>70% of new members</strong> coming from word of mouth rather than from other boutique fitness centers; only about <strong>10% of new clients</strong> previously subscribed to another boutique fitness class
        <ul>
            <li>The interviewee noted that most new clients were not previously serious about fitness, and F45’s <strong>engaging environment</strong> helps them commit to a regular workout routine</li>
            <li>The rural setting of the studio contributes to a <strong>smaller market pool</strong>, making client retention through referrals crucial to maintaining membership levels</li>
        </ul>
    </li>
    <li>The interviewee expressed <strong>skepticism</strong> about F45’s ambitious goal of reaching <strong>7,000 locations</strong> in the U.S., estimating less than a <strong>50% chance</strong> of achieving that target; they believe the concept is better suited for <strong>urban, high-income areas</strong>
        <ul>
            <li>They suggested that F45’s success is heavily dependent on <strong>geographic and demographic factors</strong>, with studios in small-town settings struggling more than those in urban areas</li>
            <li>The interviewee indicated that F45’s <strong>expansion strategy</strong> might be too aggressive and not fully account for the niche market it is designed to serve, potentially leading to challenges in less suitable areas</li>
        </ul>
    </li>
    <li>The interviewee’s studio experiences <strong>low monthly churn</strong>, around <strong>1%</strong>, with many members staying loyal since the studio’s opening; this loyalty is attributed to the strong <strong>community feel</strong> and personal attention provided by the trainers
        <ul>
            <li>The rural location and small size of the studio allow for a more <strong>personalized experience</strong>, where trainers know members by name and understand their specific needs and injuries</li>
            <li>Despite this loyalty, the interviewee admitted they have <strong>not yet recouped their initial investment</strong> and still do not draw a paycheck from the business, highlighting the financial challenges of operating in a rural area</li>
        </ul>
    </li>
    <li><strong>Revenue and profitability</strong> for the interviewee’s studio are relatively modest, with annual revenue around <strong>$270,000</strong> and profits between <strong>$20,000 to $30,000</strong>; this is likely below the system average, which the interviewee attributes to the location
        <ul>
            <li>The market dynamics in their rural area make it difficult to generate leads and convert trials into memberships; marketing efforts that work in urban areas have proven ineffective in their setting</li>
            <li>The interviewee emphasized the importance of <strong>retention and referrals</strong> in maintaining membership levels, given the challenges in attracting new clients in a less fitness-focused market</li>
        </ul>
    </li>
    <li>The interviewee is <strong>bullish</strong> on F45’s product and concept but expressed concerns about the <strong>operational challenges</strong> and lack of responsiveness from corporate; they believe these issues could eventually impact the franchise network’s success
        <ul>
            <li>There is a perception that F45’s corporate structure may not adequately <strong>hear and address the concerns</strong> of studio owners, which could hinder long-term growth and franchisee satisfaction</li>
            <li>The interviewee pointed out that despite these challenges, F45 continues to grow, but they worry that the cost of <strong>ignoring owner feedback</strong> may become significant in the future</li>
        </ul>
    </li>
    <li>F45 does not typically <strong>buy back struggling franchises</strong> but may assist in finding a new owner if a location is at risk of closing; maintaining the brand’s image in the market appears to be a priority for corporate
        <ul>
            <li>The interviewee noted that F45 was once able to claim <strong>no closed locations</strong>, but this is no longer the case as some studios have shut down</li>
            <li>The <strong>process of closing or selling a studio</strong> can be challenging, especially in markets that are not performing well, further complicating the decision for struggling franchisees</li>
        </ul>
    </li>
</ul>
          `,
        ],
        image: "/spotlight/f45_9.png",
      },
    ],
  },

  // {
  //   slug: "European Wax Center",
  //   pageData: {
  //     pic: "/logos/clubpilates.png",
  //     title: "Club Pilates",
  //     initialInvestment: "$194k-$407k",
  //     franchiseeFee: "$60k",
  //     royaltyRate: "8.0%",
  //     description: "",
  //   },
  //   keyTrends: [
  //     {
  //       leftDescription: "",
  //       rightDescription: "",
  //     },
  //     {
  //       leftDescription: "",
  //       rightDescription: "",
  //     },
  //     {
  //       leftDescription: "",
  //       rightDescription: "",
  //     },
  //   ],
  //   cardData: [
  //     {
  //       id: 1,
  //       title: "",
  //       description: "",
  //       points: [
  //         `

  //         `,
  //       ],
  //       image: "",
  //     },
  //     {
  //       id: 2,
  //       title: "",
  //       description: "",
  //       points: [
  //         `

  //         `,
  //       ],
  //       image: "",
  //     },
  //     {
  //       id: 3,
  //       title: "",
  //       description: "",
  //       points: [
  //         `

  //         `,
  //       ],
  //       image: "",
  //     },
  //     {
  //       id: 4,
  //       title: "",
  //       description: "",
  //       points: [
  //         `

  //         `,
  //       ],
  //       image: "",
  //     },
  //   ],
  // },
];

export const getRecordBySlug = (slug) => {
  return wingStopData.find(
    (record) => record.slug.toLowerCase() === slug.toLowerCase()
  );
};
