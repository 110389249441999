import { Box, Paper, Typography, Chip } from "@mui/material";
import React, { memo } from "react";

const TableHeader = ({ franchises, displayActiveFilters }) => {
  // Assume displayActiveFilters() now returns an array of descriptions
  const filters = displayActiveFilters();

  return (
    <Paper
      elevation={3}
      style={{ margin: "8px", padding: "4px", backgroundColor: "#f9f9fb" }}
    >
      {/* Top-level Box for overall layout */}
      <Box display="flex" flexDirection="column">
        {/* Franchise Count */}
        <Typography variant="h6" component="div" style={{ fontSize: 16 }}>
          <strong>Total number of franchises:</strong> {franchises.length}
        </Typography>

        {/* Filters */}
        <Box
          display="flex"
          alignItems="center" // Align items vertically in the center
          gap="10px" // Space between "Active Filters:" and the first chip
          flexWrap="wrap" // Allow wrapping if the content exceeds the width
        >
          {/* Active Filters Label */}
          <Typography variant="subtitle1">
            <strong>Active Filters:</strong>
          </Typography>

          {/* Filter Chips */}
          <Box display="flex" gap="5px" flexWrap="wrap">
            {Array.isArray(filters) && filters.length > 0 ? (
              filters.map((filter, index) => (
                <Chip
                  key={index}
                  label={filter}
                  color="primary"
                  variant="outlined"
                  size="small"
                />
              ))
            ) : (
              <Typography variant="body2">No active filters</Typography>
            )}
          </Box>
        </Box>
      </Box>
    </Paper>
  );
};

export default memo(TableHeader);
