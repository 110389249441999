import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getAllPostsSlugs, getPostBySlug } from "../../utils/dummyData";
import BlogPage from "./BlogPage";

export async function generateStaticParams() {
  return await getAllPostsSlugs();
}

export async function generateMetadata({ params }) {
  const post = await getPostBySlug(params.slug);
  return { title: post ? post.title : "Post Not Found" };
}

const BlogDefault = () => {
  const { slug } = useParams();
  const [post, setPost] = useState(null);

  useEffect(() => {
    const fetchPost = async () => {
      try {
        const fetchedPost = await getPostBySlug(slug);
        setPost(fetchedPost);
      } catch (error) {
        console.error('Failed to fetch post:', error);
      }
    };

    if (slug) {
      fetchPost();
    }
  }, [slug]);

  if (!post) {
    return <div>Post Not Found</div>;
  }

  // return <div>Data found</div>
  return <BlogPage post={post} />;
};

export default BlogDefault;
