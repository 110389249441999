import React, { memo } from "react";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import SortByAlphaIcon from "@mui/icons-material/SortByAlpha";
import {
  IconButton,
  TableCell,
  TableHead,
  TableSortLabel,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import FilterPopover from "./FilterPopover";
import IndustryFilterPopover from "./IndustryFilterPopover";
import { useFranchise } from "../context/FranchiseContext";

const stickyHeaderStyle = {
  position: "sticky",
  top: 0,
  backgroundColor: "#fff",
  zIndex: 1,
};

const TableColumns = () => {
  const {
    filters,
    setFilter,
    IndustryCategories,
    setIndustryCategories,
    orderedBy,
    sortConfig,
    requestSort,
    handleFilter,
    activeFilters,
  } = useFranchise();
  const openYear = Boolean(filters?.anchorElYear);
  const idYear = openYear ? "simple-popover" : undefined;
  const openLocations = Boolean(filters?.anchorElLocations);
  const idLocations = openLocations ? "simple-popover" : undefined;
  const openRoyaltyPercent = Boolean(filters?.anchorElRoyaltyPercent);
  const idRoyaltyPercent = openRoyaltyPercent ? "simple-popover" : undefined;
  const openInvestMin = Boolean(filters?.anchorElInvestMin);
  const idInvestMin = openInvestMin ? "simple-popover" : undefined;
  const openInvestMax = Boolean(filters?.anchorElInvestMax);
  const idInvestMax = openInvestMax ? "simple-popover" : undefined;
  const openFeeMin = Boolean(filters?.anchorElFeeMin);
  const idFeeMin = openFeeMin ? "simple-popover" : undefined;
  const openFeeMax = Boolean(filters?.anchorElFeeMax);
  const idFeeMax = openFeeMax ? "simple-popover" : undefined;
  const openRoyaltyCash = Boolean(filters?.anchorElRoyaltyCash);
  const idRoyaltyCash = openRoyaltyCash ? "simple-popover" : undefined;
  const openIndustry = Boolean(filters?.anchorElIndustry);
  const idIndustry = openIndustry ? "simple-popover" : undefined;

  const theme = useTheme(); // Using theme for consistent styling
  const isMobile = useMediaQuery(theme.breakpoints.down("sm")); // Check if screen size is mobile

  const clearIndustries = () => {
    const resetCategories = Object.keys(IndustryCategories).reduce(
      (acc, key) => {
        acc[key] = false; // Set each category to false
        return acc;
      },
      {}
    );

    setIndustryCategories(resetCategories);
  };

  const handleClickYear = (event) => {
    setFilter("anchorElYear", event.currentTarget);
  };
  const handleCloseYear = () => {
    setFilter("anchorElYear", null);
  };

  const handleClickLocations = (event) => {
    setFilter("anchorElLocations", event.currentTarget);
  };
  const handleCloseLocations = () => {
    setFilter("anchorElLocations", null);
  };

  const handleClickRoyaltyPercent = (event) => {
    setFilter("anchorElRoyaltyPercent", event.currentTarget);
  };
  const handleCloseRoyaltyPercent = () => {
    setFilter("anchorElRoyaltyPercent", null);
  };

  const handleClickInvestMin = (event) => {
    setFilter("anchorElInvestMin", event.currentTarget);
  };
  const handleCloseInvestMin = () => {
    setFilter("anchorElInvestMin", null);
  };

  const handleClickInvestMax = (event) => {
    setFilter("anchorElInvestMax", event.currentTarget);
  };
  const handleCloseInvestMax = () => {
    setFilter("anchorElInvestMax", null);
  };

  const handleClickFeeMin = (event) => {
    setFilter("anchorElFeeMin", event.currentTarget);
  };
  const handleCloseFeeMin = () => {
    setFilter("anchorElFeeMin", null);
  };

  const handleClickFeeMax = (event) => {
    setFilter("anchorElFeeMax", event.currentTarget);
  };
  const handleCloseFeeMax = () => {
    setFilter("anchorElFeeMax", null);
  };

  const handleClickRoyaltyCash = (event) => {
    setFilter("anchorElRoyaltyCash", event.currentTarget);
  };
  const handleCloseRoyaltyCash = () => {
    setFilter("anchorElRoyaltyCash", null);
  };

  const handleClickIndustry = (event) => {
    setFilter("anchorElIndustry", event.currentTarget);
  };
  const handleCloseIndustry = () => {
    setFilter("anchorElIndustry", null);
  };

  const handleCategoryChange = (event) => {
    setIndustryCategories({
      ...IndustryCategories,
      [event.target.name]: event.target.checked,
    });
  };

  const headCellData = [
    { id: "Franchise Name", label: "Franchise Name" },
    {
      id: "Request Insight", // New column for Request Insight
      label: "Request Insight",
      noSort: true,
      className: "request-insight-column",
    },
    {
      id: "Auto Apply",
      label: "Auto Apply",
      noSort: true,
      className: "auto-apply-column",
    },
    {
      id: "Request Expert", // New column for Request Expert
      label: "Request Expert",
      noSort: true,
      className: "speak-to-expert-column",
    },
    { id: "Industry", label: "Industry", noSort: true },
    {
      id: "Year Founded",
      label: "Year Founded",
      filter: {
        name: "Year Founded",
        minVal: filters?.minYearFounded,
        maxVal: filters?.maxYearFounded,
        minHook: setFilter,
        maxHook: setFilter,
        anchorEl: filters?.anchorElYear,
        id: idYear,
        open: openYear,
        forClick: handleClickYear,
        forClose: handleCloseYear,
      },
    },
    { id: "Company Website", label: "Site", noSort: true },
    {
      id: "Number of Franchisees",
      label: "Number of Locations",
      filter: {
        name: "locations",
        minVal: filters?.minLocations,
        maxVal: filters?.maxLocations,
        minHook: setFilter,
        maxHook: setFilter,
        anchorEl: filters?.anchorElLocations,
        id: idLocations,
        open: openLocations,
        forClick: handleClickLocations,
        forClose: handleCloseLocations,
      },
    },
    {
      id: "Initial Investment (Min)",
      label: "Min Init Investment",
      filter: {
        key: "Initial Investment (Min)",
        name: "Initial Investment",
        minVal: filters?.minInitialInvestmentMin,
        maxVal: filters?.maxInitialInvestmentMin,
        minHook: setFilter,
        maxHook: setFilter,
        anchorEl: filters?.anchorElInvestMin,
        id: idInvestMin,
        open: openInvestMin,
        forClick: handleClickInvestMin,
        forClose: handleCloseInvestMin,
      },
    },
    {
      id: "Initial Investment (Max)",
      label: "Max Init Investment",
      filter: {
        key: "Initial Investment (Max)",
        name: "Initial Investment",
        minVal: filters?.minInitialInvestmentMax,
        maxVal: filters?.maxInitialInvestmentMax,
        minHook: setFilter,
        maxHook: setFilter,
        anchorEl: filters?.anchorElInvestMax,
        id: idInvestMax,
        open: openInvestMax,
        forClick: handleClickInvestMax,
        forClose: handleCloseInvestMax,
      },
    },
    {
      id: "Initial Franchise Fee (Min)",
      label: "Franchise Fee (Min)",
      filter: {
        key: "Initial Franchise Fee (Min)",
        name: "Franchise Fee",
        minVal: filters?.minFranchiseFeeMin,
        maxVal: filters?.maxFranchiseFeeMin,
        minHook: setFilter,
        maxHook: setFilter,
        anchorEl: filters?.anchorElFeeMin,
        id: idFeeMin,
        open: openFeeMin,
        forClick: handleClickFeeMin,
        forClose: handleCloseFeeMin,
      },
    },
    {
      id: "Initial Franchise Fee (Max)",
      label: "Franchise Fee (Max)",
      filter: {
        key: "Initial Franchise Fee (Max)",
        name: "Franchise Fee",
        minVal: filters?.minFranchiseFeeMax,
        maxVal: filters?.maxFranchiseFeeMax,
        minHook: setFilter,
        maxHook: setFilter,
        anchorEl: filters?.anchorElFeeMax,
        id: idFeeMax,
        open: openFeeMax,
        forClick: handleClickFeeMax,
        forClose: handleCloseFeeMax,
      },
    },
    {
      id: "Royalty %",
      label: "Royalty Percent",
      filter: {
        name: "Royalty Percent",
        minVal: filters?.minRoyaltyPercent,
        maxVal: filters?.maxRoyaltyPercent,
        minHook: setFilter,
        maxHook: setFilter,
        anchorEl: filters?.anchorElRoyaltyPercent,
        id: idRoyaltyPercent,
        open: openRoyaltyPercent,
        forClick: handleClickRoyaltyPercent,
        forClose: handleCloseRoyaltyPercent,
      },
    },
    // {
    //   id: "Royalt - $/Mo",
    //   label: "Royalty Cash",
    //   filter: {
    //     name: "Royalty Cash",
    //     minVal: filters?.minRoyaltyCash,
    //     maxVal: filters?.maxRoyaltyCash,
    //     minHook: setFilter,
    //     maxHook: setFilter,
    //     anchorEl: filters?.anchorElRoyaltyCash,
    //     id: idRoyaltyCash,
    //     open: openRoyaltyCash,
    //     forClick: handleClickRoyaltyCash,
    //     forClose: handleCloseRoyaltyCash,
    //   },
    // },
    {
      id: "FDD Link",
      label: "Link to FDD",
      noSort: true,
      className: "FDD",
    },
  ];
  const headCellDataMob = [
    { id: "Franchise Name", label: "Franchise Name" },
    {
      id: "Auto Apply",
      label: "Auto Apply",
      noSort: true,
      className: "auto-apply-column",
    },
    { id: "Request Insight", label: "Request Insight", noSort: true }, // New for mobile
    { id: "Request Expert", label: "Request Expert", noSort: true },
    { id: "Industry", label: "Industry", noSort: true },
    {
      id: "Royalty %",
      label: "Royalty Percent",
      filter: {
        name: "Royalty Percent",
        minVal: filters?.minRoyaltyPercent,
        maxVal: filters?.maxRoyaltyPercent,
        minHook: setFilter,
        maxHook: setFilter,
        anchorEl: filters?.anchorElRoyaltyPercent,
        id: idRoyaltyPercent,
        open: openRoyaltyPercent,
        forClick: handleClickRoyaltyPercent,
        forClose: handleCloseRoyaltyPercent,
      },
    },
    {
      id: "FDD Link",
      label: "Link to FDD",
      noSort: true,
      className: "FDD",
    },
  ];

  const headCellDataToRender = isMobile ? headCellDataMob : headCellData;
  return (
    <>
      <TableHead
        style={stickyHeaderStyle}
        sx={{
          /* ... other existing styles */
          borderRadius: "16px",
          boxShadow: "2px 2px 5px rgba(0, 0, 0, 0.15)",
          background: "linear-gradient(to bottom, #f7f7f7 0%, #f0fafa 100%)",
        }}
      >
        <div
          style={{ display: "table-row" }}
          sx={{
            background: "linear-gradient(to bottom, #f7f7f7 0%, #f0fafa 100%)",
            "& > :last-child": {
              paddingRight: "0px !important", // Remove right padding for the last cell
            },
            "& > :first-child": {
              paddingLeft: "0px !important", // Remove left padding for the first cell
            },
            marginTop: "0px !important", // Remove top margin
            marginBottom: "0px !important", // Remove bottom margin
          }}
        >
          <TableCell
            style={{
              maxWidth: !isMobile ? "200px" : "100px", // Example maxWidth, adjust as needed
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              border: "none",
              padding: isMobile ? 2 : 15,
            }}
          ></TableCell>
          {headCellDataToRender.map((headCell) => (
            <TableCell
              key={headCell.id}
              style={{
                verticalAlign: "top",
                textAlign: "center",
                fontWeight: "bold",
                maxHeight: "4em", // Adjust based on your font-size to fit two lines
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                border: "none",
                padding: isMobile ? 2 : 15, // Remove all borders
                paddingLeft: isMobile
                  ? headCell.id === "Franchise Name"
                    ? "18px"
                    : 0
                  : headCell.id === "Franchise Name"
                  ? "15px"
                  : 0,
              }}
              sx={{
                fontWeight: "bold",
                color: "black",
                textAlign: headCell.id === "Franchise Name" ? "left" : "center",
                maxWidth: headCell.id === "Franchise Name" ? "none" : 200,
              }}
              className={headCell.className}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column", // Keep stacking vertically
                  alignItems:
                    headCell.id === "Franchise Name" ? "flex-start" : "center",
                  justifyContent:
                    headCell.id === "Franchise Name" ? "flex-start" : "center",
                  minHeight: "3em", // Adjust based on your font-size to fit two lines
                  fontSize: !isMobile ? 14 : 11,
                }}
              >
                {/* Splitting label into two parts */}
                <span>{headCell.label.split(" ")[0]}</span>
                <span>
                  {headCell.label.split(" ").slice(1).join(" ") || "\u00A0"}
                </span>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems:
                    headCell.id === "Franchise Name" ? "left" : "center",
                  justifyContent:
                    headCell.id === "Franchise Name" ? "left" : "center",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems:
                      headCell.id === "Franchise Name" ? "left" : "center",
                    justifyContent:
                      headCell.id === "Franchise Name" ? "left" : "center",
                  }}
                >
                  {!headCell.noSort && (
                    <TableSortLabel
                      active={orderedBy === headCell.id}
                      direction={
                        sortConfig?.key === headCell.id ? "asc" : "desc"
                      }
                      sx={{
                        "& .MuiSvgIcon-root": { color: "inherit" }, // Ensures icon inherits the text color for visibility
                      }}
                    >
                      <IconButton
                        onClick={() => requestSort(headCell.id)}
                        sx={{ padding: 0, marginLeft: 0, marginRight: 0 }}
                      >
                        <SortByAlphaIcon
                          style={{ fontSize: isMobile ? 18 : 25 }}
                        />
                      </IconButton>
                    </TableSortLabel>
                  )}
                  {headCell.filter && (
                    <>
                      <IconButton
                        onClick={headCell.filter.forClick}
                        sx={{
                          padding: 0,
                          marginLeft: "0px",
                          marginRight: "0px",
                        }} // Adjust margins to ensure icons are next to each other
                      >
                        <FilterAltIcon
                          color={
                            activeFilters[headCell.id] ? "primary" : "disabled"
                          }
                          style={{ fontSize: isMobile ? 18 : 25 }}
                        />
                      </IconButton>
                      <FilterPopover
                        headCell={headCell}
                        onFilterChange={handleFilter}
                      />
                    </>
                  )}
                </div>
              </div>
              {headCell.id === "Industry" && (
                <>
                  <TableSortLabel
                    active={orderedBy === headCell.id}
                    direction={
                      orderedBy === headCell.id ? sortConfig.direction : "asc"
                    }
                    sx={{
                      ".MuiSvgIcon-root": {
                        color: "inherit",
                        ml: 0,
                        mr: 0,
                        mb: 0,
                      },
                    }} // Adjust icon color and margin for visibility
                  >
                    <IconButton
                      onClick={() => requestSort(headCell.id)}
                      sx={{ padding: 0, ml: 0, mr: 0, mt: -1.2 }} // Remove margins to ensure icons are next to each other
                    >
                      <SortByAlphaIcon
                        style={{ fontSize: isMobile ? 18 : 25 }}
                      />
                    </IconButton>
                  </TableSortLabel>
                  <IconButton
                    onClick={handleClickIndustry}
                    sx={{ padding: 0, ml: 0, mr: 0, mt: -1.2 }} // Adjust margins as needed
                  >
                    <FilterAltIcon
                      color={
                        activeFilters[headCell.id] ? "primary" : "disabled"
                      }
                      style={{ fontSize: isMobile ? 18 : 25 }}
                    />
                  </IconButton>
                  <IndustryFilterPopover
                    handleFilter={handleFilter}
                    idIndustry={idIndustry}
                    anchorElIndustry={filters?.anchorElIndustry}
                    openIndustry={openIndustry}
                    handleCloseIndustry={handleCloseIndustry}
                    IndustryCategories={IndustryCategories}
                    handleCategoryChange={handleCategoryChange}
                    headCell={headCell}
                    clearIndustries={clearIndustries}
                  />
                </>
              )}
            </TableCell>
          ))}

          {isMobile ? <TableCell style={{ border: "none" }}></TableCell> : null}
        </div>
      </TableHead>
    </>
  );
};

export default memo(TableColumns);
