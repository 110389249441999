import React, { useState } from 'react'
import { TextField } from '@mui/material'
import { camelCase } from 'lodash'

const FieldComponent = ({ id, name, blur }) => {
  const [val, setVal] = useState('')
  const isMinInvestmentorFranchise =
    id === 'Initial Investment (Min)' || id === 'Initial Franchise Fee (Min)'
  const isMaxInvestmentorFranchise =
    id === 'Initial Investment (Max)' || id === 'Initial Franchise Fee (Max)'

  let updatedName = isMinInvestmentorFranchise
    ? `${name} Min`
    : isMaxInvestmentorFranchise
    ? `${name} Max`
    : name

  return (
    <TextField
      id={id}
      label={name}
      type="number"
      value={val}
      onChange={(e) => setVal(e.target.value)}
      onBlur={() => blur(camelCase(updatedName), val)}
      style={{ marginRight: 8 }}
    />
  )
}

export default FieldComponent
