// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAlJ6xY-fgolNK-t_H-uDcbshtE7r84nQo",
  authDomain: "franchise-5bd11.firebaseapp.com",
  projectId: "franchise-5bd11",
  storageBucket: "franchise-5bd11.appspot.com",
  messagingSenderId: "278628697467",
  appId: "1:278628697467:web:cf1386836cbcf2c3a8a9de",
  measurementId: "G-B0Z15ZRTS4",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

export default app;
