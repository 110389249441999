import React, { useState } from "react";
import {
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Snackbar,
  useTheme,
} from "@mui/material";

const FeedbackForm = () => {
  const theme = useTheme();
  const [findUs, setFindUs] = useState("");
  const [purpose, setPurpose] = useState("");
  const [showFindUsOtherReason, setShowFindUsOtherReason] = useState(false);
  const [showPurposeOtherReason, setShowPurposeOtherReason] = useState(false);
  const [showSuccessSnackbar, setShowSuccessSnackbar] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const response = await fetch("https://formspree.io/f/mvoeqpke", {
      method: "POST",
      body: formData,
      headers: {
        Accept: "application/json",
      },
    });
    if (response.ok) {
      // Successful submission
      console.log("Form submitted successfully");
      // Reset the form
      e.target.reset();
      setFindUs("");
      setPurpose("");
      setShowFindUsOtherReason(false);
      setShowPurposeOtherReason(false);
      setShowSuccessSnackbar(true);
    } else {
      // Handle error
      console.error("Form submission failed");
    }
  };

  const handleCloseSnackbar = () => {
    setShowSuccessSnackbar(false);
  };

  const handleFindUsChange = (e) => {
    const value = e.target.value;
    setFindUs(value);
    if (value === "Other") {
      setShowFindUsOtherReason(true);
    } else {
      setShowFindUsOtherReason(false);
    }
  };

  const handlePurposeChange = (e) => {
    const value = e.target.value;
    setPurpose(value);
    if (value === "Other") {
      setShowPurposeOtherReason(true);
    } else {
      setShowPurposeOtherReason(false);
    }
  };

  return (
    <form
      onSubmit={handleSubmit}
      style={{ maxWidth: 600, margin: "auto", padding: theme.spacing(3) }}
    >
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={showSuccessSnackbar}
        autoHideDuration={2000}
        onClose={handleCloseSnackbar}
        message="Suggestion submitted successfully!"
        sx={{
          "& .MuiSnackbarContent-root": {
            backgroundColor: theme.palette.success.main,
          },
        }}
      />
      <TextField
        label="Email"
        type="email"
        name="email"
        variant="outlined"
        fullWidth
        required
        margin="normal"
      />
      <FormControl fullWidth required margin="normal" variant="outlined">
        <InputLabel>How did you find us</InputLabel>
        <Select
          name="findUs"
          value={findUs}
          onChange={handleFindUsChange}
          label="How did you find us"
        >
          <MenuItem value="Google">Google</MenuItem>
          <MenuItem value="Community Forum">
            Community Forum (e.g., Reddit)
          </MenuItem>
          <MenuItem value="Social Media">Social Media</MenuItem>
          <MenuItem value="Word of Mouth">Word of Mouth</MenuItem>
          <MenuItem value="Other">Other</MenuItem>
        </Select>
      </FormControl>
      {showFindUsOtherReason && (
        <TextField
          label="Please specify"
          name="findUsOtherReason"
          variant="outlined"
          fullWidth
          margin="normal"
        />
      )}
      <FormControl fullWidth required margin="normal" variant="outlined">
        <InputLabel>Purpose</InputLabel>
        <Select
          name="purpose"
          value={purpose}
          onChange={handlePurposeChange}
          label="Purpose"
        >
          <MenuItem value="Want to start a franchise">
            Want to start a franchise
          </MenuItem>
          <MenuItem value="General research">General research</MenuItem>
          <MenuItem value="Other">Other</MenuItem>
        </Select>
      </FormControl>
      {showPurposeOtherReason && (
        <TextField
          label="Please specify"
          name="purposeOtherReason"
          variant="outlined"
          fullWidth
          margin="normal"
        />
      )}
      <TextField
        label="Feedback"
        name="feedback"
        variant="outlined"
        fullWidth
        multiline
        rows={4}
        margin="normal"
      />
      <Button
        type="submit"
        variant="contained"
        color="primary"
        sx={{ mt: 2, backgroundColor: "#0194d8" }} // Enhanced button styling
      >
        Submit
      </Button>
    </form>
  );
};

export default FeedbackForm;
