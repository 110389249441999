import React, { useState, useEffect } from "react";
import {
  Typography,
  Tab,
  Tabs,
  Box,
  Grid,
  Paper,
  List,
  ListItem,
  ListItemText,
  useTheme,
  AppBar,
  Toolbar,
  IconButton,
  Popover,
  MenuItem,
  useMediaQuery,
} from "@mui/material";
import { useAuth } from "./auth/Auth";
import { Menu } from "@mui/icons-material";
import EditApplyForm from "./EditApplyForm";
import { fetchAppliedFranchises } from "./components/ApplicationHandling";

const DashboardPage = () => {
  const [tabValue, setTabValue] = useState(0);
  const [appliedFranchises, setAppliedFranchises] = useState([]);
  const { currentUser } = useAuth();
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState(null);

  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
    setAnchorEl(null); // Close the dropdown menu when a tab is selected
  };

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    const fetchFranchises = async () => {
      const franchises = await fetchAppliedFranchises(currentUser);
      setAppliedFranchises(franchises);
    };
    fetchFranchises();
  }, [currentUser]);

  return (
    <>
      <Tabs
        value={tabValue}
        onChange={handleTabChange}
        indicatorColor="primary"
        textColor="primary"
        centered
      >
        <Tab label="Overview" />
        <Tab label="Edit Auto Apply Form" />
      </Tabs>
      {/* Placeholder to prevent content from being hidden under the AppBar */}
      <Grid
        container
        justifyContent="center"
        alignItems="flex-start"
        sx={{
          minHeight: "100vh",
          padding: theme.spacing(3),
        }}
      >
        <Grid item xs={12} sm={9} sx={{ maxWidth: 600 }}>
          <Box>
            {tabValue === 0 && (
              <Box
                p={3}
                sx={{
                  borderRadius: 4,
                  boxShadow: 2,
                  backgroundColor: "#f5f5f5",
                }}
              >
                <Typography variant="h5" gutterBottom>
                  Welcome {currentUser?.displayName || currentUser?.email}
                </Typography>
                <Typography variant="h6" gutterBottom>
                  You have auto applied to the following franchises:
                </Typography>
                <List>
                  {appliedFranchises.map((franchise, index) => (
                    <ListItem key={index}>
                      <ListItemText
                        primary={franchise}
                        style={
                          !isMobile ? { marginLeft: 40 } : { marginLeft: 0 }
                        }
                      />
                    </ListItem>
                  ))}
                </List>
              </Box>
            )}
            {tabValue === 1 && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Typography variant="h5" gutterBottom>
                  Current Application
                </Typography>
                <Paper
                  style={{
                    height: "70vh",
                    overflow: "auto",
                    width: "auto",
                    padding: theme.spacing(3),
                  }}
                >
                  <EditApplyForm />
                </Paper>
              </div>
            )}
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default DashboardPage;
