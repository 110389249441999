import React from 'react';
import { Box, Typography, Card, CardMedia, CardContent, Avatar } from '@mui/material';
import { Link } from "react-router-dom";
import CategoryLabel from './CategoryLabel';

export default function PostList({
    post,
    aspect,
    minimal,
    pathPrefix,
    preloadImage,
    fontSize,
    fontWeight,
    sx
}) {

    return (
        <Card
            className="overflow-hidden rounded-md transition-all hover:scale-105"
            sx={{
                ...sx,
                position: 'relative',
                overflow: 'hidden',

            }}
        >
            <Link
                to={`/blog/${pathPrefix ? `${pathPrefix}/` : ''}${post.slug.current}`}
                style={{ display: 'block', position: 'relative' }}
            >
                <CardMedia
                    component="img"
                    image={post.mainImage.src}
                    alt={post.mainImage?.alt || 'Thumbnail'}
                    sx={{
                        height: 200,
                        width: '100%',
                        objectFit: 'cover',
                        display: 'block',
                        borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
                        transition: 'transform 0.3s ease',
                        '&:hover': {
                            transform: 'scale(1.05)',
                        }
                    }}
                />
            </Link>
            <CardContent
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: 300, // Set your desired fixed height here
                    overflow: 'hidden',
                }}
            >
                <CategoryLabel categories={post.categories} nomargin={minimal} />

                <Typography
                    variant={fontSize === 'large' ? 'h4' : minimal ? 'h3' : 'h6'}
                    sx={{
                        fontWeight: fontWeight === 'normal' ? 400 : 600,
                        mt: 2,
                        color: 'text.primary',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        display: '-webkit-box',
                        WebkitBoxOrient: 'vertical',
                        WebkitLineClamp: 2,
                        maxWidth: '100%',
                    }}
                >
                    <Link
                        to={`/blog/${pathPrefix ? `${pathPrefix}/` : ''}${post.slug.current}`}
                        style={{ textDecoration: 'none', color: 'inherit' }}
                    >
                        {post.title}
                    </Link>
                </Typography>

                <Typography
                    variant="body2"
                    sx={{
                        fontWeight: 400,
                        mt: 1,
                        color: 'text.secondary',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        display: '-webkit-box',
                        WebkitBoxOrient: 'vertical',
                        WebkitLineClamp: 3,
                        maxWidth: '100%',
                    }}
                >
                    <div dangerouslySetInnerHTML={{ __html: post.shortBody }} />
                </Typography>

                <Box sx={{ mt: 'auto', display: 'flex', alignItems: 'center', gap: 2 }}>
                    {post?.author?.image ? (
                        <Link to={`/author/${post.author.slug.current}`}>
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                <Avatar
                                    src={post?.author?.image?.src}
                                    alt={post.author.name}
                                    sx={{ width: 24, height: 24 }}
                                />
                                <Typography variant="body2">
                                    {post.author.name}
                                </Typography>
                            </Box>
                        </Link>
                    ) : (
                        <Typography variant="body2">Author unknown</Typography>
                    )}
                    <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                        &bull;
                    </Typography>
                    <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                        {post.publishedAt || post._createdAt}
                    </Typography>
                </Box>
            </CardContent>
        </Card>
    );
}
