import React, { useState, useEffect } from "react";
import Data from "./data.js";
import FranchisorsTable from "./components/FranchisorsTable.jsx";
import PaginateFranchisors from "./components/PaginateFranchisors.jsx";
import SearchFranchisors from "./components/SearchFranchisors.jsx";
import {
  Container,
  Box,
  Typography,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";

function BookFranchisee() {
  const [FilteredFranchisors, setFilteredFranchisors] = useState(Data);
  const [CurrentFranchisors, setCurrentFranchisors] = useState([]);
  const [CurrentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentWeek, setCurrentWeek] = useState([]);
  const FranchisorsPerPage = 10;
  const theme = useTheme();

  const islarge = useMediaQuery(theme.breakpoints.up("lg"));

  const handleSearch = (value) => {
    setSearchTerm(value);
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setFilteredFranchisors(
        Data.filter((franchisor) =>
          franchisor.Franchisor.toLowerCase().includes(searchTerm.toLowerCase())
        )
      );
      setCurrentPage(1); // Reset to the first page on search
    }, 400); // 400 milliseconds debounce delay

    return () => clearTimeout(timer);
  }, [searchTerm]);

  const Paginate = (PageNumber) => {
    setCurrentPage(PageNumber);
  };

  const UpdateCurrentWeek = (week) => {
    setCurrentWeek(week);
  };

  useEffect(() => {
    const startIndex = (CurrentPage - 1) * FranchisorsPerPage;
    const lastIndex = startIndex + FranchisorsPerPage;
    setCurrentFranchisors(FilteredFranchisors.slice(startIndex, lastIndex));
  }, [CurrentPage, FilteredFranchisors]);

  return (
    <Container maxWidth="lg">
      <Box sx={{ my: 4 }}>
        <Typography
          sx={{ fontWeight: "bold", padding: "5px" }}
          variant="h5"
          component="h5"
          gutterBottom
        >
          Franchisee Experts
        </Typography>

        <Box sx={{ mt: 2, display: "flex", justifyContent: "space-between" }}>
          <SearchFranchisors handleSearch={handleSearch} />
          {islarge && (
            <Box
              sx={{
                backgroundColor: "#d7ecfd",
                width: "34% !important",
                width: "100%",
                maxWidth: "472px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Typography
                  variant="h6"
                  sx={{ textAlign: "center", fontWeight: "bold" }}
                >
                  Availability
                </Typography>
                <CalendarMonthIcon sx={{ fontSize: "20px" }} />
              </Box>
              <Typography
                sx={{
                  textAlign: "center",
                  fontStyle: "italic",
                  fontSize: "14px",
                  fontWeight: "bold",
                  color: "#455566",
                }}
              >
                week of {currentWeek}
              </Typography>
            </Box>
          )}
        </Box>

        <Box sx={{ mt: { sm: "15px", xs: "15px", md: "15px", lg: "0" } }}>
          <FranchisorsTable
            CurrentFranchisors={CurrentFranchisors}
            UpdateCurrentWeek={UpdateCurrentWeek}
            CurrentWeek={currentWeek}
          />
        </Box>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Box
            sx={{
              mt: 4,
              width: { xs: "100%", sm: "100%", md: "100%", lg: "66%" },
            }}
          >
            <PaginateFranchisors
              TotalFranchisors={FilteredFranchisors.length}
              FranchisorsPerPage={FranchisorsPerPage}
              Paginate={Paginate}
            />
          </Box>
          {islarge && (
            <Box
              sx={{
                width: "100%",
                maxWidth: "472px",
                backgroundColor: "#d7ecfd",
              }}
            ></Box>
          )}
        </Box>
      </Box>
    </Container>
  );
}

export default BookFranchisee;
