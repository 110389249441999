import { useState } from 'react'
import localData from '../data.json'

const initialFilterState = {
  anchorElLocations: null,
  minLocations: '',
  maxLocations: '',
  anchorElYear: null,
  minYearFounded: '',
  maxYearFounded: '',
  anchorElRoyaltyPercent: null,
  minRoyaltyPercent: '',
  maxRoyaltyPercent: '',
  anchorElInvestMin: null,
  minInitialInvestmentMin: '',
  minInitialInvestmentMax: '',
  anchorElInvestMax: null,
  maxInitialInvestmentMin: '',
  maxInitialInvestmentMax: '',
  anchorElFeeMin: null,
  minFranchiseFeeMin: '',
  maxFranchiseFeeMin: '',
  anchorElFeeMax: null,
  minFranchiseFeeMax: '',
  maxFranchiseFeeMax: '',
  anchorElRoyaltyCash: null,
  minRoyaltyCash: '',
  maxRoyaltyCash: '',
  anchorElIndustry: null,
}
const initialIndustryCategories = {
  'Food & Beverage': true,
  'Professional Services': true,
  'Lodging & Leisure': true,
  Other: true,
  'Health & Wellness': true,
  Retail: true,
  'Home, Cleaning, and Repair': true,
  'Auto & Travel': true,
  'Hair & Beauty': true,
  'Sports & Entertainment': true,
  Childrens: true,
  Pets: true,
  'Tech & IT': true,
}
const initialActiveFilters = {
  'Number of Franchisees': false,
  'Year Founded': false,
  'Royalty %': false,
  'Initial Investment (Min)': false,
  'Initial Investment (Max)': false,
  'Franchise Fee (Min)': false,
  'Franchise Fee (Max)': false,
  'Royalty - $/Mo': false,
  Industry: false,
}

const useFilters = () => {
  const [filters, setFilters] = useState(initialFilterState)
  const [franchises, setFranchises] = useState([])
  const [IndustryCategories, setIndustryCategories] = useState(
    initialIndustryCategories
  )
  const [activeFilters, setActiveFilters] = useState(initialActiveFilters)
  const setFilter = (filterName, value) => {
    setFilters((prevFilters) => ({ ...prevFilters, [filterName]: value }))
  }
  const clearFilters = () => {
    setFilters(initialFilterState)
    setIndustryCategories(initialIndustryCategories)
    setActiveFilters(initialActiveFilters)
  }

  const handleFilter = (filterName) => {
    setActiveFilters((prev) => ({
      ...prev,
      [filterName]: true,
    }))
    let filteredFranchises = localData.filter((franchise) => {
      const numLocations = parseFloat(franchise['Number of Franchisees'])
      const yearFounded = parseFloat(franchise['Year Founded'])
      const numRoyaltyPercent = parseFloat(franchise['Royalty %'] * 100)
      const numInvestMin = parseInt(franchise['Initial Investment (Min)'])
      const numInvestMax = parseInt(franchise['Initial Investment (Max)'])
      const numFeeMin = parseInt(franchise['Initial Franchise Fee (Min)'])
      const numFeeMax = parseInt(franchise['Initial Franchise Fee (Max)'])
      const numRoyaltyCash = parseFloat(franchise['Royalt - $/Mo'])
      const industryCategory = franchise['Industry']
      const meetsYearFoundedCriteria =
        (!filters?.minYearFounded || yearFounded >= filters?.minYearFounded) &&
        (!filters?.maxYearFounded || yearFounded <= filters?.maxYearFounded)
      const meetsLocationCriteria =
        (!filters?.minLocations || numLocations >= filters?.minLocations) &&
        (!filters?.maxLocations || numLocations <= filters?.maxLocations)
      const meetsRoyaltyPercent =
        (!filters?.minRoyaltyPercent ||
          numRoyaltyPercent >= filters?.minRoyaltyPercent) &&
        (!filters?.maxRoyaltyPercent ||
          numRoyaltyPercent <= filters?.maxRoyaltyPercent)
      const meetsInvestMin =
        (!filters?.minInitialInvestmentMin ||
          numInvestMin >= filters?.minInitialInvestmentMin) &&
        (!filters?.maxInitialInvestmentMin ||
          numInvestMin <= filters?.maxInitialInvestmentMin)
      const meetsInvestMax =
        (!filters?.minInitialInvestmentMax ||
          numInvestMax >= filters?.minInitialInvestmentMax) &&
        (!filters?.maxInitialInvestmentMax ||
          numInvestMax <= filters?.maxInitialInvestmentMax)
      const meetsFeeMin =
        (!filters?.minFranchiseFeeMin ||
          numFeeMin >= filters?.minFranchiseFeeMin) &&
        (!filters?.maxFranchiseFeeMin ||
          numFeeMin <= filters?.maxFranchiseFeeMin)
      const meetsFeeMax =
        (!filters?.minFranchiseFeeMax ||
          numFeeMax >= filters?.minFranchiseFeeMax) &&
        (!filters?.maxFranchiseFeeMax ||
          numFeeMax <= filters?.maxFranchiseFeeMax)
      const meetsRoyaltyCash =
        (!filters?.minRoyaltyCash ||
          numRoyaltyCash >= filters?.minRoyaltyCash) &&
        (!filters?.maxRoyaltyCash || numRoyaltyCash <= filters?.maxRoyaltyCash)
      const meetsIndustryCategory = IndustryCategories[industryCategory]
      return (
        meetsLocationCriteria &&
        meetsYearFoundedCriteria &&
        meetsRoyaltyPercent &&
        meetsInvestMin &&
        meetsInvestMax &&
        meetsFeeMin &&
        meetsFeeMax &&
        meetsRoyaltyCash &&
        meetsIndustryCategory
      )
    })
    setFranchises(filteredFranchises)
  }

  return {
    filters,
    setFilter,
    clearFilters,
    activeFilters,
    setActiveFilters,
    IndustryCategories,
    setIndustryCategories,
    handleFilter,
    franchises,
    setFranchises,
  }
}

export default useFilters
