import React, { useState } from "react";
import {
  Button,
  Popover,
  Typography,
  TextField,
  Stack,
  Switch,
  FormControlLabel,
  IconButton,
  Snackbar,
  Alert,
  Link,
  Box,
} from "@mui/material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import GoogleIcon from "@mui/icons-material/Google"; // For the Google sign-in icon
import { useAuth } from "./Auth";
import ChangePasswordDialog from "./ChangePasswordDialog";

const LoginPopover = ({ navigateToDashboard, navigateHome }) => {
  const {
    currentUser,
    signInWithGoogle,
    signInWithEmail,
    signUpWithEmail,
    logout,
  } = useAuth();
  const [anchorEl, setAnchorEl] = useState(null);
  const [isSignUp, setIsSignUp] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [showChangePasswordDialog, setShowChangePasswordDialog] =
    useState(false);
  const id = "login-popover";
  const showSnackbar = (message) => {
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const handleToggleSignUp = () => setIsSignUp(!isSignUp);
  const handleLoginSignUp = async () => {
    try {
      const message = isSignUp
        ? "Sign-up successful! You can now log in."
        : "Login successful!";
      if (isSignUp) {
        await signUpWithEmail(email, password);
      } else {
        await signInWithEmail(email, password);
      }
      showSnackbar(message);
      handleClose(); // Close the popover
    } catch (error) {
      showSnackbar(`Authentication Failed: ${error.message}`);
    }
  };

  const handleLoginSignUpWithGoogle = async () => {
    try {
      await signInWithGoogle();
      showSnackbar("Google Authentication Successful");
      handleClose(); // Close the popover
    } catch (error) {
      showSnackbar(`Google Authentication Failed: ${error.message}`);
    }
  };

  const handleLogout = async () => {
    try {
      await logout();
      showSnackbar("Logged out successfully.");
      navigateHome();
    } catch (error) {
      showSnackbar(`Logout Failed: ${error.message}`);
    }
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box>
      {currentUser ? (
        <>
          <IconButton
            color="inherit"
            onClick={navigateToDashboard}
            sx={{ marginTop: 2 }}
          >
            <AccountCircleIcon />
          </IconButton>
          <Button color="inherit" onClick={handleLogout} sx={{ marginTop: 2 }}>
            Logout
          </Button>
        </>
      ) : (
        <Button
          aria-describedby={id}
          color="inherit"
          onClick={handleClick}
          sx={{
            width: "fit-content",
            "@media (max-width:600px)": {
              fontSize: "10px",
              width: "100%",
              border: "1px solid white",
            },
          }}
        >
          Login/Signup
        </Button>
      )}
      <Popover
        id="login-popover"
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Stack spacing={2} sx={{ p: 2 }}>
          {!currentUser && (
            <>
              <Button
                startIcon={<GoogleIcon />}
                variant="contained"
                onClick={handleLoginSignUpWithGoogle}
                fullWidth
              >
                Sign In/Up with Google
              </Button>
              <FormControlLabel
                control={
                  <Switch
                    checked={isSignUp}
                    onChange={(e) => setIsSignUp(e.target.checked)}
                  />
                }
                label={
                  isSignUp
                    ? "Already have an account? Sign in"
                    : "Don't have an account? Sign up"
                }
              />
              <TextField
                label="Email"
                variant="outlined"
                fullWidth
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <TextField
                label="Password"
                variant="outlined"
                type="password"
                fullWidth
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <Typography textAlign="center" sx={{ mt: 2 }}>
                <Link
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                    setShowChangePasswordDialog(true);
                  }}
                  style={{ cursor: "pointer" }}
                >
                  Forgot password?
                </Link>
              </Typography>
              <Button variant="contained" onClick={handleLoginSignUp} fullWidth>
                {isSignUp ? "Sign Up with Email" : "Sign In with Email"}
              </Button>
              <ChangePasswordDialog
                open={showChangePasswordDialog}
                handleClose={() => setShowChangePasswordDialog(false)}
              />
            </>
          )}
        </Stack>
      </Popover>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity="info"
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default LoginPopover;
