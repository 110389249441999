import React, { useState, useEffect, useMemo } from "react";
import {
  Typography,
  useTheme,
  useMediaQuery,
  Table,
  TableHead,
  TableRow,
  Dialog,
  Box,
  DialogActions,
  TableCell,
  TableBody,
  Button,
  Snackbar,
} from "@mui/material";
import DoneIcon from "@mui/icons-material/Done";
import ClearIcon from "@mui/icons-material/Clear";
import CancelIcon from "@mui/icons-material/Cancel";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { loadStripe } from "@stripe/stripe-js";

import { useAuth } from "../auth/Auth";

const postURL =
  "https://us-central1-franchise-5bd11.cloudfunctions.net/app/send-email";
const contactEmail = "contact@FranchiseNow.me";
const stripePromise = loadStripe(
  "pk_live_51MK83mI0sGpaLBF2hF85MwnBdE7EmnGYptNM2BvGELz2iNblDJuljiov0D0B1QF0u6IWUia8yeQN136UvZsA2CAZ00Lt8hHfQU"
);

function FranchisorsTable({
  CurrentFranchisors,
  UpdateCurrentWeek,
  CurrentWeek,
}) {
  const { currentUser } = useAuth();
  const [currentDates, setCurrentDates] = useState({});
  const [currentWeekKey, setCurrentWeekKey] = useState(1);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [clickedFranchisorID, setClickedFranchisor] = useState(null);
  const [selectedFranchisor, setSelectedFranchisor] = useState({});
  const [isCalenderOpened, setIsCalenderOpened] = useState(false);
  const [selectedTimeSlot, setSelectedTimeSlot] = useState(null);
  const [scheduleBooked, setScheduleBooked] = useState(false);
  const [loading, setLoading] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const theme = useTheme();
  const isLarge = useMediaQuery(theme.breakpoints.up("lg"));
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const getLogo = (franchiseName) => {
    const downloadPath = "/logos/";
    const sanitized_name = franchiseName.replace(/\//g, "").replace(/\\/g, "");
    const fileName = `${sanitized_name}.jpg`;
    return `${downloadPath}${fileName}`;
  };
  // Generate the weekly calendar
  const weeks = useMemo(() => generateWeeklyCalendar(), []);

  useEffect(() => {
    if (currentWeekKey < 1 || currentWeekKey > Object.keys(weeks).length) {
      setCurrentWeekKey(1);
    } else {
      const currentWeek = Object.keys(weeks)[currentWeekKey - 1];
      UpdateCurrentWeek(currentWeek);
      setCurrentDates(weeks[currentWeek]);
    }
  }, [currentWeekKey, weeks]);

  function generateWeeklyCalendar() {
    const today = new Date();
    const todayDayIndex = today.getDay();

    const daysToSubtract = (todayDayIndex + 6) % 7;
    const mostRecentMonday = new Date(today);
    mostRecentMonday.setDate(today.getDate() - daysToSubtract);

    const days = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];
    const weeks = {};
    let currentDate = new Date(mostRecentMonday);

    for (let i = 0; i < 5; i++) {
      const weekStartDate = new Date(currentDate);
      const week = [];
      for (let j = 0; j < 7; j++) {
        const dayStr = currentDate.getDate();
        const monthStr = currentDate.toLocaleString("default", {
          month: "short",
        });
        week.push({ day: days[j], date: dayStr, month: monthStr });
        currentDate.setDate(currentDate.getDate() + 1);
      }
      const weekStartStr = `${weekStartDate.getDate()}-${weekStartDate.toLocaleString(
        "default",
        { month: "short" }
      )}-${weekStartDate.getFullYear()}`;
      weeks[weekStartStr] = week;
    }

    return weeks;
  }

  const formatTimeSlot = (month, day, timeslot) => {
    const ending = day === 1 ? "st" : day === 2 || day === 3 ? "rd" : "th";
    return month + " " + day + ending + ", " + timeslot;
  };

  const changeWeek = (offset) => {
    setCurrentWeekKey(currentWeekKey + offset);
  };

  const openModal = (timeSlot, Franchisor) => {
    setSelectedFranchisor(Franchisor);
    setSelectedTimeSlot(timeSlot);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const openCalenderModal = (id) => {
    setClickedFranchisor(id);
    setIsCalenderOpened(true);
  };

  const closeCalnederModal = () => {
    setIsCalenderOpened(false);
  };

  const handleCheckout = async (franchiseDetails) => {
    setLoading(true);
    const { name, price, franchisee, timeSlot } = franchiseDetails;
    // Store franchiseDetails in local storage
    localStorage.setItem("franchiseDetails", JSON.stringify(franchiseDetails));

    try {
      const stripe = await stripePromise;
      const { error } = await stripe.redirectToCheckout({
        lineItems: [{ price: "price_1PWVjUI0sGpaLBF2ZJCots3u", quantity: 1 }],
        mode: "payment",
        successUrl: `${window.location.origin}/success`,
        cancelUrl: `${window.location.origin}/`,
        clientReferenceId: `${name} - ${franchisee} "time" - ${timeSlot}`,
      });

      if (error) {
        console.error("Error during checkout: ", error);
        setLoading(false);
      }
    } catch (error) {
      console.error("Error initializing Stripe: ", error);
      setLoading(false);
    }
  };

  const handleConfirm = (expert, timeSlot) => {
    const franchiseDetails = {
      name: selectedFranchisor.Name,
      franchisee: selectedFranchisor.Franchisee,
      timeSlot: timeSlot,
      price: `$${selectedFranchisor.Hourly_Rate}`,
      franchisorEmail: selectedFranchisor.Email, // Add this line
    };
    handleCheckout(franchiseDetails);
  };

  const isDateDisabled = (date) => {
    const today = new Date();
    const targetDate = new Date(today);
    targetDate.setDate(today.getDate() + 1); // Day after tomorrow

    // Assuming date has { day, date, month } format
    const monthIndex = new Date(
      Date.parse(date.month + " 1, " + today.getFullYear())
    ).getMonth();
    const slotDate = new Date(today.getFullYear(), monthIndex, date.date);

    return slotDate < targetDate;
  };

  return (
    <>
      <Table sx={{ margin: "0 !important", boxShadow: "none" }}>
        <TableHead>
          <TableRow>
            <TableCell
              sx={{
                padding: { sm: " 10px 10px 10px 4px", xs: "8px" },
                backgroundColor: "transparent",
                border: "none",
                textAlign: "left",
                wordBreak: { xs: "break-word", sm: "break-word" },
                fontWeight: "bold",
                fontSize: {
                  xs: "12px",
                  sm: "12",
                  md: "18px",
                  lg: "18px",
                  xl: "18px",
                },
              }}
            >
              Franchisee
            </TableCell>
            <TableCell
              sx={{
                padding: { sm: "10px", xs: "8px" },
                backgroundColor: "transparent",
                border: "none",
                textAlign: "center",
                wordBreak: { xs: "break-word", sm: "break-word" },
                fontWeight: "bold",
                fontSize: {
                  xs: "12px",
                  sm: "12",
                  md: "18px",
                  lg: "18px",
                  xl: "18px",
                },
              }}
            >
              Franchisor
            </TableCell>
            <TableCell
              sx={{
                padding: { sm: "10px", xs: "8px" },
                backgroundColor: "transparent",
                border: "none",
                textAlign: "left",
                wordBreak: { xs: "break-word", sm: "break-word" },
                fontWeight: "bold",
                fontSize: {
                  xs: "12px",
                  sm: "12",
                  md: "18px",
                  lg: "18px",
                  xl: "18px",
                },
              }}
            >
              Experience
            </TableCell>
            <TableCell
              sx={{
                padding: { sm: "10px", xs: "8px" },
                backgroundColor: "transparent",
                border: "none",
                textAlign: "left",
                wordBreak: { xs: "break-word", sm: "break-word" },
                fontWeight: "bold",
                fontSize: {
                  xs: "12px",
                  sm: "12",
                  md: "18px",
                  lg: "18px",
                  xl: "18px",
                },
              }}
            >
              Multi Site
            </TableCell>
            <TableCell
              sx={{
                padding: { sm: "10px", xs: "8px" },
                backgroundColor: "transparent",
                border: "none",
                textAlign: "left",
                wordBreak: { xs: "break-word", sm: "break-word" },
                fontWeight: "bold",
                fontSize: {
                  xs: "12px",
                  sm: "12",
                  md: "18px",
                  lg: "18px",
                  xl: "18px",
                },
              }}
            >
              Hourly Rate
            </TableCell>

            {isLarge ? (
              <>
                <TableCell
                  sx={{
                    backgroundColor: "#d7ecfd",
                    border: "none",
                    width: "3% !important",
                    padding: "0",
                  }}
                >
                  <Button
                    sx={{ color: "black", minWidth: "10px", fontSize: "20px" }}
                    disabled={currentWeekKey === 1}
                    onClick={() => {
                      changeWeek(-1);
                    }}
                  >
                    {" "}
                    &lt;{" "}
                  </Button>
                </TableCell>
                {Object.values(currentDates).map((date) => (
                  <TableCell
                    sx={{
                      backgroundColor: "#d7ecfd",
                      border: "none",
                      width: "4% !important",
                      padding: "0",
                    }}
                    key={date.date}
                  >
                    <Typography sx={{ textAlign: "center", fontSize: "12px" }}>
                      {date.day}{" "}
                    </Typography>
                    <Typography sx={{ textAlign: "center", fontSize: "12px" }}>
                      {date.date}{" "}
                    </Typography>
                  </TableCell>
                ))}
                <TableCell
                  sx={{
                    backgroundColor: "#d7ecfd",
                    border: "none",
                    width: "3% !important",
                    padding: "0",
                  }}
                >
                  <Button
                    sx={{ color: "black", minWidth: "20px", fontSize: "20px" }}
                    disabled={currentWeekKey === 5}
                    onClick={() => {
                      changeWeek(+1);
                    }}
                  >
                    &gt;
                  </Button>
                </TableCell>
              </>
            ) : (
              <TableCell
                sx={{
                  padding: { sm: "10px", xs: "8px" },
                  backgroundColor: "transparent",
                  border: "none",
                  wordBreak: { xs: "break-word", sm: "break-word" },
                  fontWeight: "bold",
                  fontSize: {
                    xs: "12px",
                    sm: "12",
                    md: "18px",
                    lg: "18px",
                    xl: "18px",
                  },
                }}
              >
                Book Slot
              </TableCell>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {CurrentFranchisors.map((CurrentFranchisor, index) => (
            <TableRow key={index}>
              <TableCell
                sx={{
                  padding: { sm: "10px 10px 10px 4px", xs: "8px" },
                  border: "none",
                  verticalAlign: { lg: "super !important" },
                }}
              >
                {CurrentFranchisor.Franchisee}
              </TableCell>
              <TableCell
                sx={{
                  padding: { sm: "10px", xs: "8px" },
                  border: "none",
                  verticalAlign: { lg: "super !important" },
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    gap: 1,
                  }}
                >
                  <Typography>{CurrentFranchisor.Franchisor}</Typography>
                  <img
                    height={"50px"}
                    src={getLogo(CurrentFranchisor.Franchisor)}
                    alt={CurrentFranchisor.Franchisor}
                    style={{
                      width: isMobile ? "40px" : "100px",
                      height: isMobile ? "24px" : "60px",
                      objectFit: "contain",
                    }}
                  />
                </Box>
              </TableCell>
              <TableCell
                sx={{
                  padding: { sm: "10px", xs: "8px" },
                  border: "none",
                  verticalAlign: { lg: "super !important" },
                  textAlign: "center",
                }}
              >
                {" "}
                {CurrentFranchisor.Experience + " years"}{" "}
              </TableCell>
              <TableCell
                sx={{
                  padding: { sm: "10px", xs: "8px" },
                  border: "none",
                  verticalAlign: { lg: "super !important" },
                  textAlign: "left",
                }}
              >
                {" "}
                {CurrentFranchisor.Multi_site ? (
                  <DoneIcon
                    sx={{
                      marginLeft: { sm: "15px", md: "28px" },
                      marginBottom: -1.2,
                    }}
                  />
                ) : (
                  <ClearIcon
                    sx={{
                      marginLeft: { sm: "15px", md: "28px" },
                      marginBottom: -1.2,
                    }}
                  />
                )}{" "}
              </TableCell>
              <TableCell
                sx={{
                  padding: { sm: "10px", xs: "8px" },
                  border: "none",
                  verticalAlign: { lg: "super !important" },
                  textAlign: "center",
                  textWrap: "nowrap",
                }}
              >
                {"$" + CurrentFranchisor.Hourly_Rate}
              </TableCell>
              {isLarge ? (
                <>
                  <TableCell
                    sx={{
                      backgroundColor: "#d7ecfd",
                      border: "none",
                      width: "10px !important",
                      padding: "0",
                    }}
                  ></TableCell>
                  {Object.values(currentDates).map((date) => (
                    <TableCell
                      sx={{
                        backgroundColor: "#d7ecfd",
                        border: "none",
                        width: "5% !important",
                        padding: "2px",
                      }}
                      key={date.date}
                    >
                      {(() => {
                        const isDisabled = isDateDisabled(date);
                        return (
                          CurrentFranchisor.availability[date.day] &&
                          CurrentFranchisor.availability[date.day].map(
                            (timeslot, idx) => (
                              <Button
                                key={idx}
                                sx={{
                                  color: isDisabled
                                    ? "gray !important"
                                    : index % 2 === 0
                                    ? "#0098dc !important"
                                    : "#007bbd !important",
                                  fontWeight: "600",
                                  minWidth: "100%",
                                  fontSize: "11px",
                                  verticalAlign: "middle ",
                                  padding: "2px",
                                }}
                                onClick={() => {
                                  if (!isDisabled) {
                                    openModal(
                                      date.month +
                                        " " +
                                        date.date +
                                        "th, " +
                                        timeslot,
                                      CurrentFranchisor
                                    );
                                  }
                                }}
                                disabled={isDisabled}
                              >
                                {timeslot}
                              </Button>
                            )
                          )
                        );
                      })()}
                    </TableCell>
                  ))}

                  <TableCell
                    sx={{ backgroundColor: "#d7ecfd", border: "none" }}
                  ></TableCell>
                </>
              ) : (
                <TableCell
                  sx={{ border: "none", padding: { sm: "1px", xs: "1px" } }}
                >
                  <Button
                    sx={{ fontSize: "12px" }}
                    onClick={() => {
                      openCalenderModal(CurrentFranchisor.id);
                    }}
                  >
                    {" "}
                    Book Slot
                  </Button>
                </TableCell>
              )}
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Dialog open={isModalOpen} onClose={closeModal}>
        <Box sx={{ backgroundColor: "#f3f3f3", padding: "15px" }}>
          <Box sx={{ backgroundColor: "#e1e1e1", padding: "10px" }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Box>
                <Typography
                  sx={{
                    fontWeight: "bold",
                    padding: "5px 15px 5px 5px",
                    fontSize: "16px",
                  }}
                >
                  SEND BOOKING REQUEST?
                </Typography>
              </Box>
              <Box>
                <CancelIcon
                  sx={{ cursor: "pointer", color: "#747474" }}
                  onClick={closeModal}
                />
              </Box>
            </Box>

            <Box>
              <Typography sx={{ fontSize: "12px", padding: "5px 15px" }}>
                {selectedFranchisor.Franchisee}
              </Typography>
              <Typography sx={{ fontSize: "12px", padding: "5px 15px" }}>
                {selectedFranchisor.Franchisor}
              </Typography>
              <Typography sx={{ fontSize: "12px", padding: "5px 15px" }}>
                {selectedTimeSlot}
              </Typography>
            </Box>

            <DialogActions
              sx={{ justifyContent: "start", padding: "15px  12px  5px 12px" }}
            >
              <Button
                sx={{
                  backgroundColor: "white",
                  color: "#000",
                  padding: "5px 30px",
                  border: "1px solid",
                  borderRadius: "15px",
                  fontSize: "10px",
                }}
                onClick={(selectedFranchisor) =>
                  handleConfirm(selectedFranchisor, selectedTimeSlot)
                }
              >
                {loading ? "Redirecting to Checkout..." : "Confirm"}
              </Button>
            </DialogActions>
          </Box>
        </Box>
      </Dialog>

      {/* Success Snackbar */}
      <Snackbar
        open={scheduleBooked}
        autoHideDuration={3000}
        onClose={() => setScheduleBooked(false)}
        message="Booked successfully!"
        sx={{
          "& .MuiSnackbarContent-root": {
            backgroundColor: "green", // Background color green
            color: "white", // Text color white
          },
        }}
      />

      <Dialog open={isCalenderOpened} onClose={closeCalnederModal}>
        <Box sx={{ width: { xs: "360px", sm: "360px", md: "450px" } }}>
          <Box sx={{ backgroundColor: "#d7ecfd", width: "100% !important" }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography
                variant="h6"
                sx={{ textAlign: "center", fontWeight: "bold" }}
              >
                Availability
              </Typography>
              <CalendarMonthIcon sx={{ fontSize: "20px" }} />
            </Box>
            <Typography
              sx={{
                textAlign: "center",
                fontStyle: "italic",
                fontSize: "14px",
                fontWeight: "bold",
                color: "#c1cbd5",
              }}
            >
              week of {CurrentWeek}
            </Typography>
          </Box>

          <Table
            sx={{ width: "100%", margin: "0", backgroundColor: "#d7ecfd" }}
          >
            <TableHead>
              <TableRow>
                <TableCell
                  sx={{
                    backgroundColor: "#d7ecfd",
                    border: "none",
                    width: "3% !important",
                    padding: "0",
                  }}
                >
                  <Button
                    sx={{ color: "black", minWidth: "10px", fontSize: "20px" }}
                    disabled={currentWeekKey === 1}
                    onClick={() => {
                      changeWeek(-1);
                    }}
                  >
                    {" "}
                    &lt;{" "}
                  </Button>
                </TableCell>
                {Object.values(currentDates).map((date) => (
                  <TableCell
                    sx={{
                      backgroundColor: "#d7ecfd",
                      border: "none",
                      width: "8% !important",
                      padding: "0",
                    }}
                    key={date.date}
                  >
                    <Typography sx={{ textAlign: "center", fontSize: "12px" }}>
                      {date.day}{" "}
                    </Typography>
                    <Typography sx={{ textAlign: "center", fontSize: "12px" }}>
                      {date.date}{" "}
                    </Typography>
                  </TableCell>
                ))}
                <TableCell
                  sx={{
                    backgroundColor: "#d7ecfd",
                    border: "none",
                    width: "8% !important",
                    padding: "0",
                  }}
                >
                  <Button
                    sx={{ color: "black", minWidth: "20px", fontSize: "20px" }}
                    disabled={currentWeekKey === 5}
                    onClick={() => {
                      changeWeek(+1);
                    }}
                  >
                    &gt;
                  </Button>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell
                  sx={{
                    backgroundColor: "#d7ecfd",
                    border: "none",
                    width: "10px !important",
                    padding: "0",
                  }}
                ></TableCell>
                {Object.values(currentDates).map((date) => (
                  <TableCell
                    sx={{
                      backgroundColor: "#d7ecfd",
                      border: "none",
                      width: "4% !important",
                      padding: "2px",
                    }}
                    key={date.date}
                  >
                    {(() => {
                      if (
                        clickedFranchisorID !== null &&
                        clickedFranchisorID !== undefined
                      ) {
                        const CurrentFranchisor = CurrentFranchisors.find(
                          (franchise) => franchise.id === clickedFranchisorID
                        );
                        if (
                          CurrentFranchisor &&
                          CurrentFranchisor.availability
                        ) {
                          const isDisabled = isDateDisabled(date);
                          return CurrentFranchisor.availability[date.day]?.map(
                            (timeslot, idx) => (
                              <Button
                                key={idx}
                                sx={{
                                  color: isDisabled
                                    ? "gray !important"
                                    : "#0098dc !important",
                                  fontWeight: "600",
                                  minWidth: "100%",
                                  fontSize: "11px",
                                  padding: "2px",
                                }}
                                onClick={() => {
                                  if (!isDisabled) {
                                    openModal(
                                      formatTimeSlot(
                                        date.month,
                                        date.date,
                                        timeslot
                                      ),
                                      CurrentFranchisor
                                    );
                                  }
                                }}
                                disabled={isDisabled}
                              >
                                {timeslot}
                              </Button>
                            )
                          );
                        }
                      }
                      return null;
                    })()}
                  </TableCell>
                ))}
                <TableCell
                  sx={{ backgroundColor: "#d7ecfd", border: "none" }}
                ></TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Box>
      </Dialog>
    </>
  );
}

export default FranchisorsTable;
