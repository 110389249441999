import { TextField, InputAdornment, useTheme, useMediaQuery } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";

const SearchFranchisors = ({handleSearch}) => {
  const theme = useTheme(); // Using theme for consistent styling
  const isMobile = useMediaQuery(theme.breakpoints.down("sm")); // Check if screen size is mobile

  return (
    <TextField
      placeholder=  "Search by franchisor"
      variant="outlined"
      onChange={(e) => handleSearch(e.target.value)}
      sx={{

        width: isMobile ? 200 : 230, // Adjust width as needed
        fontSize: isMobile ? 6 : 12,
        "@media (max-width:600px)": {
          width: isMobile ? 200 : "100%",
        },

        ".MuiOutlinedInput-root": {
          height: "auto", // Let height adjust based on content and padding
          borderRadius: "25px", // More pronounced rounded corners
          backgroundColor: "#f2f2f2",
          "&:hover": {
            backgroundColor: "#f5f5f5",
          },
          "&.Mui-focused": {
            backgroundColor: "#f2f2f2",
            borderColor: "#1976d2",
          },
        },
        ".MuiInputLabel-root": {
          color: "gray",
          "&.Mui-focused": {
            color: "#000",
          },
        },
        ".MuiInputBase-input": {
          padding:  '10px 8px 10px 0', // More generous padding for a spacious feel
          fontSize: isMobile && '13px'
          // Consider adjusting font size if necessary
        },
      }}
      // Adding a start adornment for a search icon
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon
              sx={{
                color: "action.active",
                mr: isMobile ? 0 : 1,
                my: isMobile ? 0 : 0.5,
              }}
            />
          </InputAdornment>
        ),
      }}
    />
  );
};

export default SearchFranchisors;
