import React, { useState } from "react";
import { getDatabase, ref, push, set } from "firebase/database";
import { useAuth } from "../auth/Auth";
import {
  Card,
  CardContent,
  TextField,
  Button,
  Typography,
  Box,
  LinearProgress,
  Stack,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Snackbar,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import Rating from "@mui/material/Rating";
import { styled } from "@mui/system";

const CurrencyTextField = ({ label, name, value, onChange }) => {
  // Corrected handleChange to directly use event without destructuring
  const handleChange = (event) => {
    let inputValue = event.target.value.replace(/[^0-9.]/g, "");
    const floatValue = parseFloat(inputValue);
    const formattedInputValue = isNaN(floatValue) ? "" : floatValue.toString();

    // Call the onChange prop with an object that simulates an event structure
    onChange({
      target: {
        name,
        value: formattedInputValue,
      },
    });
  };

  const formatCurrency = (value) => {
    // Convert to a number and format as currency
    const number = Number(value);
    if (isNaN(number)) return ""; // Return empty if not a number

    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
    }).format(number);
  };

  return (
    <TextField
      fullWidth
      label={label}
      // Change here: display the formatted value, but the actual value is kept as a number
      value={value ? formatCurrency(value) : ""}
      onChange={handleChange}
      name={name}
      sx={{ mb: 2 }}
      variant="outlined"
      InputProps={{
        inputProps: {
          // Prevent non-numeric characters input except "-" and "."
          pattern: "[0-9.-]*",
        },
      }}
    />
  );
};

const StyledCard = styled(Card)({
  maxWidth: 600,
  margin: "20px auto",
});

const StyledRatingBox = styled(Box)({
  display: "flex",
  flexDirection: "column",
  "& > *": {
    margin: "8px 0",
  },
});

const ReviewForm = ({ onClose }) => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    franchisorName: "",
    location: "",
    sitesOwned: "",
    yearsAsFranchisee: "",
    roleAtFranchise: "",
    customRoleDescription: "",
    hourlyRate: "",
    operationalTrainingRating: 0,
    marketingSupportRating: 0,
    vendorFlexibilityRating: 0,
    communicationRating: 0,
    innovationRating: 0,
    overallScore: 0,
    franchisorStrengths: "",
    franchisorImprovements: "",
    additionalNotes: "",
    averageRevenuePerSite: "",
    averageProfitPerSite: "",
    averageBreakevenTime: "",
    hourlyCallsInterest: "",
    date: "",
  });
  const { currentUser } = useAuth();
  const [step, setStep] = useState(1);
  const [errors, setErrors] = useState({});
  const [showSuccessSnackbar, setShowSuccessSnackbar] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const postURL =
    "https://us-central1-franchise-5bd11.cloudfunctions.net/app/send-email";
  const contactEmail = "contact@FranchiseNow.me";

  const totalSteps = 5; // Assuming an additional step for the new fields
  const progress = (step / totalSteps) * 100;
  const stepRequired = {
    1: ["firstName", "lastName", "email"],
    2: [
      "franchisorName",
      "location",
      "sitesOwned",
      "yearsAsFranchisee",
      "roleAtFranchise",
    ],
    3: [
      "operationalTrainingRating",
      "marketingSupportRating",
      "vendorFlexibilityRating",
      "communicationRating",
      "innovationRating",
    ],
    4: ["franchisorStrengths", "franchisorImprovements"],
    5: ["hourlyCallsInterest"],
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    // Also clear errors for this field
    if (errors[name]) {
      setErrors((prevErrors) => ({ ...prevErrors, [name]: false }));
    }
  };

  const handleRatingChange = (name) => (event, newValue) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: newValue,
    }));
    // Also clear errors for this field
    if (errors[name]) {
      setErrors((prevErrors) => ({ ...prevErrors, [name]: false }));
    }
  };

  const handleNextStep = (e) => {
    e.preventDefault();
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isEmailValid = emailRegex.test(formData.email);
    if (!isEmailValid) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        email: "Invalid email address",
      }));
      return; // Prevent form submission if the email is not valid
    }
    const requiredFields = stepRequired[step];
    let newErrors = {};
    let hasError = false;
    if (
      formData.roleAtFranchise === "Other" &&
      !formData.customRoleDescription
    ) {
      newErrors["customRoleDescription"] = true;
      hasError = true;
    }
    if (step !== 3) {
      for (const field of requiredFields) {
        if (!formData[field]) {
          newErrors[field] = true;
          hasError = true;
        }
      }
    } else {
      for (const field of requiredFields) {
        if (formData[field] === 0) {
          newErrors[field] = true;
          hasError = true;
        }
      }
    }

    if (hasError) {
      setErrors(newErrors);
      return;
    }

    setStep(step + 1);
    setErrors({}); // Clear errors when moving to the next step
  };
  const handlePreviousStep = () => {
    if (step > 1) {
      setStep(step - 1);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!formData.hourlyCallsInterest) {
      alert("Please indicate your interest in hourly calls.");
      setErrors((prevErrors) => ({ ...prevErrors, hourlyCallsInterest: true }));
      return; // Prevent form submission if the field is not filled out
    }
    setIsSubmitting(true);
    formData.overallScore =
      (formData.innovationRating +
        formData.communicationRating +
        formData.marketingSupportRating +
        formData.vendorFlexibilityRating +
        formData.operationalTrainingRating) /
      5.0;
    formData.date = new Date().toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "long",
      year: "numeric",
    });
    // Here you could add more comprehensive validation based on your requirements
    const db = getDatabase();
    const reviewsRef = ref(db, "franchiseReviews");
    const newReviewRef = push(reviewsRef);
    set(newReviewRef, { ...formData })
      .then(() => {
        onClose("success"); // Inform the parent that submission was successful
      })
      .catch((error) => {
        console.error("Error submitting review:", error);
        alert("Failed to submit review. Please try again.");
      });
    const emailData = {
      to: [contactEmail],
      subject: `Review submitted by ${formData.firstName}`,
      text: `Recieved email from ${
        formData.firstName
      } with the following data: ${JSON.stringify(formData)}`,
    };

    fetch(postURL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(emailData),
    })
      .then((response) => {
        if (!response.ok) throw new Error(response.statusText);
        return response.text(); // Use .text() instead of .json() if the response might not be JSON
      })
      .then((data) => {
        console.log("Email sent successfully:");
      })
      .catch((error) => {
        console.error("Error sending email:", error);
      });
  };

  return (
    <StyledCard>
      <CardContent>
        <Snackbar
          open={showSuccessSnackbar}
          autoHideDuration={6000}
          onClose={() => setShowSuccessSnackbar(false)}
          message="Review submitted successfully!"
          anchorOrigin={{ vertical: "top", horizontal: "center" }} // Position the Snackbar
        />
        <Button
          variant="text"
          onClick={() => {
            setIsSubmitting(false);
            onClose(isSubmitting);
          }}
          sx={{ mt: isMobile ? -2 : 2, fontSize: isMobile ? 10 : 14, ml: 0 }}
        >
          Close
        </Button>
        <Typography
          variant="h5"
          component="h2"
          gutterBottom
          style={{ fontSize: isMobile ? 18 : 25 }}
        >
          Write a Review for a Franchise
        </Typography>
        <form onSubmit={handleSubmit}>
          {/* Step 1: Basic Info */}
          {step === 1 && (
            <>
              <Typography
                fontWeight={"bold"}
                mb={2}
                fontStyle={"italic"}
                style={{ fontSize: isMobile ? 12 : 16 }}
              >
                The more informative and nuanced your review, the more likely
                visitors will want to schedule a paid call with you! Don’t worry
                about making your review positive or negative - focus on the
                facts and the insights your experiences have provided!
              </Typography>
              <Typography style={{ fontSize: isMobile ? 12 : 16 }}>
                Your name and email will never be disclosed publicly or to any
                third parties (including the franchisor) and all reviews are
                totally anonymized
              </Typography>
              <TextField
                fullWidth
                label="First Name"
                name="firstName"
                value={formData.firstName}
                onChange={handleChange}
                margin="normal"
                required
                error={!!errors.firstName}
                helperText={errors.firstName ? "This field is required" : ""}
              />
              <TextField
                fullWidth
                label="Last Name"
                name="lastName"
                value={formData.lastName}
                onChange={handleChange}
                margin="normal"
                required
                error={!!errors.lastName}
                helperText={errors.lastName ? "This field is required" : ""}
              />
              <TextField
                fullWidth
                label="Email"
                name="email"
                type="email"
                value={formData.email}
                onChange={handleChange}
                margin="normal"
                required
                error={!!errors.email}
                helperText={errors.email ? "valid email is required" : ""}
              />
            </>
          )}
          {step === 2 && (
            <>
              <Typography
                variant="body2"
                color="textSecondary"
                sx={{ mt: 2, mb: 1, fontSize: isMobile ? 12 : 16 }}
              >
                Name of franchisor (e.g., McDonald’s)
              </Typography>
              <TextField
                fullWidth
                label="Name of franchisor"
                name="franchisorName"
                value={formData.franchisorName}
                onChange={handleChange}
                margin="normal"
                required
                error={!!errors.franchisorName}
                helperText={
                  errors.franchisorName ? "This field is required" : ""
                }
              />
              <Typography
                variant="body2"
                color="textSecondary"
                sx={{ mt: 2, mb: 1, fontSize: isMobile ? 12 : 16 }}
              >
                Feel free to be as general or specific as you like for location
                (e.g. Southern US vs Northern Texas vs Mesquite, TX):
              </Typography>
              <TextField
                fullWidth
                label="Location"
                name="location"
                value={formData.location}
                onChange={handleChange}
                margin="normal"
                required
                error={!!errors.location}
                helperText={errors.location ? "This field is required" : ""}
              />
              <TextField
                fullWidth
                label="Number of sites owned"
                name="sitesOwned"
                value={formData.sitesOwned}
                type="number"
                onChange={handleChange}
                margin="normal"
                required
                error={!!errors.sitesOwned}
                helperText={errors.sitesOwned ? "This field is required" : ""}
              />
              {isMobile && (
                <Typography
                  variant="body2"
                  color="textSecondary"
                  sx={{ mt: 2, mb: 1, fontSize: 12 }}
                >
                  How many years have you been a franchisee / operator?
                </Typography>
              )}
              <TextField
                fullWidth
                label={
                  isMobile
                    ? "Years with franchise"
                    : "How many years have you been a franchisee / operator?"
                }
                name="yearsAsFranchisee"
                value={formData.yearsAsFranchisee}
                type="number"
                onChange={handleChange}
                margin="normal"
                required
                error={!!errors.yearsAsFranchisee}
                helperText={
                  errors.yearsAsFranchisee ? "This field is required" : ""
                }
              />
              <Typography
                variant="subtitle1"
                component="div"
                sx={{ mt: 2, fontSize: isMobile ? 13 : 16 }}
              >
                Role at franchise?*
              </Typography>
              <FormControl fullWidth margin="normal">
                <InputLabel id="role-label">Choose an option</InputLabel>
                <Select
                  labelId="role-label"
                  id="role-select"
                  value={formData.roleAtFranchise}
                  label="Choose an option"
                  onChange={handleChange}
                  name="roleAtFranchise" // Ensure this matches your state's property
                  error={!!errors.roleAtFranchise}
                  helperText={
                    errors.roleAtFranchise ? "This field is required" : ""
                  }
                >
                  <MenuItem value="Franchisee">Franchisee</MenuItem>
                  <MenuItem value="General Manager">General Manager</MenuItem>
                  <MenuItem value="Other">Other</MenuItem>
                </Select>
              </FormControl>
              {formData.roleAtFranchise === "Other" && (
                <TextField
                  fullWidth
                  label="Please specify your role"
                  name="customRoleDescription"
                  value={formData.customRoleDescription}
                  onChange={handleChange}
                  margin="normal"
                  required={formData.roleAtFranchise === "Other"}
                  error={!!errors.customRoleDescription}
                  helperText={
                    errors.customRoleDescription
                      ? "This field is required when 'Other' is selected."
                      : ""
                  }
                />
              )}
            </>
          )}
          {step === 3 && (
            <StyledRatingBox>
              <Typography
                variant="subtitle1"
                gutterBottom
                fontSize={18}
                fontWeight={"bold"}
                style={{ fontSize: isMobile ? 13 : 25 }}
              >
                How is the franchisor with respect to:
              </Typography>
              <Typography
                component="legend"
                sx={{
                  color: errors.operationalTrainingRating ? "red" : "inherit",
                  mb: 1,
                  fontSize: isMobile ? 13 : 20,
                }}
              >
                Operational training provided
              </Typography>
              <Rating
                name="operationalTrainingRating"
                value={Number(formData.operationalTrainingRating)}
                required
                sx={{ marginBottom: 2 }}
                onChange={handleRatingChange("operationalTrainingRating")}
              />
              <Typography
                component="legend"
                sx={{
                  color: errors.marketingSupportRating ? "red" : "inherit",
                  mb: 1,
                  fontSize: isMobile ? 13 : 20,
                }}
              >
                Marketing and advertising support provided
              </Typography>
              <Rating
                name="marketingSupportRating"
                value={Number(formData.marketingSupportRating)}
                required
                sx={{ marginBottom: 2 }}
                onChange={handleRatingChange("marketingSupportRating")}
              />
              <Typography
                component="legend"
                sx={{
                  color: errors.vendorFlexibilityRating ? "red" : "inherit",
                  mb: 1,
                  fontSize: isMobile ? 13 : 20,
                }}
              >
                Flexibility of vendor/supplier selection
              </Typography>
              <Rating
                name="vendorFlexibilityRating"
                value={Number(formData.vendorFlexibilityRating)}
                required
                sx={{ marginBottom: 2 }}
                onChange={handleRatingChange("vendorFlexibilityRating")}
              />
              <Typography
                component="legend"
                sx={{
                  color: errors.communicationRating ? "red" : "inherit",
                  mb: 1,
                  fontSize: isMobile ? 13 : 20,
                }}
              >
                Speed, transparency, and helpfulness of Franchisor’s
                communication
              </Typography>
              <Rating
                name="communicationRating"
                value={Number(formData.communicationRating)}
                required
                sx={{ marginBottom: 2 }}
                onChange={handleRatingChange("communicationRating")}
              />
              <Typography
                component="legend"
                sx={{
                  color: errors.innovationRating ? "red" : "inherit",
                  mb: 1,
                  fontSize: isMobile ? 13 : 20,
                }}
              >
                Innovation & responding to competitors
              </Typography>
              <Rating
                name="innovationRating"
                value={Number(formData.innovationRating)}
                required
                sx={{ marginBottom: 2 }}
                onChange={handleRatingChange("innovationRating")}
              />
            </StyledRatingBox>
          )}
          {/* Step 3: Detailed Feedback */}
          {step === 4 && (
            <>
              <TextField
                fullWidth
                label="What does the franchisor do well?"
                name="franchisorStrengths"
                value={formData.franchisorStrengths}
                onChange={handleChange}
                multiline
                rows={4}
                margin="normal"
                required
                error={!!errors.franchisorStrengths}
                helperText={
                  errors.franchisorStrengths ? "This field is required" : ""
                }
                InputLabelProps={{ style: { fontSize: isMobile ? 13 : 16 } }}
              />
              <TextField
                fullWidth
                label="What can the franchisor improve on?"
                name="franchisorImprovements"
                value={formData.franchisorImprovements}
                onChange={handleChange}
                multiline
                rows={4}
                margin="normal"
                required
                error={!!errors.franchisorImprovements}
                helperText={
                  errors.franchisorImprovements ? "This field is required" : ""
                }
                InputLabelProps={{ style: { fontSize: isMobile ? 12 : 16 } }}
              />
              <TextField
                fullWidth
                label="Additional Comments"
                name="additionalNotes"
                value={formData.additionalNotes}
                onChange={handleChange}
                multiline
                rows={4}
                margin="normal"
                InputLabelProps={{ style: { fontSize: isMobile ? 13 : 16 } }}
              />
            </>
          )}
          {/* Step 5: Financials and Final Comments */}
          {step === 5 && (
            <>
              <CurrencyTextField
                fullWidth
                label="Average Revenue per Site"
                name="averageRevenuePerSite"
                value={formData.averageRevenuePerSite}
                onChange={handleChange}
                margin="normal"
                type="number"
              />
              <CurrencyTextField
                fullWidth
                label="Average Profit per Site"
                name="averageProfitPerSite"
                value={formData.averageProfitPerSite}
                onChange={handleChange}
                margin="normal"
                type="number"
              />
              <TextField
                fullWidth
                label="Average payback time in months"
                name="averageBreakevenTime"
                value={formData.averageBreakevenTime}
                onChange={handleChange}
                margin="normal"
                type="number"
                InputLabelProps={{ style: { fontSize: isMobile ? 15 : 16 } }}
              />
              <Typography
                variant="subtitle1"
                component="div"
                sx={{ mt: 2, fontWeight: "bold", fontSize: isMobile ? 13 : 16 }}
              >
                Are you interested in getting paid to take hourly calls with
                folks interested in learning more about your franchise
                experiences?*
              </Typography>
              <FormControl fullWidth margin="normal">
                <InputLabel id="hourly-calls-interest-label">
                  Choose an option
                </InputLabel>
                <Select
                  labelId="hourly-calls-interest-label"
                  id="hourly-calls-interest-select"
                  value={formData.hourlyCallsInterest}
                  label="Choose an option"
                  onChange={handleChange}
                  name="hourlyCallsInterest" // Ensure this matches your state's property
                  error={!!errors.hourlyCallsInterest}
                  helperText={
                    errors.hourlyCallsInterest ? "This field is required" : ""
                  }
                >
                  <MenuItem value="Yes">Yes</MenuItem>
                  <MenuItem value="No">No</MenuItem>
                </Select>
              </FormControl>
            </>
          )}
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={2}
            sx={{ mt: 2 }}
          >
            {step > 1 && (
              <Button
                variant="outlined"
                color="primary"
                style={{ marginTop: 20 }}
                onClick={handlePreviousStep}
              >
                Previous
              </Button>
            )}
            {step < totalSteps ? (
              <Button
                type="button"
                variant="contained"
                color="primary"
                style={{ marginTop: 20 }}
                onClick={handleNextStep}
              >
                Next
              </Button>
            ) : (
              <Button
                type="submit"
                variant="contained"
                color="primary"
                style={{ marginTop: 20 }}
              >
                Submit
              </Button>
            )}
          </Stack>
        </form>
        <LinearProgress
          variant="determinate"
          value={progress}
          style={{ marginTop: 20 }}
        />
      </CardContent>
    </StyledCard>
  );
};

export default ReviewForm;
