import { useMemo, useState } from 'react'

export const useTableSort = (
  data = [],
  initialSortConfig = { key: null, direction: 'ascending' }
) => {
  const [sortConfig, setSortConfig] = useState(initialSortConfig)
  const [orderedBy, setOrderedBy] = useState('')

  const sortedData = useMemo(() => {
    let sortableItems = [...data]
    if (sortConfig !== null) {
      sortableItems.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? -1 : 1
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? 1 : -1
        }
        return 0
      })
    }
    return sortableItems
  }, [data, sortConfig])

  const requestSort = (key) => {
    let direction = 'ascending'
    if (
      sortConfig &&
      sortConfig.key === key &&
      sortConfig.direction === 'ascending'
    ) {
      direction = 'descending'
    }
    setSortConfig({ key, direction })
    setOrderedBy(key)
  }

  return { sortedData, requestSort, sortConfig, orderedBy }
}
