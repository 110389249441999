import React from "react";
import Marquee from "react-marquee-slider";
import times from "lodash/times";
import data from "./data.json";

const getLogo = (franchiseName) => {
  const downloadPath = "/logos/";
  const sanitized_name = franchiseName.replace(/\//g, "").replace(/\\/g, "");
  const fileName = `${sanitized_name}.jpg`;
  return `${downloadPath}${fileName}`;
};

const logos = data
  .sort((a, b) => {
    // Convert "-" to 0, otherwise parse the number of franchisees
    const numFranchiseesA =
      a["Number of Franchisees"] === "-"
        ? 0
        : parseInt(a["Number of Franchisees"], 10);
    const numFranchiseesB =
      b["Number of Franchisees"] === "-"
        ? 0
        : parseInt(b["Number of Franchisees"], 10);

    // Return the comparison result
    return numFranchiseesB - numFranchiseesA;
  })
  .slice(0, 40)
  .map((franchise) => ({
    name: franchise["Franchise Name"],
    logoPath: getLogo(franchise["Franchise Name"]),
  }));

const FranchiseLogoTicker = () => {
  return (
    <div
      style={{
        position: "relative",
        overflow: "hidden",
        outline: "none",
      }}
    >
      <Marquee velocity={20}>
        {times(logos.length, Number).map((id) => (
          <img
            key={`marquee-example-logo-${id}`}
            src={logos[id].logoPath}
            alt={`Logo ${id}`}
            style={{
              marginTop: 10,
              width: 50,
              height: 30,
              marginRight: "20px",
              objectFit: "contain",
            }}
          />
        ))}
      </Marquee>
    </div>
  );
};

export default FranchiseLogoTicker;
