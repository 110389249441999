import React, { memo } from "react";
import FranchiseSearch from "./FranchiseSearch";
import { Box } from "@mui/material";

const TableSearch = ({ localData, setFranchises, setSearchQuery }) => {
  return (
    <Box className="subtitleContainer">
      <FranchiseSearch
        localData={localData}
        setSearchQuery={setSearchQuery}
        setFranchises={setFranchises}
      ></FranchiseSearch>
    </Box>
  );
};

export default memo(TableSearch);
