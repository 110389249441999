import React, { useState, useEffect } from "react";
import {
  Typography,
  Button,
  TextField,
  MenuItem,
  Grid,
  Paper,
  Box,
  useTheme,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  Snackbar,
  useMediaQuery,
} from "@mui/material";
import { getDatabase, ref, onValue, set } from "firebase/database";
import { useAuth } from "./auth/Auth";

const questionTemplates = [
  { label: "First Name", type: "text", required: true },
  { label: "Last Name", type: "text", required: true },
  { label: "Email", type: "email", required: true },
  { label: "Phone Number", type: "text", required: true },
  { label: "City and State of Residence", type: "text", required: true },
  {
    label: "Open to Relocation?",
    type: "select",
    options: ["Yes", "No"],
    required: true,
    tooltip:
      "Are you open to relocating if opportunities are not available in your area?",
  },
  {
    label: "Legal Right in the US",
    type: "select",
    options: ["Yes", "No"],
    required: true,
    tooltip:
      "Do you have the legal right to remain in the US to operate a franchise?",
  },
  {
    label: "Gender",
    type: "select",
    options: ["Male", "Female", "Other", "Prefer not to answer"],
    required: true,
  },
  {
    label: "Race / Ethnicity",
    type: "select",
    options: [
      "American Indian or Native Alaska",
      "Asian",
      "Hispanic",
      "Hawaiian or Pacific Islander",
      "Caucasian",
      "2 or more races",
      "Prefer not to answer",
    ],
    required: true,
  },
  {
    label: "Owned a Business?",
    type: "select",
    options: ["Yes", "No"],
    required: true,
    tooltip: "Have you ever owned a business or franchise?",
  },
  { label: "Details if Owned", type: "text", required: false },
  {
    label: "Franchise Commitment",
    type: "select",
    options: ["Part-Time", "Full-Time"],
    required: true,
  },
  {
    label: "Investment Capacity",
    type: "select",
    options: ["$0-100k", "$100k-250k", "$250k-500k", "$500k-1M", "$1M+"],
    required: true,
  },
  {
    label: "Total Net Worth",
    type: "select",
    options: ["$0-100k", "$100k-250k", "$250k-500k", "$500k-1M", "$1M+"],
    required: true,
  },
  {
    label: "Site Ownership Interest",
    type: "select",
    options: ["Single", "Multiple"],
    required: true,
  },
  {
    label: "How many locations do you want to purchase",
    type: "text",
    required: false,
  },
  {
    label: "Ownership Model",
    type: "select",
    options: ["Sole Proprietor", "Multiple Partners"],
    required: true,
  },
  { label: "LinkedIn Profile", type: "url", required: false },
];

const EditApplyForm = () => {
  const [application, setApplication] = useState([]);
  const [editedApplication, setEditedApplication] = useState([]);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [invalidFields, setInvalidFields] = useState([]);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [successSnackbarOpen, setSuccessSnackbarOpen] = useState(false);
  const { currentUser } = useAuth();
  const db = getDatabase();
  const theme = useTheme();

  useEffect(() => {
    const userApplicationsRef = ref(db, `applications/${currentUser?.uid}`);
    onValue(userApplicationsRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        setApplication(data.answers);
        setEditedApplication([...data.answers]); // Create a copy for editing
      } else {
        setApplication([]);
        setEditedApplication([]);
      }
    });
  }, [currentUser, db]);

  const handleInputChange = (index, value) => {
    const newEditedApplication = [...editedApplication];
    newEditedApplication[index] = value;
    setEditedApplication(newEditedApplication);
    if (invalidFields.includes(index) && value.trim() !== "") {
      setInvalidFields(
        invalidFields.filter((fieldIndex) => fieldIndex !== index)
      );
    }
  };

  const handleUpdate = () => {
    const editsMade = editedApplication.some(
      (value, index) => value !== application[index]
    );

    if (!editsMade) {
      // No edits made, do nothing
      return;
    }
    const requiredFieldsFilled = questionTemplates.every((question, index) => {
      if (question.required) {
        return (
          editedApplication[index] && editedApplication[index].trim() !== ""
        );
      }
      return true;
    });
    if (requiredFieldsFilled) {
      setConfirmDialogOpen(true);
    } else {
      const invalidFieldsIndices = questionTemplates
        .map((question, index) =>
          question.required && !editedApplication[index]?.trim() ? index : -1
        )
        .filter((index) => index !== -1);

      setInvalidFields(invalidFieldsIndices);
      const fieldsToFill = invalidFieldsIndices
        .map((index) => questionTemplates[index].label)
        .join(", ");
      setSnackbarMessage(`Please fill in all required fields: ${fieldsToFill}`);
      setSnackbarOpen(true);
    }
  };

  const confirmUpdate = () => {
    set(ref(db, `applications/${currentUser?.uid}/answers`), editedApplication)
      .then(() => {
        console.log("Application updated successfully");
        setApplication(editedApplication); // Update application with edited values
        setSuccessSnackbarOpen(true);
      })
      .catch((error) => {
        console.error("Error updating application:", error);
      });
    setConfirmDialogOpen(false);
  };

  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Grid
      container={!isMobile}
      justifyContent="center"
      spacing={3}
      sx={{
        maxWidth: 600,
        margin: "auto",
        padding: !isMobile ? theme.spacing(3) : theme.spacing(0.5),
      }}
    >
      <Typography
        variant="h5"
        gutterBottom
        sx={{ fontSize: { xs: 20, md: 30 } }}
      >
        Make edits to Application
      </Typography>
      <Snackbar
        open={successSnackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSuccessSnackbarOpen(false)}
        message="Application updated successfully"
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        sx={{
          "& .MuiSnackbarContent-root": {
            backgroundColor: "green", // Background color green
            color: "white", // Text color white
          },
        }}
      />

      {questionTemplates.map((question, index) => (
        <Grid
          item
          xs={12}
          key={index}
          sx={{ marginY: isMobile ? theme.spacing(2) : 0 }}
        >
          <Paper elevation={3} style={{ padding: theme.spacing(2) }}>
            <Typography
              variant="subtitle1"
              style={{ marginBottom: theme.spacing(1) }}
            >
              {question.label}{" "}
              {question.required && <span style={{ color: "red" }}>*</span>}
            </Typography>
            {question.type === "select" ? (
              <TextField
                select
                value={editedApplication[index] || ""}
                onChange={(e) => handleInputChange(index, e.target.value)}
                fullWidth
                variant="outlined"
                size="small"
              >
                {question.options.map((option, optionIndex) => (
                  <MenuItem key={optionIndex} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </TextField>
            ) : (
              <TextField
                value={editedApplication[index] || ""}
                onChange={(e) => handleInputChange(index, e.target.value)}
                fullWidth
                variant="outlined"
                size="small"
                error={invalidFields.includes(index)}
              />
            )}
          </Paper>
        </Grid>
      ))}

      <Grid
        item
        xs={12}
        sx={{
          display: "flex",
          justifyContent: "center",
          marginTop: { xs: 2, sm: 2, md: 0 },
        }}
      >
        <Button variant="contained" color="primary" onClick={handleUpdate}>
          Update Application
        </Button>
        <Dialog
          open={confirmDialogOpen}
          onClose={() => setConfirmDialogOpen(false)}
        >
          <DialogTitle>Confirm Update</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Are you sure you want to update your application?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setConfirmDialogOpen(false)} color="primary">
              Cancel
            </Button>
            <Button onClick={confirmUpdate} color="primary" autoFocus>
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
      </Grid>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        sx={{
          "& .MuiSnackbarContent-root": {
            backgroundColor: "red", // Background color green
            color: "white", // Text color white
          },
        }}
      />
    </Grid>
  );
};

export default EditApplyForm;
