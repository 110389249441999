import React, { useState } from "react";

import {
  Typography,
  Grid,
  Box,
  Button,
  Snackbar,
  Rating,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
} from "@mui/material";
import PushPinIcon from "@mui/icons-material/PushPin";
import { generateUniqueId } from "../utils/generateUniqueId";
import LocationCityIcon from "@mui/icons-material/LocationCity";
import { useAuth } from "../auth/Auth";

const ReviewCard = ({ review, setLoginPopupOpen }) => {
  const Ratings = {
    "Operational training provided": review.operationalTrainingRating,
    "Marketing & advertising support provided": review.marketingSupportRating,
    "Flexibility of vendor/supplier selection": review.vendorFlexibilityRating,
    "Speed & helpless in communication:": review.communicationRating,
    "Innovation & responding to competitors:": review.innovationRating,
  };

  const [scheduleBooked, setScheduleBooked] = useState(false);
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
  const { currentUser } = useAuth();

  const sendConfirmationEmail = (recipient, subject, text, sendAt) => {
    const emailData = {
      to: [recipient, contactEmail],
      subject: subject,
      text: text,
      sendAt: sendAt,
    };

    fetch(postURL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(emailData),
    })
      .then((response) => {
        if (!response.ok) throw new Error(response.statusText);
        return response.text(); // Use .text() instead of .json() if the response might not be JSON
      })
      .then((data) => {
        console.log("Email sent successfully:");
        setShowConfirmationDialog(false);
        setScheduleBooked(true);
      })
      .catch((error) => {
        console.error("Error sending email:", error);
      });
  };
  function formatReviewAndUserForEmail(review, currentUser) {
    const reviewSummary = `
  Review Details:
  - ID: ${review.id}
  - Franchisor Name: ${review.franchisorName}
  - Location: ${review.location}
  - Date: ${review.date}
  - Sites Owned: ${review.sitesOwned}
  - Years as Franchisee: ${review.yearsAsFranchisee}
  - Average Revenue Per Site: $${review.averageRevenuePerSite}
  - Average Profit Per Site: $${review.averageProfitPerSite}
  - Average Break-even Time: ${review.averageBreakevenTime} months
  - Overall Score: ${review.overallScore}
  - Additional Notes: ${review.additionalNotes}
  `;

    const userSummary = `
  User Details:
  - Name: ${currentUser.displayName}
  - Email: ${currentUser.email}
  - Profile: ${currentUser.photoURL}
  `;

    return reviewSummary + userSummary;
  }

  const handleScheduleClick = () => {
    setShowConfirmationDialog(true);
  };
  const postURL =
    "https://us-central1-franchise-5bd11.cloudfunctions.net/app/send-email";
  const contactEmail = "contact@FranchiseNow.me";

  const handleConfirm = (review) => {
    if (!currentUser) {
      console.log("No user logged in, prompting for login.");
      setLoginPopupOpen(true);
      return;
    }
    setShowConfirmationDialog(false);
    setScheduleBooked(true);
    sendConfirmationEmail(
      currentUser.email,
      "Call Request Received - FranchiseNow",
      "Hi there!\n\nThis is to confirm we have received your request. The FranchiseNow team will be in touch soon to help you schedule a call with the current franchisee."
    );
    sendConfirmationEmail(
      currentUser.email,
      "Call Request Update - FranchiseNow",
      "Hi there!\n\nUnfortunately due to the high volume of requests, there will be a delay in scheduling your call. The FranchiseNow team will be in touch as soon as possible to get you connected with the franchisee.Thank you for your patience!",
      Math.floor(Date.now() / 1000) + 600
    );
    sendConfirmationEmail(
      null,
      `Call Request Received - ${currentUser.displayName}`,
      `We have received a request to schedule for review:\n\n${formatReviewAndUserForEmail(
        review,
        currentUser
      )}`
    );
  };
  const handleCancel = () => {
    setShowConfirmationDialog(false);
  };
  const renderHourlyRateAndSchedule = () => {
    if (review.hourlyRate) {
      // Check if hourlyRate is not blank
      return (
        <>
          <Typography
            variant="body1"
            sx={{ fontSize: "17px" }}
            fontWeight="bold"
          >
            ${isNaN(review.hourlyRate) ? 0 : review.hourlyRate} / Hour
          </Typography>
          <Button
            variant="text"
            sx={{ textDecoration: "underline", fontWeight: "bold" }}
            onClick={handleScheduleClick}
          >
            Schedule
          </Button>
        </>
      );
    }
    return null; // Return null if hourlyRate is blank or not set
  };
  const ratingsValues = Object.values(Ratings);
  const averageRating = (
    ratingsValues.reduce((acc, val) => acc + val, 0) / 5.0
  ).toFixed(2);
  return (
    <div
      style={{
        width: "100%",
        maxWidth: 1000,
        height: "100%",
        marginBottom: 2,
        paddingBottom: "-24px",
        backgroundColor: "#f5f5f5",
        border: "1px solid black",
      }}
    >
      <React.Fragment key={generateUniqueId()}>
        <script type="application/ld+json">
          {`
        {
          "@context": "https://schema.org",
          "@type": "Review",
          "itemReviewed": {
            "@type": "LocalBusiness",
            "name": "${review.franchisorName}",
            "image": "${review.logo}",
            "url": "${review.companyWebsite}"
          },
          "reviewRating": {
            "@type": "Rating",
            "ratingValue": "${parseFloat(averageRating)}",
            "bestRating": "5",
            "worstRating": "0"
          },
          "reviewBody": "${review.additionalNotes}"
        }
      `}
        </script>
      </React.Fragment>
      <div sx={{ padding: 0 }}>
        <Grid container>
          <Grid
            item
            md={2}
            sm={4}
            xs={12}
            sx={{
              backgroundColor: "rgb(62,144,230)",
              borderRight: "1px solid black",
              padding: "10px 10px 0px 10px",
            }}
          >
            <Typography
              variant="h5"
              color="white"
              align="center"
              sx={{ fontWeight: "bold", marginBottom: 0 }}
            >
              {review.franchisorName}
            </Typography>
            <Typography
              variant="body2"
              color="white"
              align="center"
              style={{ marginBottom: 5, fontStyle: "italic" }}
            >
              {review.date}
            </Typography>
            <Typography
              variant="body2"
              color="white"
              align="center"
              fontSize="12px"
              sx={{ fontWeight: "bold", fontStyle: "italic", marginBottom: 2 }}
            >
              Operator for {review.yearsAsFranchisee} years
            </Typography>

            <Box
              display="flex"
              alignItems="left"
              justifyContent="left"
              color="white"
              sx={{
                display: { xs: "flex", sm: "flex", md: "flex" },
                flexDirection: { md: "column" },
                alignItems: "center",
                justifyContent: "space-around",
                gap: "15px",
                marginBottom: 1,
              }}
            >
              <Box
                display="flex"
                alignItems="left"
                justifyContent="left"
                color="white"
                fontSize="12px"
              >
                <LocationCityIcon sx={{ marginRight: "6px", margin: 0 }} />
                <Typography
                  variant="body2"
                  fontWeight="bold"
                  sx={{ margin: "4px" }}
                >
                  {review.sitesOwned}{" "}
                  {parseInt(review.sitesOwned) === 1 ? "site" : "sites"} owned
                </Typography>
              </Box>
              <Box
                display="flex"
                alignItems="left"
                justifyContent="left"
                color="white"
              >
                <PushPinIcon sx={{}} />
                <Typography variant="body2">{review.location}</Typography>
              </Box>
            </Box>

            <Box
              alignItems="center"
              justifyContent="center"
              color="white"
              // sx={{
              //   display: { xs: "flex", sm: "flex", md: "flex" },
              //   gap: "5px",
              // }}
              sx={{
                display: { xs: "flex", sm: "flex", md: "flex" },
                flexDirection: { md: "column" },
                alignItems: "center",
                justifyContent: "center",
                gap: "15px",
                marginBottom: 1,
              }}
            >
              <Typography
                variant="body2"
                align="center"
                color="white"
                fontWeight="bold"
                sx={{ marginBottom: 0, marginTop: { md: 2 } }}
              >
                Overall score:
              </Typography>
              <Typography
                variant="body2"
                align="center"
                color="white"
                fontWeight="bold"
                style={{ marginBottom: 0 }}
              >
                {averageRating} / 5.00
              </Typography>
            </Box>
            <Box
              sx={{
                transform: {
                  xs: "none",
                  md: "rotate(-90deg) translateX(-55%)",
                },
                mt: { md: "-15px" },
              }}
              align="center"
            >
              <Rating
                name="read-only"
                value={parseFloat(averageRating)} // Example value
                precision={0.2} // Example precision
                readOnly
                size="large"
              />
            </Box>
          </Grid>
          <Grid
            item
            md={4}
            sm={8}
            xs={12}
            sx={{
              padding: "10px",
              borderBottom: { xs: "1px solid black", md: "none" },
            }}
          >
            <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
              How is the franchisor with respect to:
            </Typography>
            {Object.entries(Ratings).map(([key, value], index) => (
              <div key={key} style={{ marginTop: "10px" }}>
                <Typography variant="body1">{`${
                  index + 1
                }. ${key}`}</Typography>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "start",
                    alignItems: "center",
                    marginLeft: "20px",
                  }}
                >
                  <Typography variant="body1">{value}/5</Typography>
                  <Rating
                    name="read-only"
                    value={value}
                    precision={0.5}
                    readOnly
                    disabled
                    size="large"
                  />
                </div>
              </div>
            ))}
          </Grid>

          <Grid
            item
            md={6}
            xs={12}
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <div style={{ padding: "10px" }}>
              <Grid container>
                <Grid item sm={9} xs={12}>
                  <div>
                    <Typography
                      variant="body1"
                      sx={{ fontWeight: "bold", fontSize: "17px" }}
                    >
                      What does the franchisor do well?
                    </Typography>
                    <Typography variant="subtitle2" sx={{ fontSize: "13px" }}>
                      {review.franchisorStrengths}
                    </Typography>
                  </div>

                  <div>
                    <Typography
                      variant="body1"
                      sx={{
                        fontWeight: "bold",
                        fontSize: "17px",
                        marginTop: "10px",
                      }}
                    >
                      What can the franchisor improve on?
                    </Typography>
                    <Typography variant="subtitle2" sx={{ fontSize: "13px" }}>
                      {review.franchisorImprovements}
                    </Typography>
                  </div>
                  <div>
                    <Typography
                      variant="body1"
                      sx={{
                        fontWeight: "bold",
                        fontSize: "17px",
                        marginTop: "10px",
                      }}
                    >
                      Additional comments?
                    </Typography>
                    <Typography variant="subtitle2" sx={{ fontSize: "13px" }}>
                      {review.additionalNotes}
                    </Typography>
                  </div>
                </Grid>
                <Grid
                  item
                  sm={3}
                  xs={12}
                  sx={{
                    textAlign: "center",
                    display: { xs: "none", sm: "block" },
                  }}
                >
                  {renderHourlyRateAndSchedule()}

                  <Snackbar
                    open={scheduleBooked}
                    autoHideDuration={3000}
                    onClose={() => setScheduleBooked(false)}
                    message="Scheduled successfully!"
                    sx={{
                      "& .MuiSnackbarContent-root": {
                        backgroundColor: "green", // Background color green
                        color: "white", // Text color white
                      },
                    }}
                  />
                </Grid>
              </Grid>
            </div>
            <div sx={{ margin: "20px" }}>
              <Typography
                variant="body1"
                sx={{
                  marginLeft: { xs: "0", md: "10px" },
                  fontWeight: "bold",
                  fontSize: "17px",
                  marginTop: "15px",
                  borderBottom: "1px solid black",
                  paddingLeft: { xs: "10px", md: "0" },
                }}
              >
                Financial
              </Typography>
              <Grid container sx={{ padding: "10px 0 " }}>
                <Grid
                  item
                  display="flex"
                  alignItems="baseline"
                  justifyContent="space-between"
                  flexDirection="column"
                  md={4}
                  xs={4}
                  sx={{ padding: "0 10px", gap: "5px" }}
                >
                  <Typography
                    variant="subtitle2"
                    fontWeight="bold"
                    color="rgb(62,144,230)"
                  >
                    Avg Revenue per Site
                  </Typography>
                  <Typography variant="subtitle2" color="rgb(62,144,230)">
                    {isNaN(review.averageRevenuePerSite) ||
                    !review.averageRevenuePerSite
                      ? "N/A"
                      : `$${parseInt(
                          review.averageRevenuePerSite
                        ).toLocaleString()}`}
                  </Typography>
                </Grid>
                <Grid
                  item
                  display="flex"
                  alignItems="baseline"
                  justifyContent="space-between"
                  flexDirection="column"
                  md={4}
                  xs={4}
                  sx={{ padding: "0 10px" }}
                >
                  <Typography
                    variant="subtitle2"
                    fontWeight="bold"
                    color="rgb(62,144,230)"
                  >
                    Avg Profit per Site
                  </Typography>

                  <Typography variant="subtitle2" color="rgb(62,144,230)">
                    {isNaN(review.averageProfitPerSite) ||
                    !review.averageProfitPerSite
                      ? "N/A"
                      : `$${parseInt(
                          review.averageProfitPerSite
                        ).toLocaleString()}`}
                  </Typography>
                </Grid>
                <Grid
                  item
                  display="flex"
                  alignItems="baseline"
                  justifyContent="space-between"
                  flexDirection="column"
                  md={4}
                  xs={3}
                  sx={{ padding: "0 10px" }}
                >
                  <Typography
                    variant="subtitle2"
                    fontWeight="bold"
                    color="rgb(62,144,230)"
                  >
                    Time To Payback{" "}
                  </Typography>
                  <Typography variant="subtitle2" color="rgb(62,144,230)">
                    {isNaN(review.averageBreakevenTime) ||
                    !review.averageBreakevenTime
                      ? "N/A"
                      : `${review.averageBreakevenTime} Months`}
                  </Typography>
                </Grid>
                {review.averageProfitPerSite &&
                review.averageRevenuePerSite &&
                !(
                  isNaN(review.averageBreakevenTime) &&
                  isNaN(review.averageProfitPerSite)
                ) ? (
                  <>
                    <Grid
                      item
                      display="flex"
                      alignItems="baseline"
                      justifyContent="space-between"
                      flexDirection="column"
                      md={4}
                      xs={4}
                      sx={{ padding: "0 10px" }}
                    >
                      {" "}
                    </Grid>
                    <Grid
                      item
                      display="flex"
                      alignItems="baseline"
                      justifyContent="space-between"
                      flexDirection="column"
                      md={4}
                      xs={4}
                      sx={{ padding: "0 10px" }}
                    >
                      <Typography
                        variant="subtitle2"
                        color="rgb(62,144,230)"
                        fontStyle={"italic"}
                      >
                        (
                        {Math.round(
                          (parseInt(review.averageProfitPerSite) /
                            parseInt(review.averageRevenuePerSite)) *
                            100
                        ).toLocaleString()}
                        % margin)
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      display="flex"
                      alignItems="baseline"
                      justifyContent="space-between"
                      flexDirection="column"
                      md={4}
                      xs={4}
                      sx={{ padding: "0 10px" }}
                    >
                      {" "}
                    </Grid>
                  </>
                ) : (
                  <></>
                )}
              </Grid>
            </div>
          </Grid>
          <Grid
            item
            sm={3}
            xs={12}
            sx={{ textAlign: "center", display: { xs: "block", sm: "none" } }}
          >
            <Typography
              variant="body1"
              sx={{ fontSize: "17px" }}
              fontWeight="bold"
            >
              $
              {review.hourlyRate === ""
                ? 0
                : isNaN(review.hourlyRate)
                ? 0
                : review.hourlyRate}
              /Hour
            </Typography>
            <Button
              variant="text"
              sx={{ textDecoration: "underline", fontWeight: "bold" }}
              onClick={handleScheduleClick}
            >
              Schedule
            </Button>
            <Snackbar
              open={scheduleBooked}
              autoHideDuration={3000}
              onClose={() => setScheduleBooked(false)}
              message="Scheduled successfully!"
              sx={{
                "& .MuiSnackbarContent-root": {
                  backgroundColor: "green", // Background color green
                  color: "white", // Text color white
                },
              }}
            />
            <Dialog open={showConfirmationDialog} onClose={handleCancel}>
              <DialogTitle>Confirm Schedule</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  Upon confirming, we will be in touch with you shortly
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleCancel}>Cancel</Button>
                <Button onClick={() => handleConfirm(review)} autoFocus>
                  Confirm
                </Button>
              </DialogActions>
            </Dialog>
            {/* Success Snackbar */}
            <Snackbar
              open={scheduleBooked}
              autoHideDuration={3000}
              onClose={() => setScheduleBooked(false)}
              message="Scheduled successfully!"
              sx={{
                "& .MuiSnackbarContent-root": {
                  backgroundColor: "green", // Background color green
                  color: "white", // Text color white
                },
              }}
            />
          </Grid>
        </Grid>
      </div>
    </div>
  );
};
export default ReviewCard;
