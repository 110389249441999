import {
  Box,
  TextField,
  InputAdornment,
  Button,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ReviewCard from "./ReviewCard"; // Adjust the import path as needed
import useFetchReviews from "../hooks/useFetchReviews"; // Adjust the import path

const ReviewsTable = ({ setLoginPopupOpen }) => {
  const theme = useTheme(); // Using theme for consistent styling
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { reviews, setSearchQuery, setSortDirection, sortDirection } =
    useFetchReviews();

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };
  const toggleSortDirection = () => {
    setSortDirection((currentDirection) =>
      currentDirection === "asc" ? "desc" : "asc"
    );
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          mb: 2,
        }}
      >
        <TextField
          label="Search by Franchisor Name"
          variant="outlined"
          onChange={handleSearchChange}
          sx={{
            marginRight: isMobile ? 5 : "14vw",
            width: isMobile ? 180 : 300, // Adjust width as needed
            ".MuiOutlinedInput-root": {
              height: "auto", // Let height adjust based on content and padding
              borderRadius: "25px", // More pronounced rounded corners
              backgroundColor: "white",
              "&:hover": {
                backgroundColor: "#f5f5f5",
              },
              "&.Mui-focused": {
                backgroundColor: "white",
                borderColor: "#1976d2",
              },
            },
            ".MuiInputLabel-root": {
              color: "gray",
              "&.Mui-focused": {
                color: "#1976d2",
              },
            },
            ".MuiInputBase-input": {
              padding: "10px 12px", // More generous padding for a spacious feel
              // Consider adjusting font size if necessary
            },
          }}
          // Adding a start adornment for a search icon
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon sx={{ color: "action.active", mr: 1, my: 0.5 }} />
              </InputAdornment>
            ),
          }}
        />
        <Button
          sx={{
            width: isMobile ? 145 : 300,
            fontSize: isMobile ? 10 : 16,
          }}
          variant="outlined"
          endIcon={
            sortDirection === "asc" ? (
              <ArrowUpwardIcon />
            ) : (
              <ArrowDownwardIcon />
            )
          }
          onClick={toggleSortDirection}
        >
          Sort by Rating
        </Button>
      </Box>

      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: 2,
        }}
      >
        {reviews.map((review) => (
          <ReviewCard
            key={review.id}
            review={review}
            setLoginPopupOpen={setLoginPopupOpen}
          />
        ))}
      </Box>
    </>
  );
};

export default ReviewsTable;
