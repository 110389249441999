import React from 'react';
import { Container as MuiContainer } from '@mui/material';

const cx = (...classNames) =>
  classNames.filter(Boolean).join(' ');

const myLoader = ({ src }) => {
  return src;
};

export default function Container(props) {
  return (
    <MuiContainer
      className={cx(
        'container px-8 mx-auto xl:px-5',
        props.large ? 'max-w-screen-xl' : 'max-w-screen-lg',
        !props.alt && 'py-5 lg:py-8',
        props.className
      )}
    >
      {props.children}
    </MuiContainer>
  );
}
