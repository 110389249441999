import React, { memo } from "react";
import ClearIcon from "@mui/icons-material/Clear";

import {
  Button,
  Checkbox,
  FormControlLabel,
  IconButton,
  Popover,
} from "@mui/material";

const IndustryFilterPopover = ({
  idIndustry = "simple-popover",
  anchorElIndustry,
  openIndustry,
  handleFilter,
  handleCloseIndustry,
  IndustryCategories,
  handleCategoryChange,
  headCell,
  clearIndustries,
}) => {
  const applyFilter = () => {
    handleFilter(headCell.id);
    handleCloseIndustry();
  };
  return (
    <Popover
      id={idIndustry}
      open={openIndustry}
      anchorEl={anchorElIndustry}
      onClose={handleCloseIndustry}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
    >
      <IconButton onClick={handleCloseIndustry}>
        <ClearIcon></ClearIcon>
      </IconButton>

      <div style={{ padding: 20 }}>
        <>
          {Object.keys(IndustryCategories).map((category, index) => (
            <FormControlLabel
              control={
                <Checkbox
                  checked={IndustryCategories[category]}
                  onChange={handleCategoryChange}
                  name={category}
                />
              }
              label={category}
              key={index}
            />
          ))}
        </>
        <div style={{ marginTop: 10 }}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => applyFilter()}
          >
            Apply Filter
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={clearIndustries}
            style={{
              margin: 10,
            }}
          >
            Deselect All
          </Button>
        </div>
      </div>
    </Popover>
  );
};

export default memo(IndustryFilterPopover);
