import React, { useState } from "react";
import Popup from "./components/writeReviewPopup";
import WriteReview from "./components/WriteReview";
import {
  Button,
  Box,
  Snackbar,
  Typography,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import ReviewsTable from "./components/ReviewsTable";
import LoginPopup from "./auth/LoginPopup";

const ReviewPage = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [showReviewForm, setShowReviewForm] = useState(false);
  const [showSuccessSnackbar, setShowSuccessSnackbar] = useState(false);
  const [loginPopupOpen, setLoginPopupOpen] = useState(false);

  const handleCloseReviewForm = (isSubmitted) => {
    setShowReviewForm(false);
    if (isSubmitted === "success") {
      setShowSuccessSnackbar(true);
    }
  };

  const handleCloseSnackbar = () => {
    setShowSuccessSnackbar(false);
  };

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: !isMobile ? theme.spacing(2) : theme.spacing(1),
      }}
    >
      <Typography
        variant="h4"
        gutterBottom
        sx={{ fontSize: isMobile ? "1.6rem" : "2.2rem" }}
      >
        Franchisee Reviews
      </Typography>
      <Typography
        variant="subtitle1"
        sx={{
          textAlign: "center",
          maxWidth: "600px",
          mb: theme.spacing(2),
          fontStyle: "italic",
          marginBottom: 0,
          fontSize: isMobile ? 12 : "1.1rem",
        }}
      >
        Are you a current franchisee / operator? Make money by leaving a review!
      </Typography>
      <Typography
        variant="subtitle1"
        sx={{
          textAlign: "center",
          maxWidth: "600px",
          mb: theme.spacing(2),
          fontStyle: "italic",
          marginTop: 0,
          fontSize: isMobile ? 12 : "1.1rem",
        }}
      >
        Looking to become a franchisee? Schedule a call with any of these
        experts!
      </Typography>
      <LoginPopup
        open={loginPopupOpen}
        onClose={() => setLoginPopupOpen(false)}
      />
      {!showReviewForm && (
        <Button
          variant="contained"
          onClick={() => setShowReviewForm(true)}
          sx={{
            borderRadius: theme.shape.borderRadius,
            boxShadow: theme.shadows[2],
            marginBottom: theme.spacing(2),
            backgroundColor: "#0098dc",
            fontSize: isMobile ? "0.8rem" : "0.9rem",
          }}
        >
          Leave a Review
        </Button>
      )}
      {showReviewForm && !isMobile && (
        <WriteReview onClose={handleCloseReviewForm} />
      )}
      {showReviewForm && isMobile && (
        <Popup open={showReviewForm} onClose={handleCloseReviewForm} />
      )}
      <Snackbar
        open={showSuccessSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        message="Review submitted successfully!"
        sx={{
          "& .MuiSnackbarContent-root": {
            backgroundColor: theme.palette.success.main,
          },
        }}
      />

      <ReviewsTable setLoginPopupOpen={setLoginPopupOpen} />
    </Box>
  );
};

export default ReviewPage;
