import React from 'react';
import {Pagination ,Stack} from '@mui/material';
function PaginateFranchisors({ TotalFranchisors, FranchisorsPerPage, Paginate }) {
    const pageCount = Math.ceil(TotalFranchisors / FranchisorsPerPage);

    return (
        <Stack spacing={2} alignItems="center">
            <Pagination
                count={pageCount}
                onChange={(event, value) => Paginate(value)}
                color="primary"
                showFirstButton
                showLastButton
            />
        </Stack>
    );
}

export default PaginateFranchisors;
