// AutoApplyFormPopup.jsx
import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  TextField,
  DialogActions,
  Button,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Tooltip,
} from "@mui/material";

const questionTemplates = [
  { label: "First Name", type: "text", required: true },
  { label: "Last Name", type: "text", required: true },
  { label: "Email", type: "email", required: true },
  { label: "Phone Number", type: "text", required: true },
  { label: "City and State of Residence", type: "text", required: true },
  {
    label: "Open to Relocation?",
    type: "select",
    options: ["Yes", "No"],
    required: true,
    tooltip:
      "Are you open to relocating if opportunities are not available in your area?",
  },
  {
    label: "Legal Right in the US",
    type: "select",
    options: ["Yes", "No"],
    required: true,
    tooltip:
      "Do you have the legal right to remain in the US to operate a franchise?",
  },
  {
    label: "Gender",
    type: "select",
    options: ["Male", "Female", "Other", "Prefer not to answer"],
    required: true,
  },
  {
    label: "Race / Ethnicity",
    type: "select",
    options: [
      "American Indian or Native Alaska",
      "Asian",
      "Hispanic",
      "Hawaiian or Pacific Islander",
      "Caucasian",
      "2 or more races",
      "Prefer not to answer",
    ],
    required: true,
  },
  {
    label: "Owned a Business?",
    type: "select",
    options: ["Yes", "No"],
    required: true,
    tooltip: "Have you ever owned a business or franchise?",
  },
  { label: "Details if Owned", type: "text", required: false },
  {
    label: "Franchise Commitment",
    type: "select",
    options: ["Part-Time", "Full-Time"],
    required: true,
  },
  {
    label: "Investment Capacity",
    type: "select",
    options: ["$0-100k", "$100k-250k", "$250k-500k", "$500k-1M", "$1M+"],
    required: true,
  },
  {
    label: "Total Net Worth",
    type: "select",
    options: ["$0-100k", "$100k-250k", "$250k-500k", "$500k-1M", "$1M+"],
    required: true,
  },
  {
    label: "Site Ownership Interest",
    type: "select",
    options: ["Single", "Multiple"],
    required: true,
  },
  {
    label: "How many locations do you want to purchase",
    type: "text",
    required: false,
  },
  {
    label: "Ownership Model",
    type: "select",
    options: ["Sole Proprietor", "Multiple Partners"],
    required: true,
  },
  { label: "LinkedIn Profile", type: "url", required: false },
];

const AutoApplyFormPopup = ({ open, onClose, onSubmit }) => {
  const [answers, setAnswers] = useState(questionTemplates.map(() => ""));

  const handleChange = (index, value) => {
    const newAnswers = [...answers];
    newAnswers[index] = value;
    setAnswers(newAnswers);
  };

  const handleSubmit = () => {
    const allRequiredFilled = questionTemplates.every((question, index) => {
      return (
        !question.required ||
        (question.required && answers[index].trim() !== "")
      );
    });

    if (!allRequiredFilled) {
      // Alert the user if any required fields are missing
      alert("Please fill in all required fields.");
      return; // Stop the submission process
    }
    onSubmit(answers); // Adjust based on your onSubmit handler needs
    onClose();
  };

  const renderQuestionInput = (question, index) => {
    let inputComponent;

    if (question.type === "select") {
      inputComponent = (
        <FormControl
          fullWidth
          margin="dense"
          key={index}
          required={question.required}
        >
          <InputLabel>{question.label}</InputLabel>
          <Select
            value={answers[index]}
            onChange={(e) => handleChange(index, e.target.value)}
            label={question.label}
          >
            {question.options.map((option, optionIndex) => (
              <MenuItem key={optionIndex} value={option}>
                {option}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      );
    } else {
      inputComponent = (
        <TextField
          key={index}
          margin="dense"
          label={question.label}
          type={question.type}
          fullWidth
          variant="outlined"
          value={answers[index]}
          onChange={(e) => handleChange(index, e.target.value)}
          required={question.required}
        />
      );
    }

    return question.tooltip ? (
      <Tooltip key={index} title={question.tooltip} placement="top">
        <div>{inputComponent}</div>{" "}
        {/* Wrapping in div as Tooltip child needs to be able to hold a ref */}
      </Tooltip>
    ) : (
      inputComponent
    );
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Auto Apply Form</DialogTitle>
      <DialogContentText align="center">
        In order to auto apply, please fill out the following
      </DialogContentText>

      <DialogContent>
        {questionTemplates.map((question, index) =>
          renderQuestionInput(question, index)
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button
          onClick={handleSubmit}
          color="primary"
          variant="contained"
          style={{ backgroundColor: "#0194d8" }}
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AutoApplyFormPopup;
