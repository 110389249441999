import React from "react";
import WriteReview from "./WriteReview";
import { Dialog, DialogContent } from "@mui/material";

const Popup = ({ open, onClose }) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogContent>
        <WriteReview onClose={onClose} />
      </DialogContent>
    </Dialog>
  );
};

export default Popup;
